import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button
} from '@chakra-ui/react'

import { useForm } from 'react-hook-form'
import { CheckboxController } from 'components/Form/CheckboxController'
import { useCreateIOBillingCode } from 'features/Settings/api/createIOBillingCode'

const LabelInput = ({ register, name }) => {
  return (
    <FormControl mb='1rem'>
      <FormLabel color='resolveOrange'>Label</FormLabel>
      <Input {...register(name, { required: true })} />
    </FormControl>
  )
}

const FormHeader = ({ name }) => {
  return (
    <Flex flexFlow='row' mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        Add Internal Billing Code
      </Text>
    </Flex>
  )
}

const IOBCState = {
  label: '',
  enabled: 0
}

export const AddIOBillingCodeForm = () => {
  const { register, handleSubmit, control } = useForm({ defaultValues: IOBCState })

  const createBillingCode = useCreateIOBillingCode()
  const onSubmit = form => {
    createBillingCode.mutate({ billingCode: form })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader />
      <LabelInput register={register} name='label' />
      <Flex flexFlow='column' mb='2rem'>
        <CheckboxController control={control} name='enabled' text='Usable in Quotes' />
      </Flex>
      <Button color='white' bgColor='resolveOrange' type='submit'>
        Add
      </Button>
    </form>
  )
}
