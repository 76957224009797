import { Flex } from '@chakra-ui/react'

import { QuoteNumber } from './QuoteNumber'
import { CompanyName } from './CompanyName'
import { DateAdded } from './DateAdded'
import { Engineer } from './Engineer'
import { Total } from './Total'
import { StatusBadge } from './StatusBadge'

export const ListItem = ({ quote, isSelected, handleQuoteClick }) => {
  return (
    <Flex
      onClick={handleQuoteClick}
      id={quote.quote_id}
      style={isSelected ? { backgroundColor: '#F26B21', color: 'white' } : null}
      height='4rem'
      alignItems='center'
      _hover={{
        boxShadow: 'rgba(0, 0, 0, .1) 0 0 0 1000000px inset',
        cursor: 'pointer'
      }}
    >
      <Flex justifyContent='center' ml='1rem' flexBasis='10%'>
        <QuoteNumber quoteId={quote.quote_id} isSelected={isSelected} />
      </Flex>
      <Flex
        justifyContent='flex-start'
        ml='1rem'
        flexBasis='40%'
        minW='40%'
        maxW='40%'
      >
        <CompanyName company={quote.company_name} />
      </Flex>
      <Flex justifyContent='flex-start' flexBasis='12%'>
        <DateAdded date={quote.date_added} />
      </Flex>
      <Flex
        justifyContent='flex-start'
        flexBasis='9%'
        minW='9%'
        ml='1rem'
        maxW='9%'
      >
        <Engineer engineer={quote.ad_username} blob={quote.picture_blob} />
      </Flex>

      <Flex mr='1rem' minW='10%'>
        <Total total={quote.total} />
      </Flex>

      <Flex flexBasis='12%' minW='12%' mr='1rem'>
        <StatusBadge status={quote.description} />
      </Flex>
    </Flex>
  )
}
