import { Link as ReactLink } from 'react-router-dom'

import { Flex, Heading, VStack, Link } from '@chakra-ui/react'
import { useContext, lazy, Suspense } from 'react'
import { UserContext } from 'features/Auth/context/user.context'
import { Loading } from 'components/Loading'

const Sales = lazy(() => import('../Sales'))

export const NoSelectedQuote = () => {
  const { engineer } = useContext(UserContext)
  return (
    <Flex h='100vh' flexDir='column'>
      <Heading mb='1rem' color='resolveOrange'>
        Quotes
      </Heading>
      <VStack alignItems='flex-start' mb='1rem'>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/quotes/add'
        >
          New Quote
        </Link>
      </VStack>

      {engineer.quotes.team_id === 4 && (
        <Suspense fallback={<Loading />}>
          <Sales engineerId={engineer.quotes.id}/>
        </Suspense>
      )}
    </Flex>
  )
}
