import { Link as ReactLink } from 'react-router-dom'
import { Flex, Link, Image } from '@chakra-ui/react'

import { BASE_URL } from 'config'

export const BrandLogo = props => {
  return (
    <Flex {...props}>
      <Link as={ReactLink} to='/'>
        <Image src={`${BASE_URL}/logo.png`} w='98px' h='34px' />
      </Link>
    </Flex>
  )
}
