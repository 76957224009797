import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

const createSubType = async ({ form }) => {
  await axiosClient({
    url: '/settings/sub-types',
    method: 'post',
    data: form
  })
}

export const useCreateSubType = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const history = useNavigate()
  return useMutation(createSubType, {
    onSuccess: () => {
      toastSuccess('Sub type successfully updated')
      history('/settings/sub-types')
    },
    onError: () => {
      toastFail('Failed to update sub type')
    }
  })
}