import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

const updateNotes = async ({ subId, notes }) => {
  await axiosClient({
    url: `/subs/notes/${subId}`,
    method: 'post', 
    data: { notes }
  })
}

export const useUpdateSubNotes = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateNotes, {
    onSuccess: () => {
      toastSuccess('Sub note updated successfully')
      queryClient.invalidateQueries(['sub-item'])
    },
    onError: () => {
      toastFail('Failed to update Sub note')
    }
  })
}