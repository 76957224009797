import { RepeatIcon } from '@chakra-ui/icons'
import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react'
import { useCallback } from 'react'

import { Controller, useWatch } from 'react-hook-form'

export const ItemClientCostInput = ({
  control,
  required,
  errors,
  name,
  isDisabled,
  labelText = 'Client Cost',
  setValue,
  ...props
}) => {
  const watchMarkUp = useWatch({ control, name: 'mark_up_percentage', defaultValue: 45 })
  const watchUnitPrice = useWatch({ control, name: 'unit_price', defaultValue: 0 })

  const recalcClientCost = useCallback(() => {
    const newClientCost = watchUnitPrice * ((watchMarkUp / 100) + 1)
    console.log('up: ', watchUnitPrice)
    console.log('mu: ', watchMarkUp)
    setValue('client_cost', newClientCost.toFixed(2))
  }, [setValue, watchUnitPrice, watchMarkUp])

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required,
        min: 0
      }}
      render={({ field: { ref, ...restField } }) => {
        return (
          <FormControl
            isDisabled={isDisabled}
            isInvalid={!!errors[name]}
            isRequired={required}
            { ...props }
          >
            <FormLabel>{labelText}</FormLabel>
            <Flex>
              <NumberInput flexGrow={1} min={0} step={0.01} defaultValue={0.0} {...restField}>
                <NumberInputField ref={ref} name={restField.name} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {
                setValue && (
                  <IconButton
                    ml='1rem'
                    mr='1rem'
                    variant='ghost'
                    fontSize='15px'
                    isDisabled={isDisabled}
                    aria-label='Update Client Cost'
                    fontWeight='extrabold'
                    onClick={recalcClientCost}
                    icon={<Icon color='resolveOrange' as={RepeatIcon} />}
                  />
                )
              }
            </Flex>
          </FormControl>
        )
      }}
    />
  )
}
