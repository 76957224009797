import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

const createTemplate = async ({quoteId, engineerId, description }) => {
  return await axiosClient({
    url: '/quotes/templates/add',
    method: 'post',
    data: { engineerId, quoteId, description }
  })
}

export const useCreateTemplateFromQuote = () => {
  const history = useNavigate()
  const queryClient = useQueryClient()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(createTemplate, {
    onSuccess: ({ data }) => {
      toastSuccess('Quote converted to template successfully')
      history(`/templates/${data.template}`)
      queryClient.invalidateQueries('quote-templates')
    },
    onError: () => {
      toastFail('Failed to create quote template')
    }
  })
}