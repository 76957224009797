import { useForm } from 'react-hook-form'
import { Button } from '@chakra-ui/react'
import { Header } from '../Header'
import { PopoverButton } from 'components/PopoverButton'
import { TeamNameInput } from 'components/Form/TeamNameInput'
import { useDeleteTeam } from 'features/Settings/api/deleteTeam'
import { useUpdateTeam } from 'features/Settings/api/updateTeam'
import { useGetTeam } from 'features/Settings/api/getTeam'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'

const TeamState = {
  name: ''
}

export const EditTeamForm = ({
  currentName,
  teamId,
  selectRef,
  setTeam,
  ...restProps
}) => {
  const { register, reset, handleSubmit } = useForm({
    defaultValues: TeamState
  })

  const updateTeam = useUpdateTeam()
  const handleTeamUpdate = async (form) => {
    await updateTeam.mutateAsync({ form, teamId })
    selectRef.current.clearValue()
  } 

  const deleteTeam = useDeleteTeam()
  const handleDeleteTeam = async () => {
    await deleteTeam.mutateAsync({ teamId })
    selectRef.current.clearValue()
  }

  const onSuccess = team => {
    reset(team)
  }
  const team = useGetTeam({ teamId, onSuccess })
  
  if(team.isLoading) return <Loading />
  if(team.error) return <SomethingBroke message='Failed to get Team' error={team.error.message} />
  return (
    <form onSubmit={handleSubmit(handleTeamUpdate)} {...restProps}>
      <Header name={team.data.name} />
      <TeamNameInput register={register} team={team} name='name' />
      <Button type='submit' color='white' backgroundColor='resolveOrange'>
        Save
      </Button>
      <PopoverButton
        title='Delete Team'
        message='Are you sure you want to delete this team?'
        handleClick={handleDeleteTeam}
        buttonTitle='Delete'
        color='resolveOrange'
        ml='1rem'
        backgroundColor='white'
        isLoading={deleteTeam.isLoading}
      />
    </form>
  )
}
