import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getQuoteItems = async (context) => {
  const { signal, queryKey } = context
  const [_, quoteId] = queryKey
  const { data: items } = await axiosClient({
    url: `/quotes/items/${quoteId}`,
    signal
  })
  return items
}
export const useGetQuoteItems = ({ quoteId }) => {
  return useQuery(['quote-items', quoteId], {
    queryFn: getQuoteItems,
    placeholderData: [],
    staleTime: Infinity
  })
}