import { FormControl, Flex } from '@chakra-ui/react'

import { DatePicker } from 'components/DatePicker'

export const DateInput = ({ name, handleDayChange, date }) => (
  <Flex mr='1rem' flexBasis='40%'>
    <FormControl id={name}>
      <DatePicker
        selectedDate={date.selectedDay}
        onChange={handleDayChange}
        isClearable
      />
    </FormControl>
  </Flex>
)
