import { axiosClient } from 'lib/axiosClient'
import { useQuery } from 'react-query'

const PAGE_SIZE = 10

const getSubs = async (context) => {
  const { signal, queryKey } = context
  const [_key, page, filterString] = queryKey
  const { data: subs } = await axiosClient({
    url: `/subs?limit=${PAGE_SIZE}&page=${page}${filterString}`,
    signal
  })
  return subs
}

export const useGetSubs = ({ page, filterString }) => {
  return useQuery(['subs', page, filterString], {
    queryFn: getSubs,
    placeholderData: { resultCount: 0, subs: [] }
  })
}

