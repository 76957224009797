import { useForm } from 'react-hook-form'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Button,
  Tag,
  HStack,
  TagLabel,
  TagCloseButton
} from '@chakra-ui/react'

import { UserSelectInput } from 'components/Form/UserSelectInput'
import { useGetAssignedUsers } from 'features/Quotes/api/getAssignedUsers'
import { useAssignUsers } from 'features/Quotes/api/assignUsers'
import { useUnassignUser } from 'features/Quotes/api/unassignUsers'

const initialState = {
  engineers: []
}

const userNotAlreadyAssigned = (engineerList, engineers) => {
  let isValid = true
  const ids = engineerList.map(e => (e.value)) 
  engineers.forEach(engineer => {
    if(ids.includes(engineer.value)) { 
      isValid = false
  }})
  if(engineers.length < 1) return true
  return  isValid ? true : 'User already assigned'
}

export const AssignItemModal = ({ isOpen, itemId, onClose }) => {
  const assigned = useGetAssignedUsers({ itemId })
  const assignUsers = useAssignUsers()
  const unassignUser = useUnassignUser()

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: initialState
  })
  const handleAssignSubmit = ({ engineers }) => {
    assignUsers.mutate({ engineers, itemId })
    setValue('engineers', [])
  }



  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color='resolveOrange'>Assign Item</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color='resolveOrange' mb='1rem'>Assigned</Text>
          {
            assigned.data && assigned.data.length > 0 && (
              <HStack mb='1rem'>
                {
                  assigned.data.map(itm => (
                    <Tag size='lg' bgColor='resolveOrange' color='white' id={itm.value} key={itm.value}>
                      <TagLabel>
                        {itm.label}
                      </TagLabel>
                      <TagCloseButton onClick={() => unassignUser.mutate({ userId: itm.value, itemId })} />
                    </Tag>
                  ))
                }
              </HStack>
            )
          }
          <form onSubmit={handleSubmit(handleAssignSubmit)}>
            <UserSelectInput
              isClearable
              name='engineers'
              control={control}
              labelText='Assign Users'
              errors={errors}
              placeholder='User'
              isMulti
              validation={vals => userNotAlreadyAssigned(assigned.data, vals)}
            />
            <Button
              color='white'
              backgroundColor='resolveOrange'
              mt='1rem'
              mb='1rem'
              type='submit'
            >
              Assign
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
