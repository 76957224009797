import { useRef, useState } from 'react'
import {
  Link as ReactLink,
  Routes as Switch,
  Route
} from 'react-router-dom'
import { Text, Flex, Spacer, Button } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { EditTeamForm } from './Edit'
import { AddTeamForm } from './Add'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Loading } from 'components/Loading'
import { useGetTeams } from 'features/Settings/api/getTeams'

export const TeamsSettingsContainer = () => {
  return (
    <Switch>
      <Route path='add' element={<AddTeamForm />} />
      <Route path='/' element={<TeamsSettings />} />
    </Switch>
  )
}

const TeamSelectList = ({ teams, selectRef, onChange }) => {
  return (
    <Select
      ref={selectRef}
      selectedOptionColor='resolveOranger'
      onChange={onChange}
      isClearable
      options={teams}
    />
  )
}

export const TeamsSettings = () => {
  const [team, setTeam] = useState(null)
  const selectRef = useRef()

  const onTeamChange = e => {
    setTeam(e)
  }

  const teams = useGetTeams()

  if(teams.isLoading) return <Loading />
  if(teams.error) return <SomethingBroke message='Failed to fetch Teams' error={teams.error.message} />
  return (
    <Flex flexFlow='column'>
      <Flex alignItems='center' mb='2rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Teams
        </Text>
        <Spacer />
        <Button
          as={ReactLink}
          backgroundColor='resolveOrange'
          size='sm'
          color='white'
          to='/settings/teams/add'
        >
          Add Team
        </Button>
      </Flex>

      <TeamSelectList
        onChange={onTeamChange}
        teams={teams.data}
        selectRef={selectRef}
      />

      {team && (
        <EditTeamForm
          mt='1rem'
          teamId={team.value}
          setTeam={setTeam}
          selectRef={selectRef} 
          currentName={undefined}
        />
      )}
    </Flex>
  )
}
