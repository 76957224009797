import { Flex, Text } from '@chakra-ui/react'

const getQuoteItemBackgroundColor = status => {
  switch (status) {
    case 'Ordered': {
      return 'resolveBlue'
    }
    case 'Quoted': {
      return 'resolveOrange'
    }
    case 'Lost': {
      return 'resolveRed'
    }
    case 'Invoiced': {
      return 'resolvePurple'
    }
    case 'Received': {
      return 'resolveGreen'
    }
    default: {
      return ''
    }
  }
}

export const Status = ({ status }) => (
  <Flex
    basis='5%'
    alignItems='center'
    justifyContent='center'
    pointerEvents='none'
    ml='1rem'
    mr='1rem'
  >
    <Text
      fontWeight='bold'
      pointerEvents='none'
      color={getQuoteItemBackgroundColor(status)}
      textAlign='center'
    >
      {status}
    </Text>
  </Flex>
)
