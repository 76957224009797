import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation, useQueryClient } from "react-query";
import { getUserPhoto } from "utils/GetGraphUserPicture";

class AzureADProfilePictureNotFound extends Error {
  constructor (message) {
    super(message)
    this.name = 'AzureADProfilePictureNotFound'
  }
}

const updateProfilePicture = async ({ userId, emailAddress }) => {
  const pictureBlobBase64 = await getUserPhoto(emailAddress)
  const profileNotFound = pictureBlobBase64.includes('application/json')
  if (profileNotFound) {
    return Promise.reject(new AzureADProfilePictureNotFound('Profile picture not found'))
  } else {
    await axiosClient({
      url: `/settings/users/${userId}`,
      method: 'patch',
      data: { picture_blob: pictureBlobBase64 }
    })
  }
}

export const useUpdateProfilePicture = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateProfilePicture, {
    onSuccess: () => {
      toastSuccess('Profile picture updated successfully')
      queryClient.invalidateQueries({ queryKey: ['settings-user']})
    },
    onError: error => {
      toastFail('Failed to refresh picture')
    }
  }) 
}
