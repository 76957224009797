import { Loading } from 'components/Loading';
import {
    Checkbox,
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Textarea,
    ButtonGroup,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { StepperActions } from './StepperActions';
import { useUpdateItemOrderDetails } from './api/updateItemOrderDetails';
import { UserContext } from 'features/Auth/context/user.context';
import { useGetItemOrderDetails } from './api/getItemOrderDetails';
import { BuildTierSelectInput } from 'components/Form/BuildTierSelectInput';
import { StepContext } from './context/stepper.context';

const TicketDescriptionInput = ({
    name,
    required = false,
    showLabel = true,
    rules = {},
    ...props
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <FormControl
            isInvalid={!!errors[name]}
            isRequired={required}
            {...props}
        >
            {showLabel && <FormLabel>Ticket Description</FormLabel>}
            <Textarea
                {...register(name, { required, ...rules })}
                id="ticket_description"
            />
            <FormErrorMessage>
                {errors[name] && errors[name].message}
            </FormErrorMessage>
        </FormControl>
    );
};

const NotesInput = ({ name, required = false }) => {
    const { register } = useFormContext();
    return (
        <FormControl mt="1rem">
            <FormHelperText>
                Add any additional notes or instructions
            </FormHelperText>
            <Textarea {...register(name, { required })} id="notes" />
        </FormControl>
    );
};

const NewUserInput = ({ name, required = false }) => {
    const {
        control,
        register,
        watch,
        formState: { errors },
    } = useFormContext();

    const newUser = watch('new_user');

    return (
        <FormControl mt="1rem" isInvalid={!!errors['new_user_details']}>
            <Controller
                control={control}
                name="new_user"
                render={({ field: { value, ref, ...restField } }) => (
                    <Checkbox
                        mt="1rem"
                        isChecked={value}
                        ref={ref}
                        {...restField}
                    >
                        New User
                    </Checkbox>
                )}
            />

            {newUser && (
                <>
                    <FormHelperText>
                        List the Licenses/Services that should be included in
                        the setup
                    </FormHelperText>
                    <Textarea
                        {...register('new_user_details', { required: false })}
                        id="new_user_details"
                    />
                </>
            )}
        </FormControl>
    );
};

const ServicesDetailInput = ({
    name,
    required = true,
    selectedOptionColor = 'resOrange',
    ...props
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <FormControl
            isInvalid={!!errors[name]}
            isRequired={required}
            {...props}
            mt="1rem"
        >
            <FormLabel mb={0}>Hardware, Software & Services</FormLabel>
            <FormHelperText mt={0}>
                List all Hardware, Software, and Services the client has agreed
                to
            </FormHelperText>
            <Textarea {...register(name, { required: true })} id="services" />
            <FormErrorMessage>
                {errors[name] && errors[name].message}
            </FormErrorMessage>
        </FormControl>
    );
};

const EstimatedHoursInput = ({
    name,
    required = true,
    selectedOptionColor = 'resOrange',
    ...props
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            rules={{
                required,
                min: { value: 0, message: 'Must include estimated hours' },
            }}
            render={({ field: { ref, ...restField } }) => {
                return (
                    <FormControl
                        isInvalid={!!errors[name]}
                        isRequired={required}
                        {...props}
                    >
                        <FormLabel>Estimated Hours</FormLabel>
                        <NumberInput
                            flexGrow={1}
                            min={0}
                            step={1}
                            defaultValue={0}
                            {...restField}
                        >
                            <NumberInputField
                                flexGrow={1}
                                ref={ref}
                                name={restField.name}
                            />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage>
                            {errors[name] && errors[name].message}
                        </FormErrorMessage>
                    </FormControl>
                );
            }}
        />
    );
};

const DeliveryAddressInput = ({
    name,
    required,
    showLabel = true,
    rules,
    ...props
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <FormControl
            isInvalid={!!errors[name]}
            isRequired={required}
            {...props}
        >
            {showLabel && <FormLabel>Hardware Delivery Address</FormLabel>}
            <Textarea {...register(name, { required, ...rules })} id="name" />
            <FormErrorMessage>
                {errors[name] && errors[name].message}
            </FormErrorMessage>
        </FormControl>
    );
};

export const OrderDetailsForm = () => {
    const { itemId } = useParams();
    const { engineer } = useContext(UserContext);

    const { nextStep } = useContext(StepContext);
    const orderForm = useFormContext();

    const orderDetails = useGetItemOrderDetails({
        itemId,
        onSuccess: data => {
            console.log(data);
            orderForm.setValue(
                'at_client_contact_id',
                data.at_client_contact_id
            );
            orderForm.setValue('ticket_description', data.ticket_description);
            orderForm.setValue('delivery_address', data.delivery_address);
            orderForm.setValue('device_build_tier', data.device_build_tier);
            orderForm.setValue('estimated_hours', data.estimated_hours);
            orderForm.setValue('follow_up', data.follow_up);
            orderForm.setValue('item_id', data.item_id);
            orderForm.setValue('new_user', data.new_user);
            orderForm.setValue('new_user_details', data.new_user_details);
            orderForm.setValue('notes', data.notes);
            orderForm.setValue('services', data.services);
        },
    });

    const followUp = orderForm.watch('follow_up');
    const updateItemOrderDetails = useUpdateItemOrderDetails();

    const handleSave = async form => {
        if (!orderForm.trigger()) {
            return;
        }

        await updateItemOrderDetails.mutateAsync({
            itemId,
            form,
            engineerId: engineer.quotes.id,
        });

        orderForm.reset({}, { keepValues: true });

        nextStep();
    };

    if (orderDetails.isLoading) return <Loading />;

    return (
        <form onSubmit={orderForm.handleSubmit(handleSave)}>
            <TicketDescriptionInput
                name="ticket_description"
                required
                showLabel
            />

            <DeliveryAddressInput
                name="delivery_address"
                rules={{}}
                required={false}
                showLabel
                mt="1rem"
                mb="1rem"
            />

            <FormControl>
                <Controller
                    control={orderForm.control}
                    name="follow_up"
                    render={({ field: { value, ref, ...restField } }) => (
                        <Checkbox
                            mt="1rem"
                            isChecked={value}
                            ref={ref}
                            {...restField}
                        >
                            Further work required?
                        </Checkbox>
                    )}
                />
            </FormControl>
            {followUp && (
                <>
                    <EstimatedHoursInput name="estimated_hours" />
                    <NewUserInput name="new_user" />
                    <BuildTierSelectInput
                        mt="1rem"
                        control={orderForm.control}
                        errors={orderForm.formState.errors}
                        name="device_build_tier"
                    />
                    <ServicesDetailInput name="services" />
                    <NotesInput name="notes" />
                </>
            )}

            <ButtonGroup mt="1rem">
                <Button
                    color="white"
                    colorScheme="resolveOranger"
                    type="submit"
                >
                    Save
                </Button>
            </ButtonGroup>

            <StepperActions />
        </form>
    );
};
