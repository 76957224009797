import { useQuery } from "react-query"
import { axiosClient } from "lib/axiosClient"

const getBillingCode = async (context) => {
  const { queryKey, signal } = context
  const [_key, billingCodeId] = queryKey
  const { data: { code } } = await axiosClient({
    url: `/settings/billing-codes/${billingCodeId}`,
    signal
  })
  return {
    ...code,
    active: code.active,
    enabled: code.enabled
  }
}

export const useGetBillingCode = ({ billingCodeId, onSuccess }) => {
  return useQuery(['settings-billing-code', billingCodeId], {
    queryFn: getBillingCode,
    onSuccess,
    placeholderData: []
  })
}