import { Button, Flex, Text } from '@chakra-ui/react'

import { ClientPOInput } from 'components/Form/ClientPOInput'
import { ItemClientCostInput } from 'components/Form/ItemClientCostInput'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput'
import { ItemQuantityInput } from 'components/Form/ItemQuantityInput'
import { PaymentMethodSelectInput } from 'components/Form/PaymentMethodSelectInput'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput'
import { BillingCodeSelect } from 'components/Form/BillingCodeSelect'
import { AuthSelectInput } from 'components/Form/AuthSelectInput'

export const Form = ({
  formSubmit,
  handleSubmit,
  control,
  register,
  errors
}) => {
  return (
    <form onSubmit={formSubmit(handleSubmit)}>
      <Text color='resolveBlue' mr='1rem' fontSize='2rem' fontWeight='600'>
        Add Item
      </Text>
      <SupplierSelectInput
        mt='2rem'
        control={control}
        ignoreOtherSupplier={false}
        name='supplier_id'
        errors={errors}
        selectedOptionColor='resolveBluer'
        register={register}
        required
      />

      <ItemDescriptionInput
        register={register}
        errors={errors}
        showLabel
        name='item_name'
        rules={{ maxLength: { value: 245, message: 'Description must be under 90 characters'}}}
        mb='1rem'
        required
      />

      <ItemPartCodeInput
        register={register}
        errors={errors}
        showLabel
        mt='1rem'
        flexBasis='30%'
        name='part_code'
      />

      <Flex dir='row' mb='1rem' mt='1rem'>
        <ItemQuantityInput
          name='quantity'
          errors={errors}
          control={control}
          mr='1rem'
          required
        />
        <ItemClientCostInput
          setValue={null}
          name='cost_price'
          errors={errors}
          required
          control={control}
          mr='1rem'
          labelText='Cost Price'
        />
        <ItemDeliveryPriceInput
          name='delivery_price'
          required
          errors={errors}
          control={control}
        />
      </Flex>

      <Flex>
        <PaymentMethodSelectInput
          name='payment_method_id'
          control={control}
          errors={errors}
          required
          mr='1rem'
          selectedOptionColor='resolveBluer'
        />
        <BillingCodeSelect
          name='at_billing_code_id'
          control={control}
          errors={errors}
          required
          selectedOptionColor='resolveBluer'
          labelText='Billing Code'
        />
      </Flex>

      <ClientPOInput
        register={register}
        required={false}
        name='supplier_order_number'
        labelText='Supplier Order Number'
      />

      <AuthSelectInput
        control={control}
        errors={errors}
        name='authorized_by_id'
        labelText='Authorised By'
        mb='1rem'
        mt='1rem'
        required
      />

      <Button
        variant='solid'
        backgroundColor='resolveBlue'
        color='white'
        _hover={{ bg: 'gray', color: 'resolveBlue' }}
        type='submit'
        mt='1rem'
      >
        Add
      </Button>
    </form>
  )
}
