import { Flex, SlideFade } from '@chakra-ui/react'

export const ItemListLayout = ({ children }) => {
  return (
    <Flex
      direction='column'
      mb='1rem'
      maxHeight='600px'
      overflowY='auto'
      css={{
        '&::-webkit-scrollbar': {
          width: '4px'
        },
        '&::-webkit-scrollbar-track': {
          width: '4px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'lightGrey',
          borderRadius: '4px'
        }
      }}
    >
      <SlideFade in>{children}</SlideFade>
    </Flex>
  )
}
