import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'

export const ItemDescriptionInput = ({
  name,
  errors,
  register,
  required,
  showLabel = true,
  rules,
  ...props
}) => {
  return (
    <FormControl isInvalid={!!errors[name]} isRequired={required} {...props}>
      {showLabel && <FormLabel>Description</FormLabel>}
      <Input {...register(name, { required, ...rules })} id='name' />
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  )
}
