import { Flex, Checkbox } from '@chakra-ui/react'

export const BulkSelect = ({ handleItemBulkSelect, id, selectedBulkItems }) => {
  return (
    <Flex justifyContent='center' ml='1rem' mr='1rem'>
      <Checkbox
        colorScheme='resolveOranger'
        onChange={handleItemBulkSelect}
        id={id}
        isChecked={selectedBulkItems.includes(id)}
      />
    </Flex>
  )
}
