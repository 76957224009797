import { useEffect } from 'react'
import { useSearchQuotes } from '../api/searchQuotes'
import { useSearchQuotesResultCount } from "../api/searchQuotesCount"

export const useQuoteSearch = ({ searchState, page }) => {
  const resultsCount = useSearchQuotesResultCount({ searchState })
  const search = useSearchQuotes({ searchState, page, countComplete: resultsCount.data.complete })

  return {
    resultsCount,
    search
  }
}