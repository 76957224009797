import { useEffect } from 'react'
import { Divider, Flex } from '@chakra-ui/react'
import { LeftAside } from '../../components/Layout/LeftAside'
import { RightAside } from '../../components/Layout/RightAside'
import { Left, Right } from './routes'

export const Quotes = () => {  
  useEffect(() => {
    if (document.title !== 'Resolve Quotes') {
      document.title = 'Resolve Quotes'
    }
  })

  return (
    <Flex justifyContent='center' height='100%'>
      <LeftAside>
        <Left />
      </LeftAside>
      <Divider
        height='100vh'
        variant='dashed'
        orientation='vertical'
        size='1px'
      />
      <RightAside>
        <Right />
      </RightAside>
    </Flex>
  )
}
