import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getQuoteItemCharge = async (context) => {
  const { queryKey } = context
  const [_, itemId] = queryKey
  const { data } = await axiosClient({
    url: `/quotes/item/charge/${itemId}`,
    method: 'GET'
  })
  return data.at_cost_id
}

export const useGetQuoteItemCharge = ({ itemId, onSuccess }) => {
  return useQuery(['quote-item-charge', itemId], {
    queryFn: getQuoteItemCharge,
    onSuccess
 })
}


