import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Flex,
  Button,
  PopoverTrigger,
  Popover,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverFooter,
  PopoverCloseButton
} from '@chakra-ui/react'

import { UserContext } from 'features/Auth/context/user.context'

export const Actions = ({ 
  templateId,
  deleteTemplate
}) => {
  const history = useNavigate()
  const { engineer } = useContext(UserContext)

  const handleAddItem = () => {
    history(`/templates/${templateId}/add`)
  }

  return (
    <Flex
      flexFlow='column'
      flexWrap='wrap'
      ml='1rem'
      mb='1rem'
      mt='1rem'
      justifyContent='flex-end'
    >
      <Flex justifyContent='right'>
        <Button
          onClick={handleAddItem}
          backgroundColor='resolvePurple'
          size='sm'
          mb='1rem'
          color='white'
          ml='1rem'
        >
          Add Item
        </Button>
        {engineer.permissions.canDeleteQuotes && (
          <Popover>
            <PopoverTrigger>
              <Button size='sm' variant='outline' ml='1rem'>
                Delete Template
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody p='1rem'>
                  Are you sure? This will delete the template and all items
                </PopoverBody>
                <PopoverFooter>
                  <Flex justifyContent='center'>
                    <Button isLoading={deleteTemplate.isLoading} size='md' onClick={() => deleteTemplate.mutate({ templateId })}>
                      Delete
                    </Button>
                  </Flex>
                </PopoverFooter>
              </PopoverContent>
            </Portal>
          </Popover>
        )}
      </Flex>
    </Flex>
  )
}
