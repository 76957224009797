import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Link as ReactLink,
  Routes as Switch,
  Route
} from 'react-router-dom'
import { Text, Flex, Spacer, Button } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'

import { EditSubTypeForm } from './Edit'
import { AddSubTypeForm } from './Add'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Loading } from 'components/Loading'
import { useGetSubTypes } from 'features/Settings/api/getSubTypes'

const SubTypesSelectList = ({ subTypes, onChange, selectRef }) => {
  return (
    <Select
      selectedOptionColorScheme='resolveOranger'
      ref={selectRef}
      onChange={onChange}
      isClearable
      options={subTypes}
    />
  )
}

export const SubTypeSettingsContainer = () => {
  return (
    <Switch>
      <Route path='add' element={<AddSubTypeForm />} />
      <Route path='/' element={<SubTypesSettings />} />
    </Switch>
  )
}

export const SubTypesSettings = () => {
  const [subTypeId, setSubTypeId] = useState(null)
  const selectRef = useRef()

  const onChange = subType => {
    setSubTypeId(subType?.value)
  }

  const subTypes = useGetSubTypes()

  if(subTypes.isLoading) return <Loading />
  if(subTypes.error) return <SomethingBroke message='Failed to get sub types' error={subTypes.error.message} />
  return (
    <Flex flexFlow='column'>
      <Flex alignItems='center' mb='2rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Sub Types
        </Text>
        <Spacer />
        <Button
          as={ReactLink}
          backgroundColor='resolveOrange'
          size='sm'
          color='white'
          to='/settings/sub-types/add'
        >
          Add Sub Type
        </Button>
      </Flex>

      <SubTypesSelectList
        onChange={onChange}
        subTypes={subTypes.data}
        selectRef={selectRef}
      />

      {subTypeId && (
        <EditSubTypeForm
          selectRef={selectRef}
          subTypeId={subTypeId}
        />
      )}
    </Flex>
  )
}
