import { useForm } from 'react-hook-form'

import { FormControl, FormLabel, Input, Text, Button } from '@chakra-ui/react'
import { useCreateTeam } from 'features/Settings/api/createTeam'

const BuildTierInput = ({
  register,
  name,
  team,
}) => {
  return (
    <FormControl mb='1rem'>
      <FormLabel>Name</FormLabel>
      <Input {...register(name)} />
    </FormControl>
  )
}
export const AddBuildTier = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const createTeam = useCreateTeam()
  const handleAddBuildTier = async form => {
    createTeam.mutate({ form })
  }

  return (
    <form onSubmit={handleSubmit(handleAddBuildTier)}>
      <Text
        color='resolveOrange'
        mr='1rem'
        mb='1rem'
        fontSize='2rem'
        fontWeight='600'
      >
        Add Build Tier
      </Text>
      <BuildTierInput register={register} errors={errors} name='name' />
      <Button
        mt='1rem'
        color='white'
        type='submit'
        backgroundColor='resolveOrange'
      >
        Save
      </Button>
    </form>
  )
}
