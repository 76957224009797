import { Link as ReactLink } from 'react-router-dom'
import { Flex, Text, Link, Spacer } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

export const Header = () => {
  const { quoteId, itemId } = useParams()

  return (
    <Flex mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        Q{quoteId} - Order Details
      </Text>
      <Spacer />
      <Link
        as={ReactLink}
        alignSelf='center'
        color='resolveOrange'
        _hover={{ color: 'gray.200' }}
        to={`/quotes/${quoteId}/edit/${itemId}`}
      >
        Back
      </Link>
    </Flex>
  )
}
