import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { ControlledSelect } from 'components/Form/ControlledSelect'
import { useGetTeams } from 'components/Form/api/getTeams'

export const TeamSelectInput = ({
  required,
  control,
  errors,
  name,
  selectedOptionColor = 'resolveOranger'
}) => {

  const teams = useGetTeams()
  return (
    <FormControl
      isInvalid={!!errors[name]}
      isRequired={required}
    >
      <FormLabel>Team</FormLabel>
      <ControlledSelect
        name={name}
        control={control}
        options={teams.data}
        rules={{ required }}
        isLoading={teams.isLoading}
        selectedOptionColor={selectedOptionColor}
      />

      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  )
}
