import { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Flex, Spacer } from '@chakra-ui/react'

import { DateInput } from './DateInput'
import { ActionButtons } from './ActionButtons'
import { ClientSelectInput } from 'components/Form/ClientSelectInput'
import { UserSelectInput } from 'components/Form/UserSelectInput'
import moment from 'moment'

export const SearchFilter = ({ setPage, filter, setFilter }) => {
  const [date, setDate] = useState({
    selectedDay: undefined
  })

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: filter
  })

  const handleFilterReset = () => {
    const cleanFilter = { engineer: '', date: '', clientId: '' }
    setPage(1)
    setDate({ selectedDay: '' })
    setFilter(cleanFilter)
    reset(cleanFilter)
  }

  const handleFilterSubmit = useCallback(
    form => {
      let d
      if (date.selectedDay) {
        d = new Date(date.selectedDay)
        d.setHours(2)
      }
      const normalized = {
        ...form,
        clientId: form.clientId?.value?.toString() || '',
        engineer: form.engineer?.value?.toString() || '',
        date: date.selectedDay ? moment(d).format('YYYY-MM-DD') : ''
      }
      setFilter(normalized)
      setPage(1)
    },
    [setPage, setFilter, date]
  )

  const handleDayChange = date => {
    setDate({ selectedDay: date })
  }

  return (
    <form onSubmit={handleSubmit(handleFilterSubmit)}>
      <Flex justifyContent='space-between'>
        <DateInput
          name='date'
          handleDayChange={handleDayChange}
          date={date}
        />

        <UserSelectInput
          control={control}
          validation={true}
          required={false}
          mr='1rem'
          errors={errors}
          showLabel={false}
          name='engineer'
        />

        <ClientSelectInput
          control={control}
          name='clientId'
          mr='1rem'
          required={false}
          errors={errors}
          showLabel={false}
        />

        <Spacer />
        <ActionButtons handleFilterReset={handleFilterReset} />
      </Flex>
    </form>
  )
}
