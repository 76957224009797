import { Badge, Flex } from '@chakra-ui/react'

const getBadgeColor = statusId => {
  switch (statusId) {
    case 7:
      return 'resolvePink'
    case 4:
      return 'resolveGreen'
    default:
      return 'resolvePink'
  }
}

const getItemStatus = statusId => {
  switch (statusId) {
    case 7:
      return 'Ordered'
    case 4:
      return 'Received'
    default:
      return ''
  }
}

export const StatusBadge = ({ status }) => {
  return (
    <Flex pointerEvents='none' flexBasis='12%' minW='12%' mr='1rem'>
      <Badge
        pl='0.5rem'
        pr='0.5rem'
        textAlign='center'
        width='100%'
        bgColor={getBadgeColor(status)}
        color='gray.200'
      >
        {getItemStatus(status)}
      </Badge>
    </Flex>
  )
}
