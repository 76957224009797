import { Button, Flex, Table, Spacer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, Center } from "@chakra-ui/react"
import { usePagination, useTable } from "react-table"
import { useExportData } from "react-table-plugins"
import { currencyFormatter, getExportFileBlob, preformatFloat } from "features/Reports/utils"
import { AiFillFileExcel } from "react-icons/ai"
import { Pagination } from "features/Reports/Pagination"
import _ from 'lodash'
import { useMemo } from "react"

export const SalesTable = ({ tableData }) => {
  const columns = useMemo(() => ([
    {
      Header: 'Quote',
      accessor: 'quote_id',
      Cell: props => <span>Q{props.value}</span>,
      Footer: (
        <span>
          <strong>Total</strong>
        </span>
      )
    },
    {
      Header: 'Engineer',
      accessor: 'ad_username'
    },
    {
      Header: 'Referral',
      accessor: 'ref'
    },
    {
      Header: 'Client',
      accessor: 'company_name'
    },
    {
      Header: 'Sale Price',
      accessor: 'client_cost',
      Cell: props => <Text>£{props.value}</Text>,
      Footer: (
        <Text fontWeight='bold' color='resolveOrange'>
          {currencyFormatter.format(_.sum(
            _.map(tableData, d => parseFloat(preformatFloat(d.client_cost)))
          ).toFixed(2))}
        </Text>
      )
    },
    {
      Header: 'Profit',
      accessor: 'margin',
      Cell: props => <Text>£{props.value}</Text>,
      Footer: (
        <Text fontWeight='bold' color='resolveOrange'>
          {currencyFormatter.format(_.sum(
            _.map(tableData, d => parseFloat(preformatFloat(d.margin)))
          ).toFixed(2))}
        </Text>
      )
    }
  ]), [tableData])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    footerGroups,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    exportData,
    onPageChange,
    state: { pageIndex }
  } = useTable(
    { columns, data: tableData, getExportFileBlob },
    usePagination,
    useExportData
  )
  if(tableData.length < 1) return (<Center><Text>No items found</Text></Center>)
  return (
    <>
      <Flex justifyContent='center'>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                  ))}
                </Tr>
              )
            })}
          </Tbody>
          <Tfoot>
            {footerGroups.map(group => (
              <Tr {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  <Td {...column.getFooterProps()}>
                    {column.render('Footer')}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tfoot>
        </Table>
      </Flex>
      <Flex justifyContent='center' flexFlow='row'>
        <Spacer />
        <Pagination
          prevClick={previousPage}
          nextClick={nextPage}
          pageCount={pageCount}
          page={pageIndex}
          onPageSearch={gotoPage}
          onPageChange={onPageChange}
        />
        <Spacer />
        <Flex mt='1rem'>
          <Button
            mr='1rem'
            rightIcon={<AiFillFileExcel color='green' />}
            onClick={() => {
              exportData('csv', true)
            }}
          >
            Export
          </Button>
        </Flex>
      </Flex>
    </>
  )
}