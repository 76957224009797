import { axiosClient } from 'lib/axiosClient';
import { useQuery } from 'react-query';

const getBuildTiers = async (context) => {
  const { signal } = context
  const { data: teams } = await axiosClient({
    url: '/settings/build-tiers',
    signal
  }) 
  return teams
}

export const useGetBuildTiers = () => {
  return useQuery(['settings-build-tiers'], {
    queryFn: getBuildTiers,
    placeholderData: []
  })
}
