import { Flex, Text, Spacer } from '@chakra-ui/react'

export const Header = ({ ioId }) => (
  <Flex direction='row' alignItems='center'>
    <Spacer />
    <Text fontSize='3xl' color='resolveDarkGrey' fontWeight='800'>
      IO{ioId}
    </Text>
  </Flex>
)
