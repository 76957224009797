import { useCallback } from 'react'
import { useUpdateIOBillingCode } from 'features/Settings/api/updateIOBillingCode'
import { useForm } from 'react-hook-form'
import { useDeleteIOBillingCode } from 'features/Settings/api/deleteIOBillingCode'
import { useGetIOBillingCode } from 'features/Settings/api/getIOBillingCode'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Loading } from 'components/Loading'
import { Flex, Button, Input, Text } from '@chakra-ui/react'
import { CheckboxController } from 'components/Form/CheckboxController'
import { PopoverButton } from 'components/PopoverButton'

const IOBillingCodeState = {
  enabled: 0,
  label: ''
}

export const EditBillingCode = ({ billingCodeId, selectRef, ...props }) => {
  const { register, watch, reset, handleSubmit, control } = useForm({
    defaultValues: IOBillingCodeState
  })

  const billingCodeLabel = watch('label')
  const updateBillingCode = useUpdateIOBillingCode() 
  const handleSave = async form => {
    updateBillingCode.mutateAsync({ form })
    selectRef.current.clearValue()
  }
  const deleteBillingCode = useDeleteIOBillingCode() 

  const handleDeleteCode = useCallback(async () => {
    deleteBillingCode.mutateAsync({ billingCodeId })
    selectRef.current.clearValue()
  }, [deleteBillingCode, billingCodeId, selectRef])

  const onData = data => {
    reset(data)
  }
  const billingCode = useGetIOBillingCode({ billingCodeId, onSuccess: onData })

  if(billingCode.error) return <SomethingBroke message='Failed to get Billing Code' error={billingCode.error.message} />
  if(billingCode.isFetching || billingCode.isLoading) return <Loading />
  return (
    <form { ...props} onSubmit={handleSubmit(handleSave)}>
      <Flex flexFlow='row' mt='2rem' mb='1rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          {billingCodeLabel}
        </Text>
      </Flex>
      <Input mb='1rem' {...register('label')} />
      <Flex flexFlow='column' mb='2rem'>
        <CheckboxController control={control} name='enabled' text='Usable in Quotes' />
      </Flex>
      <Button type='submit' color='white' backgroundColor='resolveOrange' isLoading={updateBillingCode.isLoading}>
        Save
      </Button>
      <PopoverButton
        title='Delete'
        message='Are you sure you want to delete this code?'
        handleClick={handleDeleteCode}
        buttonTitle='Delete'
        ml='1rem'
        isLoading={deleteBillingCode.isLoading}
        color='resolveOrange'
        backgroundColor='white'
      />
    </form>
  )
}
