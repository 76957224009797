import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getQuoteTypes = async (context) => {
  const { signal } = context
  const { data: quoteTypes } = await axiosClient({
    url: '/settings/quote-types',
    signal
  })
  return quoteTypes
}

export const useGetQuoteTypes = () => {
  return useQuery(['form-quote-types'], {
    queryFn: getQuoteTypes,
    placeholderData: [],
    staleTime: Infinity
  })
}