import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

export const updateItem = async ({form, itemId }) => {
  const nForm = {
    ...form,
    type_id: form.type_id.value,
    supplier_id: form.supplier_id.value,
    payment_plan_id: form.payment_plan_id?.value || 0,
    minimum_term_id: form.minimum_term_id?.value || 0
  }
  await axiosClient({
    url: `/quotes/templates/item/${itemId}`,
    method: 'patch', 
    data: nForm
  })
}

export const useUpdateTemplateItem = () => {
  const queryClient = useQueryClient()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(updateItem, { 
    onSuccess: () => {
      toastSuccess('Template item updated successfully')
      queryClient.invalidateQueries('templates-items')
    },
    onError: () => {
      toastFail('Failed to update template item')
    }
  })
}