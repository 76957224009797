import { Controller } from 'react-hook-form'

import { Flex, Checkbox } from '@chakra-ui/react'

export const QuoteStatusCheckboxGroup = ({ control, handleInvoice }) => {
  return (
    <Flex justifyContent='space-between' mb='1rem' mt='1rem'>
      <Controller
        control={control}
        name={'won'}
        key={'won'}
        render={({ field: { ref, value, ...restField } }) => {
          return (
            <Checkbox
              isChecked={value}
              colorScheme='green'
              color='resolveGreen'
              ref={ref}
              {...restField}
            >
              Won
            </Checkbox>
          )
        }}
      />
      <Controller
        control={control}
        name={'checked_out'}
        key={'checked_out'}
        render={({ field: { ref, value, ...restField } }) => {
          return (
            <Checkbox
              isChecked={value}
              colorScheme='orange'
              color='resolveOrange'
              ref={ref}
              {...restField}
            >
              Checked Out
            </Checkbox>
          )
        }}
      />
      <Controller
        control={control}
        name={'invoiced'}
        key={'invoiced'}
        render={({ field: { ref, onChange, value, ...restField } }) => {
          return (
            <Checkbox
              isChecked={value}
              isReadOnly
              colorScheme='purple'
              color='resolvePurple'
              ref={ref}
              {...restField}
            >
              Invoiced
            </Checkbox>
          )
        }}
      />
      <Controller
        control={control}
        name={'approved_posted'}
        key={'approved_posted'}
        render={({ field: { ref, value, ...restField } }) => {
          return (
            <Checkbox
              isChecked={value}
              colorScheme='pink'
              color='resolvePink'
              ref={ref}
              {...restField}
            >
              Approved & Posted
            </Checkbox>
          )
        }}
      />
    </Flex>
  )
}
