export const formatDate = (date) => {
  const dateTime = date || new Date().toLocaleDateString()
  return new Date(dateTime).toLocaleDateString('en-gb', { day: 'numeric', month: 'numeric', year: '2-digit' })
}

export const formatDateTime = (dt) => {
  const dateTime = dt || new Date()
  return new Date(dateTime)
    .toISOString()
    .slice(0, 19)
    .replace('T', ' ')
}

export const getFirstOfMonthDate = () => {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 1)
}

export const formatDateMySQL = (date) => {
  const dt = typeof date === 'object' ? date : new Date(date)
  return dt.toISOString()
    .slice(0, 10)
    .replace('T', ' ')
}

export const formatDateTimeMySQL = (dt) => {
  const dateTime = dt ? new Date(dt) : new Date()
  return new Date(dateTime)
    .toISOString()
    .slice(0, 19)
    .replace('T', ' ')
}

export const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
