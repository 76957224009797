import { Route, Routes } from 'react-router'
import { Divider, Flex } from '@chakra-ui/react'
import { LeftAside } from 'components/Layout/LeftAside'
import { RightAside } from 'components/Layout/RightAside'
import { AddItem } from './components/Item/Add'
import { Container as QuoteTemplateContainer } from './components/Template/List/Container'
import { Container as QuoteTemplateItemListContainer } from './components/Item/List/Container'
import { EditTemplateItem } from './components/Item/Edit'
import { NoSelectedTemplate } from 'features/Navigation/NoSelectedTemplate'
import { AddTemplate } from './components/Template/Add'

export const Templates = () => {
  return (
    <Flex justifyContent='center' height='100%'>
        <LeftAside>
          <Routes>
            <Route
              path='add'
              element={<AddTemplate />}
            />
            <Route
              path=':templateId/add'
              element={<AddItem />}
            />
            <Route
              exact
              path=':templateId/edit/:itemId'
              element={<EditTemplateItem />}
            />
            <Route
              path=':templateId?'
              element={<QuoteTemplateContainer />}
            />
          </Routes>
        </LeftAside>
        <Divider height='100vh' variant='dashed' orientation='vertical' size='1px' />
        <RightAside>
          <Routes>
            {
              ['/', 'add'].map((path, i) => (
                <Route
                  exact
                  path={path}
                  key={i}
                  element={<NoSelectedTemplate />}
                />
              ))
            }
            <Route
              path=':templateId'
              element={<QuoteTemplateItemListContainer />}
            />
            {
              [':templateId/edit/:itemId', ':templateId', ':templateId/add'].map((path, i) => (
                <Route
                  exact
                  path={path}
                  key={i}
                  element={<QuoteTemplateItemListContainer />}
                />
              )) 
            }
          </Routes>
        </RightAside>
    </Flex>
  )
}
