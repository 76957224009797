import { useCallback, useState } from 'react'
import { Flex, Box, Button, Select } from '@chakra-ui/react'

const options = [
  {
    value: 'won',
    label: 'Mark as Won'
  },
  {
    value: 'lost',
    label: 'Mark as Lost'
  },
  {
    value: 'ap',
    label: 'Mark as A&P'
  },
  {
    value: 'invoiced',
    label: 'Mark as Invoiced'
  },
  {
    value: 'ordered',
    label: 'Mark as Ordered'
  },
  {
    value: 'hide',
    label: 'Hide from Print'
  },
  {
    value: 'received',
    label: 'Mark as Received'
  }
]

export const BulkActions = ({ handleBulkAction }) => {
  const [selectedBulkAction, setselectedBulkAction] = useState(null)

  const handleBulkSelect = useCallback(() => {
    handleBulkAction(selectedBulkAction)
  }, [selectedBulkAction, handleBulkAction])

  return (
    <Box>
      <Flex alignItems='center'>
        <Select
          onChange={e => setselectedBulkAction(e.target.value)}
          placeholder='Select..'
        >
          {options.map((o, i) => (
            <option key={i} value={o.value}>
              {o.label}
            </option>
          ))}
        </Select>
        <Flex ml='1rem' alignItems='center'>
          <Button
            variant='outline'
            bgColor='resolveBlue'
            size='sm'
            onClick={handleBulkSelect}
            color='white'
          >
            Go
          </Button>
        </Flex>
      </Flex>
    </Box>
  )
}
