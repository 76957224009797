import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getPrintData = async quoteId => {
  const { data: print } = await axiosClient({ url: `/quotes/print/${quoteId}` })
  return print
}

export const useGetPrintData = ({ quoteId, onSuccess }) => {
  return useQuery(['quote-print', quoteId], () => getPrintData(quoteId), { onSuccess, refetchOnWindowFocus: false, refetchInterval: 0 })
}