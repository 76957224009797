import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import moment from "moment"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

export const updateItemInvoiceStatus = async ({ invoice, itemId, date, engineerId }) => {
  const normalizedInvoice = {
    ...invoice,
    ...(invoice.invoiced) && { invoiced: 1, invoiced_by: invoice.invoiced_by ? invoice.invoiced_by : engineerId },
    ...(!invoice.invoiced) && { invoiced: 0 },
    invoice_date: moment(date.selectedDay).format('YYYY-MM-DD HH:mm:ss')
  }
  await axiosClient({
    url: `/quotes/invoice/${itemId}`,
    method: 'post',
    data: normalizedInvoice
  })
}

export const useUpdateItemInvoiceStatus = ({quoteId, itemId}) => {
  const queryClient = useQueryClient()
  const history = useNavigate()
  const { toastSuccess, toastFail } = useResolveToast()

  return useMutation(updateItemInvoiceStatus, {
    onSuccess: () => {
      toastSuccess('Invoicing updated successfully')
      queryClient.invalidateQueries('item')
      history(`/quotes/${quoteId}/edit/${itemId}`)
    },
    onError: () => {
      toastFail('Failed to update invoicing')
    }
  })
}