import { Flex, Text } from '@chakra-ui/react'

export const LicenseCount = ({ licenseCount }) => (
  <Flex
    pointerEvents='none'
    alignItems='flex-start'
    justifyContent='center'
    minWidth='5%'
    flexBasis='5%'
  >
    <Text
      textAlign='center'
      overflow='hidden'
      whiteSpace='nowrap'
      textOverflow='ellipsis'
    >
      {licenseCount}
    </Text>
  </Flex>
)
