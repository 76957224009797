import { useNavigate, useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import { useDisclosure } from '@chakra-ui/react'
import { Form } from './Form'
import { Loading } from 'components/Loading'
import { AssignItemModal } from './Modal/AssignItemModal'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useUpdateItem } from 'features/Internal/api/updateItem'
import { useGetItem } from 'features/Internal/api/getItem'

const internalOrderState = {
  item_id: 0,
  supplier: { label: '', value: 0 },
  quantity: 0,
  cost_price: '0.00',
  part_code: '',
  io_item_ref: '',
  delivery_price: '0.00',
  authorized_by_id: 0,
  item_status_id: 7,
  other_supplier: '',
  invoiced: null,
  item_name: '',
  total: '£0.00',
  supplier_id: ''
}

export const EditIO = () => {
  const { ioId, itemId } = useParams()

  const history = useNavigate()
  const {
    isOpen: assignModalIsOpen,
    onOpen: assignModalOnOpen,
    onClose: assignModalOnClose
  } = useDisclosure()

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting },
    reset
  } = useForm({
    defaultValues: internalOrderState
  })

  const watchItemStatus = watch('item_status_id')

  const item = useGetItem({ itemId, onSuccess: item => {
    reset({...item})
  }})
  
  const updateItem = useUpdateItem()

  const handleItemStatusChange = e => {
    const newStatusId = Number(e.target.id)
    setValue('item_status_id', newStatusId)
  }

  const handleInvoice = e => {
    if (e.target.checked || e.target.innerText === 'Invoice Details') {
      history(`/internal/${ioId}/edit/${itemId}/invoice`)
    }
  }

  if (item.isFetching) return <Loading color='resolveBlue' />
  if(item.error) return <SomethingBroke message='Failed to fetch item' error={item.error.message} />
  return (
    <>
      {
        assignModalIsOpen && (
          <AssignItemModal
            isOpen={assignModalIsOpen}
            onClose={assignModalOnClose}
            itemId={itemId}
          />
        )
      }

      <Form
        formSubmit={handleSubmit}
        onSubmit={form => updateItem.mutate( { form, itemId })}
        handleInvoice={handleInvoice}
        assignModalOnOpen={assignModalOnOpen}
        control={control}
        errors={errors}
        register={register}
        handleItemStatusChange={handleItemStatusChange}
        isSubmitting={updateItem.isLoading}
        ioId={ioId}
        itemId={itemId}
      />
    </>
  )
}
