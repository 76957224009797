import {
  Center,
  Divider,
  Text
} from '@chakra-ui/react'
import { ListItem } from './ListItem'
import { ItemListLayout } from 'components/Layout/ItemListLayout'

const NoItemsFound = () => (
  <ItemListLayout>
    <Center>
      <Text color='resolvePurple'> No template items </Text>
    </Center>
  </ItemListLayout>
)

export const QuoteTemplateItemList = ({ 
  handleItemClick,
  items,
}) => {
  if (items.length < 1) return <NoItemsFound />
  return (
    <ItemListLayout>
      {
        items.map((item) => {
          return (
            <div key={item.id}>
              <ListItem
                item={item}
                handleItemClick={handleItemClick}
              />
              <Divider pointerEvents='none' />
            </div>
          )
        })
      }
    </ItemListLayout>
  )
}
