import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";

const deletePaymentMethod = async ({paymentMethodId}) => {
  await axiosClient({
    url: `/settings/payment-methods/${paymentMethodId}`,
    method: 'delete'
  })
}

export const useDeletePaymentMethod = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  const history = useNavigate()
  return useMutation(deletePaymentMethod, {
    onSuccess: () => {
      toastSuccess('Payment method successfully updated') 
      history('/settings/payment-methods')
      queryClient.invalidateQueries('settings-payment-methods')
    },
    onError: () => {
      toastFail('Failed to delete payment method')
    }
  })
}