import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

const updateSubStatus = async ({subId}) => {
  return await axiosClient({ url: `/subs/status/${subId}`, method: 'post'})
}
export const useUpdateSubStatus = () => {
  const queryClient = useQueryClient()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(updateSubStatus, { 
    onSuccess: () => {
      toastSuccess('Subscription status updated successfully')
      queryClient.invalidateQueries('sub-item')
    },
    onError: () => {
      toastFail('Failed to update subscription status')
    }})
}