import { useCallback, useContext, useEffect } from 'react'
import { Link as ReactLink, useParams, useSearchParams } from 'react-router-dom'
import {
  Flex,
  Text,
  Spacer,
  Link,
  FormControl,
  FormLabel,
  Input,
  Button,
  Badge,
  HStack
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { UserContext } from 'features/Auth/context/user.context'

import { useBulkReceive } from 'features/Quotes/api/bulkReceive'

const initialState = {
  item_location: ''
}

export const ReceivedForm = () => {
  const { quoteId } = useParams()
  const [searchParams] = useSearchParams()
  const items = searchParams.get('items').split(',')

  const { engineer } = useContext(UserContext)

  const { register, handleSubmit } = useForm({
    defaultValues: initialState
  })

  const bulkReceive = useBulkReceive(quoteId)

  const onReceivedSubmit = useCallback(async form => {
    bulkReceive.mutate({ form, items, engineerId: engineer.quotes.id })
  }, [engineer, items, bulkReceive])

  return (
    <>
      <Flex mb='1rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Q{quoteId} - Bulk Received
        </Text>
        <Spacer />
        <Link
          as={ReactLink}
          alignSelf='center'
          color='resolveOrange'
          _hover={{ color: 'gray.200' }}
          to={`/quotes/${quoteId}`}
        >
          Back
        </Link>
      </Flex>
      <HStack>
        {items.map(itm => (<Badge variant='solid' colorScheme='resolveOranger' size='lg' key={itm}>{itm}</Badge>))}
      </HStack>
      <form onSubmit={handleSubmit(onReceivedSubmit)}>
        <FormControl mt='1rem'>
          <FormLabel>Location</FormLabel>
          <Input {...register('item_location')} />
        </FormControl>

        <Button
          color='white'
          backgroundColor='resolveOrange'
          mt='1rem'
          type='submit'
        >
          Submit
        </Button>
      </form>
    </>
  )
}
