import { createContext } from 'react'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react'
import { Login } from 'features/Login'
import { useGetQuotesProfile } from 'features/Auth/api/getQuotesProfile'
import { useGetMsalProfile } from 'features/Auth/api/getMsalProfile'

export const UserContext = createContext({ engineer: {}, isError: null })

const useProfile = () => {
  const { instance } = useMsal()
  const account = instance.getActiveAccount()
  const msalProfile = useGetMsalProfile({ account })
  const quotesProfile = useGetQuotesProfile({ msal: msalProfile.data })
  if(msalProfile.error) throw new Error('Failed to fetch Azure AD profile')
  if(quotesProfile.error) throw new Error('Unable to connect to Database')
  return { engineer: quotesProfile.data, isError: msalProfile.error || quotesProfile.error }
}

export const UserContextProvider = ({ children }) => {
  const { engineer, isError } = useProfile()
  return (
    <>
      <AuthenticatedTemplate>
        <UserContext.Provider value={{ engineer, isError }}>
          {children}
        </UserContext.Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  )
}
