import { Center, Flex, Text } from '@chakra-ui/react'

const AccountDisabled = () => (
  <Center height='50rem'>
    <Flex>
      <Text ml='1rem' fontSize='large' color='resolveOrange'>
        Your account is not active. Speak to a Team Leader to enable!
      </Text>
    </Flex>
  </Center>
)

export default AccountDisabled
