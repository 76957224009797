import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation } from "react-query"
import { useNavigate } from "react-router"

export const createTemplate = async ({ form, engineerId }) => {
  const merged = {
    ...form,
    won: form.won ? 1 : 0,
    checked_out: form.checked_out ? 1 : 0,
    invoiced: form.invoiced ? 1 : 0,
    approved_posted: form.approved_posted ? 1 : 0,
    supplier_id: form.supplier_id?.value,
    client_id: form.client_id?.value,
    engineer_id: engineerId,
    subscription_id: form.subscription_id?.value || null,
    minimum_term_id: form.minimum_term_id?.value || null,
    payment_plan_id: form.payment_plan_id?.value || null,
    type_id: form.type_id?.value || 1
  }
  const { data: { templateId } } = await axiosClient({
    url: '/quotes/templates',
    method: 'post',
    data: merged
  })

  return templateId
}

export const useCreateTemplate = () => {
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(createTemplate, { onSuccess: templateId => {
    toastSuccess(`Template added successfully: T${templateId}`)
    history(`/templates/${templateId}`)
  }, onError: () => {
    toastFail('Failed to add template')
  }})
}