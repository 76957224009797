import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

const bulkAction = async ({ selectedBulkItems, action, engineerId, quoteId }) => {
  await axiosClient({
    url: `/quotes/bulk/${action}`,
    method: 'post',
    data: {
      items: selectedBulkItems,
      engineerId,
      quoteId
    }
  })
  return { action, selectedBulkItems }
}

export const useBulkAction = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(bulkAction, {
    onSuccess: ({ action, selectedBulkItems }) => {
      toastSuccess(`[${selectedBulkItems.length}] items updated successfully: ${action}`)
      queryClient.invalidateQueries(['quote-items'])
      queryClient.invalidateQueries(['search-quotes'])
    },
    onError: error => {
      toastFail(`Failed to complete bulk action ${error.response.data.msg}`)
    }
  })
}