import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getSubItem = async (context) => {
  const { signal, queryKey } = context
  const [_key, subId] = queryKey
  const { data: { sub } } = await axiosClient({
    url: `/subs/${subId}`,
    signal
  })
  return sub
}

export const useGetSubItem = ({ subId, onSuccess }) => {
  return useQuery(['sub-item', subId], {
    queryFn: getSubItem, 
    onSuccess
  })
}