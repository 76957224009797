import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getSuppliers = async (context) => {
    const { signal } = context
    const { data: suppliers } = await axiosClient({
        url: '/settings/suppliers',
        signal
    })
    return suppliers
}

export const useGetSuppliers = () => {
    return useQuery(['form-suppliers'], {
        queryFn: getSuppliers,
        placeholderData: [],
        staleTime: Infinity
    })
}