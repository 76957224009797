import { FormControl, FormLabel } from '@chakra-ui/react'
import { ControlledSelect } from './ControlledSelect'
import { useGetPaymentMethods } from './api/getPaymentMethods'

export const PaymentMethodSelectInput = ({
  control,
  name,
  required,
  errors,
  selectedOptionColor = 'resolveOranger',
  ...restProps
}) => {
  const paymentMethods = useGetPaymentMethods()
  return (
    <FormControl isInvalid={errors[name]} isRequired={required} {...restProps}>
      <FormLabel>Payment Method</FormLabel>
      <ControlledSelect
        name={name}
        rules={{ required }}
        isLoading={paymentMethods.isLoading}
        control={control}
        options={paymentMethods.data}
        selectedOptionColor={selectedOptionColor}
      />
    </FormControl>
  )
}
