import { FormControl, FormLabel } from '@chakra-ui/react'
import { DatePicker } from '../DatePicker'

export const DateSelect = ({
  handleDayChange,
  date,
  name,
  label = 'Date',
  required,
  ...props
}) => {
  return (
    <FormControl { ...props } isRequired={required}>
      <FormLabel>{label}</FormLabel>
      <DatePicker
        isClearable
        selectedDate={date.selectedDay}
        onChange={handleDayChange}
        name={name}
      />
    </FormControl>
  )
}
