import {
    Button,
    ButtonGroup,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import { useCreateAutotaskTicket } from 'features/Quotes/api/createAutotaskTicket';
import { useGetAutotaskClientContacts } from 'features/Quotes/api/getAutotaskClientContacts';
import { useGetQuoteItems } from 'features/Quotes/api/getQuoteItems';
import { useCallback, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { StepperActions } from './StepperActions';
import { useFormContext } from 'react-hook-form';
import { ControlledSelect } from 'components/Form/ControlledSelect';
import { UserContext } from 'features/Auth/context/user.context';
import { useUpdateItemTicket } from './api/updateItemTicket';
import { InfoIcon } from '@chakra-ui/icons';
import { useCreateTicketNote } from './api/createTicketNote';
import { StepContext } from './context/stepper.context';

export const AutotaskTicketSetup = () => {
    const { engineer } = useContext(UserContext);

    const [ticketTitle, setTicketTitle] = useState(null);
    const [ticketUpdated, setTicketUpdated] = useState(false);
    const { quoteId, itemId } = useParams();

    const createAutotaskTicket = useCreateAutotaskTicket({
        quoteId,
        itemId,
        onSuccess: ticket => {
            orderForm.setValue('autotask_number', ticket.ticketNumber);
            setTicketTitle(null);
        },
    });

    const { nextStep } = useContext(StepContext);
    const orderForm = useFormContext();
    const formDirty = orderForm.formState.isDirty;

    const autotaskNumber = orderForm.watch('autotask_number');
    const clientId = orderForm.watch('client_id');
    const contactId = orderForm.watch('at_client_contact_id');

    const atClientContacts = useGetAutotaskClientContacts({
        clientId: clientId.value,
    });

    const updateItemTicket = useUpdateItemTicket();
    const addTicketNote = useCreateTicketNote();

    const shouldUpdate =
        formDirty &&
        Object.keys(orderForm.formState.dirtyFields).includes(
            'autotask_number'
        );

    const items = useGetQuoteItems({ quoteId });
    const handleAutotaskTicketSetup = () => {
        const existingTicket = items.data.find(item => item.autotask_number);
        if (
            existingTicket &&
            window.confirm(
                `Use existing ticket from other quote items: ${existingTicket.autotask_number}?`
            )
        ) {
            orderForm.setValue(
                'autotask_number',
                existingTicket.autotask_number,
                { shouldDirty: true }
            );
        } else {
            setTicketTitle(`Sale for ${clientId.label}`);
        }
    };

    const newAutotaskTicket = useCallback(() => {
        createAutotaskTicket.mutate({
            ticket: {
                contactId,
                title: ticketTitle,
                client_id: clientId,
                item_id: itemId,
                quote_id: quoteId,
                engineer: {
                    engineer_id: engineer.quotes.id,
                    autotask_id: engineer.quotes.autotask_id,
                },
            },
        });
    }, [
        createAutotaskTicket,
        ticketTitle,
        engineer,
        contactId,
        itemId,
        clientId,
        quoteId,
    ]);

    const handleSave = useCallback(
        async form => {
            if (!orderForm.trigger()) {
                return;
            }

            await updateItemTicket.mutateAsync({
                itemId,
                form,
            });

            orderForm.reset({}, { keepValues: true });
            nextStep();
        },
        [orderForm, itemId, nextStep, updateItemTicket]
    );

    const handleAddTicketNote = useCallback(async () => {
        if (!(await orderForm.trigger())) return;

        await addTicketNote.mutateAsync({ itemId, autotaskNumber });
        setTicketUpdated(true);
    }, [orderForm, itemId, autotaskNumber, setTicketUpdated, addTicketNote]);

    return (
        <form onSubmit={orderForm.handleSubmit(handleSave)}>
            <FormControl
                mt="1rem"
                isInvalid={!!orderForm.formState.errors.autotask_number}
            >
                <FormLabel>
                    <Flex>
                        <Text>Autotask Ticket Number</Text>
                    </Flex>
                </FormLabel>
                <Flex dir="row">
                    <Input
                        {...orderForm.register('autotask_number', {
                            required: true,
                            pattern: {
                                value: /(T)[0-9]{8}.[0-9]{4}/g,
                                message: 'Enter a valid Autotask ticket number',
                            },
                        })}
                        type="text"
                        mr="1rem"
                    />
                    <Button
                        pl={7}
                        pr={7}
                        bg="resolveOrange"
                        isDisabled={!!autotaskNumber}
                        onClick={handleAutotaskTicketSetup}
                        color="white"
                    >
                        Create Ticket
                    </Button>
                </Flex>
                <FormErrorMessage>
                    {orderForm.formState.errors.autotask_number &&
                        'Enter a valid Autotask ticket number'}
                </FormErrorMessage>
            </FormControl>

            {ticketTitle !== null && (
                <>
                    <FormControl mt="1rem">
                        <FormLabel>Order Contact</FormLabel>
                        <ControlledSelect
                            name={'at_client_contact_id'}
                            rules={{ required: false }}
                            isLoading={atClientContacts.isLoading}
                            control={orderForm.control}
                            options={atClientContacts.data}
                            selectedOptionColor={'resolveOranger'}
                        />
                    </FormControl>
                    <FormControl mt="1rem">
                        <FormLabel>
                            The ticket will be created with the below title. If
                            you prefer a different title, please enter it below
                            and click submit to create the ticket.
                        </FormLabel>
                        <Flex dir="row" mt="1rem">
                            <Input
                                mr="1rem"
                                value={ticketTitle}
                                onChange={e => setTicketTitle(e.target.value)}
                            />
                            <Button
                                color="white"
                                bg="resolveOrange"
                                isDisabled={!!autotaskNumber}
                                onClick={newAutotaskTicket}
                            >
                                Submit
                            </Button>
                        </Flex>
                    </FormControl>
                </>
            )}

            <ButtonGroup mt="1rem">
                <Button
                    color="white"
                    colorScheme="resolveOranger"
                    type="submit"
                >
                    Save
                </Button>
                {shouldUpdate && (
                    <Flex alignItems="center">
                        <Button
                            mr="0.5em"
                            colorScheme={
                                ticketUpdated
                                    ? 'resolveGreener'
                                    : 'resolveBluer'
                            }
                            onClick={handleAddTicketNote}
                        >
                            Add Ticket Note
                        </Button>
                        <Tooltip
                            colorScheme="resolveOranger"
                            label="This will add the order details to an existing ticket as a ticket note"
                        >
                            <InfoIcon />
                        </Tooltip>
                    </Flex>
                )}
            </ButtonGroup>

            <StepperActions />
        </form>
    );
};
