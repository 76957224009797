import { axiosClient } from "lib/axiosClient";
import { useQuery } from "react-query";

const getPaymentMethods = async (context) => {
  const { signal } = context
  const { data: paymentMethods } = await axiosClient({
    url: '/settings/payment-methods',
    signal
  })
  return paymentMethods.filter(pm => !Number(pm.hidden))
}

export const useGetPaymentMethods = () => {
  return useQuery(['form-payment-methods'], {
    queryFn: getPaymentMethods,
    placeholderData: [],
    staleTime: Infinity
  })
}