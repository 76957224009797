import { Button, Flex, Link, Spacer, Tbody, Td, Text, Th, Thead, Tr, Table } from "@chakra-ui/react"
import { Pagination } from "features/Reports/Pagination"
import { useMemo } from "react"
import { AiFillFileExcel } from "react-icons/ai"
import { Link as ReactLink } from 'react-router-dom'
import { usePagination, useTable } from "react-table"
import { useExportData } from "react-table-plugins"
import { getExportFileBlob } from '../../utils'

export const AnPTable = ({ tableData }) => {

  const columns = useMemo(
    () => [
      {
        Header: 'Item',
        accessor: 'item_ref',
        Cell: props => (
          <Link
            as={ReactLink}
            to={`/quotes/${props.value
              .split('-')[0]
              .trim()
              .replace('Q', '')}`}
          >
            {props.value}
          </Link>
        )
      },
      {
        Header: 'Client',
        accessor: 'company_name'
      },
      {
        Header: 'Date',
        accessor: 'date_added',
        Cell: props => <Text>{props.value}</Text>
      },
      {
        Header: 'Engineer',
        accessor: 'ad_username'
      },
      {
        Header: 'Autotask No.',
        accessor: 'autotask_number',
        Cell: props => (
          <Text>
            {props.value && props.value !== 'undefined' && (
              <Link
                href={`https://ww4.autotask.net/ServiceDesk/TicketsList/search_requests.asp?Action=Generate&srNum=${props.value}&nc=1&issuetype=-1&subissuetype=-1&orderBy=Ticket+Number&orderByDir=back&pageTypeExt=1&pageTitle=Tickets&filter=Open&TopNavSearch=1&allocationCodeId=&TotalRows=1&useFilter=0&pageView=1`}
                isExternal
              >
                {props.value}
              </Link>
            )}
          </Text>
        )
      },
      {
        Header: "A+P'd",
        accessor: 'approved_posted',
        Cell: props => <Text>{props.value}</Text>
      },
      {
        Header: 'Invoiced',
        accessor: 'invoiced',
        Cell: props => <Text>{props.value}</Text>
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    exportData,
    state: { pageIndex }
  } = useTable(
    { columns, data: tableData, getExportFileBlob },
    usePagination,
    useExportData
  )
  if(!tableData.length > 0) return <Text>No items found</Text>
  return (
  <>
        <Flex justifyContent='center'>
          <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Tr
                    {...row.getRowProps()}
                    borderLeft='15px solid'
                    borderLeftColor={
                      row.original.item_status === 'Quoted'
                        ? 'resolveOrange'
                        : 'resolveBlue'
                    }
                  >
                    {row.cells.map(cell => (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    ))}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Flex>
        <Flex justifyContent='center' flexFlow='row'>
        <Spacer />
        <Pagination
          prevClick={previousPage}
          nextClick={nextPage}
          pageCount={pageCount}
          page={pageIndex}
          onPageSearch={gotoPage}
        />
        <Spacer />
        <Flex mt='1rem'>
          <Button
            mr='1rem'
            rightIcon={<AiFillFileExcel color='green' />}
            onClick={() => {
              exportData('csv', true)
            }}
          >
            Export
          </Button>
        </Flex>
      </Flex>
  </>
  )
}