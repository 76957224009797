import {
  Image,
  Flex,
  Text,
  Heading,
  Th,
  Table,
  Thead,
  Tr,
  Td,
  Spacer,
  Link,
  Tbody,
  LightMode
} from '@chakra-ui/react'
import moment from 'moment'
import { BASE_URL } from 'config'

import './printStyles.css'

export const PrintLayout = ({ children }) => {
  return (
    <LightMode>
      <Flex justifyContent='center' width='100%' margin='auto' mb='2rem' mt='2rem'>
        {children}
      </Flex>
    </LightMode>
  )
}

export const PrintHeader = ({
  address1,
  address2,
  city,
  postalCode,
  country,
  company_name,
  companyNumber,
  quote_ref,
  type
}) => {
  return (
    <Flex flexFlow='row'>
      <Image
        src={`${BASE_URL}/logo-rebrand.png`}
        w='228px'
        h='80px'
        mr='4rem'
      />
      <Flex mt='1rem' flexGrow={1}>
        <Flex flexFlow='column' fontSize='13px'>
          <Text fontWeight='bold'>{company_name}</Text>
          <Text>{address1}</Text>
          <Text>{address2}</Text>
          <Text>{city}</Text>
          <Text>{postalCode}</Text>
          <Text>{country}</Text>
        </Flex>
        <Spacer />
        <Flex flexFlow='row'>
          <Flex
            flexFlow='column'
            alignItems='flex-end'
            fontWeight='bold'
            fontSize='13px'
          >
            {type === 'pro-forma' ? (
              <Text mr='1rem'>Invoice No.</Text>
            ) : (
              <Text mr='1rem'>Quote No.</Text>
            )}
            <Text mr='1rem'>Date</Text>
            <Text mr='1rem'>Account No.</Text>
          </Flex>
          <Flex flexFlow='column' fontSize='13px'>
            {type === 'pro-forma' ? (
              <Text>{quote_ref.replace('Q', 'PF')}</Text>
            ) : (
              <Text>{quote_ref}</Text>
            )}
            <Text>{moment().format('DD/MM/YY')}</Text>
            <Text>{companyNumber}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const SubHeader = ({ printType }) => {
  if (printType === 'pro-forma') {
    return (
      <Flex mt='4rem'>
        <Heading as='h1' color='#4c5658' size='md'>
          Pro-Forma Invoice
        </Heading>
      </Flex>
    )
  }
  if (printType === 'fiddy') {
    return (
      <Flex mt='4rem'>
        <Heading as='h1' color='#4c5658' size='md'>
          50% Deposit
        </Heading>
      </Flex>
    )
  }
  return (
    <Flex mt='4rem'>
      <Heading></Heading>
    </Flex>
  )
}

export const PrintFooter = ({ printType, companyAddress }) => (
  <Flex flexFlow='column' mt='4rem'>
    {printType === 'pro-forma' ? (
      <>
        <Text mb='1rem' fontSize='0.70em' lineHeight='2em' color='gray.500'>
          This is not a VAT Invoice
        </Text>
        <Text fontSize='0.70em' lineHeight='2em' color='gray.500'>
          Resolve IT Solutions is a limited company registered in England with
          company number 05721052. We are VAT registered (VAT number 886394758)
          and VAT is charged at the prevailing rate. Our standard hourly rate is
          £70 plus VAT. All goods remain the property of Resolve IT until paid
          in full. If we are not paid within 2 days, we will exercise our
          statutory right to claim interest (at 8% over the Bank of England base
          rate) and compensation for debt recovery costs under the Late Payment
          Legislation Act.
        </Text>
        <Text mt='1rem' fontSize='0.70em' lineHeight='2em' color='gray.500'>
          All products and services supplied are subject to our terms and
          conditions - available here{' '}
          <Link
            fontWeight='bold'
            color='resolveOrange'
            target='_blank'
            href='https://resolve.co.uk/tandc'
          >
            resolve.co.uk/tandc
          </Link>
        </Text>
      </>
    ) : (
      <>
        <Text mb="1rem" fontWeight='bold' fontSize='0.75em'>Laptop setups are scheduled with our team after this quotation has been accepted. Although we try to get these done as quickly as possible, please allow us up to 5 working days to turn them around.</Text>
        <Text fontSize='0.70em' lineHeight='2em' color='gray.500'>
          It is acknowledged that all of the information within this quote is
          confidential, other than information that is in the public domain by
          other means; therefore you agree not to disclose without the express
          written permission of Resolve IT Solutions.
        </Text>
        <Text fontSize='0.70em' lineHeight='2em' color='gray.500'>
          All prices are subject to VAT at the current rate. Prices, models and
          specifications are subject to change. A restocking fee of 20% will be
          charged on all returns, with the exception of software which cannot be
          returned. Items are sold with a manufacturer's warranty and support
          from the manufacturer direct. E&amp;OE. Quote valid for 15 days, Dell
          hardware valid for two days. Up to date pricing and specifications
          will be given at time of ordering. Depending on credit status, a
          deposit may be required before ordering. Leasing option is available.
          Additional training or professional services can be provided at our
          standard rates.
        </Text>
        <Text mt='1rem' fontSize='0.70em' lineHeight='2em' color='gray.500'>
          All products and services supplied are subject to our terms and
          conditions - available here{' '}
          <Link
            fontWeight='bold'
            color='resolveOrange'
            target='_blank'
            href='https://resolve.co.uk/tandc'
          >
            resolve.co.uk/tandc
          </Link>
        </Text>
      </>
    )}
    <Flex mt='1rem' fontWeight='bold' fontSize='0.75em'>
      <Text>{companyAddress && (companyAddress)}</Text>
      <Spacer />
      <Text>resolve.co.uk</Text>
    </Flex>
  </Flex>
)

export const ItemsTable = ({ itemData, printType }) => {
  return (
    <>
      <Table variant='simple' mt='2rem' size='sm'>
        <Thead>
          <Tr>
            <Th isNumeric>Qty</Th>
            <Th isNumeric>Description</Th>
            <Th isNumeric>Item Price</Th>
            <Th isNumeric>Total Price</Th>
          </Tr>
        </Thead>
        <Tbody fontSize='13px'>
          {itemData.items.map(item => (
            <Tr key={item.id}>
              <Td isNumeric>{item.quantity}</Td>
              <Td isNumeric>{item.description}</Td>
              <Td isNumeric>£{item.client_cost.toFixed(2)}</Td>
              {
                printType === 'fiddy'
                  ? (
                    <Td isNumeric>
                      £
                      {parseFloat((item.client_cost * item.quantity) / 2).toFixed(
                        2
                      )}
                    </Td>
                    )
                  : (
                    <Td isNumeric>
                      £{parseFloat(item.client_cost * item.quantity).toFixed(2)}
                    </Td>
                    )
              }
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex mt='5rem'>
        <Flex flexBasis='35%'>
          {(printType === 'pro-forma' || printType === 'fiddy') && (
            <Text fontSize='0.7em' lineHeight='1.5em'>
              We are expecting immediate payment by BACS (our Account Number is
              00356608 and Sort Code is 30-97-51).
            </Text>
          )}
        </Flex>
        <Spacer />
        <Flex alignSelf='flex-end'>
          <Flex
            flexFlow='column'
            alignItems='flex-end'
            mr='1rem'
            fontSize='13px'
          >
            <Text fontWeight='bold'>Delivery</Text>
            <Text fontWeight='bold'>Sub Total (Exc VAT)</Text>
            <Text fontWeight='bold'>VAT @ 20%</Text>
            {printType === 'pro-forma' || printType === 'fiddy' ? (
              <Flex flexFlow='row' fontWeight='bold'>
                <Text> Grand Total for </Text>
                <Text ml='0.3rem' color='resolveOrange'>
                  BACS
                </Text>
                <Text ml='0.3rem'> (Inc VAT) </Text>
              </Flex>
            ) : (
              <Text fontWeight='bold'>Grand Total (Inc VAT)</Text>
            )}
          </Flex>
          <Flex
            alignSelf='flex-end'
            flexFlow='column'
            alignItems='flex-end'
            fontSize='13px'
          >
            <Text>£{itemData.costs.delivery_total.toFixed(2)}</Text>
            <Text>£{itemData.costs.sub_total_ex_vat.toFixed(2)}</Text>
            <Text>£{itemData.costs.vat_total.toFixed(2)}</Text>
            <Text>£{itemData.costs.sub_total_inc_vat.toFixed(2)}</Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export const SubscriptionsTable = ({ subData, printType }) => {
  if (subData.items.length === 0) return <></>
  return (
    <>
      <Heading as='h1' mt='2rem' color='#4c5658' size='md'>
        Subscriptions
      </Heading>
      <Table variant='simple' mt='2rem' size='sm'>
        <Thead>
          <Tr>
            <Th isNumeric>Qty</Th>
            <Th isNumeric>Description</Th>
            <Th isNumeric>Minimum Term</Th>
            <Th isNumeric>Payment</Th>
            <Th isNumeric>Item Price</Th>
            <Th isNumeric>Subscription Total</Th>
          </Tr>
        </Thead>
        <Tbody fontSize='13px'>
          {subData.items.map((item, idx) => (
            <Tr key={idx}>
              <Td isNumeric>{item.quantity}</Td>
              <Td isNumeric>{item.description}</Td>
              <Td isNumeric>{item.minimum_term}</Td>
              <Td isNumeric>{item.payment_plan}</Td>
              <Td isNumeric>£{item.client_cost.toFixed(2)}</Td>
              <Td isNumeric>£{(item.client_cost * item.quantity).toFixed(2)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex mt='5rem'>
        <Flex flexBasis='35%'>
          {printType === 'pro-forma' && (
            <Text fontSize='0.7em' lineHeight='1.5em'>
              We are expecting immediate payment by BACS (our Account Number is
              00356608 and Sort Code is 30-97-51). All online card payments are
              subject to a 4% card charge.
            </Text>
          )}
        </Flex>
        <Spacer />
        <Flex alignSelf='flex-end'>
          <Flex
            flexFlow='column'
            alignItems='flex-end'
            mr='2rem'
            fontSize='13px'
          >
            <Text fontWeight='bold'>Subscription Total (Exc VAT)</Text>
            <Text fontWeight='bold'>VAT @ 20%</Text>
            {printType === 'pro-forma' ? (
              <>
                <Text fontWeight='bold'>
                  Monthly Grand Total for BACS (Inc VAT)
                </Text>
                <Text fontWeight='bold'>
                  Monthly Grand Total for Card (Inc VAT)
                </Text>
              </>
            ) : (
              <Text fontWeight='bold'>Subscription Grand Total (Inc VAT)</Text>
            )}
          </Flex>
          <Flex
            alignSelf='flex-end'
            flexFlow='column'
            alignItems='flex-end'
            fontSize='13px'
          >
            <Text>£{subData.costs.sub_total_ex_vat.toFixed(2)}</Text>
            <Text>£{subData.costs.vat_total.toFixed(2)}</Text>
            <Text>£{subData.costs.sub_total_inc_vat.toFixed(2)}</Text>
            {printType === 'pro-forma' && (
              <Text>£{subData.costs.sub_total_inc_vat_ccharge.toFixed(2)}</Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
