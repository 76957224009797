import { useNavigate } from 'react-router-dom'
import { Box, Divider, Center } from '@chakra-ui/react'
import { ListItem } from './ListItem'
import { Layout } from 'components/Layout/Layout'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'

export const QuoteTemplateList = ({
  isFetching,
  isLoading,
  isError,
  templates,
  templateId
}) => {
  const history = useNavigate()

  const handleTemplateClick = e => {
    let targetTemplateId = ''

    if (e.target.id.length < 1) {
      targetTemplateId = e.target.parentElement.id
    } else {
      targetTemplateId = e.target.id
    }
    if (targetTemplateId !== templateId) {
      history(`/templates/${targetTemplateId}`)
    }
  }

  if (isError) return <SomethingBroke message='Failed to fetch Templates' error={isError.message} />
  if (isLoading || isFetching) return <Loading />

  if (templates.length < 1) {
    return (
      <Layout>
        <Center>
          <div>No templates found</div>
        </Center>
      </Layout>
    )
  }

  return (
    <Layout>
      {templates.map((template, i) => {
        return (
          <Box key={`${template.id}`}>
            <ListItem
              handleTemplateClick={handleTemplateClick}
              isSelected={`${template.id}` === `${templateId}`}
              template={template}
            />
            <Divider />
          </Box>
        )
      })}
    </Layout>
  )
}
