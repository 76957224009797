import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getTemplateItems = async (context) => {
  const { signal, queryKey } = context
  const [_key, templateId] = queryKey
  const { data: { items } } = await axiosClient({
    url: `/quotes/templates/${templateId}/items`,
    signal
  })
  return items
}

export const useGetTemplateItems = ({ templateId }) => {
  return useQuery(['template-items', templateId], {
    queryFn: getTemplateItems,
    placeholderData: []
  })
}
