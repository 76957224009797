import { useMsal } from "@azure/msal-react"
import { loginRequest } from "authConfig"
import { useQuery } from "react-query"
import { callMsGraph } from "utils/MsGraphApiCall"

const getMsalProfile = async (context) => {
  const msalProfile = await callMsGraph() 
  const profileData = {
    givenName: msalProfile.displayName.split(' ')[0],
    displayName: msalProfile.displayName,
    jobTitle: msalProfile.jobTitle,
    email: msalProfile.userPrincipalName.toLowerCase(),
    memberOf: [],
    loggedIn: true
  }
  return profileData
}

const MSAL_PROFILE_STATE = {
  pictureUrl: null,
  givenName: '',
  displayName: '',
  jobTitle: '',
  email: null,
  memberOf: [],
  loggedIn: false
}

export const useGetMsalProfile = ({ account }) => {
  const { instance } = useMsal()
  return useQuery(['auth-msal'], {
    queryFn: getMsalProfile,
    placeholderData: MSAL_PROFILE_STATE,
    staleTime: Infinity,
    enabled: !!account,
    retry: false,
    onError: error => {
      instance.acquireTokenRedirect({ ...loginRequest })
    }
  })
}