import { useQuery } from "react-query"

const { axiosClient } = require("lib/axiosClient")

const QUOTES_PROFILE_STATE = {
  quotes: {
    isAdmin: false,
    isLeader: false,
    ad_username: '',
    id: 0,
    autotask_id: '',
    team_id: 0,
    active: 0,
    notFound: false
  },
  msal: {
    pictureUrl: null,
    givenName: '',
    displayName: '',
    jobTitle: '',
    email: null,
    loggedIn: false
  },
  permissions: {
    isAdmin: false
  }
}
const getQuotesProfile = async (context) => {
  const { signal, queryKey } = context
  const [_, msal] = queryKey
  const { data: profile } = await axiosClient({
    url: `/settings/engineer/${msal.email}`, // `/auth/${emailAddress}`,
    signal
  })

  const userProfile = {...QUOTES_PROFILE_STATE}
  if (profile.notFound) {
    userProfile.quotes.notFound = true
    userProfile.quotes.loggedIn = true
  } else {
    const userIsAdmin = profile.engineer.admin_access === 1
    userProfile.quotes = {
      ...profile.engineer
    }
    userProfile.quotes.isAdmin = userIsAdmin
    userProfile.permissions = profile.permissions
    userProfile.quotes.loggedIn = true
  }

  userProfile.msal = { ...msal }
  return userProfile
}

export const useGetQuotesProfile = ({ msal }) => {
  return useQuery(['auth-quotes', msal], {
    queryFn: getQuotesProfile,
    enabled: !!msal.loggedIn,
    placeholderData: QUOTES_PROFILE_STATE,
    staleTime: Infinity 
  })
}