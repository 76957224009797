import { Text } from '@chakra-ui/react'

export const CompanyName = ({ company }) => (
  <>
    <Text
      pointerEvents='none'
      overflow='hidden'
      whiteSpace='nowrap'
      maxWidth='90%'
      textOverflow='ellipsis'
    >
      {company}
    </Text>
  </>
)
