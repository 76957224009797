import { FormControl, FormLabel, Input } from '@chakra-ui/react'

export const ItemInternalDescriptionInput = ({
  register,
  name,
  errors
}) => {
  return (
    <FormControl mb='1rem'>
      <FormLabel>Internal Description</FormLabel>
      <Input {...register(name)} id='internal-description' />
    </FormControl>
  )
}
