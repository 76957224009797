import { Text } from '@chakra-ui/layout'
import moment from 'moment'

export const ItemStatusText = ({
  dateAdded,
  dateOrdered,
  invoiceDate,
  checkoutDate,
  itemLocation
}) => {
  return (
    <>
      {dateAdded && (
        <Text fontSize='xs' color='gray.400'>
          <b>Date Added: </b> {moment(dateAdded).format('DD/MM/YY')}
        </Text>
      )}
      {dateOrdered && (
        <Text fontSize='xs' color='gray.400'>
          <b>Date Ordered: </b> {moment(dateOrdered).format('DD/MM/YY')}
        </Text>
      )}
      {invoiceDate && (
        <Text fontSize='xs' color='gray.400'>
          <b>Invoice Date: </b>
          {moment(invoiceDate).format('DD/MM/YY')}
        </Text>
      )}
      {checkoutDate && (
        <Text fontSize='xs' color='gray.400'>
          <b>Checkout Date: </b>
          {moment(checkoutDate).format('DD/MM/YY')}
        </Text>
      )}
      {itemLocation && (
        <Text fontSize='xs' color='gray.400'>
          <b>Item Location: </b> {itemLocation}
        </Text>
      )}
    </>
  )
}
