import { axiosClient } from "lib/axiosClient";
import { useQuery } from "react-query";

const getIOBillingCode = async (context) => {
  const { signal, queryKey } = context
  const [_key, billingCodeId] = queryKey
  const { data: { code } } = await axiosClient({
    url: `/settings/io-billing-codes/${billingCodeId}`,
    signal
  }) 
  return code
}

export const useGetIOBillingCode = ({ onSuccess, billingCodeId}) => {
  return useQuery(['settings-io-billing-code', billingCodeId], {
    queryFn: getIOBillingCode,
    onSuccess
  })
}