import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import moment from "moment"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

export const updateSubItem = async ({ form, date, subId }) => {

  let d
  if (date.selectedDay) {
    d = new Date(date.selectedDay)
    d.setHours(2)
  }

  const hydratedForm = {
    num_licenses: form.num_licenses,
    serial_license: form.serial_license,
    name: form.name,
    date_first_renewal: moment(d).format('YYYY-MM-DD HH:mm:ss'),
    supplier_id: form.supplier_id.value,
    client_id: form.client_id.value,
    engineer_id: form.engineer_id.value,
    quote_type_id: form.quote_type_id.value,
    subscriptiontype_id: form.subscriptiontype_id.value
  }

  await axiosClient({
    url: `/subs/${subId}`,
    method: 'patch',
    data: hydratedForm
  })
}

export const useUpdateSubItem = ({ subId }) => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateSubItem, { onSuccess: () => {
    toastSuccess('Subscription saved successfully')
    queryClient.invalidateQueries('sub-item')
  }, onError: () => {
    toastFail('Failed to save subscription')
  }})
  
}