import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getClients = async (context) => {
  const { signal } = context
  const { data: { clients } } = await axiosClient({
    url: '/settings/clients',
    signal
  })
  return clients.map(client => ({
    label: client.company_name,
    value: client.id
  }))
}

export const useGetClients = () => {
  return useQuery(['form-clients'], {
    queryFn: getClients, 
    staleTime: Infinity,
    placeholderData: []
  })
}