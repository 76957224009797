import { useContext, useEffect, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useDisclosure } from '@chakra-ui/react'
import { UserContext } from 'features/Auth/context/user.context'
import { AssignItemModal } from './Modals/AssignItemModal'
import { Loading } from 'components/Loading'
import { Form } from './Form'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useGetQuoteItem } from 'features/Quotes/api/getItem'
import { useUpdateQuoteItem } from 'features/Quotes/api/updateQuoteItem'
import { useCopyQuoteItem } from 'features/Quotes/api/copyQuoteItem'

const initialState = {
  approved_posted: false,
  item_status_id: 0,
  checked_out: 0,
  client_cost: 0,
  delivery_price: 0,
  description: '',
  internal_description: '',
  invoiced: false,
  mark_up_percentage: 0,
  part_code: '',
  quantity: 1,
  supplier_id: '',
  type_id: 0,
  unit_price: 0.00,
  quote_id: '',
  won: false,
  test: false
}

export const EditQuoteItemForm = () => {
  const { quoteId, itemId } = useParams()
  const history = useNavigate()
  const { engineer } = useContext(UserContext)
  const {
    isOpen: assignModalIsOpen,
    onOpen: assignModalOnOpen,
    onClose: assignModalOnClose
  } = useDisclosure()

  const methods = useForm({
    defaultValues: initialState
  })
  const { reset, setValue } = methods

  const [itemLocation, setItemLocation] = useState('')

  const item = useGetQuoteItem({ itemId, onSuccess: item => {
    setItemLocation(item.item_location)
    reset(item)
  }})

  const handleInvoice = e => {
    console.log(e)
    if (e.target.checked || e.target.innerText === 'Invoice Details') {
      history(`/quotes/${quoteId}/edit/${itemId}/invoice`)
    }
  }

  const handleItemStatusChange = useCallback(
    (e) => {
      const newStatusId = Number(e.target.id)
      if (newStatusId === 7) {
        history(`/quotes/${quoteId}/edit/${itemId}/order`)
      }
      setValue('item_status_id', newStatusId)
    },
    [quoteId, history, itemId, setValue]
  )

  const updateItem = useUpdateQuoteItem()
  const copyItem = useCopyQuoteItem(quoteId)

  const onFormSubmit = useCallback(
    async (form) => {
      await updateItem.mutateAsync({ itemId, item: form, itemLocation, engineerId: engineer.quotes.id })
    },
    [itemId, itemLocation, updateItem, engineer]
  )

  useEffect(() => {
    setValue('quote_id', quoteId)
  }, [quoteId, setValue])

  if (item.isLoading) {
    return <Loading />
  }

  if (item.error) {
    return <SomethingBroke error={item.error.message} message='Failed to fetch item' />
  }

  return (
    <FormProvider {...methods}>
      {
        assignModalIsOpen && (
          <AssignItemModal
            isOpen={assignModalIsOpen}
            onClose={assignModalOnClose}
            itemId={itemId}
          />
        )
      }

      <Form
        onFormSubmit={onFormSubmit}
        handleItemStatusChange={handleItemStatusChange}
        isSubmitting={updateItem.isLoading}
        assignModalOnOpen={assignModalOnOpen}
        handleInvoice={handleInvoice}
        engineer={engineer}
        itemLocation={itemLocation}
        onCopyItem={copyItem.mutate}
        setItemLocation={setItemLocation}
      />
    </FormProvider>
  )
}
