import { useRef, useState } from 'react'
import {
  Link as ReactLink,
  Routes as Switch,
  Route
} from 'react-router-dom'
import { Text, Flex, Spacer, Button } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { EditPaymentMethodForm } from './Edit/'
import { AddPaymentMethodForm } from './Add'
import { useGetPaymentMethods } from 'features/Settings/api/getPaymentMethods'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Loading } from 'components/Loading'

const PaymentSelectList = ({ paymentMethods, onChange, selectRef }) => {
  return (
    <Select
      selectedOptionColor='resolveOranger'
      ref={selectRef}
      onChange={onChange}
      isClearable
      options={paymentMethods}
    />
    )
  }

export const PaymentSettingsContainer = () => {
  return (
    <Switch>
      <Route path='add' element={<AddPaymentMethodForm />} />
      <Route path='/' element={<PaymentSettings />} />
    </Switch>
  )
}

export const PaymentSettings = () => {
  const [paymentMethods, setPaymentMethods] = useState([])
  const [pm, setPm] = useState(null)
  const selectRef = useRef()

  const onChange = e => {
    if (e) {
      setPm(paymentMethods.find(c => c.value === e.value))
    } else {
      setPm(null)
    }
  }

  const onSuccess = paymentMethods => {
    setPaymentMethods(paymentMethods)
  }
  const payMethods = useGetPaymentMethods({ onSuccess })
  
  if(payMethods.error) return <SomethingBroke message='Failed to get payment methods' error={payMethods.error.message} />
  if(payMethods.isLoading || payMethods.isFetching) return <Loading />
  return (
    <Flex flexFlow='column'>
      <Flex alignItems='center' mb='2rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Payment Methods
        </Text>
        <Spacer />
        <Button
          as={ReactLink}
          backgroundColor='resolveOrange'
          size='sm'
          color='white'
          to='/settings/payment-methods/add'
        >
          Add Payment Method
        </Button>
      </Flex>

      <PaymentSelectList
        onChange={onChange}
        paymentMethods={paymentMethods}
        selectRef={selectRef}
      />

      {pm && (
        <EditPaymentMethodForm
          selectRef={selectRef}
          paymentMethodId={pm.value}
        />
      )}
    </Flex>
  )
}
