import { FormControl, Input } from '@chakra-ui/react'

export const OtherClientInput = ({ register, errors, name }) => {
  return (
    <FormControl mb='1rem' isInvalid={!!errors[name]}>
      <Input
        {...register(name, {
          validate: v => { return v.length > 1 ? 1 : 'Required if Other selected' }
        })}
        placeholder='Specify other client'
        id='internal-description'
      />
    </FormControl>
  )
}
