import { axiosClient } from "lib/axiosClient";
import { useQuery } from "react-query";

const getSubType = async (context) => {
  const { signal, queryKey } = context
  const [_key, subTypeId] = queryKey
  const { data: subType } = await axiosClient({
    url: `/settings/sub-types/${subTypeId}`,
    signal
  })
  return subType
}

export const useGetSubType = ({ onSuccess, subTypeId }) => {
  return useQuery(['settings-sub-type', subTypeId], {
    queryFn: getSubType,
    onSuccess
  })
}