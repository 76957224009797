import { FormLabel, FormControl, Input } from '@chakra-ui/react'

export const SupplierOrderNumberSelectInput = ({
  register,
  required,
  name,
  labelText = 'Supplier Order Number',
  ...restProps
}) => {
  return (
    <FormControl {...restProps} isRequired={required}> 
      <FormLabel>{labelText}</FormLabel>
      <Input {...register(name, { required })} type='text' />
    </FormControl>
  )
}
