const { useResolveToast } = require("hooks/useResolveToast")
const { axiosClient } = require("lib/axiosClient")
const { useMutation } = require("react-query")

const bugReport = async (report) => {
 await axiosClient({
  url: '/feedback',
  method: 'post',
  data: report
 })
}

export const useBugReport = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(bugReport, {
    onSuccess: () => {
      toastSuccess('Feedback sent')
    },
    onError: () => {
      toastFail('Failed to send Feedback')
    }
  })
}