import { Avatar } from '@chakra-ui/react'

export const Engineer = ({ engineer, blob }) => {
  return (
    <Avatar
      size='sm'
      name={engineer}
      src={blob}
      pointerEvents='none'
    />
  )
}
