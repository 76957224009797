import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

const createItem = async ({ quoteId, item }) => {
  delete item.set_product
  const normalizedForm = {
    ...item,
    supplier_id: item.supplier_id?.value || null,
    type_id: item.type_id?.value || 1,
    minimum_term_id: item.minimum_term_id?.value || 0,
    payment_plan_id: item.payment_plan_id?.value || 0
  }

  return await axiosClient({
    url: `/quotes/item/${quoteId}`,
    method: 'post',
    data: normalizedForm
  })
}

export const useCreateItem = quoteId => {
  const queryClient = useQueryClient()
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()

  return useMutation(createItem, {
    onSuccess: () => {
      toastSuccess('Item added successfully')
      queryClient.invalidateQueries(['quote-items'])
      queryClient.invalidateQueries(['sales-stats'])
      history(`/quotes/${quoteId}`)
    },
    onError: () => {
      toastFail('Failed to add item')
    }
  })
}
