import { Link as ReactLink } from 'react-router-dom'
import { Flex, Text, Link, Spacer, Button } from '@chakra-ui/react'

export const Header = ({ ioId }) => {
  return (
    <Flex mb='1rem'>
      <Text color='resolveBlue' mr='1rem' fontSize='2rem' fontWeight='600'>
        Edit Item
      </Text>
      {/* TODO: Implement copy item */}
      <Button
        size='xs'
        alignSelf='center'
        isDisabled
        bg='resolveBlue'
        color='white'
      >
        Copy Item
      </Button>
      <Spacer />
      <Link
        as={ReactLink}
        alignSelf='center'
        color='resolveBlue'
        _hover={{ color: 'gray.200' }}
        to={`/internal/${ioId}`}
      >
        Back
      </Link>
    </Flex>
  )
}
