import { Flex, Text } from '@chakra-ui/react'

export const SubType = ({ type }) => (
  <Flex
    pointerEvents='none'
    alignItems='flex-start'
    justifyContent='flex-start'
    mr='1rem'
    minWidth='20%'
    flexBasis='20%'
  >
    <Text
      overflow='hidden'
      whiteSpace='nowrap'
      textOverflow='ellipsis'
      fontSize='sm'
    >
      {type}
    </Text>
  </Flex>
)
