import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { ControlledSelect } from './ControlledSelect'
import { axiosClient } from 'lib/axiosClient'
import { useQuery } from 'react-query'

const getBillingCodes = async (context) => {
    const { signal } = context
    const { data: codes } = await axiosClient({
        url: '/settings/autotask/billing-codes',
        signal
    })
    return codes
        .filter(code => code.enabled)
        .map(code => ({ label: code.label, value: code.value }))
}

const useGetBillingCodes = () => {
    return useQuery(['form-billing-codes'], {
        queryFn: getBillingCodes,
        placeholderData: [],
        staleTime: Infinity
    })
}

export const BillingCodeSelect = ({
    control,
    name,
    required,
    errors,
    labelText = 'Autotask Billing Codes',
    selectedOptionColor = 'resolveOranger',
    ...restProps
}) => {

    const billingCodes = useGetBillingCodes()
    return (
        <FormControl isInvalid={errors[name]} isRequired={required} {...restProps}>
            <FormLabel>{labelText}</FormLabel>
            <ControlledSelect
                name={name}
                control={control}
                errors={errors}
                options={billingCodes.data}
                isLoading={billingCodes.isLoading}
                rules={{ required }}
                selectedOptionColor={selectedOptionColor}
            />
            <FormErrorMessage>
                { errors[name] && errors[name].message}
            </FormErrorMessage>
        </FormControl>
    )
}
