import { useState } from 'react'
import {
  Flex,
  HStack,
  Spacer,
  Heading
} from '@chakra-ui/react'
import { SalesTable } from './Table'
import { DateRange } from '../../DateRangePicker'
import { ControlledUserSelect } from 'components/Form/ControlledUserSelect'
import { getFirstOfMonthDate } from 'utils'
import { useGetSalesReport } from 'features/Reports/api/getSalesReport'
import { Loading } from 'components/Loading'
import { useFilter } from './useFilter'

export const Sales = () => {
  const [engineer, setEngineer] = useState()
  const [startDate, setStartDate] = useState(
    getFirstOfMonthDate()
  )
  const [endDate, setEndDate] = useState(new Date())
  const { filterString } = useFilter({ engineer, startDate, endDate })
  const report = useGetSalesReport(filterString)

  return (
    <Flex mt='1rem' justifyContent='flex-start' width='80%' flexFlow='column'>
      <HStack mt='1rem' mb='1rem'>
        <Heading color='resolveOrange'>Sales Report</Heading>
        <Spacer />
        <HStack w='40%'>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <ControlledUserSelect
            onChange={engineer => setEngineer(engineer)}
            value={engineer}
            isClearable={true}
            mr='1rem'
          />
        </HStack>l
      </HStack>
      { report.isFetching ? <Loading /> : ( report.isSuccess && <SalesTable tableData={report.data} /> )}
    </Flex>
  )
}
