import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Text,
  FormControl,
  FormLabel,
  Flex,
  Button,
  Input
} from '@chakra-ui/react'
import { useResolveToast } from 'hooks/useResolveToast'
import { axiosClient } from 'lib/axiosClient'
import { useMutation, useQueryClient } from 'react-query'

const NameInput = ({ register, name }) => {
  return (
    <FormControl mb='1rem'>
      <FormLabel>Label</FormLabel>
      <Input {...register(name)} />
    </FormControl>
  )
}

const FormHeader = ({ name }) => (
  <Flex flexFlow='row' mt='2rem' mb='1rem'>
    <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
      {name}
    </Text>
  </Flex>
)

const createQuoteType = async ({ quoteType }) => {
  await axiosClient({
    url: '/settings/quote-types',
    method: 'post',
    data: quoteType
  })
}

const useCreateQuoteType = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const history = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(createQuoteType, {
    onSuccess: () => {
      toastSuccess('Quote Type created successfull')
      queryClient.invalidateQueries(['form-quote-types'])
      queryClient.invalidateQueries(['settings-quote-types'])
      history('/settings/quote-types')
    },
    onError: () => {
      toastFail('Failed to create Quote Type')
    }
  })
}

const QuoteTypesState = {
  label: '',
  options: ''
}

export const AddQuoteType = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: QuoteTypesState })

  const createQuoteType = useCreateQuoteType()
  
  const onSubmit = form => {
    createQuoteType.mutate({ quoteType: form })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader name='Add Quote Type' />
      <NameInput register={register} name='label' />
      <FormControl>
        <FormLabel>Options</FormLabel>
        <Input {...register('options')} />
      </FormControl>
      <Button
        type='submit'
        mt='1rem'
        color='white'
        backgroundColor='resolveOrange'
      >
        Save
      </Button>
    </form>
  )
}
