import { axiosClient } from 'lib/axiosClient';
import { useQuery } from 'react-query';

const getTeams = async (context) => {
  const { signal } = context
  const { data: teams } = await axiosClient({
    url: '/settings/teams',
    signal
  }) 
  return teams
}

export const useGetTeams = () => {
  return useQuery(['settings-teams'], {
    queryFn: getTeams,
    placeholderData: []
  })
}
