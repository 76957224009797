import { axiosClient } from "lib/axiosClient"

const { useResolveToast } = require("hooks/useResolveToast")
const { useMutation, useQueryClient } = require("react-query")

const updateBillingCode = async ({ form }) => {
  const nForm = {
    ...form,
    active: form.active ? 1 : 0,
    enabled: form.enabled ? 1 : 0
  }
  await axiosClient({
    url: '/settings/billing-codes',
    method: 'patch',
    data: nForm
  })
}

export const useUpdateBillingCode = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateBillingCode, {
    onSuccess: () => {
      toastSuccess('Billing code successfully updated')
      queryClient.invalidateQueries(['settings-billing-codes'])
      queryClient.invalidateQueries(['form-billing-codes'])
    },
    onError: () => {
      toastFail('Failed to update billing code')
    }
  })
}