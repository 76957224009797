import { Text, Flex, Button } from '@chakra-ui/react'
import { useSyncClients } from 'features/Settings/api/syncClients'

export const ClientSettingsContainer = () => {
  const syncClients = useSyncClients()

  return (
    <>
      <Flex flexFlow='column'>
        <Flex alignItems='center' mb='2rem'>
          <Text
            color='resolveOrange'
            mr='1rem'
            fontSize='2rem'
            fontWeight='600'
          >
            Clients
          </Text>
        </Flex>
      </Flex>
      <Flex>
        <Button
          onClick={() => syncClients.refetch()}
          backgroundColor='resolveOrange'
          size='sm'
          isLoading={syncClients.isFetching}
          color='white'
        >
          Synchronise Clients
        </Button>
      </Flex>
    </>
  )
}
