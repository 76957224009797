import axios from 'axios'
import { msalInstance } from 'index'
import { loginRequest } from '../authConfig'
import { BASE_API_URL } from '../config'

const SESSION_EXPIRED_STATUS_CODE = 401

const baseApiClient = axios.create({
  baseURL: BASE_API_URL
})

export const axiosClient = async ({ ...options }) => {
  const account = msalInstance.getActiveAccount()
  const onSuccess = response => response
  const token = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account
  })

  const bearerStringWithToken = 'Bearer ' + token.idToken
  const onError = error => {
    if (error.response.status === SESSION_EXPIRED_STATUS_CODE) {
      msalInstance.acquireTokenRedirect({ ...loginRequest })
    }
    return Promise.reject(error)
  }

  return baseApiClient({ ...options, headers: { Authorization: bearerStringWithToken } }).then(onSuccess).catch(onError)
}