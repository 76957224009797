import { Badge } from '@chakra-ui/react'

export const StatusBadge = ({ status }) => {
  const getBadgeColor = () => {
    switch (status) {
      case 'Quoted':
        return 'resolveOrange'
      case 'Won':
        return 'resolveGreen'
      case 'Lost':
        return 'resolveRed'
      default:
        return 'resolvePurple'
    }
  }
  return (
    <Badge
      pointerEvents='none'
      pl='0.5rem'
      pr='0.5rem'
      textAlign='center'
      width='100%'
      bgColor={getBadgeColor()}
      color='gray.200'
    >
      {status}
    </Badge>
  )
}
