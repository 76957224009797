import { FormControl } from '@chakra-ui/react'
import { ControlledSelect } from './ControlledSelect'
import { useQuery } from 'react-query'
import { axiosClient } from 'lib/axiosClient'

const getMinimumSubscriptionTerms = async (context) => {
  const { signal } = context
  const { data: { minTerms } } = await axiosClient({
    url: '/settings/minimum-terms',
    signal
  })
  return minTerms
}

const useGetMinimumSubscriptionTerms = () => {
  return useQuery(['form-minimum-terms'], {
    queryFn: getMinimumSubscriptionTerms,
    placeholderData: [],
    staleTime: Infinity
  })
}
export const MinimumTermSelectInput = ({
  name,
  control,
  errors,
  required,
  selectedOptionColor = 'resolveOranger',
  placeholder = 'Minimum Term',
  ...restProps
}) => {
  const minTerms = useGetMinimumSubscriptionTerms()
  return (
    <FormControl
      isRequired={required}
      isInvalid={!!errors[name]}
      {...restProps}
    >
      <ControlledSelect
        name={name}
        control={control}
        options={minTerms.data}
        isLoading={minTerms.isLoading}
        rules={{ required }}
        placeholder={placeholder}
        selectedOptionColor={selectedOptionColor}
      />
    </FormControl>
  )
}
