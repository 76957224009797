
import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getInvoice = async itemId => {
  const { data: invoice } = await axiosClient({
    url: `/io/invoice/${itemId}`
  })

  return invoice
}

export const useGetInvoice = ({itemId, onSuccess }) => {
  return useQuery(['invoice'], () => getInvoice(itemId), { onSuccess })
}
