import {
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  Flex
} from '@chakra-ui/react'

import { QuotesQIcon } from 'components/QuotesQIcon'

export const QuoteNumberInput = ({ register, name }) => (
  <Flex mr='1rem' flexBasis='25%'>
    <FormControl id='quoteId'>
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <QuotesQIcon color='resolveOrange' />
        </InputLeftElement>
        <Input type='number' {...register(name)} />
      </InputGroup>
    </FormControl>
  </Flex>
)
