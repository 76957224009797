import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation } from "react-query"

export const deleteUser = async ({ userId }) => {
  await axiosClient({
    url: `/settings/users/${userId}`,
    method: 'delete'
  })
}

export const useDeleteUser = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(deleteUser, {
    onSuccess: () => {
      toastSuccess('User deleted successfully')
    },
    onError: error => {
      toastFail('Failed to delete user')
    }
  })
}

