import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { UserContext } from 'features/Auth/context/user.context'
import { Form } from './Form'
import { useGetSupplier } from 'features/Internal/api/getSupplier'
import { useCreateItem } from 'features/Internal/api/createItem'

const internalOrderState = {
  supplier_id: '',
  part_code: '',
  quantity: 1,
  description: '',
  payment_method_id: '',
  supplier_order_number: '',
  authorized_by_id: '',
  cost_price: 0,
  delivery_price: 0,
  other_supplier: '',
  at_billing_code_id: ''
}

export const AddItem = () => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: internalOrderState
  })

  const { ioId } = useParams()
  const { engineer } = useContext(UserContext)
  const supplierId = watch('supplier_id')
  const createItem = useCreateItem(ioId)
  const useSupplier = useGetSupplier({ supplierId })

  useEffect(() => {
    if(useSupplier.status === 'success') {
      setValue('delivery_price', useSupplier.data.delivery_price)
      setValue('at_billing_code_id', useSupplier.data.at_billing_code_id)
    }
  }, [useSupplier, setValue]);
 
  return (
    <Form
      formSubmit={handleSubmit}
      handleSubmit={form => createItem.mutate({ form: { ...form, engineer_id: engineer.quotes.id }, ioId })}
      register={register}
      control={control}
      errors={errors}
    />
  )
}
