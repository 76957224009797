import { Link as ReactLink } from 'react-router-dom'

import { Heading, VStack, Link, Text } from '@chakra-ui/react'

export const SettingsNavigation = () => {
  return (
    <aside>
      <Heading color='resolveOrange' mb='1rem'>
        Settings
      </Heading>
      <VStack alignItems='flex-start' mb='2rem'>
        <Link as={ReactLink} _hover={{ color: 'resolveOrange' }} to='/settings'>
          General
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/autotask'
        >
          Autotask
        </Link>
        <Link as={ReactLink} _hover={{ color: 'resolvePurple' }} to='/templates'>
          Templates
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/mail'
        >
          Mail
        </Link>
      </VStack>
      <Text color='resolveOrange' mb='1rem' fontSize='md' fontWeight='bold'>
        Quotes
      </Text>
      <VStack alignItems='flex-start'>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/users'
        >
          Users
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/teams'
        >
          Teams
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/suppliers'
        >
          Suppliers
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/clients'
        >
          Clients
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/products'
        >
          Set Products
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/billing-codes'
        >
          Billing Codes
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/build-tiers'
        >
          Device Build Tiers
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/io-billing-codes'
        >
          Internal Billing Codes
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/payment-methods'
        >
          Payment Methods
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/sub-types'
        >
          Subscription Types
        </Link>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveOrange' }}
          to='/settings/quote-types'
        >
          Quote Types
        </Link>
      </VStack>
    </aside>
  )
}
