import { Heading, Divider, Flex, Spacer, Link } from '@chakra-ui/react'
import { useCallback, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// import { Container as QuoteItems } from './ItemList/Container'
import { Container as QuoteItems } from 'features/Quotes/components/Item/List/Container'
import { Container as QuoteList } from './QuoteList/Container'
import { Route, Routes } from 'react-router'
import { SearchContext } from 'features/Search/context/search.context'
import { LeftAside } from 'components/Layout/LeftAside'
import { RightAside } from 'components/Layout/RightAside'
import { AdvancedSearch } from './Advanced'
import { Link as ReactLink } from 'react-router-dom'

export const Search = () => {
  return (
    <Flex justifyContent='center' height='100%'>
      <LeftAside>
        <Routes>
          <Route
            path='/:quoteId?'
            element={<SearchContainer />}
          />
        </Routes>
      </LeftAside>
      <Divider
        height='100vh'
        variant='dashed'
        orientation='vertical'
        size='1px'
      />
      <RightAside>
        <Routes>
          <Route
            path='/:quoteId'
            element={<QuoteItems />}
          />
          <Route
            path='/'
            element={<AdvancedSearch />}
          />
        </Routes>
      </RightAside>
    </Flex>
  )
}

export const SearchContainer = () => {
  const { quoteId } = useParams()
  const { searchState } = useContext(SearchContext)
  const history = useNavigate()

  const handleBack = useCallback(() => {
    history('/search', { replace: true })
  }, [history, searchState])

  return (
    <>
      <Flex>
        <Heading color='resolveOrange'>Search Results</Heading>
        <Spacer />
        {
          quoteId && (
            <Link
              as={ReactLink}
              alignSelf='center'
              color='resolveOrange'
              _hover={{ color: 'gray.200' }}
              to='/search'
              state={searchState}
            >
              Back
            </Link>
          )
        }
      </Flex>
      <QuoteList />
    </>
  )
}
