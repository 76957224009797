import React from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { useColorMode } from '@chakra-ui/react'

import 'react-datepicker/dist/react-datepicker.css'
import '../styles/date-picker.css'
import enGB from 'date-fns/locale/en-GB'
registerLocale('en-GB', enGB)

export const DatePicker = ({
  selectedDate,
  onChange,
  isClearable = false,
  showPopperArrow = false,
  placeholder = 'Date',
  ...props
}) => {
  const isLight = useColorMode().colorMode === 'light'
  return (
    <div className={isLight ? 'light-theme' : 'dark-theme'}>
      <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        utcOffset={0}
        isClearable={isClearable}
        placeholderText={placeholder}
        showPopperArrow={showPopperArrow}
        locale='en-GB'
        dateFormat='dd/MM/yy'
        className='react-datapicker__input-text'
        {...props}
      />
    </div>
  )
}
