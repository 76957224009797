import { Flex, Link, Spacer, Text } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'

export const Header = ({ subId }) => {
  return (
    <Flex mb='1rem'>
      <Text color='resolveGreen' mr='1rem' fontSize='2rem' fontWeight='600'>
        Edit Subscription
      </Text>
      <Spacer />
      <Link
        as={ReactLink}
        alignSelf='center'
        color='gray.200'
        _hover={{ color: 'resolveGreen' }}
        to={`/subs/${subId}`}
      >
        Back
      </Link>
    </Flex>
  )
}
