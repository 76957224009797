import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Tooltip
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useGetGeneralSettings } from 'features/Settings/api/getGeneralSettings'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useUpdateGeneralSettings } from 'features/Settings/api/updateGeneralSettings'

const generalSettingsState = {
  VAT: 20
}

export const GeneralSettings = () => {
  const { handleSubmit, reset, register } = useForm({
    defaultValues: generalSettingsState
  })
  const onData = data => {
    reset(data)
  }
  const generalSettings = useGetGeneralSettings({ onSuccess: onData })
  const updateSettings = useUpdateGeneralSettings() 
  const handleSave = form => {
    updateSettings.mutate({ form })
  }

  if(generalSettings.error) return <SomethingBroke message='Failed to get settings' error={generalSettings.error.message} />
  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Flex flexFlow='column'>
        <Heading color='resolveOrange'>Settings</Heading>
        <FormControl mt='2rem'>
          <FormLabel color='resolveOrange'>Company Address</FormLabel>
          <Input {...register('company_address')} />
        </FormControl>
        <FormControl mb='1rem' mt='1rem'>
          <FormLabel color='resolveOrange'>VAT %</FormLabel>
          <Input {...register('VAT')} />
        </FormControl>
        <Flex>
          <FormControl mr='1rem' flexBasis='30%'>
            <Tooltip
              hasArrow
              placement='top'
              label='The minimum amount of mark up allowed before alerting to management'
            >
              <FormLabel color='resolveOrange'>Mark Up Warn %</FormLabel>
            </Tooltip>
            <Input {...register('mark_up_warn_perc')} />
          </FormControl>
          <FormControl mb='1rem'>
            <FormLabel color='resolveOrange'>Mark Up Mail</FormLabel>
            <Input {...register('mark_up_warn_mail')} />
          </FormControl>
        </Flex>
      </Flex>
      <Button type='submit' isLoading={updateSettings.isLoading} color='white' bgColor='resolveOrange'>
        Save
      </Button>
    </form>
  )
}
