import { useQuery } from "react-query"
import { axiosClient } from "lib/axiosClient"

const getAutotaskSettings = async (context) => {
  const { signal } = context
  const { data: settings } = await axiosClient({
    url: '/settings/autotask',
    signal
  })
  return settings
}

export const useGetAutotaskSettings = ({ onSuccess }) => {
  return useQuery(['settings-autotask'], {
    queryFn: getAutotaskSettings,
    onSuccess
  })
}