import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getTemplates = async (quoteId) => {
  const { data: { templates } }= await axiosClient({
    url: '/quotes/templates'
  })
  return templates.map(tmpl => ({
    value: tmpl.id,
    label: tmpl.description
  }))
}

export const useGetTemplates = () => {
  return useQuery(['quote-templates'], getTemplates)
}