import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

export const syncAutotaskCost = async (itemId) => {
  await axiosClient({
    url: `/autotask/costs/sync/${itemId}`,
    method: 'post'
  })
}

export const useSyncAutotaskCost = itemId => {
  const queryClient = useQueryClient()
  const { toastSuccess, toastFail } = useResolveToast()
  return useMutation(syncAutotaskCost, {
    onSuccess: () => {
      toastSuccess('Successfully updated cost with Autotask')
      queryClient.invalidateQueries(['item'])
    },
    onError: () => {
      toastFail('Failed to update quote')
    }
  })
}
