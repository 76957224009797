import { axiosClient } from "lib/axiosClient"
const { useQuery } = require("react-query")

const getNextIoId = async () => {
  const { data: { ioId } } = await axiosClient({ url: '/io/add' })
  return ioId
}

export const useGetNextIoId = () => {
  return useQuery('io-nextid', getNextIoId)
}

