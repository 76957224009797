import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

const createTeam = async ({ form }) => {
  await axiosClient({
    url: '/settings/teams',
    method: 'post',
    data: form
  })
}

export const useCreateTeam = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const history = useNavigate()
  return useMutation(createTeam, {
    onSuccess: () => {
      toastSuccess('Team added successfully')
      history('/settings/teams')
    },
    onError: () => {
      toastFail('Failed to add team')
    }
  })
}