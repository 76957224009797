import { Flex, Text } from '@chakra-ui/react'

export const CompanyName = ({ companyName }) => (
  <Flex
    mr='1rem'
    overflow='hidden'
    pointerEvents='none'
    justifyContent='flex-start'
    minWidth='24%'
    flexBasis='24%'
  >
    <Text
      fontWeight='bold'
      overflow='hidden'
      whiteSpace='nowrap'
      textOverflow='ellipsis'
    >
      {companyName}
    </Text>
  </Flex>
)
