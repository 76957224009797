import { FormControl, FormLabel, Input } from "@chakra-ui/react"

export const QuoteReferenceInput = ({ 
  register,
  name,
  ...restProps
}) => {
  return (
    <FormControl {...restProps} mb='1rem'>
      <FormLabel>Quote ID</FormLabel>
      <Input {...register(name)} />
    </FormControl>
  )
}