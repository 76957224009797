import { FormControl, Flex, Box } from '@chakra-ui/react'

const getItemStatusLabelFromItemStatusId = value => {
  switch (value) {
    case '8': {
      return 'Quoted'
    }
    case '7': {
      return 'Ordered'
    }
    case '4': {
      return 'Received'
    }
    case '6': {
      return 'Lost'
    }
    default: {
      return 'None'
    }
  }
}

const getBgColorFromItemStatusId = statusId => {
  switch (statusId) {
    case '8': {
      return 'resolveOrange'
    }
    case '7': {
      return 'resolveBlue'
    }
    case '4': {
      return 'resolveGreen'
    }
    case '6': {
      return 'resolveRed'
    }
    default: {
      return 'None'
    }
  }
}

const getIsSelectedColor = (id, itemStatusId) => {
  switch (String(itemStatusId) === String(id)) {
    case true: {
      return {
        bgColor: getBgColorFromItemStatusId(id),
        color: 'white'
      }
    }
    case false: {
      return {}
    }
    default: {
      return {}
    }
  }
}

const options = ['8', '7', '4', '6']

export const ItemStatusRadioGroup = ({ itemStatusId, handleItemStatusChange }) => {
  return (
    <FormControl mt='1rem' mb='1rem'>
      <Flex justifyContent='space-between'>
        {options.map((id, i) => {
          const bgColor = getIsSelectedColor(id, itemStatusId)
          return (
            <Box
              onClick={handleItemStatusChange}
              id={id}
              key={i}
              cursor='pointer'
              borderColor='grey.400'
              borderWidth='1px'
              borderRadius='md'
              {...bgColor}
              _focus={{
                boxShadow: 'outline'
              }}
              _hover={{
                boxShadow: 'rgba(0, 0, 0, .1) 0 0 0 1000000px inset',
                color: 'white',
                bg: getBgColorFromItemStatusId(id),
                cursor: 'pointer'
              }}
              px={5}
              py={2}
            >
              {getItemStatusLabelFromItemStatusId(id)}
            </Box>
          )
        })}
      </Flex>
    </FormControl>
  )
}
