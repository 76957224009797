import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation, useQueryClient } from "react-query";

const deleteBuildTier = async ({ buildTierId }) => {
  await axiosClient({
    url: `/settings/build-tiers/${buildTierId}`,
    method: 'delete'
  })
}

export const useDeleteBuildTier = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(deleteBuildTier, {
    onSuccess: () => {
      toastSuccess('Build tier deleted successfully')
      queryClient.invalidateQueries('setting-build-tiers') 
    },
    onError: () => {
      toastFail('Failed to delete build tier')
    }
  })
}