import { Flex, Text, Badge, Spacer } from '@chakra-ui/react'
import moment from 'moment'

const ListItemQuantity = ({ quantity }) => (
  <Flex
    basis='10%'
    pointerEvents='none'
    justifyContent='center'
    alignItems='center'
    mr='1rem'
  >
    <Text pointerEvents='none'>{Math.round(Number(quantity), 1)} X</Text>
  </Flex>
)

export const SubItem = ({ item, handleItemClick }) => {
  return (
    <Flex onClick={handleItemClick} width='100%'>
      <Flex
        _hover={{ boxShadow: 'rgba(0, 0, 0, .1) 0 0 0 1000000px inset' }}
        basis='100%'
        cursor='pointer'
        pt='1rem'
        pb='1rem'
      >
        <ListItemQuantity quantity={item.num_licenses} />

        <Flex
          basis='55%'
          pointerEvents='none'
          justifyContent='center'
          direction='column'
        >
          <Text fontSize='1xl' pointerEvents='none'>
            {item.supplier_id.label}
          </Text>
          <Text fontSize='sm'>{item.name}</Text>
        </Flex>

        <Spacer />

        <Flex pointerEvents='none' flexBasis='20%' direction='column'>
          <Flex justifyContent='flex-end'>
            <Flex
              direction='column'
              alignItems='flex-end'
              pointerEvents='none'
              mt='0.5rem'
              mb='0.5rem'
            >
              <Badge
                pointerEvents='none'
                mr='1rem'
                color='white'
                bg='resolveGreen'
                size='sm'
              >
                {moment(item.date_first_renewal).format('DD/MM/YY')}
              </Badge>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
