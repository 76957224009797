import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Tag,
    Link,
    Spacer,
    Spinner,
    FormHelperText,
} from '@chakra-ui/react';
import { useDeleteCost } from 'features/Quotes/api/deleteCost';
import { useCreateCost } from 'features/Quotes/api/createCost';
import { useGetAutotaskCosts } from 'features/Quotes/api/getTicketCosts';
import { useResolveToast } from 'hooks/useResolveToast';
import { useParams } from 'react-router';
import { useClearItemCharge } from 'features/Quotes/api/clearItemCharge';
import { StepperActions } from './StepperActions';
import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import { useGetQuoteItemCharge } from './api/getQuoteItemCharge';

export const AutotaskCost = () => {
    const { itemId } = useParams();
    const { toastFail } = useResolveToast();

    const orderForm = useFormContext();
    const ticketNumber = orderForm.watch('autotask_number');
    const billingCodeId = orderForm.watch('at_billing_code_id');
    const purchaseOrderNumber = orderForm.watch('client_pon');
    const itemRef = orderForm.watch('item_ref');

    const { data: costId } = useGetQuoteItemCharge({
        itemId,
        onSuccess: data => orderForm.setValue('at_cost_id', data),
    });

    const costs = useGetAutotaskCosts({ ticketNumber });
    const createCost = useCreateCost();
    const deleteCost = useDeleteCost();
    const clearItemCharge = useClearItemCharge();

    const itemCost = useMemo(() => {
        return costs.data.find(c => c.id === costId);
    }, [costId, costs]);

    const handleAddCost = async () => {
        if (!billingCodeId) {
            toastFail('Must select billing code before adding costs');
            return;
        }

        const costData = {
            at_billing_code_id: billingCodeId.value,
            purchaseOrderNumber,
            ticketNumber,
            itemId,
        };
        await createCost.mutateAsync({ cost: costData });
    };

    const handleDeleteCost = async e => {
        const selectedCost = costs.data.find(
            c => c.id === parseInt(e.target.id)
        );
        if (selectedCost) {
            await deleteCost.mutateAsync({ selectedCost, itemId });
        }
    };

    const handleClearCharge = async () => {
        await clearItemCharge.mutateAsync({ itemId });
        orderForm.setValue('at_cost_id', null);
    };

    if (costs.isLoading || costs.isFetching) {
        return (
            <>
                <FormControl mt="1rem">
                    <FormLabel>Autotask Cost</FormLabel>
                    <Spinner
                        thickness="5px"
                        emptyColor="gray.200"
                        color="resolveOrange"
                    />
                </FormControl>
                <StepperActions />
            </>
        );
    }

    if (!costId) {
        return (
            <>
                <FormControl mt="1rem">
                    <FormLabel>Autotask Cost</FormLabel>
                    <Flex flexDir="column">
                        <Flex>
                            <Button
                                variant="outline"
                                color="resolveOrange"
                                isLoading={createCost.isLoading}
                                onClick={handleAddCost}
                            >
                                Add Cost
                            </Button>
                        </Flex>
                    </Flex>
                </FormControl>
                <StepperActions />
            </>
        );
    }

    if (costId && !itemCost) {
        return (
            <>
                <FormControl mt="1rem">
                    <FormHelperText>
                        Item has an associated charge, but it wasn't found on
                        the ticket. Click the below button to clear the Charge
                        from the Item and then add the Charge again
                    </FormHelperText>
                    <Button
                        variant="outline"
                        mt="1rem"
                        color="resolveOrange"
                        isLoading={createCost.isLoading}
                        onClick={handleClearCharge}
                    >
                        Clear Cost
                    </Button>
                </FormControl>
                <StepperActions />
            </>
        );
    }

    return (
        <>
            <FormControl mt="1rem">
                <FormLabel>Autotask Cost</FormLabel>
                {costs.data
                    .filter(c => c.internalPurchaseOrderNumber === itemRef)
                    .map(cost => (
                        <Flex key={cost.id}>
                            <Tag color="green">
                                <Link
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://ww4.autotask.net/Autotask/views/ServiceDesk/ServiceDeskTicket/service_ticket_panel_edit.aspx?ticketRenderType=Cost&GenericId=${cost.id}&ticketID=${cost.ticketID}`}
                                >
                                    {cost.name}
                                </Link>
                            </Tag>
                            <Spacer />
                            <Button
                                size="xs"
                                color="resolveRed"
                                variant="ghost"
                                id={cost.id}
                                isLoading={deleteCost.isLoading}
                                onClick={handleDeleteCost}
                            >
                                Delete
                            </Button>
                        </Flex>
                    ))}
            </FormControl>
            <StepperActions />
        </>
    );
};
