import { Link as ReactLink } from 'react-router-dom'
import { Heading, VStack, Link } from '@chakra-ui/react'

export const SubscriptionNavigation = () => {
  return (
    <aside>
      <Heading color='resolveGreen' mb='1rem'>
        Subscriptions
      </Heading>
      <Link as={ReactLink} _hover={{ color: 'resolveGreen' }} to='/subs/add'>
        New Subscription
      </Link>
      <Heading mt='2rem' mb='1rem' fontSize='3xl' color='resolveGreen'>
        Settings
      </Heading>
      <VStack alignItems='flex-start'>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveGreen' }}
          to='/settings/sub-types'
        >
          Subscription Types
        </Link>
      </VStack>
    </aside>
  )
}
