import {
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  Flex
} from '@chakra-ui/react'

import { QuotesIIcon } from 'components/QuotesIIcon'

export const IONumberInput = ({ register, name }) => (
  <Flex mr='1rem' flexBasis='30%'>
    <FormControl id='ioId'>
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <QuotesIIcon color='resolveBlue' />
        </InputLeftElement>
        <Input type='number' {...register(name)} />
      </InputGroup>
    </FormControl>
  </Flex>
)
