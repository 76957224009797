import { Link as ReactLink } from 'react-router-dom'
import { Flex, Text, Link, Spacer } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

export const Header = () => {
  const { ioId, itemId } = useParams()

  return (
    <Flex mb='1rem'>
      <Text color='resolveBlue' mr='1rem' fontSize='2rem' fontWeight='600'>
        Invoice Item - {itemId}
      </Text>
      <Spacer />
      <Link
        as={ReactLink}
        alignSelf='center'
        color='resolveBlue'
        _hover={{ color: 'gray.200' }}
        to={`/internal/${ioId}/edit/${itemId}`}
      >
        Back
      </Link>
    </Flex>
  )
}
