import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";

const createSupplier = async ({ form }) => {
  const hForm = {
    ...form,
    default_billing_code: form.default_billing_code.value
  }
  await axiosClient({
    url: '/settings/suppliers',
    method: 'post',
    data: hForm
  })
}

export const useCreateSupplier = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const history = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(createSupplier, {
    onSuccess: () => {
      toastSuccess('Supplier added successfully')
      queryClient.invalidateQueries(['settings-suppliers'])
      queryClient.invalidateQueries(['form-suppliers'])
      history('/settings/suppliers')
    },
    onError: () => {
      toastFail('Failed to add supplier')
    }
  })
}