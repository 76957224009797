import { FormControl, Text } from '@chakra-ui/react'

import { axiosClient } from 'lib/axiosClient'
import { useQuery } from 'react-query'
import { ControlledSelect } from './ControlledSelect'

const ProductTypes = {
  1: 'goods',
  2: 'subscriptions'
}

const getProducts = async (context) => {
  const { signal, queryKey } = context
  const [_, supplierId, type] = queryKey
  const { data: { products } } = await axiosClient({
    url: `/products/${supplierId.value}/${type.value}`, 
    signal
  }) 

  return products.map(p => ({ value: p.id, label: p.description}))
}
const useGetProducts = ({ type, supplierId }) => {
  return useQuery(['form-set-products', supplierId, type], {
    queryFn: getProducts,
    placeholderData: [],
    staleTime: Infinity,
    enabled: !!(type?.value && supplierId.value)
  })
}
export const SetProductSelectInput = ({
  type,
  required,
  name,
  supplierId,
  control
}) => {
  const products = useGetProducts({ supplierId, type })
  if (products.data.length < 1) {
    return (
      <FormControl mb='1rem' color='resolveRed'>
        <Text>No set {ProductTypes[type.value]}</Text>
      </FormControl>
    )
  }
  return (
    <FormControl mb='1rem' isRequired={required}>
      <ControlledSelect 
        isLoading={products.isLoading}
        control={control}
        rules={true}
        options={products.data}
        name={name}
        selectedOptionColor='resolveOranger'
        placeholder='Select a set product / subscription'
      />
    </FormControl>
  )
}
