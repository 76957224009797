import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getProduct = async (context) => {
  const { signal, queryKey } = context 
  const [_, productId] = queryKey
  const { data: product } = await axiosClient({ url: `/products/${productId}`, signal })
  return product
}

export const useGetProduct = ({ productId, onSuccess }) => {
  const { toastFail } = useResolveToast()
  return useQuery(['quote-set-product', productId], {
    queryFn: getProduct,
    enabled: !!productId,
    onSuccess,
    onError: () => {
      toastFail('Failed to load product')
    }
  })
}