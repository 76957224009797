import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const PAGE_SIZE = 10

const getTemplates = async (context) => {
  const { signal, queryKey } = context
  const [_key, page] = queryKey
  const { data: templates } = await axiosClient({
    url: `/quotes/templates?limit=${PAGE_SIZE}&page=${page}`,
    signal
  })
  return templates
}

export const useGetTemplates = ({ page }) => {
  return useQuery(['templates', page], {
    queryFn: getTemplates,
    placeholderData: { resultCount: [], templates: [] }
  })
}