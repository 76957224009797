import { useController } from 'react-hook-form'

import { createFilter, Select } from 'chakra-react-select'

export const ControlledSelect = ({
  control,
  name,
  rules,
  options,
  isLoading,
  ...props
}) => {
  const {
    field: { onChange, onBlur, value, ref }
  } = useController({
    name,
    control,
    rules
  })

  return (
    <Select
      name={name}
      ref={ref}
      filterOption={createFilter({ ignoreAccents: false, matchFrom: 'start' })}
      onChange={onChange}
      isLoading={isLoading}
      onBlur={onBlur}
      value={value}
      options={options}
      {...props}
    />
  )
}
