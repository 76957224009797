import { Flex, Text } from '@chakra-ui/react'

export const IONumber = ({ ioId, isSelected }) => (
  <Flex justifyContent='center' ml='1rem' flexBasis='10%'>
    <Text
      fontWeight='bold'
      color={isSelected ? 'white' : 'resolveBlue'}
      mr='0.2rem'
      pointerEvents='none'
    >
      IO
    </Text>
    <Text fontWeight='bold' pointerEvents='none'>{ioId}</Text>
  </Flex>
)
