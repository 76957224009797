import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getBillingCodes = async (context) => {
  const { signal } = context
  const { data: billingCodes } = await axiosClient({
    url: '/settings/billing-codes',
    signal
  })

  return billingCodes
}

export const useGetBillingCodes = () => {
  return useQuery(['settings-billing-codes'], {
    queryFn: getBillingCodes,
    placeholderData: [],
    staleTime: Infinity
  })
}