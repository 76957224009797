import { useState } from 'react'
import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Spacer,
  Heading
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { Loading } from 'components/Loading'
import { DateRange } from '../../DateRangePicker'
import { getFirstOfMonthDate } from 'utils'
import { QuotationTable } from './Table'
import { useFilter } from './useFilter'
import { useGetQuotationReport } from 'features/Reports/api/getQuotationReport'

const options = [
  { label: 'Quoted', value: 8 },
  { label: 'Ordered', value: 7 },
  { label: 'Received', value: 4 },
  { label: 'Lost', value: 6 }
]
export const Quotation = () => {
  const [status, setStatus] = useState()
  const [startDate, setStartDate] = useState(getFirstOfMonthDate())
  const [endDate, setEndDate] = useState(new Date())

  const { filterString } = useFilter({ status, startDate, endDate })

  const report = useGetQuotationReport(filterString)

  const handleStatusSelect = newStatus => {
    if (newStatus) {
      setStatus(newStatus.value)
    } else {
      setStatus(null)
    }
  }

  console.log(report.data)
  return (
    <Flex mt='1rem' justifyContent='flex-start' width='80%' flexFlow='column'>
      <HStack mt='1rem' mb='1rem'>
        <Heading color='resolveOrange'>Quotation Report</Heading>
        <Spacer />
        <HStack w='40%'>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select
              options={options}
              isClearable
              onChange={handleStatusSelect}
              selectedOptionColor='resolveOranger'
            />
          </FormControl>
        </HStack>
      </HStack>
      { report.isFetching ? <Loading /> : ( report.isSuccess && <QuotationTable tableData={report.data} />)}
    </Flex>
  )
}
