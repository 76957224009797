import { Button, Flex, Spacer, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react"
import { currencyFormatter } from "features/Reports/utils"
import moment from "moment"
import { useMemo } from "react"
import { Pagination } from "features/Reports/Pagination"
import { AiFillFileExcel } from "react-icons/ai"
import { usePagination, useTable } from "react-table"
import { useExportData } from "react-table-plugins"
import { getExportFileBlob, calculateTotalForProperty } from "features/Reports/utils"

const quoteStatusColors = new Map()
quoteStatusColors.set('Ordered', 'resolveBlue')
quoteStatusColors.set('Quoted', 'resolveOrange')
quoteStatusColors.set('Received', 'resolveBlue')
quoteStatusColors.set('Lost', 'resolveRed')

export const QuotationTable = ({tableData}) => {

  const columns = useMemo(
    () => ([
      {
        Header: 'Item',
        accessor: 'item_ref',
        Footer: (
          <span>
            <strong>Total</strong>
          </span>
        )
      },
      {
        Header: 'Description',
        accessor: 'description',
        show: false
      },
      {
        Header: 'Date Quoted',
        accessor: 'date_quoted',
        Cell: props => <Text>{moment(props.value).format('DD/MM/YY')}</Text>
      },
      {
        Header: 'Cost',
        accessor: 'cost',
        Cell: props => <Text>£{props.value}</Text>,
        Footer: (
          <Text fontWeight='bold' color='resolveOrange'>
            {currencyFormatter.format(calculateTotalForProperty(tableData, 'cost'))}
          </Text>
        )
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: props => <Text>£{props.value}</Text>,
        Footer: (
          <Text fontWeight='bold' color='resolveOrange'>
            {currencyFormatter.format(calculateTotalForProperty(tableData, 'price'))}
          </Text>
        )
      },
      {
        Header: 'Mark Up',
        accessor: 'mark_up_percentage',
        Cell: props => <Text>{props.value} %</Text>
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: props => <Text color={quoteStatusColors.get(props.value)}>{props.value}</Text>
      }
    ]), [tableData])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    footerGroups,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    exportData,
    state: { pageIndex }
  } = useTable(
    { columns, data: tableData, getExportFileBlob, initialState: { hiddenColumns: [] }},
    usePagination,
    useExportData
  )

  return (
    <>
          <Flex justifyContent='center'>
            <Table {...getTableProps()}>
              <Thead>
                {headerGroups.map(headerGroup => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <Th  {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                      ))}
                    </Tr>
                  )
                })}
              </Tbody>
              <Tfoot>
                {footerGroups.map(group => (
                  <Tr {...group.getFooterGroupProps()}>
                    {group.headers.map(column => (
                      <Td {...column.getFooterProps()}>
                        {column.render('Footer')}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tfoot>
            </Table>
          </Flex>
          <Flex justifyContent='center' flexFlow='row'>
        <Spacer />
        <Pagination
          prevClick={previousPage}
          nextClick={nextPage}
          pageCount={pageCount}
          page={pageIndex}
          onPageSearch={gotoPage}
        />
        <Spacer />
        <Flex mt='1rem'>
          <Button
            mr='1rem'
            rightIcon={<AiFillFileExcel color='green' />}
            onClick={() => {
              exportData('csv', true)
            }}
          >
            Export
          </Button>
        </Flex>
      </Flex>
    </>
  )
}