import { useQuery } from "react-query"
import { axiosClient } from "lib/axiosClient"

const getMailSettings = async (context) => {
  const { signal } = context
  const { data: settings } = await axiosClient({
    url: '/settings/mail',
    signal
  })
  return settings
}

export const useGetMailSettings = ({ onSuccess }) => {
  return useQuery(['settings-mail'], {
    queryFn: getMailSettings,
    onSuccess
  })
}