import { useParams } from 'react-router';
import { useContext } from 'react';
import { Steps, Step } from 'chakra-ui-steps';
import { BillingDetailsForm } from './BillingDetailsForm';
import { OrderDetailsForm } from './OrderDetailsForm';
import { AutotaskTicketSetup } from './AutotaskTicketSetup';
import { AutotaskCost } from './AutotaskCost';
import { StepContext } from './context/stepper.context';
import { FormProvider, useForm } from 'react-hook-form';
import { Center, Text, Flex } from '@chakra-ui/react';
import { StepperActions } from './StepperActions';

export const steps = [
    { label: 'Billing Details', Component: BillingDetailsForm },
    { label: 'Order Details', Component: OrderDetailsForm },
    { label: 'Autotask Ticket', Component: AutotaskTicketSetup },
    { label: 'Autotask Cost', Component: AutotaskCost },
];

const OrderFormDefaultValues = {
    ticket_description: null,
    new_user: false,
    new_user_details: null,
    device_build_tier: null,
    services: null,
    notes: null,
    follow_up: false,
    estimated_hours: '0',
    client_pon: '',
    payment_method_id: '',
    at_billing_code_id: '',
    client_id: { value: null, label: '' },
    contact_id: '',
    supplier_order_number: '',
    item_ref: '',
    at_cost_id: '',
    autotask_number: '',
    date_ordered: '',
};

const Completed = ({ back }) => (
    <>
    <Center>
        <Flex textAlign="center" direction="column">
            <Text fontWeight="bold" size="md">
                Item Ordered
            </Text>
        </Flex>
    </Center>
    <StepperActions />
    </>
);

export const OrderStepper = () => {
    const { itemId } = useParams();

    const { activeStep } = useContext(StepContext);

    const orderForm = useForm({
        defaultValues: {
            ...OrderFormDefaultValues,
            item_id: itemId,
        },
    });

    return (
        <FormProvider {...orderForm}>
            <Steps
                colorScheme="resolveOranger"
                activeStep={activeStep}
                mt="1rem"
                mb="1rem"
            >
                {steps.map((step, index) => {
                    const ActiveStep = steps[index].Component;
                    return (
                        <Step key={step.label} label={step.label}>
                            <ActiveStep />
                        </Step>
                    );
                })}
            </Steps>

            {activeStep === steps.length && <Completed />}

        </FormProvider>
    );
};
