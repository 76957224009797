import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

export const setBulkReceived = async ({ items, form, engineerId }) => {
  await axiosClient({
    url: '/quotes/bulk/received',
    method: 'post',
    data: {
      items, 
      form,
      engineerId
    }
  })
}

export const useBulkReceive = quoteId => {
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(setBulkReceived, {
    onSuccess: () => {
      history(`/quotes/${quoteId}`)
      toastSuccess('Items set to received successfully')
      queryClient.invalidateQueries(['quotes'])
      queryClient.invalidateQueries(['quote-items'])
    },
    onError: () => {
      toastFail('Failed to set items to Received')
    }
  })
}
