import { Link as ReactLink, useNavigate } from 'react-router-dom'
import { Flex, Text, Link, Spacer, Button } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

export const Header = ({ onCopyItem, onEditQuote }) => {
  const { quoteId, itemId } = useParams()
  const history = useNavigate()
  const goBack = () => {
    history(-1)
  }
  return (
    <Flex mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        Edit Item
      </Text>
      <Button
        size='xs'
        alignSelf='center'
        bg='gray.600'
        onClick={onEditQuote}
        color='white'
        mr='1rem'
      >
        Edit Quote
      </Button>
      <Button
        size='xs'
        alignSelf='center'
        bg='resolveOrange'
        onClick={() => onCopyItem(itemId)}
        color='white'
      >
        Copy Item
      </Button>
      <Spacer />
      <Link
        as={ReactLink}
        alignSelf='center'
        color='resolveOrange'
        onClick={goBack}
        _hover={{ color: 'gray.200' }}
      >
        Back
      </Link>
    </Flex>
  )
}
