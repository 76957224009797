import { Link as ReactLink } from 'react-router-dom'
import { VStack, Link, Heading } from '@chakra-ui/react'

export const IONavigation = () => {
  return (
    <aside>
      <Heading mb='1rem' color='resolveBlue'>
        Internal
      </Heading>
      <VStack alignItems='flex-start'>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolveBlue' }}
          to='/internal/add'
        >
          New Order
        </Link>
      </VStack>
    </aside>
  )
}
