import { useParams, Link as ReactLink } from 'react-router-dom'
import { Button, Flex, FormLabel, Link, Spacer, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { ItemClientCostInput } from 'components/Form/ItemClientCostInput'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput'
import { ItemInternalDescriptionInput } from 'components/Form/ItemInternalDescriptionInput'
import { ItemMarkupInput } from 'components/Form/ItemMarkupInput'
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput'
import { ItemQuantityInput } from 'components/Form/ItemQuantityInput'
import { ItemUnitPriceInput } from 'components/Form/ItemUnitPriceInput'
import { MinimumTermSelectInput } from 'components/Form/MinimumTermSelectInput'
import { PaymentPlanSelectInput } from 'components/Form/PaymentPlanSelectInput'
import { SupplierProductTypeSelectInput } from 'components/Form/SupplierProductTypeSelectInput'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { useGetTemplateItem } from 'features/Templates/api/getTemplateItem'
import { useDeleteTemplateItem } from 'features/Templates/api/deleteTemplateItem'
import { useUpdateTemplateItem } from 'features/Templates/api/updateTemplateItem'
import { Loading } from 'components/Loading'

export const EditTemplateItem = () => {
  const { templateId, itemId } = useParams()

  const {
    handleSubmit,
    watch,
    control,
    register,
    reset,
    isSubmitting,
    setValue,
    formState: { errors }
  } = useForm()

  const productTypeId = watch('type_id')

  const onTemplateItemData = item => {
    reset(item)
  }
  const item = useGetTemplateItem({ itemId, onSuccess: onTemplateItemData })
  const updateItem = useUpdateTemplateItem({ itemId })
  const deleteItem = useDeleteTemplateItem({ templateId })

  const handleSave = form => {
    updateItem.mutate({ form, itemId })
  }

  if(item.isFetching || item.isLoading) return <Loading />
  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Flex mb='1rem'>
        <Text color='resolvePurple' mr='1rem' fontSize='2rem' fontWeight='600'>
          Edit Item
        </Text>
        <Spacer />
        <Link
          as={ReactLink}
          alignSelf='center'
          color='resolvePurple'
          _hover={{ color: 'gray.200' }}
          to={`/templates/${templateId}`}
        >
          Back
        </Link>
      </Flex>
      <SupplierSelectInput
        control={control}
        register={register}
        required
        ignoreOtherSupplier={false}
        errors={errors}
        name='supplier_id'
        selectedOptionColor='resolvePurpler'
      />

      <SupplierProductTypeSelectInput
        control={control}
        errors={errors}
        name='type_id'
      />

      <Flex mb='1rem'>
        <ItemPartCodeInput
          register={register}
          errors={errors}
          name='part_code'
          showLabel
          flexBasis='35%'
          mr='1rem'
        />
        <ItemDescriptionInput
          register={register}
          errors={errors}
          name='description'
        />
      </Flex>

      <ItemInternalDescriptionInput
        register={register}
        name='internal_description'
        errors={errors}
      />

      <Flex mb='1rem'>
        <ItemQuantityInput
          control={control}
          name='quantity'
          mr='1rem'
          errors={errors}
          selectedOptionColor='resolvePurpler'
        />
        <ItemUnitPriceInput
          control={control}
          name='unit_price'
          errors={errors}
          setValue={setValue}
        />
        <ItemMarkupInput
          control={control}
          name='mark_up_percentage'
          errors={errors}
          setValue={setValue}
        />
      </Flex>

      <Flex mb='1rem'>
        <ItemClientCostInput
          control={control}
          name='client_cost'
          errors={errors}
          setValue={setValue}
        />
        <ItemDeliveryPriceInput
          control={control}
          name='delivery_price'
          errors={errors}
        />
      </Flex>

      {productTypeId === 2 && (
        <>
          <FormLabel mt='1rem'>
            Subscription Options
          </FormLabel>
          <Flex mb='2rem'>
            <MinimumTermSelectInput
              required
              control={control}
              errors={errors}
              name='minimum_term_id'
              mr='1rem'
            />
            <PaymentPlanSelectInput
              required
              control={control}
              errors={errors}
              name='payment_plan_id'
            />
          </Flex>
        </>
      )}

      <Button
        variant='solid'
        backgroundColor='resolvePurple'
        color='white'
        _hover={{ bg: 'gray', color: 'resolvePurple' }}
        type='submit'
        mr='1rem'
        isLoading={isSubmitting}
      >
        Save
      </Button>
      <Button 
        variant='outline'
        color='resolvePurple'
        onClick={() => deleteItem.mutate({ itemId })}
      >
        Delete Item
      </Button>
    </form>
  )
}
