import { useCallback } from 'react'
import { useToast } from '@chakra-ui/react'

export const useResolveToast = () => {
  const toast = useToast()

  const toastSuccess = useCallback(
    (title, message) => {
      toast({
        title,
        description: message,
        status: 'success',
        duration: 3000,
        isClosable: true
      })
    },
    [toast]
  )

  const toastFail = useCallback(
    (title, message) => {
      toast({
        title,
        description: message,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    },
    [toast]
  )

  return { toastSuccess, toastFail }
}
