import { Flex, Text } from '@chakra-ui/react'
import moment from 'moment'

export const ListItemDateAdded = ({ date }) => (
  <Flex
    pointerEvents='none'
    alignItems='flex-start'
    justifyContent='center'
    minWidth='12%'
    flexBasis='12%'
  >
    <Text fontSize='xs' fontWeight='bold'>
      {moment(date).format('DD/MM/YY')}
    </Text>
  </Flex>
)
