import { FormLabel, FormControl, Input } from '@chakra-ui/react'

export const ClientPOInput = ({
  register,
  required,
  name,
  labelText = 'Client P/O',
  ...props
}) => {
  return (
    <FormControl isRequired={required} {...props}>
      <FormLabel>{labelText}</FormLabel>
      <Input {...register(name, { required })} type='text' mr='1rem' />
    </FormControl>
  )
}
