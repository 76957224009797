import { Center, Flex, Text, Button, Heading } from '@chakra-ui/react'
import { TiVendorMicrosoft } from 'react-icons/ti'
import { useMsal } from '@azure/msal-react'
import { useCallback } from 'react'

export const Login = () => {
  const { instance } = useMsal()

  const handleLogIn = useCallback(() => {
    instance.loginRedirect()
  }, [instance])

  return (
      <Center h='80vh'>
        <Flex direction='column' justifyContent='center'>
          <Heading mb='1rem'>
            Resolve{' '}
            <Text display='inline' color='resolveOrange'>
              Q
            </Text>
            uotes
          </Heading>
          <Button
            rightIcon={<TiVendorMicrosoft color='#F26B21' />}
            onClick={handleLogIn}
          >
            Sign In
          </Button>
          <Text mt='1rem' fontSize='xs' textAlign='center' color='gray.300'>
            Sign in with your Microsoft Office account
          </Text>
        </Flex>
      </Center>
  )
}
