import { useState, useCallback, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useResolveToast } from 'hooks/useResolveToast'

import { Header } from './Header'
import { ItemList } from './index'
import { Actions } from './Actions'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useGetQuoteItems } from 'features/Quotes/api/getQuoteItems'
import { useBulkAction } from 'features/Quotes/api/bulkAction'
import { UserContext } from 'features/Auth/context/user.context'

export const Container = () => {
  const { quoteId, itemId } = useParams()
  const [selectedBulkItems, setSelectedBulkItems] = useState([])
  const { engineer } = useContext(UserContext)
  const items = useGetQuoteItems({ quoteId })
  const history = useNavigate()
  const { toastFail } = useResolveToast()

  const handleItemClick = useCallback(
    e => {
      if (itemId !== e.target.id) {
        const newPath = `/quotes/${quoteId}/edit/${e.target.id}`
        history(newPath)
      }
    },
    [quoteId, itemId, history]
  )

  const bulkAction = useBulkAction()

  const handleBulkAction = useCallback(
    async action => {
      if (selectedBulkItems.length === 0) {
        toastFail('Select some items to update')
        return
      }
      if (action === 'invoiced') {
        history(
          `/quotes/${quoteId}/invoice?items=${selectedBulkItems.join(',')}`
        )
        return
      }
      if (action === 'received') {
        history(
          `/quotes/${quoteId}/received?items=${selectedBulkItems.join(',')}`
        )
        return
      }
      if (action === 'ordered') {
        history(
          `/quotes/${quoteId}/order?items=${selectedBulkItems.join(',')}`
        )
        return
      }
      console.log('selectedBulkIems: ', selectedBulkItems)
      bulkAction.mutate({ action, selectedBulkItems, engineerId: engineer.quotes.id, quoteId })
    },
    [
      selectedBulkItems,
      history,
      quoteId,
      toastFail,
      bulkAction,
      engineer
    ]
  )

  const handleItemBulkSelect = useCallback(
    e => {
      let targetId = Number(e.target.id)
      let index = selectedBulkItems.indexOf(targetId)
      if (index === -1) {
        setSelectedBulkItems([...selectedBulkItems, targetId])
        return
      }
      if (selectedBulkItems.length === 1) {
        setSelectedBulkItems([])
        return
      }
      setSelectedBulkItems([
        ...selectedBulkItems.filter(item => item !== targetId)
      ])
    },
    [selectedBulkItems]
  )

  const handleSelectAll = useCallback(
    e => {
      const isChecked = e.target.checked
      if (isChecked) {
        setSelectedBulkItems(items.data.map(e => e.id))
      } else {
        setSelectedBulkItems([])
      }
    },
    [items]
  )

  useEffect(() => {
    setSelectedBulkItems([])
  }, [setSelectedBulkItems, quoteId])

  if (items.isLoading || items.isFetching ) return <Loading />
  if (items.error) return <SomethingBroke message='Failed to fetch items' error={items.error.message} />
  return (
    <>
      <Header
        handleBulkAction={handleBulkAction}
        quoteId={quoteId}
        handleSelectAll={handleSelectAll}
      />
      <ItemList
        items={items.data}
        handleItemClick={handleItemClick}
        selectedBulkItems={selectedBulkItems}
        handleItemBulkSelect={handleItemBulkSelect}
      />
      <Actions items={items.data} quoteId={quoteId} selectedBulkItems={selectedBulkItems} />
    </>
  )
}
