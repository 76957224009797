import React from 'react'
import { Center, Divider, Text } from '@chakra-ui/react'
import { ListItem } from './ListItem'
import { ItemListLayout } from 'components/Layout/ItemListLayout'

const NoItemsFound = () => (
  <ItemListLayout>
    <Center>
      <Text color='resolveBlue'>No items found</Text>
    </Center>
  </ItemListLayout>
)

export const ItemList = ({ handleItemClick, items }) => {
  if(items.length < 1) return <NoItemsFound />
  return (
    <ItemListLayout>
      {items.map((item, i) => {
        return (
          <div key={item.item_id}>
            <ListItem item={item} handleItemClick={handleItemClick} />
            <Divider pointerEvents='none' />
          </div>
        )
      })}
    </ItemListLayout>
  )
}
