import { useState, useRef, useCallback, useContext } from 'react'
import {
  useDisclosure,
  Modal,
  Icon,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  IconButton,
  FormControl,
  FormLabel,
  Text,
  Textarea,
  Box,
  Image,
  Flex,
  HStack,
  Kbd
} from '@chakra-ui/react'
import { AiFillDelete, AiFillBug } from 'react-icons/ai'
import { BiPaste, BiMailSend } from 'react-icons/bi'
import { IoIosInformationCircle } from 'react-icons/io'
import { FaWindows } from 'react-icons/fa'
import { UserContext } from 'features/Auth/context/user.context'
import { useBugReport } from './bugReport'
import { useResolveToast } from 'hooks/useResolveToast'

const Hint = () => {
  return (
    <Flex mb='1rem'>
      <Icon
        mt='1rem'
        w={6}
        h={6}
        color='resolveOrange'
        as={IoIosInformationCircle}
      />
      <Flex>
        <Text ml='1rem' color='gray.400' fontSize='xs'>
          Hint: Use <Icon ml='0.2rem' as={FaWindows} /> + <Kbd>shift</Kbd> + <Kbd mr='0.2rem'>S</Kbd> to snip a
          section of your screen. You can close this window at any time, your
          input will be saved until the page is reloaded
        </Text>
      </Flex>
    </Flex>
  )
}

const BetaTestHelper = () => {
  const [blobs, setBlobs] = useState([])
  const [details, setDetails] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const pasteRef = useRef()
  const detailsRef = useRef()

  const { toastFail } = useResolveToast()
  const { engineer } = useContext(UserContext)

  const handleDetailsChange = e => {
    const text = e.target.value
    setDetails(text)
  }

  const handlePasteEvent = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      if (blobs.length >= 6) {
        toastFail('Please do not send more than 6 images')
        return
      }
      const file = e.clipboardData.items[0].getAsFile()
      if (!file) {
        toastFail({
          title: 'Failed to paste',
          message: 'Try copying an image instead'
        })
      } else {
        const imageUrl = URL.createObjectURL(file)
        const reader = new FileReader()
        reader.addEventListener('load', function () {
          setBlobs(pBlobs => [...pBlobs, { imageUrl, base: this.result }])
        })
        reader.readAsDataURL(file)
      }
    },
    [toastFail, setBlobs, blobs]
  )


  const bugReport = useBugReport()
  const sendFeedback = useCallback(async () => {
      await bugReport.mutateAsync({
        blobs,
        data: { engineer: engineer.quotes.ad_username, details }
      })
      setBlobs([])
      setDetails('')
      detailsRef.current.value = ''
      onClose()
  }, [blobs, onClose, detailsRef, details, engineer])

  const handleImageDelete = useCallback(
    e => {
      const idx = e.target.id
      const copy = [...blobs]
      copy.splice(idx, 1)
      setBlobs(copy)
    },
    [setBlobs, blobs]
  )

  return (
    <>
      <Modal isOpen={isOpen} size='xl' onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text color='resolveOrange'>QuotesJS Beta Feedback</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={0}>
            <FormControl id='Name'>
              <FormLabel color='resolveOrange'>Details</FormLabel>
              <Textarea
                ref={detailsRef}
                onChange={handleDetailsChange}
                defaultValue={details}
              />
            </FormControl>
            <Box
              mt='1rem'
              _hover={{
                boxShadow: 'rgba(0, 0, 0, .1) 0 0 0 1000000px inset',
                cursor: 'pointer'
              }}
              borderRadius='4px'
              mb='1rem'
              p='1rem'
              ref={pasteRef}
              color='resolveOrange'
              onPaste={handlePasteEvent}
              width='100%'
              borderColor='black'
              textAlign='center'
            >
              <Icon as={BiPaste} mr='1rem' w={25} h={25} />
              Paste Here
            </Box>
            <Flex wrap='wrap' justifyContent='center'>
              {blobs.map((b, i) => (
                <Box
                  id={`${i}`}
                  key={i}
                  position='relative'
                  mr='1rem'
                  mb='1rem'
                  borderWidth='2px'
                  borderColor='gray.400'
                >
                  <IconButton
                    icon={<AiFillDelete />}
                    aria-label='Delete image'
                    color='resolveOrange'
                    position='absolute'
                    id={`${i}`}
                    top={2}
                    left={2}
                    onClick={handleImageDelete}
                  />
                  <Image height={150} width={150} id={`${i}`} src={b.imageUrl} />
                </Box>
              ))}
            </Flex>

            <Hint />

            <Flex justifyContent='center' mb='1.5rem'>
              <Button
                w='100%'
                bg='resolveOrange'
                rightIcon={<BiMailSend size={25} />}
                color='white'
                isLoading={bugReport.isLoading}
                onClick={sendFeedback}
              >
                Send
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Flex dir='column' id='beta-helper' justifyContent='center'>
        <HStack>
          <Box position='fixed' bottom={50} right={50}>
            <Button
              color='resolveOrange'
              onClick={onOpen}
              leftIcon={<AiFillBug color='resolveOrange' />}
            >
              Report Bug
            </Button>
          </Box>
        </HStack>
      </Flex>
    </>
  )
}

export default BetaTestHelper
