import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation } from "react-query"

const updateSettings = async ({ form }) => {
  await axiosClient({
    url: '/settings/mail',
    method: 'post',
    data: form
  })
}

export const useUpdateMailSettings = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(updateSettings, {
    onSuccess: () => {
      toastSuccess('Mail settings updated successfully')
    },
    onError: () => {
      toastFail('Failed to update settigns')
    }
  })
}