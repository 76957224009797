import { useResolveToast } from "hooks/useResolveToast"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

const { axiosClient } = require("lib/axiosClient")

const deleteSub = async ({ subId }) => {
  await axiosClient({
    url: `/subs/${subId}`,
    method: 'delete'
  })
}

export const useDeleteSub = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  const history = useNavigate()
  return useMutation(deleteSub, {
    onSuccess: () => {
      toastSuccess('Sub deleted successfully')
      queryClient.invalidateQueries('subs')
      history('/subs')
    },
    onError: () => {
      toastFail('Failed to delete Sub')
    }
  })
}