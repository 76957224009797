
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import App from 'App';
import { msalConfig } from 'authConfig';
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import customTheme from 'styles/theme';
import { PublicClientApplication, EventType } from '@azure/msal-browser'

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

const container = document.getElementById('root');
const root = createRoot(container)
const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } }})
root.render(  
  <StrictMode>
    <Router>
      <ChakraProvider theme={customTheme}>
        <ColorModeScript />
        <QueryClientProvider client={queryClient}>
          <App pca={msalInstance} />
        </QueryClientProvider>
      </ChakraProvider>
    </Router>
  </StrictMode>
);