import { axiosClient } from 'lib/axiosClient'
import { useQuery } from 'react-query'

const PAGE_SIZE = 10

export const getInternalOrders = async (context) => {
  const { queryKey } = context
  const [_, page, filterString] = queryKey
  const { data: result } = await axiosClient({
    url: `/io?limit=${PAGE_SIZE}&page=${page}${filterString}`
  })
  return result
}

export const useInternalOrders = ({ page, filterString }) => {
  return useQuery(['internal-orders', page, filterString], { 
    queryFn: getInternalOrders,
    placeholderData: { resultCount: 0, IOs: []} })
}