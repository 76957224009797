import { useCallback, useContext, useEffect, useState } from 'react'
import { useParams, Link as ReactLink, useSearchParams } from 'react-router-dom'
import {
  Button,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Spacer,
  Link,
  HStack,
  Badge
} from '@chakra-ui/react'
import { useForm, useWatch } from 'react-hook-form'
import { UserContext } from 'features/Auth/context/user.context'
import { ClientPOInput } from 'components/Form/ClientPOInput'
import { PaymentMethodSelectInput } from 'components/Form/PaymentMethodSelectInput'
import { BillingCodeSelect } from 'components/Form/BillingCodeSelect'
import { useBulkOrder } from 'features/Quotes/api/bulkOrder'
import { useCreateBulkAutotaskTicket } from 'features/Quotes/api/createBulkAutotaskTicket'
import { useGetQuote } from 'features/Quotes/api/getQuote'
import { useResolveToast } from 'hooks/useResolveToast'
import { useQueryClient } from 'react-query'

const initialState = {
  autotask_number: '',
  client_pon: '',
  payment_method_id: undefined,
  client_id: '',
  at_billing_code_id: ''
}

export const OrderForm = () => {
  const [searchParams] = useSearchParams()
  const { quoteId } = useParams()
  const items = searchParams.get('items').split(',')
  const { toastFail, toastSuccess } = useResolveToast()

  const [ticketTitle, setTicketTitle] = useState(null)
  const [clientName, setClientName] = useState('')
  const queryClient = useQueryClient()
  const { engineer } = useContext(UserContext)

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useForm({ defaultValues: initialState })

  const autotask_number = watch('autotask_number') 
  const client_id = watch('client_id')

  const handleAutotaskTicketSetup = useCallback(() => {
    setTicketTitle(`Sale for ${clientName}`)
  }, [clientName])

  const bulkOrder = useBulkOrder(quoteId)
  const createBulkTicket = useCreateBulkAutotaskTicket({ quoteId, onSuccess: (value) => {
    queryClient.invalidateQueries(['quote-items'])
    setValue('autotask_number', autotask_number)
    setTicketTitle(null)
    toastSuccess('Ticket created')
  }})

  const handleOrder = useCallback(form => {
    bulkOrder.mutate({ form, items, engineerId: engineer.quotes.id })
  }, [items, engineer])

  const newAutotaskTicket = useCallback(() => {
    createBulkTicket.mutate({
      title: ticketTitle,
      client_id,
      quote_id: quoteId,
      items,
      engineer: {
        engineer_id: engineer.quotes.id,
        autotask_id: engineer.quotes.autotask_id
      }
    })
  }, [
    createBulkTicket,
    engineer,
    client_id,
    items,
    ticketTitle,
    quoteId
  ])

  const handleTicketTitleInput = e => {
    let title = e.target.value
    setTicketTitle(title)
  }
  
  useEffect(() => {
    if(createBulkTicket.data) {
      setValue('autotask_number', createBulkTicket.data)
      setTicketTitle(null)
    }
  }, [createBulkTicket, setValue]);

  const quote = useGetQuote({ quoteId, onSuccess: quote => {
    console.log(quote)
    setClientName(quote.client_id.label)
    setValue('client_id', quote.client_id.value)
  }})

  return (
    <>
      <Flex>
        <Text color='resolveOrange' mr='1rem' mb='1rem' fontSize='2rem' fontWeight='600'>
          Q{quoteId} - Bulk Order
        </Text>
        <Spacer />
        <Link
          as={ReactLink}
          alignSelf='center'
          color='resolveOrange'
          _hover={{ color: 'gray.200' }}
          to={`/quotes/${quoteId}`}
        >
          Back
        </Link>
      </Flex>
      <HStack mb='1rem'>
        {items.map(itm => (<Badge variant='solid' colorScheme='resolveOranger' size='lg' key={itm}>{itm}</Badge>))}
      </HStack>
      <form onSubmit={handleSubmit(handleOrder)}>
        <BillingCodeSelect
          name='at_billing_code_id'
          control={control}
          errors={errors}
          labelText='Billing Code'
          required
        />

        <FormControl mt='1rem'>
          <FormLabel>
            <Flex>
              <Text>Autotask Number</Text>
            </Flex>
          </FormLabel>
          <Flex dir='row'>
            <Input
              {...register('autotask_number', { required: true })}
              type='text'
              mr='1rem'
            />
            <Button
              pl={7}
              pr={7}
              bg='resolveOrange'
              isDisabled={!!autotask_number}
              onClick={handleAutotaskTicketSetup}
              color='white'
            >
              Create Ticket
            </Button>
          </Flex>
        </FormControl>

        {ticketTitle !== null && (
          <FormControl mt='1rem'>
            <FormLabel>
              The ticket will be created with the below title. If you prefer a
              different title, please enter it below and click submit to create
              the ticket.
            </FormLabel>
            <Flex dir='row' mt='1rem'>
              <Input
                mr='1rem'
                value={ticketTitle}
                onChange={handleTicketTitleInput}
              />
              <Button
                color='white'
                bg='resolveOrange'
                onClick={newAutotaskTicket}
              >
                Submit
              </Button>
            </Flex>
          </FormControl>
        )}

        <ClientPOInput
          required={false}
          register={register}
          name='client_pon'
          errors={errors}
          mt='1rem'
        />

        <PaymentMethodSelectInput
          control={control}
          required={true}
          name='payment_method_id'
          errors={errors}
          mt='1rem'
        />
        <Flex>
          <Button
            isLoading={bulkOrder.isLoading}
            mt='1rem'
            mb='1rem'
            bg='resolveOrange'
            color='white'
            type='submit'
          >
            Save
          </Button>
        </Flex>
      </form>
    </>
  )
}
