import { useContext } from 'react'
import { Center, Spinner, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { Form } from './Form'
import { Layout } from 'components/Layout/Layout'

import { useCreateInternalOrder } from 'features/Internal/api/createInternalOrder'
import { UserContext } from 'features/Auth/context/user.context'
import { useGetNextIoId } from 'features/Internal/api/getNextOrderId'
import SomethingBroke from 'components/Errors/SomethingBroke'

const LoadingNextOrderId = () => (
  <Layout>
    <Center height='100px'>
      <Spinner thickness='5px' emptyColor='gray.200' />
      <Text ml='1rem'>Generating Order</Text>
    </Center>
  </Layout>
)

const internalOrderState = {
  supplier_id: '',
  part_code: '',
  quantity: 1,
  cost_price: 0,
  delivery_price: 0,
  other_supplier: '',
  io_billing_code_id: '',
  payment_method_id: 0
}

export const AddIO = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: internalOrderState
  })

  const { engineer } = useContext(UserContext)
  const createInternalOrder = useCreateInternalOrder()
  const getNextIoId = useGetNextIoId()

  if(getNextIoId.error) return <SomethingBroke message='Failed to generate order ID' error={getNextIoId.error.message} />
  if(getNextIoId.isLoading) return <LoadingNextOrderId />
  return (
    <Form
      formSubmit={handleSubmit}
      handleSubmit={(form) => createInternalOrder.mutate({ form: { ...form, engineer_id: engineer.quotes.id} })}
      nextOrderId={getNextIoId.data}
      register={register}
      control={control}
      errors={errors}
    />
  )
}
