import { axiosClient } from 'lib/axiosClient';
import { useQuery } from 'react-query';

const PAGE_SIZE = 10;
const searchQuotes = async context => {
  const { signal, queryKey } = context;
  const [_, searchState, page] = queryKey;
  const { data: quotes } = await axiosClient({
    url: `/quotes/search?limit=${PAGE_SIZE}&page=${page}`,
    method: 'post',
    data: searchState,
    signal
  });
  return quotes;
};

export const useSearchQuotes = ({ countComplete, searchState, page }) => {
  const enabled = countComplete && searchState !== null
  return useQuery(['search-quotes', searchState, page], {
    queryFn: searchQuotes,
    enabled: enabled,
    placeholderData: { quotes: [] }
  })
};
