import { useResolveToast } from "hooks/useResolveToast"
import { useQueryClient, useMutation } from "react-query"
import { axiosClient } from "lib/axiosClient"

const deleteIOBillingCode = async ({ billingCodeId }) => {
  await axiosClient({
    url: `/settings/io-billing-codes/${billingCodeId}`,
    method: 'delete'
  })
}

export const useDeleteIOBillingCode = () => {
  const queryClient = useQueryClient()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(deleteIOBillingCode, {
    onSuccess: () => {
      toastSuccess('Billing code deleted')
      queryClient.invalidateQueries('settings-io-billing-codes')
    },
    onError: () => {
      toastFail('Failed to delete billing code') 
    }
  })
}

