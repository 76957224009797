import { Flex } from '@chakra-ui/react'
import {
  Route,
  Routes,
} from 'react-router-dom'
import { AandP } from './components/ApprovedAndPosted'
import { Quotation } from './components/Quotation'
import { Sales } from './components/Sales'

export const Reports = () => {
  return (
    <Flex justifyContent='center' height='100%'>
      <Routes>
        <Route path='ap' element={<AandP />} />
        <Route path='sales' element={<Sales />} />
        <Route path='quotation' element={<Quotation />} />
      </Routes>
    </Flex>
  )
}
