import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

const createQuote = async ({ quote, engineerId }) => {
  const merged = {
    ...quote,
    won: quote.won ? 1 : 0,
    checked_out: quote.checked_out ? 1 : 0,
    invoiced: quote.invoiced ? 1 : 0,
    approved_posted: quote.approved_posted ? 1 : 0,
    supplier_id: quote.supplier_id?.value,
    client_id: quote.client_id?.value,
    engineer_id: engineerId,
    subscription_id: quote.subscription_id?.value || null,
    minimum_term_id: quote.minimum_term_id?.value || null,
    payment_plan_id: quote.payment_plan_id?.value || null,
    type_id: quote.type_id?.value || 1
  }
  const { data: { quoteId }} = await axiosClient({
    url: '/quotes',
    method: 'post',
    data: merged
  })
  return quoteId
}

export const useCreateQuote = () => {
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(createQuote, {
    onSuccess: (quoteId) => {
      toastSuccess(`Quote added successfully: Q${quoteId}`)
      queryClient.invalidateQueries(['sales-stats'])
      history(`/quotes/${quoteId}`)
    },
    onError: () => {
      toastFail('Failed to add Quote')
    }
  })
}