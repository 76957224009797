import { FormControl, FormLabel, Input } from '@chakra-ui/react'

export const NameInput = ({
  register,
  required,
  name,
  labelText = 'Subscription Name',
}) => {
  return (
    <FormControl isRequired={required} mt='1rem'>
      <FormLabel>{labelText}</FormLabel>
      <Input {...register(name, { required })} />
    </FormControl>
  )
}
