import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

export const copyQuote = async ({ quoteId, copy }) => {
  const { engineerId, clientId } = copy
  const { data: newQuoteId } = await axiosClient({
    url: `/quotes/copy/quote/${quoteId}`,
    method: 'post',
    data: { engineerId, clientId }
  })
  return newQuoteId.quoteId
}

export const useCopyQuote = () => {
  const history = useNavigate()
  const queryClient = useQueryClient()
  const { toastFail, toastSuccess } = useResolveToast()

  return useMutation(copyQuote, {
    onSuccess: (quoteId) => {
      history(`/quotes/${quoteId}`)
      toastSuccess('Quote copied successfully')
      queryClient.invalidateQueries(['quotes'])
      queryClient.invalidateQueries(['sales-stats']) 
    },
    onError: () => {
      toastFail('Failed to copy quotes')
    }
  })
}