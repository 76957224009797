import { Flex, FormControl, FormLabel, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react"
import { Controller } from "react-hook-form"

export const SalesTargetInput = ({ control, errors, required, name, ...props }) => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { ref, ...restField } }) => {
        return (
          <FormControl
            {...props}
            isRequired={required}
            isInvalid={!!errors[name]}
          >
            <FormLabel>Sales Target</FormLabel>
            <Flex>
              <NumberInput flexGrow={1} min={0} step={0} defaultValue={0.0} {...restField}>
                <NumberInputField flexGrow={1} ref={ref} name={restField.name} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Flex>
          </FormControl>
        )
      }}
    />
)