import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

export const updateQuote = async ({ quoteId, quote }) => {
  await axiosClient({
    url: `/quotes/edit/${quoteId}`,
    method: 'post',
    data: quote
  })
}

export const useUpdateQuote = quoteId => {
  const queryClient = useQueryClient()
  const { toastSuccess, toastFail } = useResolveToast()
  return useMutation(updateQuote, {
    onSuccess: () => {
      toastSuccess('Successfully updated Quote')
      queryClient.invalidateQueries(['item', 'quotes'])
    },
    onError: () => {
      toastFail('Failed to update quote')
    }
  })
}