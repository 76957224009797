import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

export const setBulkOrdered = async ({ items, form, engineerId }) => {

    const hydrated = {
        ...form,
        at_billing_code_id: form.at_billing_code_id.value,
        payment_method_id: form.payment_method_id.value,
        client_id: form.client_id.value
    }

    await axiosClient({
        url: '/quotes/bulk/ordered',
        method: 'post',
        data: { items, form: hydrated, engineerId }
    })
    return items.length
}

export const useBulkOrder = (quoteId) => {
    const { toastFail, toastSuccess } = useResolveToast()
    const history = useNavigate()
    const queryClient = useQueryClient()
    return useMutation(setBulkOrdered, {
        onSuccess: (itemCount) => {
            toastSuccess(`Items successfully ordered`)
            queryClient.invalidateQueries(['quote-items']) 
            history(`/quotes/${quoteId}`)
        },
        onError: () => {
            toastFail('Failed to update items')
        }
    })
}