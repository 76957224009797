/* eslint-disable camelcase */
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'

import { Loading } from 'components/Loading'
import {
  ItemsTable,
  PrintFooter,
  PrintHeader,
  PrintLayout,
  SubHeader,
  SubscriptionsTable
} from './PrintLayout'
import { useGetPrintData } from 'features/Quotes/api/getPrintDetails'
import SomethingBroke from 'components/Errors/SomethingBroke'

export const Print = () => {
  const { quoteId, printType } = useParams()
  const [companyAddress, setCompanyAddress] = useState()
  const [client, setClient] = useState({
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    country: '',
    id: '',
    delivery: 0,
    companyNumber: ''
  })

  const [itemData, setItemData] = useState({
    items: [],
    costs: {
      delivery_total: 0,
      sub_total_ex_vat: 0,
      sub_total_inc_vat: 0,
      vat_total: 0
    }
  })

  const [subData, setSubData] = useState({
    items: [],
    costs: {
      delivery_total: 0,
      sub_total_ex_vat: 0,
      sub_total_inc_vat: 0,
      vat_total: 0
    }
  })

  const print = useGetPrintData({ quoteId, onSuccess: print => {
    const { autotask, items, subscriptions, companyAddress, ...client } = print
    const {
      address1,
      address2,
      city,
      postalCode,
      country,
      id,
      ...rest
    } = autotask
  
    let delivery_total = 0
    const vat = 20 / 100
    const cc_charge = 0.045
    let sub_total_ex_vat = 0
  
    const title = `${client.company_name} - ${client.quote_ref} `
    document.title = title
  
    items.forEach(item => {
      delivery_total += item.delivery_price
      let item_total = 0
      if (printType === 'fiddy') {
        item_total = (item.client_cost * item.quantity) / 2
      } else {
        item_total = item.client_cost * item.quantity
      }
      sub_total_ex_vat += item_total + item.delivery_price
    })
  
    let vat_total = sub_total_ex_vat * vat
  
    setClient(state => ({
      ...state,
      ...client,
      address1,
      address2,
      city,
      postalCode,
      country,
      id,
      ...rest
    }))
  
    setCompanyAddress(pv => companyAddress)
  
    const itemCosts = {
      delivery_total,
      sub_total_ex_vat,
      sub_total_inc_vat: sub_total_ex_vat + vat_total,
      vat_total
    }
  
    sub_total_ex_vat = 0
    vat_total = 0
    subscriptions.forEach(sub => {
      delivery_total += sub.delivery_price
      const item_total = sub.client_cost * sub.quantity
      sub_total_ex_vat += item_total + sub.delivery_price
    })
  
    vat_total = sub_total_ex_vat * vat
    const sub_total_inc_vat = sub_total_ex_vat + vat_total
  
    const subCosts = {
      sub_total_ex_vat,
      vat_total,
      sub_total_inc_vat,
      sub_total_inc_vat_ccharge:
        sub_total_inc_vat + (sub_total_inc_vat * cc_charge)
    }
  
    setItemData({ costs: itemCosts, items: [...items] })
    setSubData({ costs: subCosts, items: [...subscriptions] })
  }})

  if (print.error) return <SomethingBroke message='Failed to print Quote' error={print.error.message} />
  if (print.isFetching || print.isLoading) return <Loading />

  return (
    <PrintLayout>
      <Flex flexFlow='column' w='800px' style={{ transform: 'scale(0.90)' }}>
        <PrintHeader
          address1={client.address1}
          address2={client.address2}
          city={client.city}
          postalCode={client.postalCode}
          country={client.country}
          company_name={client.client_id === 99999999 ? client.other_client : client.company_name}
          printType={printType}
          companyNumber={client.companyNumber}
          quote_ref={client.quote_ref}
        />
        <SubHeader printType={printType} />
        {
          itemData.items.length > 0 && (
            <ItemsTable itemData={itemData} printType={printType} />
          )
        }
        {
          subData.items.length > 0 && (
            <SubscriptionsTable subData={subData} printType={printType} />
          )
        }
        <PrintFooter printType={printType} companyAddress={companyAddress} />
      </Flex>
    </PrintLayout>
  )
}
