import { Box, Divider, Center } from '@chakra-ui/react'
import { Layout } from 'components/Layout/Layout'
import { ListItem } from './ListItem'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useNavigate } from 'react-router-dom'
import { Loading } from 'components/Loading'

const NoQuotesFound = () => (
  <Layout>
    <Center>
      <div>No quotes found</div>
    </Center>
  </Layout>
)

export const QuoteList = ({ 
  isFetching,
  isLoading,
  quotes,
  quoteId,
  isError
}) => {

  const history = useNavigate()
  
  const handleQuoteClick = e => {
    let targetQuoteId = ''
    if (e.target.id.length < 1) {
      targetQuoteId = e.target.parentElement.id
    } else {
      targetQuoteId = e.target.id
    }
    if (targetQuoteId !== quoteId) {
      history(`/quotes/${targetQuoteId}`)
    }
  }

  if (isError) return <SomethingBroke message='Failed to fetch Quotes' error={isError.message} />
  if (isLoading || isFetching) return <Loading />
  if (quotes.length < 1) return <NoQuotesFound />

  return (
    <Layout>
      {quotes.map((quote, i) => {
        return (
          <Box key={`${quote.quote_id}`}>
            <ListItem
              handleQuoteClick={handleQuoteClick}
              isSelected={`${quote.quote_id}` === `${quoteId}`}
              quote={quote}
            />
            <Divider />
          </Box>
        )
      })}
    </Layout>
  )
}
