import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getItemInvoiceStatus = async (context) => {
  const { signal, queryKey } = context 
  const [_, itemId] = queryKey
  const { data: { invoice } } = await axiosClient({
    url: `/quotes/invoice/${itemId}`,
    signal
  })
  return invoice
}

export const useGetItemInvoiceStatus = ({ itemId, onSuccess }) => {
  return useQuery(['invoice', itemId], {
    queryFn: getItemInvoiceStatus,
    placeholderData: [],
    onSuccess
  })
}

