import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useQuery, useQueryClient } from "react-query"

const syncClients = async (context) => {
  const { signal } = context
  const { data: success } = await axiosClient({
    url: '/settings/clients',
    method: 'post',
    signal
  })
  return success
}

export const useSyncClients = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useQuery(['settings-sync-clients'], {
    queryFn: syncClients,
    enabled: false,
    onSuccess: () => {
      toastSuccess('Clients syncronised successfully')
      queryClient.invalidateQueries(['form-clients'])
    },
    onError: () => {
      toastFail('Failed to sync clients with AT')
    }
  })
}