import { axiosClient } from 'lib/axiosClient'
import { useQuery } from 'react-query'

const PAGE_SIZE = 10

export const getQuotes = async (context) => {
  const { queryKey, signal } = context
  const [_, page, filterString] = queryKey
  const { data: quotes } = await axiosClient({
    url: `/quotes?limit=${PAGE_SIZE}&page=${page}${filterString}`,
    signal
  })
  return quotes
}

export const useGetQuotes = ({ page, filterString }) => {
  return useQuery(['quotes', page, filterString],
    {
      queryFn: getQuotes,
      placeholderData: { resultCount: 0, quotes: [] }
    }
  )
}