import { RepeatIcon } from '@chakra-ui/icons'
import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Icon
} from '@chakra-ui/react'
import { useCallback } from 'react'

import { Controller, useWatch } from 'react-hook-form'

export const ItemMarkupInput = ({
  setValue,
  control,
  required,
  isDisabled,
  errors,
  name,
  ...props
}) => {
  const watchClientCost = useWatch({ control, name: 'client_cost', defaultValue: 0 })
  const watchUnitPrice = useWatch({ control, name: 'unit_price', defaultValue: 0 })

  const recalcMarkup = useCallback(() => {
    const clientCost = parseFloat(watchClientCost)
    const unitPrice = parseFloat(watchUnitPrice)
    const newMarkUp = (((clientCost - unitPrice) / unitPrice) * 100).toFixed(2)
    setValue('mark_up_percentage', newMarkUp)
  }, [watchClientCost, watchUnitPrice, setValue])

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { ref, ...restField } }) => {
        return (
          <FormControl {...props} isDisabled={isDisabled} isRequired={required} isInvalid={!!errors[name]}>
            <FormLabel>Mark Up (%)</FormLabel>
            <Flex>
              <NumberInput
                flexGrow={1}
                step={1}
                defaultValue={45}
                {...restField}
              >
                <NumberInputField ref={ref} name={restField.name} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {setValue && (
                <IconButton
                  ml='1rem'
                  variant='ghost'
                  isDisabled={isDisabled}
                  fontSize='15px'
                  onClick={recalcMarkup}
                  icon={<Icon color='resolveOrange' as={RepeatIcon} />}
                  colorScheme='resolveOranger'
                />
              )}
            </Flex>
          </FormControl>
        )
      }}
    />
  )
}
