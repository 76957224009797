import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

const createTemplateItem = async ({ form, templateId }) => {
    const { set_product, ...restForm } = form
    const nForm = {
        ...restForm,
        type_id: form.type_id.value,
        supplier_id: form.supplier_id?.value,
        payment_plan_id: form.payment_plan_id?.value || 0,
        minimum_term_id: form.minimum_term_id?.value || 0
    }
    await axiosClient({
        url: `/quotes/templates/item/${templateId}`,
        method: 'post',
        data: nForm
    })
}

export const useCreateTemplateItem = templateId => {
    const queryClient = useQueryClient()
    const history = useNavigate()
    const { toastFail, toastSuccess } = useResolveToast()
    return useMutation(createTemplateItem, {
        onSuccess: () => {
            toastSuccess('Template item added successfully')
            queryClient.invalidateQueries('template-items')
            history(`/templates/${templateId}`)
        }, onError: () => {
            toastFail('Failed to add template item')
        }
    })
}