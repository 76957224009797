import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getTicketCosts = async (context) => {
    const { signal, queryKey } = context
    const [_, ticketNumber] = queryKey
    const { data: costs } = await axiosClient({
        url: `/autotask/costs/${ticketNumber}`,
        signal
    })
    return costs
}

export const useGetAutotaskCosts = ({ ticketNumber }) => {
    const { toastFail } = useResolveToast()
    return useQuery(['ordered-ticket-costs', ticketNumber], {
        queryFn: getTicketCosts,
        placeholderData: [],
        staleTime: 1,
        enabled: !!ticketNumber,
        onError: error => {
            console.log(error)
            toastFail('Failed to get Autotask cost')
        }
    })
}
