import { Route, Routes } from 'react-router'

import { TeamsSettingsContainer } from '../components/Teams'
import { SupplierSettingsContainer } from '../components/Suppliers'
import { UserSettingsContainer } from '../components/Users/index'
import { BCSettingsContainer } from '../components/BillingCodes'
import { PaymentSettingsContainer } from '../components/PaymentMethods'
import { ProductSettingsContainer } from '../components/Products'
import { ClientSettingsContainer } from '../components/Clients'
import { GeneralSettings } from '../components/General'
import { SubTypeSettingsContainer } from '../components/SubTypes'
import { MailSettingsContainer } from '../components/Mail'
import { AutotaskSettingsContainer } from '../components/Autotask'
import { QuoteTypesSettingsContainer } from '../components/QuoteTypes'
import { IOBCSettingsContainer } from '../components/InternalBillingCodes'
import { SettingsNavigation } from 'features/Navigation/SettingsNavigation'
import { BuildTiersSettingsContainer } from '../components/BuildTiers'

export const Left = ({ engineer }) => {
  return (
    <Routes>
      <Route path='teams/*' element={<TeamsSettingsContainer />} />
      <Route path='suppliers/*' element={<SupplierSettingsContainer />} />
      <Route
        path='payment-methods/*'
        element={<PaymentSettingsContainer />}
      />
      <Route path='products/*' element={<ProductSettingsContainer />} />
      <Route
        path='io-billing-codes/*'
        element={<IOBCSettingsContainer />}
      />
      <Route
        path='build-tiers/*'
        element={<BuildTiersSettingsContainer />}
      />
      <Route
        path='clients/*'
        element={<ClientSettingsContainer />}
      />
      <Route path='sub-types/*' element={<SubTypeSettingsContainer />} />
      <Route path='billing-codes/*' element={<BCSettingsContainer />} />
      <Route path='mail/*' element={<MailSettingsContainer />} />
      <Route path='autotask/*' element={<AutotaskSettingsContainer />} />
      <Route path='users/*' element={<UserSettingsContainer />} />
      <Route path='quote-types/*' element={<QuoteTypesSettingsContainer />} />
      <Route path='*' element={<GeneralSettings />} />
    </Routes>
  )
}

export const Right = ({ engineer }) => {
  return (
    <Routes>
      <Route path='/*' element={<SettingsNavigation />} />
    </Routes>
  )
}
