import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

export const unassignUser = async ({ userId, itemId }) => {
  await axiosClient({
    url: `/quotes/unassign/${itemId}`,
    method: 'post',
    data: { userId }
  })
}

export const useUnassignUser = () => {
  const queryClient = useQueryClient()
  const { toastSuccess, toastFail } = useResolveToast()
  return useMutation(unassignUser, {
    onSuccess: () => {
      toastSuccess('User unassigned successfully')
      queryClient.invalidateQueries('assigned-engineers-quote-item')
    },
    onError: () => {
      toastFail('Failed to unassign user')
    }
  })
}