import { useContext, useEffect } from 'react'
import { Link as ReactLink, useParams, useSearchParams } from 'react-router-dom'
import {
  Flex,
  Text,
  Spacer,
  Link,
  FormControl,
  FormLabel,
  Input,
  Button,
  Badge,
  HStack
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { UserContext } from 'features/Auth/context/user.context'

import moment from 'moment'
import { useBulkInvoice } from 'features/Quotes/api/bulkInvoice'

const DEFAULT_VALUES = {
  invoice_date: moment().format('DD/MM/YY')
}

export const InvoiceForm = () => {
  const [searchParams] = useSearchParams()
  const { quoteId } = useParams()
  const { engineer } = useContext(UserContext)
  const items = searchParams.get('items').split(',')

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: DEFAULT_VALUES
  })

  useEffect(() => {
    setValue('invoiced_by', engineer.quotes.id)
  }, [engineer, setValue])

  const bulkInvoice = useBulkInvoice(quoteId)
  const onInvoiceSubmit = async form => {
    bulkInvoice.mutate({ items, form: {
      ...form,
      invoice_date: moment(form.invoice_date).format('YYYY-MM-DD')
    }})
  }
  return (
    <>
      <Flex mb='1rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Q{quoteId} - Bulk Invoice
        </Text>
        <Spacer />
        <Link
          as={ReactLink}
          alignSelf='center'
          color='resolveOrange'
          _hover={{ color: 'gray.200' }}
          to={`/quotes/${quoteId}`}
        >
          Back
        </Link>
      </Flex>
      <Flex mb='1rem'>
        <Text color='gray.200' fontSize='0.8em'>
          The bulk invoice feature should only be used when an outgoing invoice number (where applicable) has been received. This will mark all items as invoiced in SMS and may not be correctly invoiced in Sage.
        </Text>
      </Flex>
      <HStack>
        {items.map(itm => (<Badge variant='solid' colorScheme='resolveOranger' size='lg' key={itm}>{itm}</Badge>))}
      </HStack>
      <form onSubmit={handleSubmit(onInvoiceSubmit)}>
        <FormControl mt='1rem'>
          <FormLabel>Supplier Invoice Number</FormLabel>
          <Input {...register('supplier_invoice_number')} />
        </FormControl>

        <FormControl mt='1rem'>
          <FormLabel>Outgoing Invoice Number</FormLabel>
          <Input {...register('outgoing_invoice_number')} />
        </FormControl>

        <FormControl mt='1rem'>
          <FormLabel>Invoice Date</FormLabel>
          <Input {...register('invoice_date')} />
        </FormControl>

        <Button
          color='white'
          backgroundColor='resolveOrange'
          mt='1rem'
          type='submit'
        >
          Submit
        </Button>
      </form>
    </>
  )
}
