import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { ControlledSelect } from 'components/Form/ControlledSelect'
import { useGetTemplates } from 'features/Quotes/api/getTemplates'

export const TemplateSelectInput = ({
  control,
  errors,
  name,
  labelText = 'Template',
  showLabel = true,
  required = true,
  placeholder = 'Client'
}) => {

  const templates = useGetTemplates()

  return (
    <FormControl
      mb='1rem'
      isInvalid={!!errors[name]}
      isRequired={required}
      mr='1rem'
    >
      {showLabel && <FormLabel>{labelText}</FormLabel>}
      <ControlledSelect
        name={name}
        control={control}
        options={templates.data}
        rules={{ required }}
        isLoading={templates.isLoading}
        placeholder={placeholder}
        selectedOptionColor='resolveOranger'
      />
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  )
}
