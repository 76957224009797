import { Button, Flex, Input, Text } from "@chakra-ui/react"
import SomethingBroke from "components/Errors/SomethingBroke"
import { CheckboxController } from "components/Form/CheckboxController"
import { Loading } from "components/Loading"
import { useGetBillingCode } from "features/Settings/api/getBillingCode"
import { useUpdateBillingCode } from "features/Settings/api/updateBillingCode"
import { useForm } from "react-hook-form"

const INITIAL_FORM_STATE = { 
  enabled: 0,
  active: 0,
  label: ''
}
export const EditBillingCode = ({ billingCodeId, selectRef }) => {
  const { reset, register, handleSubmit, control } = useForm({
    defaultValues: INITIAL_FORM_STATE
  })


  const updateBillingCode = useUpdateBillingCode()
  const handleSave = async form => {
    await updateBillingCode.mutate({ form })
    selectRef.current.clearValue()
  }
  const onSuccess = data => {
    reset(data)    
  }
  const billingCode = useGetBillingCode({ billingCodeId, onSuccess })

  if(billingCode.error) return <SomethingBroke message='Failed to get Billing Code' error={billingCode.error.message} />
  if(billingCode.isLoading) return <Loading />
  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Flex flexFlow='row' mt='2rem' mb='1rem'>
        <Text 
          color='resolveOrange' 
          mr='1rem' 
          fontSize='2rem' 
          fontWeight='600'
        >
          {billingCodeId.label}
        </Text>
      </Flex>
      <Input mb='1rem' isReadOnly {...register('label')} />
      <Flex flexFlow='column' mb='2rem'>
        <CheckboxController 
          control={control} 
          name='enabled' text='Usable in Quotes' />
        <CheckboxController control={control} name='active' isReadOnly text='Active in Autotask' />
      </Flex>
      <Button type='submit' color='white' backgroundColor='resolveOrange'>
        Save
      </Button>
    </form>
  )
}