import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import moment from "moment"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

export const createSub = async ({ form, date, engineer }) => {
  let d = new Date()
  if (date.selectedDay) {
    d = new Date(date.selectedDay)
    d.setHours(2)
  }
  const merged = {
    ...form,
    client_id: form.client_id.value,
    num_licenses: Number(form.num_licenses),
    date_first_renewal: moment(d).format('YYYY-MM-DD'),
    date_created: moment().format('YYYY-MM-DD'),
    subscriptiontype_id: form.subscriptiontype_id.value,
    supplier_id: form.supplier_id.value,
    engineer_id: engineer.quotes.id,
    quote_type_id: form.quote_type_id.value
  }
  const { data: { newSubId } } = await axiosClient({
    url: '/subs',
    method: 'post', 
    data: merged
  })
  return newSubId
}

export const useCreateSub = () => {
  const queryClient = useQueryClient()
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(createSub, { onSuccess: subId => {
    toastSuccess('Sub added successfully')
    history(`/subs/${subId}`)
    queryClient.invalidateQueries('subs')
  }, onError: () => {
    toastFail('Failed to add sub')
  }})
}