import { Avatar } from '@chakra-ui/react'

export const Engineer = ({ engineer, blob }) => {
  return <Avatar size='sm' name={engineer} src={blob} />
}

// export const Engineer = ({engineer}) => (
// 	<Flex pointerEvents="none" alignItems="flex-start" justifyContent="flex-start"  minWidth="15%" flexBasis="15%">
// 		<Text textAlign="center" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">{engineer}</Text>
// 	</Flex>
// )
