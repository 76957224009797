import { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Flex } from '@chakra-ui/react'
import { DateInput } from './DateInput'
import { QuoteNumberInput } from './QuoteNumberInput'
import { ActionButtons } from './ActionButtons'
import { ClientSelectInput } from 'components/Form/ClientSelectInput'
import { UserSelectInput } from 'components/Form/UserSelectInput'
import moment from 'moment'

const DEFAULT_FILTER_STATE = {
  engineer: '',
  clientId: '',
  date: '',
  quoteId: ''
}

export const SearchFilter = ({ setPage, filter, setFilter }) => {
  const [date, setDate] = useState({
    selectedDay: undefined
  })

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      quoteId: filter.quoteId,
      engineer: filter.engineer,
      date: filter.date,
      clientId: filter.clientId
    }
  })

  const resetFilter = () => {
    setPage(1)
    setDate({ selectedDay: '' })
    setFilter(DEFAULT_FILTER_STATE)
    reset(DEFAULT_FILTER_STATE)
  }

  const handleFilterSubmit = useCallback(
    form => {
      let d
      if (date.selectedDay) {
        d = new Date(date.selectedDay)
        d.setHours(2)
      }
      const normalized = {
        ...form,
        clientId: form.clientId?.value?.toString() || '',
        engineer: form.engineer?.value?.toString() || '',
        date: date.selectedDay ? moment(d).format('YYYY-MM-DD') : ''
      }

      setFilter(normalized)
      setPage(1)
    },
    [setFilter, date, setPage]
  )

  const handleDayChange = date => {
    setDate({ selectedDay: date })
  }

  return (
    <form onSubmit={handleSubmit(handleFilterSubmit)}>
      <Flex>
        <QuoteNumberInput register={register} name='quoteId' />

        <DateInput
          name='date'
          handleDayChange={handleDayChange}
          placeholder='Date'
          date={date}
        />

        <Flex flexBasis='60%'>
          <ClientSelectInput
            control={control}
            errors={errors}
            showLabel={false}
            required={false}
            placeholder='Client'
            name='clientId'
            isClearable
            mr='1rem'
          />
        </Flex>

        <Flex flexBasis='40%'>
          <UserSelectInput
            control={control}
            errors={errors}
            name='engineer'
            validation={true}
            showLabel={false}
            mr='1rem'
            filter={item => true}
          />
        </Flex>
        <ActionButtons handleFilterReset={resetFilter} />
      </Flex>
    </form>
  )
}
