import { axiosClient } from 'lib/axiosClient'
import { useQuery } from 'react-query'

export const fetchItems = async (ioId) => {
  console.log('getting io items')
  const { data: items } = await axiosClient({
    url: `/io/items/${ioId}`
  })
  return items.items
}
export const useGetIoItems = ({ ioId }) => {
  return useQuery(['io-items', ioId], () => fetchItems(ioId))
}