import { axiosClient } from "lib/axiosClient"
import { useResolveToast } from "hooks/useResolveToast"
import { useQueryClient, useMutation } from "react-query"

const deleteCost = async ({ selectedCost, itemId }) => {
  await axiosClient({
    url: '/autotask/costs',
    method: 'delete',
    data: {
      costId: selectedCost.id,
      ticketId: selectedCost.ticketID,
      itemId
    }
  })
}

export const useDeleteCost = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(deleteCost, {
    onSuccess: () => {
      toastSuccess('Cost deleted successfully')
      queryClient.invalidateQueries(['ordered-ticket-costs'])
      queryClient.invalidateQueries(['item-billing-details'])
      queryClient.invalidateQueries(['item-status'])
      queryClient.invalidateQueries(['quote-item-charge'])
    },
    onError: () => {
      toastFail('Failed to remove cost')
    }
  })
}