import { FormControl, FormLabel } from '@chakra-ui/react'
import { useGetIOBillingCodes } from './api/getIOBillingCodes'
import { ControlledSelect } from './ControlledSelect'

export const IOBillingCodeSelect = ({
  control,
  name,
  required,
  errors,
  labelText = 'Internal Billing Code',
  selectedOptionColor = 'resolveOranger',
  ...restProps
}) => {
  const billingCodes = useGetIOBillingCodes()
  return (
    <FormControl isRequired={required} {...restProps}>
      <FormLabel>{labelText}</FormLabel>
      <ControlledSelect
        name={name}
        control={control}
        options={billingCodes.data}
        isLoading={billingCodes.isLoading}
        rules={{ required }}
        selectedOptionColor={selectedOptionColor}
      />
    </FormControl>
  )
}
