import { axiosClient } from "lib/axiosClient";
import { useQuery } from "react-query";

const getPaymentMethod = async (context) => {
  const { signal, queryKey } = context
  const [ _key, paymentMethodId] = queryKey
  const { data: paymentMethod } = await axiosClient({
    url: `/settings/payment-method/${paymentMethodId}`,
    signal
  })
  return {
    ...paymentMethod,
    hidden: Number(paymentMethod.hidden) ? false : true
  } 
}

export const useGetPaymentMethod = ({ paymentMethodId, onSuccess }) => {
  return useQuery(['settings-payment-method', paymentMethodId], {
    queryFn: getPaymentMethod,
    onSuccess
  })
}