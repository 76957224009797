import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import moment from "moment"
import { useMutation } from "react-query"
import { useNavigate } from "react-router"

const createInternalOrder = async ({ form }) => {
  const nForm = {
    ...form,
    invoiced: form.invoiced ? 1 : 0,
    quid_co: form.quid_co ? 1 : 0,
    date_added: moment().format('YYYY-MM-DD HH:mm:ss'),
    payment_method_id: form.payment_method_id?.value || null,
    io_billing_code_id: form.io_billing_code_id?.value || null,
    authorized_by_id: form.authorized_by_id?.value || null,
    supplier_id: form.supplier_id?.value || null,
    date_ordered: moment(form.date_ordered).format('YYYY-MM-DD HH:mm:ss'),
    invoice_date: form.invoice_date
      ? moment(form.invoice_date).format('YYYY-MM-DD HH:mm:ss')
      : null
  }
  const { data: newIoId } = await axiosClient({
    url: '/io',
    method: 'post',
    data: nForm
  })

  return newIoId
}

export const useCreateInternalOrder = () => {
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(createInternalOrder, {
    onSuccess: newIoId => {
      toastSuccess('Internal Order added successfully')
      history(`/internal/${newIoId}`)
    },
    onError: () => {
      toastFail('Failed to add Internal Order')
    }
  })
}