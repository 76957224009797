import { Flex } from '@chakra-ui/react'

import { IONumber } from './components/IONumber'
import { Description } from './components/Description'
import { DateAdded } from './components/DateAdded'
import { Engineer } from './components/Engineer'
import { Total } from './components/Total'
import { StatusBadge } from './components/StatusBadge'

export const ListItem = ({ io, handleIoClick, isSelected }) => {
  return (
    <Flex
      onClick={handleIoClick}
      id={io.io_id}
      style={isSelected ? { backgroundColor: '#00A7E0', color: 'white' } : null}
      height='4rem'
      alignItems='center'
      _hover={{
        boxShadow: 'rgba(0, 0, 0, .1) 0 0 0 1000000px inset',
        cursor: 'pointer'
      }}
    >
      <IONumber ioId={io.io_id} isSelected={isSelected} />
      <Description description={io.description} />
      <DateAdded date={io.date_added} />
      <Engineer engineer={io.ad_username} blob={io.picture_blob} />
      <Total total={io.total} />
      <StatusBadge status={io.io_status} />
    </Flex>
  )
}
