import { Text, Flex } from '@chakra-ui/react'
import moment from 'moment'

export const ItemStatusText = ({
  dateAdded,
  dateOrdered,
  invoiceDate,
  itemLocation,
  ...props
}) => {
  return (
    <Flex flexFlow='column' {...props}>
      {dateAdded && (
        <Text fontSize='xs' color='gray.400'>
          <b>Date Added: </b> {moment(dateAdded).format('DD/MM/YY')}
        </Text>
      )}
      {dateOrdered && (
        <Text fontSize='xs' color='gray.400'>
          <b>Date Ordered: </b> {moment(dateOrdered).format('DD/MM/YY')}
        </Text>
      )}
      {invoiceDate && (
        <Text fontSize='xs' color='gray.400'>
          <b>Invoice Date: </b>
          {moment(invoiceDate).format('DD/MM/YY')}
        </Text>
      )}
      {itemLocation && (
        <Text fontSize='xs' color='gray.400'>
          <b>Item Location: </b> {itemLocation}
        </Text>
      )}
    </Flex>
  )
}
