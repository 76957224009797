import { useForm } from 'react-hook-form'
import {
  Text,
  FormControl,
  FormLabel,
  Flex,
  Button,
  Input
} from '@chakra-ui/react'

import { PopoverButton } from 'components/PopoverButton'
import { useDeleteSubType } from 'features/Settings/api/deleteSubType'
import { useGetSubType } from 'features/Settings/api/getSubType'
import { useUpdateSubType } from 'features/Settings/api/updateSubType'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useEffect } from 'react'

const LabelInput = ({ register, name }) => {
  return (
    <FormControl mb='1rem'>
      <FormLabel color='resolveOrange'>Label</FormLabel>
      <Input {...register(name, { isRequired: true })} />
    </FormControl>
  )
}

const FormHeader = ({ name }) => {
  return (
    <Flex flexFlow='row' mt='2rem' mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        {name}
      </Text>
    </Flex>
  )
}

const subTypeState = {
  label: '',
  id: 0
}

export const EditSubTypeForm = ({ subTypeId, selectRef }) => {
  const { register, reset, handleSubmit } = useForm({
    defaultValues: subTypeState
  })

  const onSuccess = subType => {
    reset(subType)
  }
  const subType = useGetSubType({ onSuccess, subTypeId })
  const deleteSubType = useDeleteSubType()
  const updateSubType = useUpdateSubType()

  const handleUpdate = async form => {
    await updateSubType.mutateAsync({ form, subTypeId })
    selectRef.current.clearValue()
  }
  const handleDelete = async () => {
    await deleteSubType.mutateAsync({ subTypeId })
    selectRef.current.clearValue()
  }
  
  if(subType.isLoading) return <Loading />
  if(subType.error) return <SomethingBroke message='Failed to get sub type' error={subType.error.message} />
  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <FormHeader name={subType.data.name} />
      <LabelInput name='label' register={register} />
      <Flex>
        <Button type='submit' color='white' backgroundColor='resolveOrange'>
          Save
        </Button>
        <PopoverButton
          title='Delete'
          message='Are you sure you want to delete this sub type?'
          handleClick={handleDelete}
          buttonTitle='Delete'
          color='resolveOrange'
          ml='1rem'
          backgroundColor='white'
          isLoading={deleteSubType.isLoading}
        />
      </Flex>
    </form>
  )
}
