import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation } from "react-query"
import { useNavigate } from "react-router"

export const createAutotaskBulkTicket = async ticket => {
  const { data: { ticketNumber }} = await axiosClient({
    url: '/autotask/ticket',
    method: 'post',
    data: ticket
  })

  return ticketNumber
}

export const useCreateBulkAutotaskTicket = ({ quoteId, onSuccess }) => {
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(createAutotaskBulkTicket, {
    onSuccess,
    onError: () => {
      toastFail('Failed to create ticket')
    }
  })
}