import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

const createPaymentMethod = async ({ paymentMethod }) => {
  await axiosClient({
    url: '/settings/payment-methods',
    method: 'post',
    data: paymentMethod
  })
}

export const useCreatePaymentMethod = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const history = useNavigate()
  return useMutation(createPaymentMethod, {
    onSuccess: () => {
      toastSuccess('Payment method successfully updated') 
      history('/settings/payment-methods')
    },
    onError: () => {
      toastFail('Failed to update payment method') 
    }
  })
}