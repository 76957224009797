import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation, useQueryClient } from "react-query";

const updateSubType = async ({ form, subTypeId }) => {
  await axiosClient({
    url: `/settings/sub-types/${subTypeId}`,
    method: 'patch',
    data: form
  })
}

export const useUpdateSubType = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateSubType, {
    onSuccess: () => {
      toastSuccess('Sub type successfully updated') 
      queryClient.invalidateQueries(['settings-sub-types'])
    },
    onError: () => {
      toastFail('Failed to update sub type')
    }
  })
}