import { useState, useEffect } from 'react'

const defaultFilterState = {
  engineer: '',
  ioId: '',
  date: ''
}

export const useIOFilter = () => {
  const [filter, setFilter] = useState(defaultFilterState)
  const [filterString, setFilterString] = useState('')
  useEffect(() => {
    let tmpStr = ''
    if (filter.ioId.length > 0) {
      tmpStr += `&ioId=${filter.ioId}`
    }
    if (filter.date.length > 0) {
      tmpStr += `&dateAdded=${filter.date}`
    }
    if (filter.engineer.length > 0) {
      tmpStr += `&engineer=${filter.engineer}`
    }
    setFilterString(tmpStr)
  }, [filter])

  return { filterString, filter, setFilter }
}
