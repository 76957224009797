import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const testAutotaskAPI = async (context) => {
  const { signal } = context
  return await axiosClient({
    url: '/autotask/test',
    signal
  })
}

export const useTestAutotaskAPI = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  return useQuery(['settings-autotask-test'], {
    queryFn: testAutotaskAPI,
    enabled: false,
    onSuccess: () => {
      toastSuccess('Autotask test successful')
    },
    onError: () => {
      toastFail('Autotask test failed')
    }
  })
}