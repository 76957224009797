import { useRef, useState } from 'react'
import {
  Link as ReactLink,
  Routes as Switch,
  Route
} from 'react-router-dom'
import { Text, Flex, Spacer, Button } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { EditBuildTier } from './Edit'
import { AddBuildTier } from './Add'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Loading } from 'components/Loading'
import { useGetBuildTiers } from 'features/Settings/api/getBuildTiers'

export const BuildTiersSettingsContainer = () => {
  return (
    <Switch>
      <Route path='add' element={<AddBuildTier />} />
      <Route path='/' element={<BuildTiersSettings />} />
    </Switch>
  )
}

const BuildTierSelectList = ({ teams, selectRef, onChange }) => {
  return (
    <Select
      ref={selectRef}
      selectedOptionColor='resolveOranger'
      onChange={onChange}
      isClearable
      options={teams}
    />
  )
}

export const BuildTiersSettings = () => {
  const [buildTier, setBuildTier] = useState(null)
  const selectRef = useRef()

  const onTierChange = e => {
    setBuildTier(e)
  }

  const buildTiers = useGetBuildTiers()

  if(buildTiers.isLoading) return <Loading />
  if(buildTiers.error) return <SomethingBroke message='Failed to fetch Build Tiers' error={buildTiers.error.message} />

  return (
    <Flex flexFlow='column'>
      <Flex alignItems='center' mb='2rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Device Build Tiers
        </Text>
        <Spacer />
        <Button
          as={ReactLink}
          backgroundColor='resolveOrange'
          size='sm'
          color='white'
          to='/settings/build-tiers/add'
        >
          Add Build Tier
        </Button>
      </Flex>

      <BuildTierSelectList
        onChange={onTierChange}
        teams={buildTiers.data}
        selectRef={selectRef}
      />

      {buildTier && (
        <EditBuildTier
          mt='1rem'
          buildTierId={buildTier.value}
          setBuildTier={setBuildTier}
          selectRef={selectRef} 
          currentName={undefined}
        />
      )}
    </Flex>
  )
}
