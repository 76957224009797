import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation } from "react-query"
import { useNavigate } from "react-router"

const createIOBillingCode = async ({ billingCode }) => {
  await axiosClient({
    url: '/settings/io-billing-codes',
    method: 'post',
    data: billingCode
  })
}

export const useCreateIOBillingCode = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const history = useNavigate()
  return useMutation(createIOBillingCode, {
    onSuccess: () => {
      toastSuccess('Internal code added successfully')
      history('/settings/io-billing-codes')
    },
    onError: () => {
      toastFail('Failed to add internal billing code')
    }
  })
}

