import { useEffect, useRef, useState } from 'react'
import {
  Link as ReactLink,
  Routes as Switch,
  Route
} from 'react-router-dom'
import { Text, Flex, Button, Spacer } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'

import { EditUserForm } from './Edit'
import { AddUserForm } from './Add'

import { useQueryClient } from 'react-query'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useGetUsers } from 'features/Settings/api/getUsers'

export const UserSettingsContainer = () => {
  return (
    <Switch>
      <Route path='add' element={<AddUserForm />} />
      <Route path='/' element={<UsersSetting />} />
    </Switch>
  )
}

const UserSelectList = ({ users, onUserChange, selectRef, ...restProps }) => {
  return (
    <>
      <Select
        {...restProps}
        selectedOptionColorScheme='resolveOranger'
        isClearable
        ref={selectRef}
        onChange={onUserChange}
        options={users}
      />
    </>
  )
}

export const UsersSetting = () => {
  const [userId, setUserId] = useState()

  const users = useGetUsers()
  const selectRef = useRef()

  const onUserChange = user => {
    setUserId(user?.value)
  }

  if (users.isLoading) return <Loading />
  if (users.error) return <SomethingBroke message='Failed to get users' error={users.error.message} />

  return (
    <Flex flexFlow='column'>
      <Flex alignItems='center' mb='2rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Users
        </Text>
        <Spacer />
        <Button
          as={ReactLink}
          backgroundColor='resolveOrange'
          size='sm'
          color='white'
          to='/settings/users/add'
        >
          Add User
        </Button>
      </Flex>

      <UserSelectList
        onUserChange={onUserChange}
        users={users.data}
        selectRef={selectRef}
      />

      {userId && (
        <EditUserForm
          mt='1rem'
          selectRef={selectRef}
          userId={userId}
        />
      )}
    </Flex>
  )
}
