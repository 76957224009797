import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

const deleteQuote = async ({ quoteId }) => {
  return await axiosClient({
    url: '/quotes',
    method: 'post',
    data: { quoteId }
  })
}

export const useDeleteQuote = ({ quoteId }) => {
  const queryClient = useQueryClient()
  const { toastFail, toastSuccess } = useResolveToast()
  const history = useNavigate()
  return useMutation(deleteQuote, {
    onSuccess: () => {
      toastSuccess(`Quote deleted successfully: ${quoteId} `)
      history('/quotes')
      queryClient.invalidateQueries('quotes')
    },
    onError: () => {
      toastFail('Failed to delete Quote')
    }
  })
}