import { useEffect, useState } from "react";

export const useFilter = (filter) => {
  const [filterString, setFilterString] = useState('')
  useEffect(() => {
    let tmpFilter = ''
    if (filter.length !== 0) {
      tmpFilter = `?filter=${filter.join(',')}`
    }
    setFilterString(tmpFilter)
  }, [filter]);

  return { filterString }
}