import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"
  
const getBillingCodes = async (context) => {
  const { signal } = context
  const { data: billingCodes } = await axiosClient({
    url: '/settings/io-billing-codes',
    signal
  })

  return billingCodes
}

export const useGetIOBillingCodes = () => {
  return useQuery(['settings-io-billing-codes'], {
    queryFn: getBillingCodes,
    placeholderData: []
  })
} 