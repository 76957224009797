import { useRef, useState } from 'react'
import {
  Link as ReactLink,
  Routes as Switch,
  Route
} from 'react-router-dom'
import { Text, Flex, Spacer, Button } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { EditSupplierForm } from './Edit'
import { AddSupplierForm } from './Add'
import { useGetSuppliers } from 'features/Settings/api/getSuppliers'

export const SupplierSettingsContainer = () => {
  return (
    <Switch>
      <Route path='add' element={<AddSupplierForm />} />
      <Route path='/' element={<SupplierSettings />} />
    </Switch>
  )
}

const SupplierSelectList = ({ suppliers, onChange, selectRef }) => {
  return (
    <Select
      onChange={onChange}
      ref={selectRef}
      isClearable
      selectedOptionColorScheme='resolveOranger'
      options={suppliers}
    />
  )
}

export const SupplierSettings = () => {
  const [supplierId, setSupplierId] = useState(null)
  const selectRef = useRef()

  const onChange = e => {
    setSupplierId(e?.value)
  }

  const suppliers = useGetSuppliers()
  if(suppliers.error) throw new Error('Failed to fetch suppliers')
  return (
    <Flex flexFlow='column'>
      <Flex alignItems='center' mb='2rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Suppliers
        </Text>
        <Spacer />
        <Button
          as={ReactLink}
          backgroundColor='resolveOrange'
          size='sm'
          color='white'
          to='/settings/suppliers/add'
        >
          Add Supplier
        </Button>
      </Flex>

      <SupplierSelectList
        selectRef={selectRef}
        onChange={onChange}
        suppliers={suppliers.data}
      />

      {supplierId && (
        <EditSupplierForm
          mt='1rem'
          supplierId={supplierId}
          selectRef={selectRef}
        />
      )}
    </Flex>
  )
}
