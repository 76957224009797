import { axiosClient } from "lib/axiosClient"
import { useMutation } from "react-query"
import { useResolveToast } from "hooks/useResolveToast"
import { useQueryClient } from "react-query"

const updateIOBillingCode = async ({ form }) => {
  const nForm = {
    label: form.label,
    id: form.value,
    enabled: form.enabled ? 1 : 0
  }
  await axiosClient({
    url: '/settings/io-billing-codes',
    method: 'patch',
    data: nForm
  })
}

export const useUpdateIOBillingCode = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateIOBillingCode, {
    onSuccess: () => {
      toastSuccess('Billing Code updating successfully')
      queryClient.invalidateQueries(['settings-io-billing-codes'])
      queryClient.invalidateQueries(['form-io-billing-codes'])
    },
    onError: () => {
      toastFail('Failed to update billing code')
    }
  })
}
