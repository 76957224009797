import { useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { ItemList } from './'
import { Header } from './Header'
import { Actions } from './Actions'
import { useGetIoItems } from 'features/Internal/api/getIoItems'

export const Container = () => {
  const { ioId, itemId } = useParams()
  const history = useNavigate()

  const ioItems = useGetIoItems({ ioId })

  const handleItemClick = useCallback(
    e => {
      if (itemId !== e.target.id) {
        const newPath = `/internal/${ioId}/edit/${e.target.id}`
        history(newPath)
      }
    },
    [ioId, itemId, history]
  )

  // TODO: Sort out deleting orders
  const handleDeleteOrder = useCallback(() => {
    // apiDelete(`/io/${ioId}`)
    //   .then(res => {
    //     toastSuccess('Order deleted successfully')
    //     history('/internal')
    //   })
    //   .catch(error => {
    //     console.log(error)
    //     toastFail('Failed to delete order')
    //   })
  }, [])

  const handleAddItem = useCallback(() => {
    // TODO: Handle add item to existing InternalOrder
  }, [])

  if (ioItems.isFetching) return <Loading color='resolveBlue' />
  if (ioItems.error) return <SomethingBroke message='Failed to fetch items' error={ioItems.error.message} />

  return (
    <>
      <Header ioId={ioId} />
      <ItemList items={ioItems.data} handleItemClick={handleItemClick} />
      <Actions
        ioId={ioId}
        handleDeleteOrder={handleDeleteOrder}
      />
    </>
  )
}
