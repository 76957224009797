import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Checkbox
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { DatePicker } from 'components/DatePicker'
import { UserContext } from 'features/Auth/context/user.context'
import { useParams } from 'react-router-dom'
import { Header } from './Header'
import { useGetItemInvoiceStatus } from 'features/Quotes/api/getInvoiceStatus'
import { useUpdateItemInvoiceStatus } from 'features/Quotes/api/updateItemInvoiceStatus'
import SomethingBroke from 'components/Errors/SomethingBroke'

const DEFAULT_VALUES = {
  invoice_date: moment().format('DD/YY/MM'),
  supplier_invoice_number: '',
  outgoing_invoice_number: '',
  invoiced: 0
}

export const Invoice = () => {
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: DEFAULT_VALUES
  })
  const { engineer } = useContext(UserContext)
  const { quoteId, itemId } = useParams()
  const [date, setDate] = useState({ selectedDay: moment().toDate() })

  const invoice = useGetItemInvoiceStatus({ itemId, onSuccess: invoice => {
    setDate({ selectedDay: invoice.invoice_date ? moment(invoice.invoice_date).toDate() : moment().toDate() })
    reset(invoice)
  }})

  const updateInvoice = useUpdateItemInvoiceStatus({quoteId, itemId})

  const onInvoiceSubmit = useCallback(form => {
    updateInvoice.mutate({ engineerId: engineer.quotes.id, invoice: form, itemId, date })
  }, [engineer, itemId, updateInvoice, date])

  const handleDayChange = date => {
    setDate({ selectedDay: date })
  }

  if(invoice.error) return <SomethingBroke message='Failed to get Invoice' error={invoice.error.message} />
  return (
    <>
      <Header />
      <form onSubmit={handleSubmit(onInvoiceSubmit)}>
        <FormControl>
          <FormLabel>
            Supplier Invoice Number
          </FormLabel>
          <Input {...register('supplier_invoice_number')} />
        </FormControl>

        <FormControl mt='1rem' mb='1rem'>
          <FormLabel>
            Outgoing Invoice Number
          </FormLabel>
          <Input {...register('outgoing_invoice_number')} />
        </FormControl>

        <FormControl>
          <FormLabel>Invoice Date</FormLabel>
          <DatePicker
            selectedDate={date.selectedDay}
            onChange={handleDayChange}
            name='date_added'
          />
        </FormControl>

        <FormControl>
          <Controller
            control={control}
            name='invoiced'
            key='invoiced'
            render={({ field: { ref, value, ...restField } }) => {
              return (
                <Checkbox
                  isChecked={!!value}
                  colorScheme='purple'
                  color='resolvePurple'
                  ref={ref}
                  mt='1rem'
                  {...restField}
                >
                  Invoiced
                </Checkbox>
              )
            }}
          />
        </FormControl>

        <Button
          color='white'
          backgroundColor='resolveOrange'
          mt='1rem'
          mb='1rem'
          type='submit'
        >
          Submit
        </Button>
      </form>
    </>
  )
}
