import { useContext, useEffect } from 'react'
import { Avatar } from '@chakra-ui/react'
import { UserContext } from 'features/Auth/context/user.context'

const UserAvatar = props => {
  const { engineer } = useContext(UserContext)

  return (
    <Avatar
      name={engineer.msal.displayName}
      size='sm'
      bgColor='resolveOrange'
      {...props}
      src={engineer.quotes.picture_blob}
    />
  )
}

export default UserAvatar
