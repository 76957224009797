import { useContext, useState, useCallback } from 'react'
import { Flex, Button, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { UserContext } from 'features/Auth/context/user.context'
import { ClientSelectInput } from 'components/Form/ClientSelectInput'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { SubscriptionTypeSelect } from 'components/Form/SubscriptionTypeSelect'
import { NameInput } from 'components/Form/NameInput'
import { DateSelect } from 'components/Form/DateSelect'
import { SerialLicenseInput } from 'components/Form/SerialLicenseInput'
import { LicenseCountInput } from 'components/Form/LicenseCountInput'
import { QuoteTypeSelect } from 'components/Form/QuoteTypeSelect'
import { useCreateSub } from 'features/Subs/api/createSub'

const initialState = {
  client_id: '',
  subscriptiontype_id: '',
  supplier_id: '',
  quote_type_id: 0,
  name: '',
  num_licenses: 0,
  serial_license: '',
  date_first_renewal: ''
}

export const AddSub = () => {
  const [date, setDate] = useState({ selectedDay: '' })
  const { engineer } = useContext(UserContext)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: initialState
  })

  const createSub = useCreateSub()

  const handleDayChange = date => {
    setDate({ selectedDay: date })
  }

  const handleCreateSub = useCallback(
    form => {
      createSub.mutate({ date, form, engineer })
    },
    [date, engineer, createSub]
  )

  return (
    <form onSubmit={handleSubmit(handleCreateSub)}>
      <Text color='resolveGreen' mb='2rem' fontSize='2rem' fontWeight='600'>
        New Subscription
      </Text>
      <ClientSelectInput
        control={control}
        required
        name='client_id'
        mb='1rem'
        errors={errors}
        selectedOptionColor='resolveGreener'
      />
      <SubscriptionTypeSelect
        required
        control={control}
        errors={errors}
        name='subscriptiontype_id'
        selectedOptionColor='resolveGreener'
      />

      <Flex>
        <QuoteTypeSelect
          name='quote_type_id'
          required
          errors={errors}
          control={control}
          selectedOptionColor='resolveGreener'
          mr='1rem'
        />
        <DateSelect
          label='Renewal Date'
          date={date}
          handleDayChange={handleDayChange}
          selectedOptionColor='resolveGreener'
        />
      </Flex>

      <SupplierSelectInput
        control={control}
        required
        register={register}
        errors={errors}
        name='supplier_id'
        selectedOptionColor='resolveGreener'
        mt='1rem'
        ignoreOtherSupplier
      />

      <NameInput name='name' required register={register} />

      <Flex dir='row' mt='1rem'>
        <LicenseCountInput register={register} name='num_licenses' />
        <SerialLicenseInput register={register} name='serial_license' />
      </Flex>

      <Button
        variant='solid'
        backgroundColor='resolveGreen'
        color='white'
        mt='1rem'
        _hover={{ bg: 'gray', color: 'resolveGreen' }}
        type='submit'
        isLoading={isSubmitting}
      >
        Add Subscription
      </Button>
    </form>
  )
}
