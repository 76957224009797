import { SearchFilter } from './SearchFilter'
import { Paginator } from 'components/Paginator'
import { QuoteList } from './index'
import { useParams } from 'react-router'
import { useQuoteFilter } from 'hooks/useQuoteFilter'
import { useState } from 'react'
import { useGetQuotes } from 'features/Quotes/api/getQuotes'
import SomethingBroke from 'components/Errors/SomethingBroke'

export const Container = () => {
  const { quoteId } = useParams()
  const { filter, setFilter, filterString } = useQuoteFilter()
  const [page, setPage] = useState(1)
  const quotes = useGetQuotes({ page, filterString }) 

  if(quotes.error) throw new Error('Database error')
  return (
    <>
      <SearchFilter
        setPage={setPage}
        setFilter={setFilter}
        filter={filter}
      />
      <QuoteList 
        quotes={quotes.data.quotes}
        isFetching={quotes.isFetching}
        isLoading={quotes.isLoading}
        quoteId={quoteId}
        isError={quotes.error}
      />
      <Paginator
        resultCount={quotes.data.resultCount}
        page={page}
        setPage={setPage}
      />
    </>
  )
}
