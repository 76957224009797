import { Avatar, Flex } from '@chakra-ui/react'

export const Engineer = ({ engineer, blob }) => {
  return (
    <Flex
      pointerEvents='none'
      justifyContent='flex-start'
      flexBasis='10%'
      minW='10%'
      ml='1rem'
      maxW='10%'
    >
      <Avatar
        size='sm'
        name={engineer}
        src={blob}
      />
    </Flex>
  )
}
