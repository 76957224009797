import { useState, useEffect } from 'react'

const defaultFilterState = {
  engineer: '',
  clientId: '',
  date: ''
}

export const useSubFilter = () => {
  const [filter, setFilter] = useState(defaultFilterState)
  const [filterString, setFilterString] = useState('')
  useEffect(() => {
    let tmpStr = ''
    if (filter.clientId.length > 0) {
      tmpStr += `&clientId=${filter.clientId}`
    }
    if (filter.date.length > 0) {
      tmpStr += `&dateAdded=${filter.date}`
    }
    if (filter.engineer.length > 0) {
      tmpStr += `&engineer=${filter.engineer}`
    }
    setFilterString(tmpStr)
  }, [filter])

  return { filterString, filter, setFilter }
}
