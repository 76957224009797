import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { CheckboxController } from 'components/Form/CheckboxController'
import { axiosClient } from 'lib/axiosClient'
import { useMutation, useQuery } from 'react-query'
import { useResolveToast } from 'hooks/useResolveToast'

const initialPermissionState = {
  canAccessQuotes: false,
  canAddQuotes: false,
  canDeleteQuotes: false,
  canInvoice: false,
  canApproveAndPost: false,
  canAssignQuotes: false,
  canAccessSubs: false,
  canDeleteSubs: false,
  canUpdateSettings: false,
  canViewInternal: false,
  canAccessReports: false,
  isAdmin: false
}

const updateUserPermissions = async ({ userId, permissions }) => {
  await axiosClient({
    url: `/settings/users/permissions/${userId}`,
    permissions,
    method: 'patch',
    data: permissions
  })
}

const useUpdateUserPermissions = () => {
  const { toastSuccess, toastFail } = useResolveToast()
  return useMutation(updateUserPermissions, {
    onSuccess: () => {
      toastSuccess('Permissions updated successfully')
    },
    onError: () => {
      toastFail('Failed to update user permissions')
    }
  })
}

const getUserPermissions = async (context) => {
  const { signal, queryKey } = context
  const [_, userId] = queryKey
  const { data: permissions } = await axiosClient({
    url: `/settings/users/permissions/${userId}`,
    signal
  })
  return permissions
}

const useGetUserPermissions = ({ onSuccess, userId }) => {
  return useQuery(['settings-user-permissions', userId], {
    queryFn: getUserPermissions,
    placeholderData: {},
    enabled: !!userId,
    onSuccess
  })
}

export const PermissionsModal = ({ isOpen, onClose, userId }) => {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: initialPermissionState
  })

  const updateUserPermissions = useUpdateUserPermissions()
  const handlePermissionSubmit = async form => {
    await updateUserPermissions.mutateAsync({ userId, permissions: form })
    onClose()
  }


  const onSuccess = perms => {
    reset(perms)
  }
  const userPermissions = useGetUserPermissions({ userId, onSuccess })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color='resolveOrange'> User Permissions: {userId?.label}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(handlePermissionSubmit)}>
            <Text mb='1rem' color='resolveOrange'>Admin</Text>
            <VStack align='start'>
              <CheckboxController control={control} name='isAdmin' text='Admin Access' />
              <CheckboxController control={control} name='canInvoice' text='Invoicing' />
              <CheckboxController control={control} name='canApproveAndPost' text='Approve & Post' />
              <CheckboxController control={control} name='canAccessReports' text='Reports' />
            </VStack>

            <Text mb='1rem' mt='1rem' color='resolveOrange'>Quotes</Text>
            <VStack align='start'>
              <CheckboxController control={control} name='canAccessQuotes' text='View Quotes' />
              <CheckboxController control={control} name='canAddQuotes' text='Add Quotes' />
              <CheckboxController control={control} name='canDeleteQuotes' text='Delete Quotes' />
            </VStack>

            <Text mt='1rem' mb='1rem' color='resolveOrange'>Subs</Text>
            <VStack align='start'>
              <CheckboxController control={control} name='canAccessSubs' text='Access Subscriptions' />
              <CheckboxController control={control} name='canDeleteSubs' text='Delete Subscriptions' />
            </VStack>

            <Text mt='1rem' mb='1rem' color='resolveOrange'>Internal</Text>
            <VStack align='start'>
              <CheckboxController control={control} name='canViewInternal' text='View Internal' />
              <CheckboxController control={control} name='canDeleteInternalOrders' text='Delete Internal Orders' />
              <CheckboxController control={control} name='canAssignQuotes' text='Re-assign Quotes' />
            </VStack>
            <Button
              color='white'
              backgroundColor='resolveOrange'
              mt='2rem'
              mb='1rem'
              type='submit'
            >
              Submit
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
