import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Text
} from '@chakra-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Header } from './Header'
import { ItemStatusRadioGroup } from '../components/ItemStatusRadioGroup'
import { ItemStatusText } from '../components/ItemStatusText'
import { QuoteStatusCheckboxGroup } from '../components/QuoteStatusCheckboxGroup'

import { ItemClientCostInput } from 'components/Form/ItemClientCostInput'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput'
import { ItemInternalDescriptionInput } from 'components/Form/ItemInternalDescriptionInput'
import { ItemMarkupInput } from 'components/Form/ItemMarkupInput'
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput'
import { ItemQuantityInput } from 'components/Form/ItemQuantityInput'
import { ItemUnitPriceInput } from 'components/Form/ItemUnitPriceInput'
import { MinimumTermSelectInput } from 'components/Form/MinimumTermSelectInput'
import { PaymentPlanSelectInput } from 'components/Form/PaymentPlanSelectInput'
import { SupplierProductTypeSelectInput } from 'components/Form/SupplierProductTypeSelectInput'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { UserSelectInput } from 'components/Form/UserSelectInput'
import { useNavigate } from 'react-router-dom'
import { EditQuoteModal } from '../Modals/EditQuoteModal'
import { PopoverButton } from 'components/PopoverButton'
import { useDeleteQuoteItem } from 'features/Quotes/api/deleteQuoteItem'
import { useEffect } from 'react'
import { useResolveToast } from 'hooks/useResolveToast'
import { useSyncAutotaskCost } from 'features/Quotes/api/syncAutotaskCost'

export const Form = ({
  engineer,
  assignModalOnOpen,
  handleInvoice,
  handleItemStatusChange,
  isSubmitting,
  itemLocation,
  onCopyItem,
  onFormSubmit,
  setItemLocation
}) => {
  const { quoteId, itemId } = useParams()
  const history = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { toastFail } = useResolveToast()

  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    formState: { errors, isDirty, dirtyFields }
  } = useFormContext()
  const watchItemCheckedOut = useWatch({ control, name: 'checked_out' })
  const watchItemLocation = useWatch({ control, name: 'item_location' })
  const watchCheckoutDate = useWatch({ control, name: 'checkout_date' })
  const watchDateAdded = useWatch({ control, name: 'date_added' })
  const watchInvoiceDate = useWatch({ control, name: 'invoice_date' })
  const watchDateOrdered = useWatch({ control, name: 'date_ordered' })
  const watchItemStatusId = useWatch({ control, name: 'item_status_id' })
  const watchProductType = useWatch({ control, name: 'type_id' })
  const watchDescription = watch('description')
  const watchAtCostId = watch('at_cost_id')
  const watchAnP = watch('approved_posted')

  const deleteItem = useDeleteQuoteItem(quoteId)
  
  const onEditQuote = () => {
    onOpen()
  }

  const handleOrderDetailsClick = () => {
    history(`/quotes/${quoteId}/edit/${itemId}/order`)
  } 


  const syncAutotaskCostQuery = useSyncAutotaskCost(itemId)
  const syncAutotaskCost = async () => {
    if(isDirty) {
      toastFail('Changes detected, save the Item before synchronizing the cost with Autotask')
      return
    }
    await syncAutotaskCostQuery.mutate(itemId)
  }

  return (
    <>
      {isOpen && (
        <EditQuoteModal
          isOpen={isOpen}
          onClose={onClose}
          quoteId={quoteId}
        />
      )}

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Header
          onEditQuote={onEditQuote}
          onCopyItem={onCopyItem}
        />

        <SupplierSelectInput
          control={control}
          register={register}
          required
          ignoreOtherSupplier={false}
          errors={errors}
          name='supplier_id'
        />

        <SupplierProductTypeSelectInput
          control={control}
          errors={errors}
          name='type_id'
        />

        <Flex mb='1rem'>
          <ItemPartCodeInput
            register={register}
            errors={errors}
            name='part_code'
            flexBasis='30%'
            mr='1rem'
            showLabel
          />
          <ItemDescriptionInput
            register={register}
            errors={errors}
            required={true}
            rules={{
              maxLength: {
                value: 90,
                message: 'Description must be under 90 characters'
              }
            }}
            name='description'
          />
        </Flex>

        <ItemInternalDescriptionInput
          register={register}
          name='internal_description'
          errors={errors}
        />

        <Flex mb='1rem'>
          <ItemQuantityInput
            control={control}
            name='quantity'
            required
            isDisabled={false}
            mr='1rem'
            errors={errors}
          />
          <ItemUnitPriceInput
            control={control}
            name='unit_price'
            errors={errors}
            required
            isDisabled={false}
            mr='1rem'
            setValue={setValue}
          />
          <ItemMarkupInput
            control={control}
            name='mark_up_percentage'
            errors={errors}
            isDisabled={false}
            required
            setValue={setValue}
          />
        </Flex>
        <Flex mb='1rem'>
          <ItemClientCostInput
            control={control}
            name='client_cost'
            errors={errors}
            required
            isDisabled={false}
            setValue={setValue}
          />
          <ItemDeliveryPriceInput
            control={control}
            required={false}
            isDisabled={false}
            name='delivery_price'
            errors={errors}
          />
        </Flex>
        {watchProductType?.value === 2 && (
          <>
            <FormLabel mt='1rem'>Subscription Options</FormLabel>
            <Flex mb='2rem'>
              <MinimumTermSelectInput
                required
                control={control}
                errors={errors}
                name='minimum_term_id'
                mr='1rem'
              />
              <PaymentPlanSelectInput
                required
                control={control}
                errors={errors}
                name='payment_plan_id'
              />
            </Flex>
          </>
        )}

        <QuoteStatusCheckboxGroup
          control={control}
          handleInvoice={handleInvoice}
        />

        <Divider />

        <ItemStatusRadioGroup
          itemStatusId={watchItemStatusId}
          handleItemStatusChange={handleItemStatusChange}
        />

        {watchItemCheckedOut === true && (
          <UserSelectInput
            validation={true}
            name='checked_out_by'
            errors={errors}
            control={control}
            showLabel={false}
            mb='1rem'
          />
        )}

        {watchItemStatusId === 4 && (
          <FormControl mb='1rem' isRequired>
            <FormLabel>Item Location</FormLabel>
            <Flex dir='row'>
              <Input
                value={itemLocation}
                onChange={e => setItemLocation(e.target.value)}
                mr='1rem'
              />
            </Flex>
          </FormControl>
        )}

        <ItemStatusText
          dateAdded={watchDateAdded}
          dateOrdered={watchDateOrdered}
          invoiceDate={watchInvoiceDate}
          checkoutDate={watchCheckoutDate}
          itemLocation={watchItemLocation}
        />

        <Flex mt='1rem' flexDir='row'>
          <Button
            variant='solid'
            backgroundColor='resolveOrange'
            color='white'
            _hover={{ bg: 'gray', color: 'resolveOrange' }}
            type='submit'
            mr='1rem'
            isLoading={isSubmitting}
          >
            Save
          </Button>

          {engineer.permissions.canAssignQuotes && (
            <Button
              variant='solid'
              backgroundColor='resolveOrange'
              color='white'
              mr='1rem'
              onClick={assignModalOnOpen}
              _hover={{ bg: 'gray', color: 'resolveOrange' }}
            >
              Assign
            </Button>
          )}

          {(watchItemStatusId === 7 || watchItemStatusId === 4) && (
            <Button
              variant='solid'
              backgroundColor='resolveOrange'
              color='white'
              onClick={handleOrderDetailsClick}
              _hover={{ bg: 'gray', color: 'resolveOrange' }}
              mr='1rem'
            >
              Order Details
            </Button>
          )}
          {
            watchAtCostId && (
              <Button
                variant='solid'
                backgroundColor='resolveBlue'
                color='white'
                onClick={syncAutotaskCost}
                isLoading={syncAutotaskCostQuery.isLoading}
                isDisabled={!!watchAnP}
             >
              Sync Cost
             </Button>
            )
          }
          {
            watchItemStatusId !== 8 && (
              <Button
                variant='solid'
                backgroundColor='resolvePurple'
                color='white'
                marginLeft='auto'
                onClick={handleInvoice}
                _hover={{ bg: 'gray', color: 'resolvePurple' }}
              >
                Invoice Details
              </Button>
            )
          }
        </Flex>


        {engineer.permissions.canDeleteQuotes && (
          <Flex dir='row' mt='1rem'>
            <PopoverButton
              title='Delete Item'
              buttonTitle='Delete'
              
              color='resolveOrange'
              message='Are you sure you want to delete this item?'
              isLoading={deleteItem.isLoading}
              handleClick={() => deleteItem.mutate(itemId)}
            />
          </Flex>
        )}
      </form>
    </>
  )
}
