import { useContext } from 'react'

import { UserContext } from 'features/Auth/context/user.context'

import { AppRoutes } from 'routes'

import BetaTestHelper from 'features/BetaTestHelper'
import UserNotFound from 'components/Errors/UserNotFound'
import LoadingProfile from 'components/LoadingProfile'
import AccountDisabled from 'components/Errors/AccountDisabled'
import ErrorBoundary from 'components/Errors/ErrorBoundary'
import { SearchContextProvider } from './Search/context/search.context'

export const Sms = () => {
  const { engineer } = useContext(UserContext)

  if (engineer.notFound) return <UserNotFound />
  if (!engineer.quotes.loggedIn) return <LoadingProfile />
  if (!engineer.quotes.active) return <AccountDisabled />
  return (
    <>
      <SearchContextProvider>
        <ErrorBoundary>
          <BetaTestHelper />
          <AppRoutes />
        </ErrorBoundary>
      </SearchContextProvider>
    </>
  )
}

