import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

export const deleteItem = async itemId => {
  await axiosClient({
    url: `/quotes/item/${itemId}`,
    method: 'delete'
  })
}

export const useDeleteQuoteItem = (quoteId) => {
  const queryClient = useQueryClient()
  const history = useNavigate()
  const { toastSuccess, toastFail } = useResolveToast()
  return useMutation(deleteItem, {
    onSuccess: () => {
      toastSuccess('Item deleted succesfully')
      queryClient.invalidateQueries(['quote-items'])
      history(`/quotes/${quoteId}`)
    },
    onError: () => {
      toastFail('Failed to delete item')
    }
  })
}