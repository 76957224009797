import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation, useQueryClient } from "react-query";

const updateUser = async ({ userId, user }) => {
  await axiosClient({
    url: `/settings/users/${userId}`,
    method: 'patch',
    data: {
      ...user,
      team_id: user.team_id.value,
      leader: user.leader ? 1 : 0,
      autotask_id: Number(user.autotask_id)
    }
  })
}

export const useUpdateUser = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateUser, {
    onSuccess: () => {
      toastSuccess('User updated successfully')
      queryClient.invalidateQueries(['settings-user'])
      queryClient.invalidateQueries(['settings-users'])
      queryClient.invalidateQueries(['form-users'])
    },
    onError: error => {
      toastFail('Failed to update user')
    }
  })
}