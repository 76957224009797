import { axiosClient } from "lib/axiosClient";
import { useQuery } from "react-query";

const getPaymentMethods = async (context) => {
  const { signal } = context
  const { data: paymentMethods } = await axiosClient({
    url: '/settings/payment-methods',
    signal
  })
  return paymentMethods
}

export const useGetPaymentMethods = ({ onSuccess }) => {
  return useQuery(['settings-payment-methods'], {
    queryFn: getPaymentMethods,
    onSuccess
  })
}