import { useCallback, useEffect } from 'react'
import {
  Text,
  FormControl,
  FormLabel,
  Flex,
  Button,
  Input,
  Icon
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { PopoverButton } from 'components/PopoverButton'
import { BillingCodeSelect } from 'components/Form/BillingCodeSelect'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import { Loading } from 'components/Loading'
import { useGetSupplier } from 'features/Settings/api/getSupplier'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useDeleteSupplier } from 'features/Settings/api/deleteSupplier'
import { useUpdateSupplier } from 'features/Settings/api/updateSupplier'

const NameInput = ({ register, name }) => {
  return (
    <FormControl mb='1rem'>
      <FormLabel color='resolveOrange'>Name</FormLabel>
      <Input {...register(name)} />
    </FormControl>
  )
}

const FormHeader = ({ name, active, handleActiveClick }) => {
  return (
    <Flex flexFlow='row' mt='2rem' mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        {name}
      </Text>
      <Flex alignItems='flex-start' onClick={handleActiveClick}>
        {
          active
            ? (
              <>
                <Button
                  variant='unstyled'
                  leftIcon={<Icon as={ViewIcon} />}
                  p='5px'
                  color='white'
                  bg='resolveGreen'
                >
                  Active
                </Button>
              </>
              )
            : (
              <>
                <Button
                  variant='unstyled'
                  leftIcon={<Icon as={ViewOffIcon} />}
                  p='5px'
                  color='white'
                  bg='resolveRed'
                >
                  Inactive
                </Button>
              </>
              )
            }
      </Flex>
    </Flex>
  )
}

const SupplierState = {
  name: '',
  active: true,
  default_delivery: 0,
  default_billing_code: 0
}

export const EditSupplierForm = ({
  supplierId,
  selectRef,
  ...restProps
}) => {
  const {
    register,
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: SupplierState })

  const updateSupplier = useUpdateSupplier()
  const watchActive = watch('active')

  const handleSupplierUpdate = async supplier => {
    await updateSupplier.mutateAsync({ supplierId, supplier })
    selectRef.current.clearValue()
  }

  const handleActiveClick = useCallback(() => {
    setValue('active', !watchActive)
  }, [watchActive, setValue])

  const deleteSupplier = useDeleteSupplier()
  const handleDeleteSupplier = useCallback(async () => {
    await deleteSupplier.mutateAsync({ supplierId })
    selectRef.current.clearValue()
  }, [deleteSupplier, selectRef, supplierId])

  const onSuccess = supplier => {
    reset(supplier)
  }
  const supplier = useGetSupplier({ supplierId, onSuccess })
  if(supplier.isLoading) return <Loading />
  if(supplier.error) return <SomethingBroke message='Failed to get Supplier' error={supplier.error.message} />
  return (
    <form
      onSubmit={handleSubmit(handleSupplierUpdate)}
      {...restProps}
    >
      <FormHeader
        name='Edit Supplier'
        active={watchActive}
        handleActiveClick={handleActiveClick}
      />
      <NameInput register={register} name='name' />
      <ItemDeliveryPriceInput
        control={control}
        name='default_delivery'
        errors={errors}
        labelText='Default Postage' 
        required={undefined}
      />
      <BillingCodeSelect
        control={control}
        name='default_billing_code'
        mt='1rem'
        mb='1rem'
        errors={errors}
        labelText='Default Billing Code'
        required={undefined}
      />
      <Flex>
        <Button
          type='submit'
          mt='1rem'
          color='white'
          backgroundColor='resolveOrange'
        >
          Save
        </Button>
        <PopoverButton
          title='Delete'
          mt='1rem'
          ml='1rem'
          message='Are you sure you want to delete this supplier?'
          handleClick={handleDeleteSupplier}
          buttonTitle='Delete Supplier'
          color='resolveOrange'
          backgroundColor='white'
          isLoading={undefined}
        />
      </Flex>
    </form>
  )
}
