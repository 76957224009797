import {
  FormControl,
  Flex,
  Box,
  Text,
  useRadioGroup,
  useRadio
} from '@chakra-ui/react'

import { useController } from 'react-hook-form'

const getItemStatusLabelFromItemStatusId = value => {
  switch (value) {
    case '7': {
      return 'Ordered'
    }
    case '4': {
      return 'Received'
    }
    default: {
      return 'None'
    }
  }
}

const getBgColorFromItemStatusId = statusId => {
  switch (statusId) {
    case '7': {
      return 'resolvePink'
    }
    case '4': {
      return 'resolveGreen'
    }
    default: {
      return 'None'
    }
  }
}

const RadioGroupCard = props => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Flex as='label' cursor='pointer'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderColor='grey.400'
        borderWidth='1px'
        borderRadius='md'
        mr='1rem'
        _checked={{
          bg: getBgColorFromItemStatusId(input.value),
          color: 'white'
        }}
        _focus={{
          boxShadow: 'outline'
        }}
        px={4}
        py={2}
        pointerEvents='none'
      >
        {props.children}
      </Box>
    </Flex>
  )
}

export const StatusRadioGroup = ({
  name,
  control,
  handleItemStatusChange,
  ...props
}) => {
  const { field } = useController({ control, name })
  const options = ['7', '4']

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange: field.onChange,
    value: String(field.value)
  })

  const group = getRootProps()

  return (
    <FormControl {...props}>
      <Flex {...group}>
        {options.map((value, i) => {
          const radio = getRadioProps({ value })
          return (
            <RadioGroupCard key={value} {...radio}>
              <Text pointerEvents='none'>
                {getItemStatusLabelFromItemStatusId(value)}
              </Text>
            </RadioGroupCard>
          )
        })}
      </Flex>
    </FormControl>
  )
}
