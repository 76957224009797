import { useEffect, useState } from 'react'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { ControlledSelect } from '../../components/Form/ControlledSelect'
import { useQuery } from 'react-query'
import { axiosClient } from 'lib/axiosClient'

const getLeader = async (context) => {
  const { signal } = context
  const { data: users } = await axiosClient({
    url: '/settings/users',
    signal
  })
  return users.filter(user => user.leader)
}
const useGetLeader = () => {
  return useQuery(['form-leader'], {
    queryFn: getLeader,
    placeholderData: [],
    staleTime: Infinity
  })
}
export const AuthSelectInput = ({
  control,
  required = false,
  isClearable = false,
  isMulti = false,
  labelText = 'User',
  errors,
  name,
  showLabel = true,
  placeholder = 'User',
  selectedOptionColor = 'resolveOranger',
  ...props
}) => {

  const leaders = useGetLeader()
  return (
    <FormControl
      isRequired={required}
      isInvalid={!!errors[name]}
      mr='1rem'
      {...props}
    >
      {showLabel && <FormLabel>{labelText}</FormLabel>}
      <ControlledSelect
        isLoading={false}
        isClearable={false}
        name={name}
        control={control}
        options={leaders.data}
        isMulti={isMulti}
        placeholder={placeholder}
        rules={{ required }}
        selectedOptionColor={selectedOptionColor}
      />
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  )
}
