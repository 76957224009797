import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button
} from '@chakra-ui/react'

import { useForm } from 'react-hook-form'
import { useCreateSubType } from 'features/Settings/api/createSubType'

const LabelInput = ({ register, name }) => {
  return (
    <FormControl mb='1rem'>
      <FormLabel color='resolveOrange'>Label</FormLabel>
      <Input {...register(name, { required: true })} />
    </FormControl>
  )
}

const FormHeader = () => {
  return (
    <Flex flexFlow='row' mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        Add Sub Type
      </Text>
    </Flex>
  )
}

const PMState = {
  label: ''
}

export const AddSubTypeForm = () => {
  const { register, handleSubmit } = useForm({ defaultValues: PMState })

  const createSubType = useCreateSubType()
  const onSubmit = form => {
    createSubType.mutate({ form })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader />
      <LabelInput register={register} name='label' />
      <Button color='white' bgColor='resolveOrange' type='submit'>
        Add
      </Button>
    </form>
  )
}
