import {
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { ClientSelectInput } from 'components/Form/ClientSelectInput'
import { useGetQuote } from 'features/Quotes/api/getQuote'
import { useUpdateQuote } from 'features/Quotes/api/updateQuote'
import { UserSelectInput } from 'components/Form/UserSelectInput'
import { Loading } from 'components/Loading'
import { OtherClientInput } from 'components/Form/OtherClientInput'

const editDefaultState = {
    lead_source: '0',
    engineer_id: '',
    client_id: { value: null, label: null},
    other_client: null,
    ref: ''
}

export const EditQuoteModal = ({
    quoteId,
    isOpen,
    onClose
}) => {
    const {
        control,
        reset,
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({ defaultValues: editDefaultState })

    const quote = useGetQuote({
        quoteId, onSuccess: quote => {
            reset(quote)
        }
    })
    const updateQuote = useUpdateQuote(quoteId)

    const onQuoteUpdate = useCallback(
        async form => {
            updateQuote.mutate({
                quoteId, quote: {
                    other_client: form.other_client,
                    client_id: form.client_id.value,
                    engineer_id: form.engineer_id.value
                }
            })
            onClose()
        }, [updateQuote, quoteId, onClose]
    )
    
    const watchClient = useWatch({ control, name: 'client_id' })

    if (quote.isLoading) return <Loading />
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody p='1.5rem'>
                        <Heading size='md' mb='1rem' color='resolveOrange'>
                            Edit Quote
                        </Heading>
                        <form onSubmit={handleSubmit(onQuoteUpdate)}>
                            <ClientSelectInput
                                required={true}
                                errors={errors}
                                name='client_id'
                                mb='1rem'
                                control={control}
                            />
                            {watchClient.value === 99999999 && (
                                <OtherClientInput
                                    errors={errors}
                                    name='other_client'
                                    register={register}
                                />
                            )}
                            <UserSelectInput
                                control={control}
                                errors={errors}
                                name='engineer_id'
                                isClearable={false}
                                validation={true}
                            />
                            <Button
                                bgColor='resolveOrange'
                                mt='1rem'
                                color='white'
                                type='submit'
                            >
                                Save
                            </Button>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
