import './styles/fonts.css';
import { UserContextProvider } from 'features/Auth/context/user.context';
import { MsalProvider } from '@azure/msal-react';
import { Sms } from 'features';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { Navigation } from 'features/Navigation/Navigation';

const App = ({ pca }) => (
  <MsalProvider instance={pca}>
    <UserContextProvider>
      <Navigation />
      <ErrorBoundary>
        <Sms />
      </ErrorBoundary>
    </UserContextProvider>
  </MsalProvider>
);

export default App;
