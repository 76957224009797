import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getSalesReport = async (context) => {
  const { signal, queryKey } = context
  const [_, filterString] = queryKey
  const { data: { items } } = await axiosClient({
    url: `/reports/sales?${filterString}`,
    signal
  })
  return items
}

export const useGetSalesReport = (filterString) => {
  return useQuery(['sales-report', filterString], {
    queryFn: getSalesReport,
    placeholderData: []
  })
}