import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

const createProduct = async ({ form }) => {
  const hForm = {
    ...form,
    type_id: form.type_id.value,
    unit_price: parseFloat(form.unit_price),
    mark_up_percentage: Number(form.mark_up_percentage),
    delivery_price: parseFloat(form.delivery_price),
    payment_plan_id: form.payment_plan_id?.value || 0,
    minimum_term_id: form.minimum_term_id?.value || 0,
    supplier_id: form.supplier_id.value
  }
  await axiosClient({
    url: '/settings/products',
    method: 'post',
    data: hForm
  })
}

export const useCreateProduct = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const history = useNavigate()
  return useMutation(createProduct, {
    onSuccess: () => {
      toastSuccess('Product added successfully')
      history('/settings/products')
    },
    onError: () => {
      toastFail('Failed to add product')
    }
  })
}