import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

const updateSupplier = async ({ supplierId, supplier }) => {
  const { id, ...cForm } = supplier
  const nForm = {
    ...cForm,
    active: cForm.active ? 1 : 0,
    default_billing_code: cForm.default_billing_code?.value || null
  }
  await axiosClient({
    url: `/settings/suppliers/${supplierId}`,
    method: 'patch',
    data: nForm
  })
}

export const useUpdateSupplier = () => {
  const { toastSuccess, toastFail } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateSupplier, {
    onSuccess: () => {
      toastSuccess('Supplier updated successfully')
      queryClient.invalidateQueries(['settings-suppliers'])
      queryClient.invalidateQueries(['form-suppliers'])
    },
    onError: () => {
      toastFail('Failed to update Supplier') 
    }
  })
}