import { Flex, Spacer, Box, Text, Checkbox } from '@chakra-ui/react'

import { BulkActions } from './BulkActions'

export const Header = ({ handleBulkAction, quoteId, handleSelectAll }) => (
  <Flex flexFlow='column'>
    <Flex flexFlow='row'>
      <BulkActions handleBulkAction={handleBulkAction} quoteId={quoteId} />
      <Spacer />
      <Box>
        <Text fontSize='3xl' color='resolveDarkGrey' fontWeight='800'>
          Q{quoteId}
        </Text>
      </Box>
    </Flex>
    <Flex flexFlow='row' mr='1.2rem' mb='1rem' mt='1rem' justifyContent='flex-end'>
      <Text mr='1rem'>Select All</Text>
      <Checkbox colorScheme='resolveOranger' defaultChecked={false} onChange={handleSelectAll} />
    </Flex>
  </Flex>
)
