import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getSupplier = async (context) => {
  const { signal, queryKey } = context
  const [_, supplierId] = queryKey
  const { data: supplier } = await axiosClient({
    url: `/settings/suppliers/${supplierId.value}`,
    signal
  })
  return supplier
}

export const useGetSupplier = ({supplierId, productTypeId, onSuccess}) => {
  return useQuery(['quote-item-supplier', supplierId], {
    queryFn: getSupplier,
    onSuccess,
    enabled: !!supplierId && productTypeId?.value === 1
  })
}