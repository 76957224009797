import { useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Center,
  Divider,
  Flex,
  SlideFade,
  Text,
  Textarea
} from '@chakra-ui/react'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Actions } from './Actions'
import { SubItem } from '.'
import { ItemListLayout } from 'components/Layout/ItemListLayout'
import { useGetSubItem } from 'features/Subs/api/getSubItem'
import { useUpdateSubNotes } from 'features/Subs/api/updateSubNotes'
import { useDeleteSub } from 'features/Subs/api/deleteSub'


const NoItemsFound = () => (
  <ItemListLayout>
    <Center>
      <Text color='resolveGreen'>No sub items</Text>
    </Center>
  </ItemListLayout>
)

export const Container = () => {
  const { subId } = useParams()
  const history = useNavigate()
  const [subNotes, setSubNotes] = useState('')

  const onSuccess = sub => {
    setSubNotes(sub.notes)
  }
  const sub = useGetSubItem({ subId, onSuccess })
  const deleteSub = useDeleteSub()
  const updateSubNotes = useUpdateSubNotes()

  const handleItemClick = useCallback(
    e => {
      if (subId !== e.target.id) {
        history(`/subs/${subId}/edit`)
      }
    },
    [subId, history]
  )

  const handleDeleteItem = useCallback(async () => {
    deleteSub.mutate({ subId })
  }, [subId, deleteSub])

  const handleNoteChange = e => {
      setSubNotes(e.target.value)
    } 

  const handleSubNotesUpdate = useCallback(async () => {
    updateSubNotes.mutate({ subId, notes: subNotes })
  }, [subNotes, subId, updateSubNotes])


  if (sub.isLoading) return <Loading />
  if (sub.error) return <SomethingBroke message='Failed to fetch sub' error={sub.error.message} />
  if (!sub) return <NoItemsFound />
  return (
    <>
      <Flex
        direction='column'
        mt='2rem'
        mb='1rem'
        maxHeight='600px'
        overflowY='auto'
        css={{
          '&::-webkit-scrollbar': {
            width: '4px'
          },
          '&::-webkit-scrollbar-track': {
            width: '4px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'lightGrey',
            borderRadius: '4px'
          }
        }}
      >
        <SlideFade in>
          <SubItem item={sub.data} handleItemClick={handleItemClick} />
        </SlideFade>
      </Flex>
      <Divider />
      <Textarea
        value={subNotes}
        size='md'
        onChange={handleNoteChange}
        mt='1rem'
      />
      <Actions
        handleDeleteItem={handleDeleteItem}
        isDeletingSub={deleteSub.isLoading}
        handleSubNotesUpdate={handleSubNotesUpdate}
      />
    </>
  )
}
