import { Text } from '@chakra-ui/react'

export const QuoteNumber = ({ quoteId, isSelected }) => (
  <>
    <Text
      pointerEvents='none'
      fontWeight='bold'
      color={isSelected ? 'white' : 'resolveOrange'}
      mr='0.2rem'
    >
      Q
    </Text>
    <Text pointerEvents='none' fontWeight='bold'>
      {quoteId}
    </Text>
  </>
)
