import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Flex,
  Button,
  PopoverTrigger,
  Popover,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverFooter,
  PopoverCloseButton,
  Input,
  FormControl,
  FormLabel
} from '@chakra-ui/react'

import { UserContext } from 'features/Auth/context/user.context'
import { CSVLink } from 'react-csv'
import { PopoverButton } from 'components/PopoverButton'
import { ControlledClientSelect } from 'components/Form/ControlledClientSelect'
import { useCreateTemplateFromQuote } from 'features/Quotes/api/createTemplateFromQuote'
import { useDeleteQuote } from 'features/Quotes/api/deleteQuote'
import { useCopyQuote } from 'features/Quotes/api/copyQuote'
import { useResolveToast } from 'hooks/useResolveToast'

export const Actions = ({ items, quoteId, selectedBulkItems }) => {
  const history = useNavigate()
  const { toastFail } = useResolveToast()
  const { engineer } = useContext(UserContext)
  const [templateName, setTemplateName] = useState('')
  const [copyQuoteClientId, setCopyQuoteClientId] = useState()

  const deleteQuote = useDeleteQuote(quoteId)
  const copyQuote = useCopyQuote()
  const createTemplateFromQuote = useCreateTemplateFromQuote()

  const addItem = () => {
    history(`/quotes/${quoteId}/add`)
  }

  const printQuote = e => {
    const printType = e.target.id
    history(`/quotes/print/${quoteId}/${printType}`)
  }

  const handleCopyQuote = useCallback(() => {
    if(!copyQuoteClientId || copyQuoteClientId === 'undefined') {
      toastFail('Please select a client to copy quote')
      return;
    }
    copyQuote.mutate({
      copy: {
        engineerId: engineer.quotes.id,
        clientId: copyQuoteClientId.value
      },
      quoteId
    })
  }, [copyQuoteClientId, copyQuote, toastFail, engineer, quoteId])

  return (
    <Flex
      flexFlow='column'
      flexWrap='wrap'
      ml='1rem'
      mb='1rem'
      justifyContent='flex-end'
    >
      <Flex justifyContent='right'>
        <Button
          onClick={addItem}
          backgroundColor='resolveOrange'
          size='sm'
          mb='1rem'
          color='white'
          ml='1rem'
        >
          Add Item
        </Button>
        <Popover>
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button size='sm' variant='outline' ml='1rem'>
                  Copy Quote
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody p='1rem'>
                    <ControlledClientSelect onChange={setCopyQuoteClientId} selectedClient={copyQuoteClientId} />
                  </PopoverBody>
                  <PopoverFooter>
                    <Flex justifyContent='center'>
                      <Button size='md' onClick={handleCopyQuote}>
                        Copy Quote
                      </Button>
                    </Flex>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </>
          )}
        </Popover>
        {engineer.permissions.canDeleteQuotes && (
          <PopoverButton
            title='Delete Quote'
            isDisabled
            ml='1rem'
            message='Are you sure? This will delete the quote and all items'
            isLoading={deleteQuote.isLoading}
            buttonTitle='Delete'
            handleClick={() => deleteQuote.mutate({ quoteId })}
            size='sm'
            variant='outline'
          />
        )}
      </Flex>
      <Flex justifyContent='right' mb='1rem'>
        <Button
          size='sm'
          variant='outline'
          ml='1rem'
          id='print'
          onClick={printQuote}
        >
          Print
        </Button>
        <Button
          size='sm'
          variant='outline'
          ml='1rem'
          id='pro-forma'
          onClick={printQuote}
        >
          Pro-Forma Invoice
        </Button>
        <Button
          size='sm'
          variant='outline'
          ml='1rem'
          id='fiddy'
          onClick={printQuote}
        >
          50% Deposit
        </Button>
      </Flex>
      <Flex justifyContent='right'>
        <Popover>
          <PopoverTrigger>
            <Button size='sm' variant='outline' ml='1rem'>
              Template
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody p='1rem'>
                <FormControl>
                  <FormLabel color='resolveOrange'>Template Name</FormLabel>
                  <Input onChange={e => setTemplateName(e.target.value)} />
                </FormControl>
              </PopoverBody>
              <PopoverFooter>
                <Flex justifyContent='center'>
                  <Button size='md' onClick={() => createTemplateFromQuote.mutate({ quoteId, engineerId: engineer.quotes.id, description: templateName })}>
                    Create Template
                  </Button>
                </Flex>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </Popover>
        <CSVLink
          data={
            selectedBulkItems.length > 0
              ? items
                .filter(itm => selectedBulkItems.includes(itm.id))
                .map(itm => ({
                  description: itm.description,
                  unit_price: itm.unit_price,
                  client_cost: itm.client_cost,
                  quantity: itm.quantity,
                  original_purchase_price: itm.quantity * itm.unit_price,
                  original_quote: itm.quantity * itm.client_cost
                }))
              : []
          }
          filename={`project-cost-export-${quoteId}.csv`}
        >
          <Button
            size='sm'
            isDisabled={selectedBulkItems.length < 1}
            variant='outline'
            ml='1rem'
          >
            Project Export
          </Button>
        </CSVLink>
      </Flex>
    </Flex>
  )
}

