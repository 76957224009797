import { useNavigate } from 'react-router-dom'
import { Flex, Box, Divider, Center, SlideFade } from '@chakra-ui/react'

import { ListItem } from './ListItem'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'

const NoItemsFound = () => (
  <Flex direction='column' mt='2rem'>
    <SlideFade in>
      <Center>
        <div>No internal orders found</div>
      </Center>
    </SlideFade>
  </Flex>
)
export const IOList = ({ isFetching, isLoading, ios = [], isError, ioId }) => {
  const history = useNavigate()

  const handleIoClick = e => {
    let targetItemId = ''
    if (e.target.id.length < 1) {
      targetItemId = e.target.parentElement.id
    } else {
      targetItemId = e.target.id
    }
    if (targetItemId !== ioId) {
      history(`/internal/${targetItemId}`)
    }
  }

  if (isError) return <SomethingBroke message='Failed to fetch Internal Orders' error={isError.message} />
  if (isLoading || isFetching) return <Loading />
  if(ios.length < 1) return <NoItemsFound />
  return (
    <Flex direction='column' mt='2rem'>
      <SlideFade in={ios.length > 0}>
        {ios.map((io, i) => {
          return (
            <Box key={`${io.io_id}`}>
              <ListItem
                handleIoClick={handleIoClick}
                isSelected={Number(io.io_id) === Number(ioId)}
                io={io}
              />
              <Divider />
            </Box>
          )
        })}
      </SlideFade>
    </Flex>
  )
}
