import { Flex, Text } from '@chakra-ui/react'

export const Description = ({ description }) => (
  <Flex
    pointerEvents='none'
    alignItems='flex-start'
    justifyContent='flex-start'
    minWidth='27%'
    flexBasis='27%'
  >
    <Text
      overflow='hidden'
      whiteSpace='nowrap'
      textOverflow='ellipsis'
      fontSize='md'
    >
      {description}
    </Text>
  </Flex>
)
