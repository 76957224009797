import { FormControl, FormLabel, Input } from '@chakra-ui/react'

export const SerialLicenseInput = ({
  register,
  name,
}) => {
  return (
    <FormControl>
      <FormLabel>Serial / License Number</FormLabel>
      <Input {...register(name)} />
    </FormControl>
  )
}
