import { axiosClient } from "lib/axiosClient"
import moment from "moment"
import { useResolveToast } from "hooks/useResolveToast"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

const createItem = async ({ ioId, form }) => {
  const nItem = {
    ...form,
    supplier_id: form.supplier_id.value,
    at_billing_code_id: form.at_billing_code_id.value,
    payment_method_id: form.payment_method_id.value,
    authorized_by_id: form.authorized_by_id,
    date_added: moment().format('YYYY-MM-DD')
  }
  await axiosClient({
    url: `/io/item/${ioId}`,
    method: 'post',
    data: nItem
  })
}

export const useCreateItem = ioId => {
  const queryClient = useQueryClient()
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(createItem, { 
    onSuccess: () => {
      toastSuccess('Item successfully added to IO')
      queryClient.invalidateQueries(['io-items'])
      history(`/internal/${ioId}`)
    },
    onError: () => {
      toastFail('Failed to add Internal Order')
    }
  })
}