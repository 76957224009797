import { FormControl, FormLabel, Input } from '@chakra-ui/react'

export const TeamNameInput = ({
  register,
  name,
  team,
}) => {
  return (
    <FormControl mb='1rem'>
      <FormLabel>Name</FormLabel>
      <Input {...register(name)} />
    </FormControl>
  )
}
