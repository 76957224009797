import { Controller, useWatch } from 'react-hook-form'
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input
} from '@chakra-ui/react'

import { Header } from './Header'
import { ItemStatusText } from './ItemStatusText'
import { StatusRadioGroup } from './StatusRadioGroup'

import { ItemClientCostInput } from 'components/Form/ItemClientCostInput'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput'
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput'
import { ItemQuantityInput } from 'components/Form/ItemQuantityInput'
import { PaymentMethodSelectInput } from 'components/Form/PaymentMethodSelectInput'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { AuthSelectInput } from 'components/Form/AuthSelectInput'
import { IOBillingCodeSelect } from 'components/Form/IOBillingCodeSelect'
import { ClientPOInput } from 'components/Form/ClientPOInput'

export const Form = ({
  formSubmit,
  handleInvoice,
  onSubmit,
  control,
  errors,
  register,
  handleItemStatusChange,
  assignModalOnOpen,
  isSubmitting,
  ioId,
  itemId
}) => {
  const dateOrdered = useWatch({ control, name: 'date_ordered' })
  const itemLocation = useWatch({ control, name: 'item_location' })
  const itemStatusId = useWatch({ control, name: 'item_status_id' })
  const invoiceDate = useWatch({ control, name: 'invoice_date' })

  return (
    <form onSubmit={formSubmit(onSubmit)}>
      <Header ioId={ioId} />

      <SupplierSelectInput
        control={control}
        register={register}
        errors={errors}
        required
        ignoreOtherSupplier={false}
        name='supplier_id'
        selectedOptionColor='resolveBluer'
      />

      <ItemDescriptionInput
        register={register}
        rules={{ maxLength: { value: 245, message: 'Description must be less than 245 characters'} }}
        required
        errors={errors}
        name='item_name'
      />

      <Flex dir='row' mt='1rem'>
        <ItemQuantityInput
          control={control}
          errors={errors}
          required
          name='quantity'
          mr='1rem'
        />
        <ItemClientCostInput
          name='cost_price'
          required
          mr='1rem'
          setValue={null}
          labelText='Cost Price'
          control={control}
          errors={errors}
        />
        <ItemDeliveryPriceInput
          control={control}
          errors={errors}
          required
          name='delivery_price'
        />
      </Flex>

      <Flex dir='row' mt='1rem' mb='1rem'>
        <PaymentMethodSelectInput
          name='payment_method_id'
          control={control}
          errors={errors}
          mr='1rem'
          required
          selectedOptionColor='resolveBluer'
        />

        <IOBillingCodeSelect
          name='io_billing_code_id'
          control={control}
          errors={errors}
          required
          labelText='Billing Code'
          selectedOptionColor='resolveBluer'
        />
      </Flex>

      <Flex>
        <ItemPartCodeInput
          register={register}
          name='part_code'
          errors={errors}
          showLabel
          mr='1rem'
        />
        <ClientPOInput
          register={register}
          required={false}
          name='supplier_order_number'
          labelText='Supplier Order Number'
        />
      </Flex>

      <AuthSelectInput
        name='authorized_by_id'
        errors={errors}
        control={control}
        labelText='Authorized By'
        required
        selectedOptionColor='resolveBluer'
        mt='1rem'
        mb='1rem'
      />

      <Flex dir='row' mt='1rem'>
        <Controller
          control={control}
          name='invoiced'
          key='invoiced'
          render={({ field: { ref, onChange, value, ...restField } }) => {
            return (
              <Checkbox
                mr='1rem'
                isChecked={value}
                onChange={e => {
                  handleInvoice(e)
                  onChange(e)
                }}
                colorScheme='purple'
                color='resolvePurple'
                ref={ref}
                {...restField}
              >
                Invoiced
              </Checkbox>
            )
          }}
        />
      </Flex>

      <StatusRadioGroup
        control={control}
        mt='1rem'
        mb='1rem'
        name='item_status_id'
        handleItemStatusChange={handleItemStatusChange}
      />

      <ItemStatusText
        mb='1rem'
        dateAdded={null}
        dateOrdered={dateOrdered}
        invoiceDate={invoiceDate}
        itemLocation={itemLocation}
      />

      {itemStatusId === '4' && (
        <FormControl mb='1rem' isInvalid={!!errors['item_location']}>
          <FormLabel>Item Location</FormLabel>
          <Flex dir='row'>
            <Input
              {...register('item_location', { required: 'Location is required when setting item to received' })}
              mr='1rem'
            />
          </Flex>
          <FormErrorMessage>
            {errors['item_location'] && errors['item_location'].message}
          </FormErrorMessage>
        </FormControl>
      )}

      <Flex>
        <Button
          variant='solid'
          backgroundColor='resolveBlue'
          color='white'
          _hover={{ bg: 'gray', color: 'resolveBlue' }}
          type='submit'
          mr='1rem'
          isLoading={isSubmitting}
        >
          Save
        </Button>

        <Button
          variant='solid'
          backgroundColor='resolveBlue'
          color='white'
          mr='1rem'
          onClick={assignModalOnOpen}
          _hover={{ bg: 'gray', color: 'resolveBlue' }}
        >
          Assign
        </Button>

        {
            itemStatusId !== 7 && (
              <Button
                variant='solid'
                backgroundColor='resolvePurple'
                color='white'
                marginLeft='auto'
                onClick={handleInvoice}
                _hover={{ bg: 'gray', color: 'resolvePurple' }}
              >
                Invoice Details
              </Button>
            )
          }
      </Flex>
    </form>
  )
}
