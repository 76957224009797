import { ArrowBackIcon, ArrowForwardIcon, ArrowLeftIcon, ArrowRightIcon, Search2Icon } from '@chakra-ui/icons'
import {
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  NumberInput,
  NumberInputField,
  Text
} from '@chakra-ui/react'
import { useCallback, useRef } from 'react'

export const Pagination = ({
  prevClick,
  nextClick,
  page,
  pageCount,
  onPageSearch,
  onPageChange
}) => {
  const pageRef = useRef()

  const handlePageSearch = useCallback(() => {
    onPageSearch(parseInt(pageRef.current.value))
  }, [pageRef, onPageSearch])

  const resetPage = () => {
    onPageSearch(0)
  }

  const goToLastPage = useCallback(() => {
    onPageSearch(parseInt(pageCount - 1))
  }, [pageCount, onPageSearch])

  return (
    <Flex alignItems='center' mt='2rem'>
      <IconButton
        variant='ghost'
        color='resolveOrange'
        icon={<ArrowLeftIcon w='4' h='4' />}
        onClick={resetPage}
        fontSize='20px'
      />
      <IconButton
        variant='ghost'
        color='resolveOrange'
        icon={<ArrowBackIcon w='4' h='4' />}
        onClick={prevClick}
        fontSize='20px'
      />
      <Flex alignItems='center' justifyContent='space-around'>
        <Text>Page </Text>
        <Flex flexBasis='35%'>
          <InputGroup size='md'>
            <NumberInput
              defaultValue={page}
              key={`${page}:${Math.random()}`}
              max={pageCount}
              min='1'
            >
              <NumberInputField ref={pageRef} />
            </NumberInput>
            <InputRightElement ml='1rem'>
              <IconButton
                variant='ghost'
                color='resolveOrange'
                icon={<Search2Icon w='4' h='4' />}
                onClick={handlePageSearch}
              />
            </InputRightElement>
          </InputGroup>
        </Flex>
        <Text>of</Text>
        <Text>{pageCount - 1}</Text>
      </Flex>
      <IconButton
        variant='ghost'
        color='resolveOrange'
        icon={<ArrowForwardIcon w='4' h='4' />}
        onClick={nextClick}
        fontSize='20px'
      />
      <IconButton
        variant='ghost'
        fontSize='20px'
        onClick={goToLastPage}
        icon={<ArrowRightIcon w='4' h='4' />}
        color='resolveOrange'
      />
    </Flex>
  )
}
