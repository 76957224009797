import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'

import { AuthenticatedNavigation } from './AuthenticatedNavigation'
import { BrandLogo } from './BrandLogo'

export const Navigation = () => {
  const location = useLocation()

  const backgroundColor = useMemo(() => {
    const { pathname } = location
    if (pathname.match(/internal/)) {
      return 'resolveBlue'
    }
    if (pathname.match(/templates/)) {
      return 'resolvePurple'
    }
    if (pathname.match(/subs/)) {
      return 'resolveGreen'
    }
    return 'resolveOrange'
  }, [location])

  return (
    <Flex
      borderBottom='5px'
      borderBottomColor={backgroundColor}
      borderBottomStyle='solid'
      padding='1rem'
      alignItems='center'
      id='navBar'
    >
      <BrandLogo ml='1rem' id='branding' />
      <AuthenticatedNavigation />
    </Flex>
  )
}
