import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import moment from "moment"
import { useMutation, useQueryClient } from "react-query"

const updateItem = async ({ form, itemId }) => {
  const nForm = {
    ...form,
    authorized_by_id: form.authorized_by_id?.value || null,
    supplier_id: form.supplier_id.value,
    invoiced: form.invoiced ? 1 : 0,
    quid_co: form.quid_co ? 1 : 0,
    payment_method_id: form.payment_method_id?.value || null,
    io_billing_code_id: form.io_billing_code_id?.value || null,
    date_ordered: moment(form.date_ordered).format('YYYY-MM-DD'),
    invoice_date: form.invoice_date
      ? moment(form.invoice_date).format('YYYY-MM-DD')
      : null
  }
  await axiosClient({
    url: `/io/item/${itemId}`,
    method: 'patch', 
    data: nForm
  })
}

export const useUpdateItem =  () => { 
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateItem, {
    onSuccess: () => {
      toastSuccess('Item updated successfully')
      queryClient.invalidateQueries(['io-items'])
      queryClient.invalidateQueries(['io-item'])
    },
    onError: () => {
        toastFail('Failed to update item')
    }
  })
}