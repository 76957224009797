import { useForm } from 'react-hook-form'
import {
  Text,
  FormControl,
  FormLabel,
  Flex,
  Button,
  Input
} from '@chakra-ui/react'
import { PopoverButton } from 'components/PopoverButton'
import { useUpdatePaymentMethod } from 'features/Settings/api/updatePaymentMethod'
import { useDeletePaymentMethod } from 'features/Settings/api/deletePaymentMethod'
import { useGetPaymentMethod } from 'features/Settings/api/getPaymentMethod'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { CheckboxController } from 'components/Form/CheckboxController'

const LabelInput = ({ register, name }) => {
  return (
    <FormControl mb='1rem'>
      <FormLabel color='resolveOrange'>Label</FormLabel>
      <Input {...register(name, { isRequired: true })} />
    </FormControl>
  )
}

const FormHeader = ({ name }) => {
  return (
    <Flex flexFlow='row' mt='2rem' mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        {name}
      </Text>
    </Flex>
  )
}

const PMState = {
  label: '',
  id: 0,
  hidden: false
}

export const EditPaymentMethodForm = ({
  paymentMethodId,
  selectRef,
}) => {
  const { control, register, reset, handleSubmit } = useForm({ defaultValues: PMState })
  const updatePaymentMethod = useUpdatePaymentMethod()
  const handleUpdate = async form => {
    updatePaymentMethod.mutate({ paymentMethod: form })
    selectRef.current.clearValue()
  }

  const deletePaymentMethod = useDeletePaymentMethod()
  const handleDelete = async () => {
    deletePaymentMethod.mutateAsync({ paymentMethodId })
    selectRef.current.clearValue()
  }

  const onSuccess = pm => {
    reset(pm)
  }
  const paymentMethod = useGetPaymentMethod({ paymentMethodId, onSuccess })

  if(paymentMethod.error) return <SomethingBroke message='Failed to get Payment Method' error={paymentMethod.error.message} />
  if(paymentMethod.isLoading || paymentMethod.isFetching) return <Loading />
  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <FormHeader name={paymentMethod.data.label} />
      <LabelInput name='label' register={register} />
      <CheckboxController control={control} name='hidden' text='Usable in Quotes' />
      <Flex>
        <Button type='submit' color='white' isLoading={updatePaymentMethod.isLoading} backgroundColor='resolveOrange'>
          Save
        </Button>
        <PopoverButton
          title='Delete'
          message='Are you sure you want to delete this payment method?'
          handleClick={handleDelete}
          buttonTitle='Delete'
          color='resolveOrange'
          isLoading={deletePaymentMethod.isLoading}
          ml='1rem'
          backgroundColor='white'
        />
      </Flex>
    </form>
  )
}
