import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"


const assignUsers = async ({ engineers, itemId }) => {
  await axiosClient({
    url: `/io/assign/${itemId}`,
    method: 'post',
    data: { engineers }
  })
}

export const useAssignUsers = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(assignUsers, {
    onSuccess: () => {
      toastSuccess('User(s) successfully assigned')
      queryClient.invalidateQueries('assigned-engineers-item')
    },
    onError: () => {
      toastFail('Failed to assign user')
    }
  })
}