import { FormControl, FormLabel } from '@chakra-ui/react'
import { ControlledSelect } from './ControlledSelect'
import { axiosClient } from 'lib/axiosClient'
import { useQuery } from 'react-query'

const getQuoteTypes = async (context) => {
  const { signal } = context
  const { data: quoteTypes } = await axiosClient({
    url: '/settings/quote-types',
    signal
  })
  return quoteTypes
}

const useGetQuoteTypes = () => {
  return useQuery(['form-quote-types'], {
    queryFn: getQuoteTypes,
    placeholderData: [],
    staleTime: Infinity
  })
}
export const QuoteTypeSelect = ({
  name,
  control,
  errors,
  required,
  selectedOptionColor = 'resolveOranger',
  ...restProps
}) => {

  const quoteTypes = useGetQuoteTypes()
  return (
    <FormControl
      isInvalid={!!errors[name]}
      {...restProps}
      isRequired={required}
    >
      <FormLabel>
        Number of Quote Options (e.g. 3 for 3 years)
      </FormLabel>
      <ControlledSelect
        name={name}
        rules={{ required }}
        control={control}
        options={quoteTypes.data}
        isLoading={quoteTypes.isLoading}
        selectedOptionColor={selectedOptionColor}
      />
    </FormControl>
  )
}
