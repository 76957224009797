import { Flex, Text } from '@chakra-ui/react'

export const Description = ({ description }) => (
  <Flex
    justifyContent='flex-start'
    ml='1rem'
    flexBasis='40%'
    minW='40%'
    maxW='40%'
    pointerEvents='none'
  >
    <Text
      overflow='hidden'
      whiteSpace='nowrap'
      maxWidth='90%'
      textOverflow='ellipsis'
      pointerEvents='none'
    >
      {description}
    </Text>
  </Flex>
)
