import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useCreatePaymentMethod } from 'features/Settings/api/createPaymentMethod'

const LabelInput = ({ register, name }) => {
  return (
    <FormControl mb='1rem'>
      <FormLabel color='resolveOrange'>Label</FormLabel>
      <Input {...register(name, { required: true })} />
    </FormControl>
  )
}

const FormHeader = ({ name }) => {
  return (
    <Flex flexFlow='row' mt='2rem' mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        Add Payment Method
      </Text>
    </Flex>
  )
}

const PMState = {
  label: ''
}

export const AddPaymentMethodForm = () => {
  const { register, handleSubmit } = useForm({ defaultValues: PMState })
  const createPaymentMethod = useCreatePaymentMethod()
  const onSubmit = form => {
    createPaymentMethod.mutate({ paymentMethod: form })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader />
      <LabelInput register={register} name='label' />
      <Button isLoading={createPaymentMethod.isLoading} color='white' bgColor='resolveOrange' type='submit'>
        Add
      </Button>
    </form>
  )
}
