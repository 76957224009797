import { RepeatIcon } from '@chakra-ui/icons'
import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Icon
} from '@chakra-ui/react'
import { useCallback } from 'react'

import { Controller, useWatch } from 'react-hook-form'

export const ItemUnitPriceInput = ({
  control,
  required,
  errors,
  name,
  isDisabled,
  setValue,
  ...props
}) => {
  const watchClientCost = useWatch({ control, name: 'client_cost', defaultValue: 0 })
  const watchMarkUp = useWatch({ control, name: 'mark_up_percentage', defaultValue: 0 })

  const recalcUnitPrice = useCallback(() => {
    const dMarkUp = (watchMarkUp / 100) + 1
    const newUnitPrice = watchClientCost / dMarkUp
    setValue('unit_price', newUnitPrice.toFixed(2))
  }, [watchClientCost, watchMarkUp, setValue])

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { ref, ...restField } }) => {
        return (
          <FormControl
            {...props}
            isDisabled={isDisabled}
            isRequired={required}
            isInvalid={!!errors[name]}
          >
            <FormLabel>Unit Price</FormLabel>
            <Flex>
              <NumberInput flexGrow={1} min={0} step={0.01} defaultValue={0.0} {...restField}>
                <NumberInputField flexGrow={1} ref={ref} name={restField.name} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {
                setValue && (
                  <IconButton
                    ml='1rem'
                    variant='ghost'
                    isDisabled={isDisabled}
                    fontSize='15px'
                    onClick={recalcUnitPrice}
                    icon={<Icon color='resolveOrange' as={RepeatIcon} />}
                    colorScheme='resolveOranger'
                    aria-label={''}
                  />
                )
              }
            </Flex>
          </FormControl>
        )
      }}
    />
  )
}
