import { Flex, Text, Badge, Stat, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/react'

const getQuoteItemBackgroundColor = status => {
  switch (status) {
    case 'Ordered': {
      return 'resolvePink'
    }
    case 'Received': {
      return 'resolveGreen'
    }
    default: {
      return ''
    }
  }
}

const Quantity = ({ quantity }) => (
  <Flex
    basis='10%'
    pointerEvents='none'
    justifyContent='center'
    alignItems='center'
    mr='1rem'
  >
    <Text pointerEvents='none'>{Math.round(Number(quantity), 1)} X</Text>
  </Flex>
)

const SupplierAndDescription = ({ supplier, description }) => (
  <Flex
    basis='55%'
    pointerEvents='none'
    justifyContent='center'
    direction='column'
  >
    <Text fontSize='1xl' pointerEvents='none'>
      {description}
    </Text>
    <Text fontSize='sm'>{supplier}</Text>
  </Flex>
)

const Status = ({ status }) => (
  <Flex
    basis='5%'
    alignItems='center'
    justifyContent='center'
    pointerEvents='none'
    ml='1rem'
    mr='1rem'
  >
    <Text
      fontWeight='bold'
      pointerEvents='none'
      color={getQuoteItemBackgroundColor(status)}
      textAlign='center'
    >
      {status}
    </Text>
  </Flex>
)

export const ListItem = ({ item, handleItemClick, handleItemBulkSelect }) => {
  return (
    <Flex id={item.item_id} key={item.item_id}>
      <Flex onClick={handleItemClick} width='100%'>
        <Flex
          _hover={{ boxShadow: 'rgba(0, 0, 0, .1) 0 0 0 1000000px inset' }}
          basis='100%'
          id={item.item_id}
          cursor='pointer'
          pt='1rem'
          pb='1rem'
        >
          <Quantity quantity={item.quantity} />

          <SupplierAndDescription
            supplier={item.supplier}
            description={item.item_name}
          />

          <Status status={item.item_status} />

          <Flex
            pointerEvents='none'
            flexBasis='20%'
            direction='column'
            mr='1rem'
          >
            <Stat>
              <StatHelpText textAlign='right'>
                {' '}
                <Badge
                  pointerEvents='none'
                  color='white'
                  bg='resolveBlue'
                  size='sm'
                >
                  {item.io_item_ref}
                </Badge>
              </StatHelpText>
              <StatLabel textAlign='right' fontSize='x-small'>
                Total (ex <b>VAT</b>)
              </StatLabel>
              <StatNumber textAlign='right' fontSize='sm'>{item.total}</StatNumber>
            </Stat>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
