import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { ControlledSelect } from '../../components/Form/ControlledSelect'
import { useGetClients } from './api/getClients'

export const ClientSelectInput = ({
  control,
  errors,
  name,
  showLabel = true,
  required,
  isClearable = false,
  selectedOptionColor = 'resolveOranger',
  placeholder = 'Client',
  ...restProps
}) => {

  const clients = useGetClients()

  return (
    <FormControl
      isInvalid={!!errors[name]}
      { ...restProps }
      isRequired={required}
    >
      {showLabel && <FormLabel>Client</FormLabel>}
      <ControlledSelect
        name={name}
        isLoading={clients.isLoading}
        control={control}
        options={clients.data}
        rules={{ required }}
        placeholder={placeholder}
        isClearable={isClearable}
        selectedOptionColor={selectedOptionColor}
      />
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  )
}
