import { axiosClient } from 'lib/axiosClient'
import { useQuery } from 'react-query'

const getSupplier = async (context) => {
  const { signal, queryKey } = context 
  const [_, supplierId] = queryKey
  const { data: supplier } = await axiosClient({
    url: `/settings/suppliers/${supplierId}`,
    signal
  })
  return supplier
}
export const useGetSupplier = ({ supplierId, onSuccess }) => {
  return useQuery(['settings-supplier', supplierId], {
    queryFn: getSupplier,
    enabled: !!supplierId,
    onSuccess
  })
}