import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

export const createQuoteFromTemplate = async ({ template, engineerId }) => {
  return await axiosClient({
    url: '/quotes/templates/add/template',
    method: 'post',
    data: {
      client_id: template.client_id.value,
      template_id: template.template_id.value,
      engineer_id: engineerId
    }
  })
}

export const useCreateQuoteFromTemplate = () => {
  const history = useNavigate()
  const queryClient = useQueryClient()
  const { toastFail, toastSuccess } = useResolveToast()

  return useMutation(createQuoteFromTemplate, {
    onSuccess: (data) => {
      history(`/quotes/${data.quoteId}`)
      toastSuccess('Quote copied successfully')
      queryClient.invalidateQueries('quotes')
    },
    onError: () => {
      toastFail('Failed to copy quotes')
    }
  })
}