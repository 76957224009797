import { Flex, Text } from '@chakra-ui/react'
import { Quantity } from './Quantity'
import { SupplierAndDescription } from './SupplierAndDescription'

export const ListItem = ({ item, handleItemClick }) => {
  return (
    <Flex
      _hover={{ boxShadow: 'rgba(0, 0, 0, .1) 0 0 0 1000000px inset' }}
      basis='100%'
      onClick={handleItemClick}
      id={item.id}
      cursor='pointer'
      pt='1rem'
      pb='1rem'
      justifyContent='space-around'
    >
      <Quantity quantity={item.quantity} />

      <SupplierAndDescription
        supplier={item.supplier}
        description={item.description}
        typeId={item.type_id}
      />

      <Flex pointerEvents='none' direction='column' mt='0.5rem'>
        <Text textAlign='right' pointerEvents='none' fontSize='x-small'>
          Total (ex <b>VAT</b>)
        </Text>
        <Text
          pointerEvents='none'
          fontSize='xs'
          fontWeight='bold'
          textAlign='right'
        >
          £ {item.client_cost}
        </Text>
      </Flex>
    </Flex>
  )
}
