import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getAssignedEngineers = async (itemId) => {
  const { data: { engineers } } = await axiosClient({
    url: `/io/assign/${itemId}`
  })
  return engineers
}

export const useGetAssigned = ({ itemId }) => {
  return useQuery(['assigned-engineers-item', itemId], () => getAssignedEngineers(itemId))
}
