import { Box, Divider, Center } from '@chakra-ui/react'

import { Layout } from '../../../components/Layout/Layout'

import { ListItem } from './ListItem'
import { Loading } from '../../../components/Loading'
import {
  useNavigate,
  useParams
} from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { SearchContext } from '../context/search.context'

export const QuoteList = () => {
  const history = useNavigate()
  const { quoteId } = useParams()

  const { results, searchState, isSearching } = useContext(SearchContext)

  const handleQuoteClick = e => {
    let targetQuoteId = ''

    if (e.target.id.length < 1) {
      targetQuoteId = e.target.parentElement.id
    } else {
      targetQuoteId = e.target.id
    }
    if (targetQuoteId !== quoteId) {
      history(`/search/${targetQuoteId}`)
    }
  }

  if (isSearching) {
    return <Loading />
  }

  if (results.length < 1) {
    return (
      <Layout>
        <Center>
          <div>No quotes found</div>
        </Center>
      </Layout>
    )
  }

  return (
    <Layout>
      {results.map((quote, i) => {
        return (
          <Box key={i}>
            <ListItem
              handleQuoteClick={handleQuoteClick}
              isSelected={`${quote.quote_id}` === `${quoteId}`}
              quote={quote}
            />
            <Divider />
          </Box>
        )
      })}
    </Layout>
  )
}
