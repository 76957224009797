import { Icon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Avatar, Button, Flex, IconButton, Spacer, Text } from '@chakra-ui/react'
import { BiRefresh } from 'react-icons/bi'

export const Header = ({ username, active, blob, toggleStatus, refreshUserPicture }) => {
  return (
    <Flex flexFlow='row' mt='2rem' mb='1rem' alignItems='center'>
      <Flex>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          {username}
        </Text>
        <Avatar name={username} src={blob} mr='1rem' />
        <IconButton onClick={refreshUserPicture} aria-label='Refresh user picture' fontSize='x-large' color='resolveOrange' alignSelf='center' icon={<BiRefresh />} />
      </Flex>
      <Spacer />
      <Flex alignItems='flex-start' onClick={toggleStatus}>
        {active
          ? (
            <Button
              variant='unstyled'
              leftIcon={<Icon as={ViewIcon} />}
              p='5px'
              color='white'
              bg='resolveGreen'
            >
              Active
            </Button>
            )
          : (
            <Button
              variant='unstyled'
              leftIcon={<Icon as={ViewOffIcon} />}
              p='5px'
              color='white'
              bg='resolveRed'
            >
              Inactive
            </Button>
            )}
      </Flex>
    </Flex>
  )
}
