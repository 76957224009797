import { useEffect, useState } from 'react'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { useGetUsers } from './api/getUsers'

export const ControlledUserSelect = ({ onChange, value, isClearable = false, ...props }) => {
  const users = useGetUsers()

  return (
    <FormControl { ...props }>
      <FormLabel>User</FormLabel>
      <Select
        options={users.data}
        isClearable={isClearable}
        selectedOptionColor='resolveOranger'
        onChange={onChange}
        value={value}
      />
    </FormControl>
  )
}
