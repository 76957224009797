import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

const createCost = async ({ cost }) => {
  const { data: res } = await axiosClient({
    url: '/autotask/costs',
    method: 'post',
    data: cost
  })
  return res
} 

export const useCreateCost = () => {
  const queryClient = useQueryClient()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(createCost, {
    onSuccess: () => {
      toastSuccess('Cost added successfully')
      queryClient.invalidateQueries(['ordered-ticket-costs'])
      queryClient.invalidateQueries(['item-billing-details'])
      queryClient.invalidateQueries(['item-status'])
      queryClient.invalidateQueries(['quote-item-charge'])
    },
    onError: () => {
      toastFail('Failed to add costs to ticket')
    }
  })
}