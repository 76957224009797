import { BillingCodeSelect } from 'components/Form/BillingCodeSelect';
import { Button } from "@chakra-ui/react";
import { ClientPOInput } from 'components/Form/ClientPOInput';
import { PaymentMethodSelectInput } from 'components/Form/PaymentMethodSelectInput';
import { SupplierOrderNumberSelectInput } from 'components/Form/SupplierOrderNumberSelectInput';
import { Loading } from 'components/Loading';
import { UserContext } from 'features/Auth/context/user.context';
import { useContext, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { StepperActions } from './StepperActions';
import { useGetItemBillingDetails } from './api/getItemBillingDetails';
import { useUpdateItemBillingDetails } from './api/updateItemBillingDetails';
import { StepContext } from './context/stepper.context';

export const BillingDetailsForm = () => {
    const { itemId } = useParams();
    const { engineer } = useContext(UserContext);

    const { nextStep } = useContext(StepContext)

    const orderForm = useFormContext();

    const updateItemBillingDetails = useUpdateItemBillingDetails();

    const itemBillingDetails = useGetItemBillingDetails({
        itemId,
        onSuccess: data => {
            orderForm.setValue('at_billing_code_id', data.at_billing_code_id);
            orderForm.setValue('autotask_number', data.autotask_number);
            orderForm.setValue('client_id', data.client_id);
            orderForm.setValue('date_ordered', data.date_ordered);
            orderForm.setValue('supplier_id', data.supplier_id);
            orderForm.setValue('item_ref', data.item_ref);
            orderForm.setValue('quote_id', data.quote_id);
            orderForm.setValue('at_cost_id', data.at_cost_id);
            orderForm.setValue('client_pon', data.client_pon);
            orderForm.setValue(
                'supplier_order_number',
                data.supplier_order_number
            );
            orderForm.setValue('payment_method_id', data.payment_method_id);
        },
    });

    const handleSave = useCallback(
        async form => {
            if(!orderForm.trigger()) {
                return
            }

            await updateItemBillingDetails.mutateAsync({
                itemId,
                form,
                engineerId: engineer.quotes.id,
            });

            orderForm.reset({}, { keepValues: true });
            nextStep()
        },
        [engineer, itemId, nextStep, orderForm, updateItemBillingDetails]
    );

    if (itemBillingDetails.isLoading) return <Loading />;

    return (
        <form onSubmit={orderForm.handleSubmit(handleSave)}>
            <BillingCodeSelect
                name="at_billing_code_id"
                control={orderForm.control}
                errors={orderForm.formState.errors}
                labelText="Billing Code"
                required
                mt="1rem"
            />
            <PaymentMethodSelectInput
                control={orderForm.control}
                name="payment_method_id"
                errors={orderForm.formState.errors}
                required={true}
                mt="1rem"
            />
            <ClientPOInput
                register={orderForm.register}
                name="client_pon"
                errors={orderForm.formState.errors}
                required={false}
                mt="1rem"
            />
            <SupplierOrderNumberSelectInput
                register={orderForm.register}
                name="supplier_order_number"
                errors={orderForm.formState.errors}
                required={false}
                mt="1rem"
            />

            <Button color="white" mt="1rem" colorScheme="resolveOranger" type="submit">Save</Button>

            <StepperActions />

        </form>
    );
};
