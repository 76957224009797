import { FormControl, FormLabel } from '@chakra-ui/react'
import { DatePicker } from 'components/DatePicker'

export const DateRange = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const onChange = dates => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }
  return (
    <FormControl>
      <FormLabel>Date Range</FormLabel>
      <DatePicker
        selected={startDate}
        isClearable
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
      />
    </FormControl>
  )
}
