import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { ControlledSelect } from 'components/Form/ControlledSelect'
import { useGetUsers } from './api/getUsers'

export const UserSelectInput = ({
  control,
  required = false,
  isClearable = false,
  isMulti = false,
  labelText = 'User',
  errors,
  name,
  showLabel = true,
  placeholder = 'User',
  selectedOptionColor = 'resolveOranger',
  validation = {},
  ...props
}) => {

  const users = useGetUsers()

  return (
    <FormControl
      isRequired={required}
      isInvalid={!!errors[name]}
      {...props}
    >
      {showLabel && <FormLabel>{labelText}</FormLabel>}
      <ControlledSelect
        isClearable={isClearable}
        name={name}
        control={control}
        isLoading={undefined}
        options={users.data}
        rules={validation}
        isMulti={isMulti}
        placeholder={placeholder}
        selectedOptionColor={selectedOptionColor}
      />
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  )
}
