import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation, useQueryClient } from "react-query";

const updateUserStatus = async ({ userId }) => {
  await axiosClient({
    url: `/settings/users/status/${userId}`,
    method: 'post'
  })
}

export const useUpdateUserStatus = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateUserStatus, {
    onSuccess: () => {
      toastSuccess('User status updated successfully')
      queryClient.invalidateQueries('settings-user')
    },
    onError: error => {
      toastFail('Failed to update user status')
    }
  })
}