import { Flex } from "@chakra-ui/react"
import { Header } from "./Header.js"
import { OrderStepper } from "./OrderStepper.js"
import { StepContextProvider } from "./context/stepper.context.js"

export const Ordered = () => {
    return (
        <StepContextProvider>    
            <Flex direction="column" width="100%">
                <Header />
                <OrderStepper /> 
            </Flex>  
        </StepContextProvider>
    )
}