import {
  FormControl,
  FormLabel,
  Input,
  Button
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { useCallback, useContext, useState } from 'react'
import { UserContext } from 'features/Auth/context/user.context'
import { useParams } from 'react-router-dom'
import { Header } from './Header'
import { DatePicker } from 'components/DatePicker'
import { useGetInvoice } from 'features/Internal/api/getInvoice'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Loading } from 'components/Loading'
import { useUpdateInvoice } from 'features/Internal/api/updateInvoice'

const DEFAULT_VALUES = {
  supplier_invoice_number: '',
  pink_number: '',
  invoice_date: moment()
}

export const Invoice = () => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: DEFAULT_VALUES
  })
  const { engineer } = useContext(UserContext)
  const { ioId, itemId } = useParams()

  const [date, setDate] = useState({ selectedDay: '' })

  const getInvoice = useGetInvoice({ itemId, onSuccess: invoice => {
    setDate({ selectedDay: invoice.invoice_date ? moment(invoice.invoice_date).toDate() : moment().toDate() })
    reset({ ...invoice })
  }})
  const updateInvoice = useUpdateInvoice({ioId, itemId})

  const onInvoiceSubmit = useCallback(form => {
    updateInvoice.mutate({ form: { ...form, invoiced_by: engineer.quotes.id }, itemId })
  }, [engineer, itemId, updateInvoice])

  const handleDayChange = date => {
    setDate({ selectedDay: date })
  }

  if(getInvoice.isFetching) return <Loading />
  if(getInvoice.error) return <SomethingBroke message='Failed to get invoice data' error={getInvoice.error.message} />
  return (
    <>
      <Header />
      <form onSubmit={handleSubmit(onInvoiceSubmit)}>
            <FormControl mt='1rem'>
              <FormLabel color='resolveBlue'>Supplier Invoice Number</FormLabel>
              <Input {...register('supplier_invoice_number')} />
            </FormControl>

            <FormControl mt='1rem'>
              <FormLabel color='resolveBlue'>Pink Number</FormLabel>
              <Input {...register('pink_number')} />
            </FormControl>

            <FormControl mt='1rem'>
              <FormLabel color='resolveBlue'>Invoice Date</FormLabel>
              <DatePicker
                selectedDate={date.selectedDay}
                onChange={handleDayChange}
                name='date_added'
              />
            </FormControl>

            <Button
              color='white'
              backgroundColor='resolveBlue'
              mt='1rem'
              mb='1rem'
              type='submit'
            >
              Submit
            </Button>
          </form>
    </>
  )
}
