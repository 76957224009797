import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { CheckboxController } from 'components/Form/CheckboxController'

const initialPermissionState = {
  canAccessQuotes: false,
  canAddQuotes: false,
  canDeleteQuotes: false,
  canInvoice: false,
  canApproveAndPost: false,
  canAssignQuotes: false,
  canAccessSubs: false,
  canDeleteSubs: false,
  canUpdateSettings: false,
  canViewInternal: false,
  canAccessReports: false,
  isAdmin: false
}

export const PermissionsModal = ({
  isOpen,
  onClose,
  handlePermissionsSubmit
}) => {
  const { handleSubmit, control } = useForm({
    defaultValues: initialPermissionState
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color='resolveOrange'>User Permissions</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(handlePermissionsSubmit)}>
            <Text mb='1rem' color='resolveOrange'>Admin</Text>
            <VStack align='start'>
              <CheckboxController control={control} name='isAdmin' text='Admin Access' />
              <CheckboxController control={control} name='canInvoice' text='Invoicing' />
              <CheckboxController control={control} name='canApproveAndPost' text='Approve & Post' />
              <CheckboxController control={control} name='canAccessReports' text='Reports' />
            </VStack>

            <Text mb='1rem' mt='1rem' color='resolveOrange'>Quotes</Text>
            <VStack align='start'>
              <CheckboxController control={control} name='canAccessQuotes' text='View Quotes' />
              <CheckboxController control={control} name='canAddQuotes' text='Add Quotes' />
              <CheckboxController control={control} name='canDeleteQuotes' text='Delete Quotes' />
            </VStack>

            <Text mt='1rem' mb='1rem' color='resolveOrange'>Subs</Text>
            <VStack align='start'>
              <CheckboxController control={control} name='canAccessSubs' text='Access Subscriptions' />
              <CheckboxController control={control} name='canDeleteSubs' text='Delete Subscriptions' />
            </VStack>

            <Text mt='1rem' mb='1rem' color='resolveOrange'>Internal</Text>
            <VStack align='start'>
              <CheckboxController control={control} name='canViewInternal' text='View Internal' />
              <CheckboxController control={control} name='canDeleteInternalOrders' text='Delete Internal Orders' />
              <CheckboxController control={control} name='canAssignQuotes' text='Re-assign Quotes' />
            </VStack>
            <Button
              color='white'
              backgroundColor='resolveOrange'
              mt='2rem'
              mb='1rem'
              type='submit'
            >
              Submit
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
