import { FormControl, Input, FormLabel } from '@chakra-ui/react'

export const ItemPartCodeInput = ({
  register,
  errors,
  name,
  required = false,
  showLabel = true,
  ...props
}) => {
  return (
    <FormControl { ...props } isInvalid={!!errors[name]}>
      {
        showLabel && (<FormLabel>Part Code</FormLabel>)
      }
      <Input {...register(name, { required })} id='partcode' />
    </FormControl>
  )
}
