import React, { useState } from 'react'

import { IOList } from '.'
import { SearchFilter } from './SearchFilter'
import { Paginator } from 'components/Paginator'
import { useParams } from 'react-router'
import { useIOFilter } from 'hooks/useIOFilter'
import { useInternalOrders } from 'features/Internal/api/getInternalOrders'

export const Container = () => {
  const { ioId } = useParams()
  const { filter, setFilter, filterString } = useIOFilter()
  const [page, setPage] = useState(1)

  const internalOrdersQuery = useInternalOrders({ page, filterString })

  return (
    <>
      <SearchFilter setPage={setPage} setFilter={setFilter} filter={filter} />
      <IOList ios={internalOrdersQuery.data?.IOs} isFetching={internalOrdersQuery.isFetching} isLoading={internalOrdersQuery.isLoading} isError={internalOrdersQuery.isError} ioId={ioId} />
      <Paginator componentColor='resolveBlue' resultCount={internalOrdersQuery.data?.resultCount || 0 } page={page} setPage={setPage} />
    </>
  )
}
