import { Route, Routes as Switch } from 'react-router'
import { Divider, Flex } from '@chakra-ui/react'
import { LeftAside } from 'components/Layout/LeftAside'
import { RightAside } from 'components/Layout/RightAside'
import { SubscriptionNavigation } from '../Navigation/SubscriptionNavigation'
import { Container as SubListContainer } from './components/Sub/List/Container'
import { Container as ItemListContainer } from './components/Item/List/Container'
import { EditSub } from './components/Sub/Edit'
import { AddSub } from './components/Sub/Add'

const Subs = () => {
  return (
    <Flex justifyContent='center' height='100%'>
      <LeftAside>
        <Switch>
          <Route path=':subId/edit' element={<EditSub />} />
          <Route path='add' element={<AddSub />} />
          <Route path=':subId?' element={<SubListContainer />} />
        </Switch>
      </LeftAside>
      <Divider
        height='100vh'
        variant='dashed'
        orientation='vertical'
        size='1px'
      />
      <RightAside>
        <Switch>
          {
            ['/', 'add'].map((path, i) => (
              <Route
                exact
                path={path}
                key={i}
                element={<SubscriptionNavigation />}
              />
            ))
          }
          {
            [':subId/edit', ':subId'].map((path, i) => (
              <Route
                path={path}
                key={i}
                element={<ItemListContainer />}
              />
            ))
          }
        </Switch>
      </RightAside>
    </Flex>
  )
}

export default Subs
