import { Button, Flex, FormControl, FormLabel, Heading, Input, Spacer } from '@chakra-ui/react'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ClientSelectInput } from 'components/Form/ClientSelectInput'
import { ItemClientCostInput } from 'components/Form/ItemClientCostInput'
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput'
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput'
import { SupplierOrderNumberSelectInput } from 'components/Form/SupplierOrderNumberSelectInput'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { UserSelectInput } from 'components/Form/UserSelectInput'
import { useNavigate } from 'react-router'
import { QuoteReferenceInput } from 'components/Form/QuoteReferenceInput'
import { DateSelect } from 'components/Form/DateSelect'
import { SearchContext } from 'features/Search/context/search.context'

const DEFAULT_STATE = {
  quote_ref: '',
  autotask_number: '',
  client_id: '',
  engineer_id: '',
  supplier_id: '',
  supplier_order_number: '',
  part_code: '',
  description: '',
  net_amount: '' 
}

export const AdvancedSearch = () => {
  const [date, setDate] = useState({ selectedDay: undefined })
  const { handleSubmit, control, register, setValue, reset, formState: { errors } } = useForm({
    defaultValues: DEFAULT_STATE
  })

  const history = useNavigate()
  const { searchState, setPage } = useContext(SearchContext)

  const handleSearch = form => {
    let d
    if (date.selectedDay) {
      d = new Date(date.selectedDay)
      d.setHours(2)
    }
    const nForm = {
      ...form,
      date_added: date.selectedDay ? moment(d).format('YYYY-MM-DD') : undefined,
      search: 'advanced' 
    } 
    setPage(1)
    history('/search', { state: nForm })
  }

  const handleDayChange = dSelected => {
    setDate({
      selectedDay: dSelected
    })
  }

  const resetForm = () => {
    setDate({ selectedDay: null })
    reset(DEFAULT_STATE)
  }

  useEffect(() => {
    if(searchState) {
      if(searchState.search !== 'advanced') {
        reset(DEFAULT_STATE)
      } else {
        reset(searchState)
      }
      if(searchState.date_added) {
        handleDayChange(new Date(searchState.date_added))
      }
    }
  }, [searchState, reset])

  return (
    <form onSubmit={handleSubmit(handleSearch)}>
      <Flex>
        <Heading color='resolveOrange' mb='1rem'>Advanced Search</Heading>
        <Spacer />
        <Button mt='0.5rem' color='resolveOrange' onClick={resetForm}>Reset</Button>
      </Flex>
      <Heading mb='1rem' fontSize='lg' fontWeight='semibold' color='resolveOrange'>Quote Details</Heading>
      <Flex flexDir='row'>
        <QuoteReferenceInput
          name='quote_ref'
          mr='1rem'
          register={register}
        />
        <DateSelect
          required={false}
          date={date}
          name='date_added'
          handleDayChange={handleDayChange}
        />
      </Flex>

      <Flex>
        <ClientSelectInput
          control={control}
          required={false}
          name='client_id'
          errors={errors}
          mr='1rem'
          isClearable
        />        
        <UserSelectInput
          required={false}
          control={control}
          validation={true}
          name='engineer_id'
          errors={errors}
          mb='1rem'
          filter={item => true}
        />
      </Flex>  

      <Heading fontSize='lg' mt='1rem' mb='1rem' fontWeight='semibold' color='resolveOrange'>Item Details</Heading>

      <ItemClientCostInput
        control={control}
        name='net_amount'
        required={false}
        setValue={null}
        errors={errors}
        mb='1rem'
        labelText='Net Amount'
      />
      
      <Flex>
        <SupplierSelectInput
          control={control}
          register={null}
          required={false}
          ignoreOtherSupplier={false}
          name='supplier_id'
          isClearable
          errors={errors}
          mb='1rem'
          mr='1rem'
        />

        <SupplierOrderNumberSelectInput
          required={false}
          register={register}
          name='supplier_order_number'
          flexBasis='66%'
          errors={errors}
        />
      </Flex>

      <Flex>
        <ItemPartCodeInput
          register={register}
          name='part_code'
          flexBasis='50%'
          mr='1rem'
          errors={errors}
        />
        <FormControl mb='1rem'>
          <FormLabel>Autotask Number</FormLabel>
          <Input {...register('autotask_number')} />
        </FormControl>
      </Flex>

      <ItemDescriptionInput
        register={register}
        rules={true}
        name='description'
        required={false}
        errors={errors}
      />

      <Button mt='1rem' bgColor='resolveOrange' color='white' type='submit'>Search</Button>
    </form>
  )
}
