import { Link as ReactLink } from 'react-router-dom'
import { Flex, Heading, VStack, Link } from '@chakra-ui/react'

export const NoSelectedTemplate = () => {
  return (
    <Flex h='100vh' flexDir='column'>
      <Heading mb='1rem' color='resolvePurple'>
        Templates
      </Heading>
      <VStack alignItems='flex-start'>
        <Link
          as={ReactLink}
          _hover={{ color: 'resolvePurple' }}
          to='/templates/add'
        >
          New Template
        </Link>
      </VStack>
    </Flex>
  )
}
