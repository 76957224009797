import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation } from "react-query"
import { useNavigate } from "react-router"

const updateInvoice = async ({form, itemId}) => {
  await axiosClient({ 
    url: `/io/invoice/${itemId}`,
    method: 'post',
    data: form
  })
}

export const useUpdateInvoice = ({ itemId, ioId }) => {
  const { toastFail, toastSuccess } = useResolveToast()
  const history = useNavigate()
  return useMutation(updateInvoice, {
    onSuccess: () => {
      toastSuccess('Item invoiced successfully')
      history(`/internal/${ioId}/edit/${itemId}`)
    },
    onError: () => {
      toastFail('Failed to invoice item')
    }
  })
}