import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation } from "react-query";

const deleteSubType = async ({ subTypeId }) => {
  await axiosClient({
    url: `/settings/sub-types/${subTypeId}`,
    method: 'delete'
  })
}

export const useDeleteSubType = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(deleteSubType, {
    onSuccess: () => {
      toastSuccess('Sub type deleted successfully')
    },
    onError: () => {
      toastFail('Failed to delete sub type')
    }
  })
}