import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getTemplateItem = async (context) => {
  const { signal, queryKey } = context
  const [_key, itemId] = queryKey
  const { data: item } = await axiosClient({
    url: `/quotes/templates/item/${itemId}`,
    signal
  })
  return item
}

export const useGetTemplateItem = ({ itemId, onSuccess }) => {
  return useQuery(['template-item', itemId], {
    queryFn: getTemplateItem,
    onSuccess
  })
}
