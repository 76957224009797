import { useParams } from 'react-router'
import { Paginator } from 'components/Paginator'
import { QuoteTemplateList } from './index'
import { useState } from 'react'
import { useGetTemplates } from 'features/Templates/api/getTemplates'

export const Container = () => {
  const { templateId } = useParams()
  const [page, setPage] = useState(1)
  const templates = useGetTemplates({ page }) 

  return (
    <>
      <QuoteTemplateList
        templates={templates.data.templates}
        isFetching={templates.isFetching}
        isLoading={templates.isLoading}
        isError={templates.error}
        templateId={templateId}
      />
      <Paginator
        resultCount={0}
        page={page}
        componentColor='resolvePurple'
        setPage={setPage}
      />
    </>
  )
}
