import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react'

import { Controller } from 'react-hook-form'

export const ItemDeliveryPriceInput = ({
  control,
  required,
  name,
  errors,
  labelText = 'Delivery Price',
  ...restProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { ref, ...restField } }) => {
        return (
          <FormControl
            isInvalid={!!errors[name]}
            isRequired={required}
            {...restProps}
          >
            <FormLabel>{labelText}</FormLabel>
            <NumberInput min={0} step={0.01} defaultValue={0.0} {...restField}>
              <NumberInputField ref={ref} name={restField.name} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        )
      }}
    />
  )
}
