import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useContext
} from 'react'
import {
  Flex,
  IconButton,
  Text,
  InputGroup,
  InputRightElement,
  NumberInputField,
  NumberInput
} from '@chakra-ui/react'
import { ArrowRightIcon, ArrowLeftIcon, Search2Icon } from '@chakra-ui/icons'
import { SearchContext } from '../../context/search.context'

export const Paginator = () => {
  const inputRef = useRef()
  const { setPage, page, resultCount, pageSize } = useContext(SearchContext)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    const pageCount = Math.ceil(resultCount / pageSize)
    setTotalPages(pageCount)
  }, [resultCount, pageSize])

  const handlePageNextClick = e => {
    if (page >= totalPages) {
      return
    }
    setPage(Number(page) + 1)
  }

  const handlePageSearch = () => {
    setPage(inputRef.current?.value)
  }

  const handlePagePreviousClick = e => {
    if (page !== 1) {
      const newPage = page - 1
      setPage(newPage)
    }
  }

  useEffect(() => {
    function setCurrentPageOnInput () {
      if (inputRef.current) {
        inputRef.current.value = page
      }
    }
    setCurrentPageOnInput()
  }, [page])

  return (
    <Flex justifyContent='center' alignItems='center' mt='2rem'>
      <IconButton
        variant='ghost'
        color='resolveOrange'
        icon={<ArrowLeftIcon w='4' h='4' />}
        onClick={handlePagePreviousClick}
        fontSize='20px'
      />
      <Flex basis='50%' alignItems='center' justifyContent='space-around'>
        <Text>Page </Text>
        <Flex basis='25%'>
          <PaginatorPageInput
            totalPages={totalPages}
            currentPage={page}
            onPageSearch={handlePageSearch}
            ref={inputRef}
          />
        </Flex>
        <Text>of</Text>
        <Text>{totalPages}</Text>
      </Flex>
      <IconButton
        variant='ghost'
        fontSize='20px'
        onClick={handlePageNextClick}
        icon={<ArrowRightIcon w='4' h='4' />}
        color='resolveOrange'
      />
    </Flex>
  )
}

const PaginatorPageInput = forwardRef(
  ({ onPageSearch, currentPage, totalPages }, ref) => {
    return (
      <InputGroup size='md'>
        <NumberInput
          defaultValue={currentPage}
          key={`${currentPage}:${Math.random()}`}
          max={totalPages}
          min={1}
        >
          <NumberInputField ref={ref} />
        </NumberInput>
        <InputRightElement ml='1rem'>
          <IconButton
            variant='ghost'
            color='resolveOrange'
            icon={<Search2Icon w='4' h='4' />}
            onClick={onPageSearch}
          />
        </InputRightElement>
      </InputGroup>
    )
  }
)
