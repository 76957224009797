import moment from "moment";
import { useEffect, useState } from "react";

export const useFilter = ({ status, startDate, endDate}) => {
  const [ filterString, setFilterString ] = useState('')
  useEffect(() => {
    let tmpFilterString = ''
    if (status && status !== undefined) {
      tmpFilterString += `status=${status}&`
    }

    if (startDate) {
      const formattedStartDate = new Date(startDate)
      // adding two hours to ensure the correct day is selected (utc goes over)
      formattedStartDate.setHours(2)
      const startDateMySQL = moment(formattedStartDate).format('YYYY-MM-DD')
      tmpFilterString += `startDate=${startDateMySQL}`
    }

    if (endDate) {
      const formattedEndDate = new Date(endDate)
      // adding two hours to ensure the correct day is selected (utc goes over)
      formattedEndDate.setHours(2)
      const endDateMySQL = moment(formattedEndDate).format('YYYY-MM-DD')
      tmpFilterString += `&endDate=${endDateMySQL}`
    }
    setFilterString(tmpFilterString)
  }, [status, startDate, endDate])

  return { filterString }
}