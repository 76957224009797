import { useForm } from 'react-hook-form'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import {
  Text,
  FormControl,
  FormLabel,
  Flex,
  Button,
  Input
} from '@chakra-ui/react'
import { useCreateSupplier } from 'features/Settings/api/createSupplier'
import { BillingCodeSelect } from 'components/Form/BillingCodeSelect'

const NameInput = ({ register, name, ...props }) => {
  return (
    <FormControl {...props}>
      <FormLabel>Name</FormLabel>
      <Input {...register(name)} />
    </FormControl>
  )
}

const FormHeader = ({ name }) => (
  <Flex flexFlow='row' mt='2rem' mb='1rem'>
    <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
      {name}
    </Text>
  </Flex>
)

const SupplierState = {
  name: '',
  default_delivery: 0,
  active: 1,
  default_billing_code: 0
}

export const AddSupplierForm = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: SupplierState })

  const createSupplier = useCreateSupplier()
  const onSubmit = async form => {
    createSupplier.mutate({ form })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader name='Add Supplier' />
      <NameInput mb='1rem' register={register} name='name' />
      <ItemDeliveryPriceInput
        control={control}
        name='default_delivery'
        errors={errors}
        labelText='Default Postage' 
        required={undefined}
      />
      <BillingCodeSelect
        control={control}
        name='default_billing_code'
        mt='1rem'
        mb='1rem'
        errors={errors}
        labelText='Default Billing Code'
        required
      />
      <Button
        type='submit'
        mt='1rem'
        color='white'
        backgroundColor='resolveOrange'
      >
        Save
      </Button>
    </form>
  )
}
