import { Button, Flex, FormControl, FormLabel, Input, Spacer } from '@chakra-ui/react'

export const Form = ({ handleSubmit, handleUpdate, register, testAutotaskAPI }) => {
  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <FormControl mt='2rem'>
        <FormLabel color='resolveOrange'>Username</FormLabel>
        <Input type='email' {...register('at_username')} />
      </FormControl>
      <Flex>
        <FormControl mt='1rem' mr='1rem'>
          <FormLabel color='resolveOrange'>Secret</FormLabel>
          <Input type='password' {...register('at_secret')} />
        </FormControl>
        <FormControl mt='1rem'>
          <FormLabel color='resolveOrange'>Integration Code</FormLabel>
          <Input {...register('at_integration_code')} />
        </FormControl>
      </Flex>
      <FormControl mt='1rem'>
        <FormLabel color='resolveOrange'>Default Queue ID</FormLabel>
        <Input {...register('at_default_queue_id')} />
      </FormControl>
      <FormControl mt='1rem'>
        <FormLabel color='resolveOrange'>Assigned Resource Role ID</FormLabel>
        <Input {...register('at_default_role_id')} />
      </FormControl>
      <Flex mt='2rem'>
        <Button type='submit' backgroundColor='resolveOrange' color='white'>
          Save
        </Button>
        <Spacer />
        <Button
          backgroundColor='resolveOrange'
          onClick={() => testAutotaskAPI.refetch()}
          isLoading={testAutotaskAPI.isFetching}
          color='white'
        >
          Test
        </Button>
      </Flex>
    </form>
  )
}
