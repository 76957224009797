import { Flex, Text } from '@chakra-ui/react'
import { CompanyName } from './CompanyName'
import { DateAdded } from './DateAdded'
import { Engineer } from './Engineer'

export const ListItem = ({ template, isSelected, handleTemplateClick }) => {
  return (
    <Flex
      onClick={handleTemplateClick}
      id={template.id}
      style={isSelected ? { backgroundColor: '#9E1A96', color: 'white' } : null}
      height='4rem'
      alignItems='center'
      _hover={{
        boxShadow: 'rgba(0, 0, 0, .1) 0 0 0 1000000px inset',
        cursor: 'pointer'
      }}
    >
      <Flex justifyContent='center' ml='1rem' flexBasis='10%'>
        <>
          <Text
            pointerEvents='none'
            fontWeight='bold'
            color={isSelected ? 'white' : 'resolvePurple'}
            mr='0.2rem'
          >
            T
          </Text>
          <Text pointerEvents='none' fontWeight='bold'>
            {template.id}
          </Text>
        </>
      </Flex>
      <Flex justifyContent='flex-start' ml='1rem' width='60%'>
        <CompanyName company={template.description} />
      </Flex>
      <Flex justifyContent='flex-start' grow={1}>
        <DateAdded date={template.date_added} />
      </Flex>
      <Flex justifyContent='flex-start' ml='1rem' mr='2rem'>
        <Engineer
          engineer={template.ad_username}
          blob={template.picture_blob}
        />
      </Flex>
    </Flex>
  )
}
