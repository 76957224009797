import { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  Flex,
  Button,
  Input
} from '@chakra-ui/react'
import { SubscriptionStatus } from './SubscriptionStatus'
import { Header } from './Header'
import { ClientSelectInput } from 'components/Form/ClientSelectInput'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { SubscriptionTypeSelect } from 'components/Form/SubscriptionTypeSelect'
import { DatePicker } from 'components/DatePicker'
import { UserSelectInput } from 'components/Form/UserSelectInput'
import { Loading } from 'components/Loading'
import { QuoteTypeSelect } from 'components/Form/QuoteTypeSelect'
import { useGetSubItem } from 'features/Subs/api/getSubItem'
import { useUpdateSubItem } from 'features/Subs/api/updateSubItem'
import { useUpdateSubStatus } from 'features/Subs/api/updateSubStatus'
import SomethingBroke from 'components/Errors/SomethingBroke'

const initialState = {
  client_id: null,
  subscription_type: '',
  supplier_id: 0,
  quote_type_id: 0,
  name: '',
  active: 1,
  num_licenses: 0,
  serial_license: '',
  renewalDate: ''
}

export const EditSub = () => {
  const { subId } = useParams()

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: initialState
  })
  const [date, setDate] = useState({
    selectedDay: undefined
  })

  const handleDayChange = date => {
    setDate({ selectedDay: date })
  }

  const sub = useGetSubItem({ subId, onSuccess: data => {
    setDate({ selectedDay: new Date(data.date_first_renewal) })
    reset(data)
  }})
  const updateSubItem = useUpdateSubItem({subId})
  const updateSubStatus = useUpdateSubStatus({subId})

  const handleFormSubmit = useCallback(form => {
    updateSubItem.mutate({ subId, form, date })
  }, [date, subId, updateSubItem])

  const handleActiveClick = () => {
    updateSubStatus.mutate({subId})
  }

  const watchActive = watch('active')

  if(sub.isLoading || sub.isFetching) return <Loading />
  if(sub.error) return <SomethingBroke message='Failed to get Sub' error={sub.error.message} />

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Header subId={subId} />

      <UserSelectInput
        control={control}
        name='engineer_id'
        selectedOptionColor='resolveGreener'
        errors={errors}
        mb='1rem'
      />

      <Flex>
        <SubscriptionTypeSelect
          control={control}
          errors={errors}
          name='subscriptiontype_id'
          selectedOptionColor='resolveGreener'
        />

        <QuoteTypeSelect
          control={control}
          errors={errors}
          name='quote_type_id'
          selectedOptionColor='resolveGreener'
          ml='1rem'
        />
      </Flex>

      <ClientSelectInput control={control} name='client_id' selectedOptionColor='resolveGreener' errors={errors} />

      <SupplierSelectInput
        control={control}
        register={register}
        errors={errors}
        ignoreOtherSupplier
        selectedOptionColor='resolveGreener'
        name='supplier_id'
      />

      <FormControl isRequired mt='1rem'>
        <FormLabel>Subscription Name</FormLabel>
        <Input {...register('name', { required: true })} />
      </FormControl>

      <Flex dir='row' mt='1rem'>
        <FormControl mr='1rem'>
          <FormLabel>Number of Licenses</FormLabel>
          <Input {...register('num_licenses')} />
        </FormControl>

        <FormControl>
          <FormLabel>Serial / License Number</FormLabel>
          <Input {...register('serial_license')} />
        </FormControl>
      </Flex>

      <Flex dir='row' mt='1rem'>
        <FormControl isRequired mr='1rem'>
          <FormLabel>Renewal Date</FormLabel>
          <DatePicker
            selectedDate={date.selectedDay}
            onChange={handleDayChange}
          />
        </FormControl>
        <SubscriptionStatus
          handleActiveClick={handleActiveClick}
          active={watchActive}
        />
      </Flex>

      <Button
        variant='solid'
        backgroundColor='resolveGreen'
        color='white'
        _hover={{ bg: 'gray', color: 'resolveGreen' }}
        type='submit'
        mt='1rem'
        isLoading={isSubmitting}
      >
        Save
      </Button>
    </form>
  )
}
