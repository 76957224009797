import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getQuoteItem = async (context) => {
  const { signal, queryKey } = context
  const [_, itemId] = queryKey
  const { data: item } = await axiosClient({
    url: `/quotes/item/${itemId}`,
    signal
  })
  return {
    ...item,
    previous_item_status_id: item.item_status_id,
    checked_out: item.checked_out === 1,
    won: item.won === 1,
    invoiced: item.invoiced === 1,
    invoice_date: item.invoiced === 1 ? item.invoice_date : undefined,
    approved_posted: item.approved_posted === 1
  }
}

export const useGetQuoteItem = ({itemId, onSuccess}) => {
  return useQuery(['quote-item', itemId], {
    queryFn: getQuoteItem,
    onSuccess
  })
}