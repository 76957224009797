import { useCallback, useContext } from 'react';
import { StepContext } from './context/stepper.context';
import { useNavigate, useParams } from 'react-router';
import {
    IoMdRefresh,
    IoMdArrowDropright,
    IoMdArrowDropleft,
} from 'react-icons/io';
import {
    ButtonGroup,
    IconButton,
    Button,
    PopoverTrigger,
    PopoverCloseButton,
    useDisclosure,
    Popover,
    PopoverContent,
    PopoverFooter,
    PopoverBody,
    PopoverHeader,
} from '@chakra-ui/react';
import { steps } from './OrderStepper';
import { useFormContext } from 'react-hook-form';

export const StepperActions = () => {
    const { quoteId, itemId } = useParams();
    const { nextStep, prevStep, reset, activeStep } = useContext(StepContext);

    const { isOpen, onToggle, onClose } = useDisclosure();

    const orderForm = useFormContext();
    const isDirty = orderForm.formState.isDirty;

    const navigate = useNavigate();
    const navigateItem = () => {
        navigate(`/quotes/${quoteId}/edit/${itemId}`);
    };

    const handleBack = () => {
        prevStep();
    };

    const handleNext = useCallback(async () => {
        if (!(await orderForm.trigger())) {
            return;
        }

        if (isDirty) {
            console.log("You haven't saved your changes!!");
            console.log(orderForm.formState.dirtyFields);
            onToggle();
            return;
        }

        nextStep();
    }, [isDirty, nextStep, onToggle, orderForm]);

    const handleDiscard = useCallback(() => {
        onToggle();
        nextStep();
        orderForm.reset({}, { keepValues: true });
    }, [nextStep, onToggle, orderForm]);

    return (
        <ButtonGroup width="100%" mt="2rem" justifyContent="space-between">
            <ButtonGroup>
                <IconButton
                    colorScheme="resolveOranger"
                    icon={<IoMdRefresh />}
                    onClick={reset}
                    aria-label={''}
                    color="white"
                />
            </ButtonGroup>
            <ButtonGroup>
                <IconButton
                    colorScheme="resolveOranger"
                    color="white"
                    isDisabled={activeStep === 0}
                    icon={<IoMdArrowDropleft />}
                    onClick={handleBack}
                    aria-label={''}
                >
                    Prev
                </IconButton>
                {activeStep === steps.length ? (
                    <>
                        <Button
                            onClick={navigateItem}
                            color="white"
                            colorScheme="resolveOranger"
                        >
                            Back to Item
                        </Button>
                    </>
                ) : (
                    <>
                        <Popover
                            returnFocusOnClose={false}
                            isOpen={isOpen}
                            onClose={onClose}
                            placement="right"
                            closeOnBlur={false}
                        >
                            <PopoverTrigger>
                                <IconButton
                                    colorScheme="resolveOranger"
                                    onClick={handleNext}
                                    color="white"
                                    isDisabled={activeStep === 4}
                                    icon={<IoMdArrowDropright />}
                                    aria-label={''}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverCloseButton />
                                <PopoverHeader fontWeight="bold">Unsaved changes</PopoverHeader>
                                <PopoverBody>
                                    You haven't saved your changes, are you sure
                                    you want to continue?
                                </PopoverBody>
                                <PopoverFooter
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <ButtonGroup size="sm">
                                        <Button
                                            variant="outline"
                                            colorScheme="red"
                                            onClick={handleDiscard}
                                        >
                                            Discard Changes
                                        </Button>
                                        <Button
                                            colorScheme="resolveGreener"
                                            type="submit"
                                        >
                                            Save & Continue
                                        </Button>
                                    </ButtonGroup>
                                </PopoverFooter>
                            </PopoverContent>
                        </Popover>
                    </>
                )}
            </ButtonGroup>
        </ButtonGroup>
    );
};
