import { useContext } from 'react'
import { Flex, Button } from '@chakra-ui/react'

import { UserContext } from 'features/Auth/context/user.context'

import { PopoverButton } from 'components/PopoverButton'

export const Actions = ({ handleSubNotesUpdate, handleDeleteItem, isDeletingSub }) => {
  const { engineer } = useContext(UserContext)
  return (
    <Flex
      flexWrap='wrap'
      ml='1rem'
      mt='1rem'
      mb='1rem'
      justifyContent='flex-end'
    >
      <Button
        backgroundColor='resolveGreen'
        onClick={handleSubNotesUpdate}
        size='sm'
        mb='1rem'
        color='white'
        ml='1rem'
      >
        Save Notes
      </Button>
      {(
        engineer.permissions.canDeleteSubs ||
        engineer.permissions.isAdmin
      ) && (
        <PopoverButton
          buttonTitle='Delete'
          title='Delete Sub'
          message='Are you sure? This will delete the sub'
          handleClick={handleDeleteItem}
          isLoading={isDeletingSub}
          size='sm'
          ml='1rem'
          variant='outline'
        />
      )}
    </Flex>
  )
}
