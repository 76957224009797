import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation } from "react-query"

const updateAutotaskSettings = async ({ form }) => {
  return await axiosClient({
    url: '',
    method: 'post',
    data: form
  })
}

export const useUpdateAutotaskSettings = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(updateAutotaskSettings, {
    onSuccess: () => {
      toastSuccess('Settings updated successfully')
    },
    onError: () => {
      toastFail('Failed to update Autotask settings')
    }
  })
}