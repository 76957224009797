import { useForm } from 'react-hook-form'
import { Spacer, Flex, Button, useDisclosure, NumberInput } from '@chakra-ui/react'
import { PopoverButton } from 'components/PopoverButton'
import { PermissionsModal } from './PermissionsModal'
import { TeamSelectInput } from '../Form/TeamSelectInput'
import { UsernameInput } from '../Form/UsernameInput'
import { EmailInput } from '../Form/EmailInput'
import { AutotaskIdInput } from '../Form/AutotaskIdInput'
import { Header } from './Header'
import { CheckboxController } from 'components/Form/CheckboxController'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useDeleteUser } from 'features/Settings/api/deleteUser'
import { useUpdateUser } from 'features/Settings/api/updateUser'
import { useUpdateProfilePicture } from 'features/Settings/api/updateProfilePicture'
import { useGetUser } from 'features/Settings/api/getUser'
import { useUpdateUserStatus } from 'features/Settings/api/updateUserStatus'
import { SalesTargetInput } from 'components/Form/SalesTargetInput'

const initialUserState = {
  ad_username: '',
  picture_blob: '',
  active: 0,
  team_id: 0,
  email: '',
  admin_access: false,
  autotask_id: '',
  leader: false,
  sales_target: null
}

export const EditUserForm = ({
  userId,
  selectRef,
  ...restProps
}) => {
  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
   formState: { errors }
  } = useForm({
    defaultValues: initialUserState
  })

  const watchActive = watch('active')
  const watchPB = watch('picture_blob')
  const emailAddress = watch('email')
  const teamId = watch('team_id')

  const onSuccess = user => {
    reset(user)
  }
  const user = useGetUser({ userId, onSuccess })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const updateUserStatus = useUpdateUserStatus()
  const toggleStatus = () => {
    updateUserStatus.mutate({ userId })
  }

  const updateProfilePicture  = useUpdateProfilePicture()
  const refreshUserPicture = () => {
    updateProfilePicture.mutate({ userId, emailAddress })
  }

  const updateUser = useUpdateUser()
  const handleUserUpdate = async (form) => {
    await updateUser.mutateAsync({ userId, user: form })
    selectRef.current.clearValue()
  }

  const deleteUser = useDeleteUser()
  const handleDeleteUser = async () => {
    await deleteUser.mutateAsync({ userId })
    selectRef.current.clearValue()
  }

  if (user.isLoading) return <Loading />
  if (user.error) return <SomethingBroke message='Failed to get user' error={user.error.message} />

  return (
    <>
      {user && (
        <PermissionsModal
          onClose={onClose}
          isOpen={isOpen}
          userId={userId}
        />
      )}

      <form onSubmit={handleSubmit(handleUserUpdate)} {...restProps}>
        <Header
          username={user.data.ad_username}
          toggleStatus={toggleStatus}
          active={watchActive}
          blob={watchPB}
          refreshUserPicture={refreshUserPicture}
        />
        <TeamSelectInput required control={control} errors={errors} name='team_id' />
        { 
          teamId?.value === 4 && (
            <SalesTargetInput mt='1rem' control={control} required={false} errors={errors} name='sales_target' />
          )
        }
        <CheckboxController
          control={control}
          name='leader'
          text='Leader'
          mt='1rem'
          mb='1rem'
        />
        <UsernameInput 
          required
          register={register}
          name='ad_username'
          errors={errors}
        />
        <EmailInput required register={register} name='email' />
        <AutotaskIdInput required register={register} name='autotask_id' />
        <Flex flexFlow='row'>
          <Button type='submit' color='white' backgroundColor='resolveOrange'>
            Save
          </Button>
          <PopoverButton
            title='Delete User'
            isLoading={deleteUser.isLoading}
            ml='1rem'
            message='Are you sure you want to delete this user?'
            handleClick={handleDeleteUser}
            buttonTitle='Delete'
            color='resolveOrange'
            backgroundColor='white'
          />
          <Spacer />
          <Button
            backgroundColor='resolveOrange'
            color='white'
            onClick={() => onOpen()}
          >
            Permissions
          </Button>
        </Flex>
      </form>
    </>
  )
}
