import { Flex, Text } from '@chakra-ui/react'

export const SupplierAndDescription = ({ supplier, description }) => (
  <Flex
    basis='55%'
    pointerEvents='none'
    justifyContent='center'
    direction='column'
  >
    <Text fontSize='1xl' pointerEvents='none'>
      {description}
    </Text>
    <Text fontSize='sm'>{supplier}</Text>
  </Flex>
)
