import { Paginator } from './Paginator'
import { QuoteList } from './index'

export const Container = ({ quotes, isFetching }) => {

  return (
    <>
      <QuoteList />
      <Paginator />
    </>
  )
}
