import { Center, Flex, Text } from '@chakra-ui/react'

const UserNotFound = () => (
  <Center height='50rem'>
    <Flex>
      <Text ml='1rem' fontSize='large' color='resolveOrange'>
        Quotes profile not found, speak to a Team Leader for assistance!
      </Text>
    </Flex>
  </Center>
)

export default UserNotFound
