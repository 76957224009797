import {
    useParams,
    Link as ReactLink
} from 'react-router-dom'
import { Button, Flex, FormLabel, Link, Spacer, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { ItemClientCostInput } from 'components/Form/ItemClientCostInput'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput'
import { ItemInternalDescriptionInput } from 'components/Form/ItemInternalDescriptionInput'
import { ItemMarkupInput } from 'components/Form/ItemMarkupInput'
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput'
import { ItemQuantityInput } from 'components/Form/ItemQuantityInput'
import { ItemUnitPriceInput } from 'components/Form/ItemUnitPriceInput'
import { MinimumTermSelectInput } from 'components/Form/MinimumTermSelectInput'
import { PaymentPlanSelectInput } from 'components/Form/PaymentPlanSelectInput'
import { SetProductSelectInput } from 'components/Form/SetProductSelectInput'
import { SupplierProductTypeSelectInput } from 'components/Form/SupplierProductTypeSelectInput'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { useGetProduct } from 'features/Quotes/api/getProduct'
import { useCreateTemplateItem } from 'features/Templates/api/createTemplateItem'
import { useEffect } from 'react'
import { useGetSupplier } from 'features/Quotes/api/getSupplier'

const INITIAL_ITEM_STATE = {
  client_cost: 0.00,
  delivery_price: 0,
  description: '',
  mark_up_percentage: 45,
  part_code: '',
  quantity: 1,
  supplier_id: '',
  type_id: { value: 1, label: 'Goods' },
  unit_price: 0.00,
  minimum_term_id: 0,
  internal_description: '',
  payment_plan_id: 0,
  set_product: undefined
}

export const AddItem = () => {
    const { templateId } = useParams()
    const {
        handleSubmit,
        watch,
        control,
        register,
        setValue,
        resetField,
        formState: { errors }
    } = useForm({
        defaultValues: INITIAL_ITEM_STATE
    })

    const productId = watch('set_product')
    const productTypeId = watch('type_id')
    const supplierId = watch('supplier_id')

    useEffect(() => {
        if (productTypeId) {
            resetField('part_code')
            resetField('description')
            resetField('client_cost')
            resetField('delivery_price')
            resetField('unit_price')
            resetField('quantity')
            resetField('mark_up_percentage')
            resetField('internal_description')
        }
    }, [productTypeId, resetField])

    const handleSave = form => {
        createTemplateItem.mutate({ form, templateId })
    }

    const product = useGetProduct({
        productId: productId?.value, onSuccess: product => {
            setValue('part_code', product.part_code)
            setValue('client_cost', product.client_cost)
            setValue('unit_price', product.unit_price)
            setValue('description', product.description)
            setValue('mark_up_percentage', product.mark_up_percentage)
            setValue('delivery_price', product.delivery_price)
            if (productTypeId?.value === 2) {
                setValue('minimum_term_id', product.minimum_term_id)
                setValue('payment_plan_id', product.payment_plan_id)
            }
        }
    })

    const supplier = useGetSupplier({
        supplierId, productTypeId, onSuccess: supplier => {
            setValue('delivery_price', supplier.default_delivery)
        }
    })

    const createTemplateItem = useCreateTemplateItem(templateId)

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <Flex mb='1rem'>
                <Text color='resolvePurple' mr='1rem' fontSize='2rem' fontWeight='600'>
                    Add Item
                </Text>
                <Spacer />
                <Link
                    as={ReactLink}
                    alignSelf='center'
                    color='resolvePurple'
                    _hover={{ color: 'gray.200' }}
                    to={`/templates/${templateId}`}
                >
                    Back
                </Link>
            </Flex>
            <SupplierSelectInput
                control={control}
                register={register}
                required
                ignoreOtherSupplier={false}
                errors={errors}
                name='supplier_id'
                selectedOptionColor='resolvePurpler'
            />

            <SupplierProductTypeSelectInput
                control={control}
                errors={errors}
                name='type_id'
            />

            {
                productTypeId && supplierId && (
                    <SetProductSelectInput
                        supplierId={supplierId}
                        type={productTypeId}
                        control={control}
                        name='set_product'
                        required={false}
                    />
                )
            }

            <Flex mb='1rem'>
                <ItemPartCodeInput
                    register={register}
                    errors={errors}
                    name='part_code'
                    flexBasis='30%'
                    mr="1rem"
                    showLabel
                />
                <ItemDescriptionInput
                    register={register}
                    errors={errors}
                    name='description'
                    required
                    rules={{
                        maxLength: {
                            value: 90,
                            message: 'Description must be under 90 characters'
                        }
                    }}
                />
            </Flex>

            <ItemInternalDescriptionInput
                register={register}
                name='internal_description'
                errors={errors}
            />

            <Flex mb='1rem'>
                <ItemQuantityInput
                    control={control}
                    name='quantity'
                    mr='1rem'
                    errors={errors}
                />
                <ItemUnitPriceInput
                    control={control}
                    name='unit_price'
                    errors={errors}
                    setValue={setValue}
                />
                <ItemMarkupInput
                    control={control}
                    name='mark_up_percentage'
                    errors={errors}
                    setValue={setValue}
                />
            </Flex>

            <Flex mb='1rem'>
                <ItemClientCostInput
                    control={control}
                    name='client_cost'
                    errors={errors}
                    setValue={setValue}
                />
                <ItemDeliveryPriceInput
                    control={control}
                    name='delivery_price'
                    errors={errors}
                />
            </Flex>

            {productTypeId?.value === 2 && (
                <>
                    <FormLabel color='resolvePurple' mt='1rem'>
                        Subscription Options
                    </FormLabel>
                    <Flex mb='2rem'>
                        <MinimumTermSelectInput
                            required
                            control={control}
                            errors={errors}
                            name='minimum_term_id'
                            mr='1rem'
                            selectedOptionColor='resolvePurpler'
                        />
                        <PaymentPlanSelectInput
                            required
                            control={control}
                            errors={errors}
                            name='payment_plan_id'
                            selectedOptionColor='resolvePurpler'
                        />
                    </Flex>
                </>
            )}

            <Button
                variant='solid'
                backgroundColor='resolvePurple'
                color='white'
                _hover={{ bg: 'gray', color: 'resolvePurple' }}
                type='submit'
                mr='1rem'
            >
                Save
            </Button>
        </form>
    )
}
