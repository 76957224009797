import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { useWatch } from 'react-hook-form'
import { ControlledSelect } from 'components/Form/ControlledSelect'
import { OtherSupplierInput } from './OtherSupplierInput'
import { useGetSuppliers } from './api/getSuppliers'

export const SupplierSelectInput = ({
  register,
  errors,
  name,
  control,
  isClearable = false,
  required,
  ignoreOtherSupplier,
  selectedOptionColor = 'resolveOranger',
  ...props
}) => {
  const watchSupplier = useWatch({ name, control })

  const suppliers = useGetSuppliers()
  return (
    <>
      <FormControl
        mb='1rem'
        isInvalid={!!errors[name]}
        isRequired={required}
        {...props}
      >
        <FormLabel>Supplier</FormLabel>
        <ControlledSelect
          isLoading={suppliers.isLoading}
          name={name}
          control={control}
          options={suppliers.data}
          rules={{ required }}
          isClearable={isClearable}
          selectedOptionColor={selectedOptionColor}
        />

        <FormErrorMessage>
          {errors[name] && errors[name].message}
        </FormErrorMessage>
      </FormControl>

      {!ignoreOtherSupplier && watchSupplier && watchSupplier.value === 7 && (
        <OtherSupplierInput
          register={register}
          name='other_supplier'
          required={required}
          errors={errors}
        />
      )}
    </>
  )
}
