import { useState } from 'react'
import {
  Checkbox,
  CheckboxGroup,
  Flex,
  Heading,
  HStack,
  Spacer,
} from '@chakra-ui/react'
import { Loading } from 'components/Loading'
import { useGetApprovedAndPostedReport } from 'features/Reports/api/getApprovedAndPostedReport'
import { AnPTable } from './Table'
import { useFilter } from './useFilter'

export const AandP = () => {
  const [filter, setFilter] = useState([])
  const { filterString } = useFilter(filter)
  const report = useGetApprovedAndPostedReport(filterString)

  return (
    <Flex width='80%' flexFlow='column' justifyContent='flex-start' mt='1rem'>
      <HStack mt='1rem' mb='1rem'>
        <Heading color='resolveOrange'>
          Approved & Posted / Invoiced Report
        </Heading>
        <Spacer />
        <Flex>
          <CheckboxGroup onChange={e => setFilter(e)} value={filter}>
            <HStack spacing={5}>
              <Checkbox fontWeight='bold' value='anp' colorScheme='resolvePinker'>
                Hide A+P'd
              </Checkbox>
              <Checkbox fontWeight='bold' value='invoiced' colorScheme='resolvePurpler'>
                Hide Invoiced
              </Checkbox>
              <Checkbox fontWeight='bold' value='quoted' colorScheme='resolveOranger'>
                Hide Quoted
              </Checkbox>
              <Checkbox fontWeight='bold' value='subs' colorScheme='resolveBluer'>
                Hide Subs
              </Checkbox>
            </HStack>
          </CheckboxGroup>
        </Flex>
      </HStack>
      { report.isFetching ? <Loading /> : ( report.isSuccess && <AnPTable tableData={report.data} /> )}
    </Flex>
  )
}
