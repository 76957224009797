import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const fetchItem = async itemId => {
  const { data: item } = await axiosClient({
    url: `/io/item/${itemId}`
  })
  return item
}

export const useGetItem = ({itemId, onSuccess }) => {
  return useQuery(['io-item', itemId], () => fetchItem(itemId), { onSuccess })
}