import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation, useQueryClient } from "react-query";

const deleteProduct = async ({ productId }) => {
  await axiosClient({
    url: `/settings/products/${productId}`,
    method:'delete'
  })  
}

export const useDeleteProduct = () => {
  const queryClient = useQueryClient()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(deleteProduct, {
    onSuccess: () => {
      toastSuccess('Product successfully updated')
      queryClient.invalidateQueries('settings-products')
    },
    onError: () => {
      toastFail('Failed to update product')
    }
  })
}