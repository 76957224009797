import { axiosClient } from "lib/axiosClient"
import moment from "moment"
import { useQuery } from "react-query"

const getApprovedAndPostedReport = async (context) => {
  const { signal, queryKey } = context
  const [_key, filterString] = queryKey
  const { data: { data } } = await axiosClient({
    url: `/reports/anp${filterString}`,
    signal
  })
  const items = data.map(item => ({ 
    ...item, 
    date_added: moment(item.date_added).format('DD/MM/YY'),
    invoiced: item.invoiced ? 'Yes' : 'No',
    approved_posted: item.approved_posted ? 'Yes' : 'No'
  }))
  return items
}

export const useGetApprovedAndPostedReport = (filterString) => {
  return useQuery(['approved-posted', filterString], { 
    queryFn: getApprovedAndPostedReport,
    placeholderData: [],
  })
}