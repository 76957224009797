import { FormControl, FormLabel } from '@chakra-ui/react'
import { createFilter, Select } from 'chakra-react-select'
import { useGetClients } from './api/getClients'

export const ControlledClientSelect = ({
  onChange,
  selectedClient
}) => {
  const clients = useGetClients()
  return (
    <FormControl
      mb='1rem'
      isRequired
      mr='1rem'
    >
      <FormLabel>Client</FormLabel>
      <Select
        name='client_id'
        filterOption={createFilter({ ignoreAccents: false })}
        onChange={onChange}
        value={selectedClient}
        options={clients.data}
      />
    </FormControl>
  )
}
