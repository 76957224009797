import { Paginator } from 'components/Paginator'
import { SearchFilter } from 'features/Subs/components/Sub/List/SearchFilter'
import { SubList } from '.'
import { useParams } from 'react-router'
import { useSubFilter } from 'hooks/useSubFilter'
import { useState } from 'react'
import { useGetSubs } from 'features/Subs/api/getSubs'

export const Container = () => {
  const { subId } = useParams()
  const { filter, setFilter, filterString } = useSubFilter()
  const [page, setPage] = useState(1)
  const subs = useGetSubs({ page, filterString })
  return (
    <>
      <SearchFilter 
        setPage={setPage} 
        setFilter={setFilter}
        filter={filter} 
      />
      <SubList 
        subs={subs.data.subs}
        isFetching={subs.isFetching}
        isLoading={subs.isLoading}
        subId={subId}
        isError={subs.error}
      />
      <Paginator 
        componentColor='resolveGreen'
        resultCount={subs.data.resultCount}
        page={page}
        setPage={setPage}
      />
    </>
  )
}
