import { useRef, useState } from 'react'
import {
  Link as ReactLink,
  Routes as Switch,
  Route,
} from 'react-router-dom'
import { Text, Flex, Spacer, Button, Input } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { EditProductForm } from './Edit'
import { AddProductForm } from './Add'
import { useGetProducts } from 'features/Settings/api/getProducts'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Loading } from 'components/Loading'
import { CSVLink } from 'react-csv'
import { axiosClient } from 'lib/axiosClient'
import { useResolveToast } from 'hooks/useResolveToast'
import { useQueryClient } from 'react-query'

const ProductSelectList = ({ products, selectRef, onChange }) => {
  return (
    <Select
      ref={selectRef}
      selectedOptionColor='resolveOranger'
      onChange={onChange}
      isClearable
      options={products}
    />
  )
}

export const ProductSettingsContainer = () => {
  return (
    <Switch>
      <Route path='add' element={<AddProductForm />} />
      <Route path='/' element={<ProductSettings />} />
    </Switch>
  )
}

export const ProductSettings = () => {
  const [product, setProduct] = useState(null)
  const [upload, setUpload] = useState(false)
  const { toastSuccess, toastFail } = useResolveToast()
  const selectRef = useRef()
  const queryClient = useQueryClient()

  const onChange = e => {
    if(e) {
      setProduct(products.data.filter(p => (p.id === e.value))[0])
      return
    }
    setProduct(null)
  }

  const uploadProducts = async (e) => {
    e.preventDefault()
    const file = e.target[0].files[0]
    if(!file) {
      toastFail('Attach a file before uploading')
      return
    }
    const formData = new FormData()
    formData.append('import', file)
    axiosClient( { url:'/settings/products/import', method: 'post', data: formData }).then(res => {
      toastSuccess('Set Products uploaded successfully')
      queryClient.invalidateQueries(['settings-products'])
    }).catch(error => {
      toastFail('Failed to parse uploaded file')
    })
  }

  const products = useGetProducts()

  if(products.error) return <SomethingBroke message='Failed to get products' error={products.error.message} />
  if(products.isLoading ) return <Loading />
  return (
    <Flex flexFlow='column'>
      <Flex alignItems='center' mb='2rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Set Products
        </Text>
        <Spacer />
        <Button
          as={ReactLink}
          backgroundColor='resolveOrange'
          size='sm'
          color='white'
          to='/settings/products/add'
        >
          Add Set Product
        </Button>
      </Flex>

      <ProductSelectList
        onChange={onChange}
        products={products.data.map(p => ({ label: `${p.supplier_name} - ${p.description}`, value: p.id}))}
        selectRef={selectRef}
      />

      {product && (
        <EditProductForm
          selectRef={selectRef}
          product={product}
        />
      )}

      {
        !product && (
          <>
          <Flex mt='2rem'>
            <CSVLink
              data={products.data.map(product => ({
                description: product.description,
                part_code: product.part_code,
                client_cost: product.client_cost,
                unit_price: product.unit_price,
                mark_up_percentage: product.mark_up_percentage,
                delivery_price: product.delivery_price,
                quantity: product.quantity
              }))}
              filename={`set-products.csv`}
            >
              <Button
                size='sm'
                backgroundColor='resolveOrange'
                color='white'
                mr='1rem'
              >
                Export Products
              </Button>
            </CSVLink>

              <Button
                backgroundColor='resolveOrange'
                size='sm'
                color='white'
                onClick={e => setUpload(!upload)}
              >
                Import Products
              </Button>
          </Flex>

              { upload && (
                <form method='post' onSubmit={uploadProducts} encType='multipart/form-data'>
                  <Flex mt='1rem'>
                    <Input flexBasis="40%" type='file' name='import' mr='1rem' sx={{
                      "::file-selector-button": {
                        height: 10,
                        padding: 0,
                        mr: 4,
                        background: "none",
                        border: "none",
                        fontWeight: "bold",
                      },
                    }} />
                    <Button
                      type='submit'
                      backgroundColor='resolveOrange'
                      color='white'
                    >Upload</Button>
                  </Flex>
                </form>
              )
              }
          </>
        )
      }


    </Flex>
  )
}
