import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getSupplier = async (context) => {
  const { signal, queryKey } = context
  const [_, supplierId] = queryKey
  const { data: supplierData } = await axiosClient({
    url: `/settings/suppliers/${supplierId.value}`
  })
  return supplierData
}

export const useGetSupplier = ({ supplierId, productTypeId, onSuccess }) => {
  return useQuery(['io-item-supplier', supplierId], {
    queryFn: getSupplier,
    enabled: !!supplierId && productTypeId?.value === 1,
    onSuccess
  })
}