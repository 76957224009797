import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getAssignedUsers = async (context) => {
  const { signal, queryKey } = context
  const [_, itemId] = queryKey
  const { data: { engineers } } = await axiosClient({
    url: `/quotes/assign/${itemId}`,
    signal
  })
  return engineers
}

export const useGetAssignedUsers = ({ itemId }) => {
  return useQuery(['assigned-engineers-quote-item', itemId], {
    queryFn: getAssignedUsers,
    placeholderData: [],
    staleTime: Infinity
  })
}