
import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getItemOrderDetails = async (context) => {
  const { queryKey } = context
  const [_, itemId] = queryKey
  const { data } = await axiosClient({
    url: `/quotes/item/order/${itemId}`,
    method: 'GET'
  })

  console.log("Got item order details", data)
  return data 
}



export const useGetItemOrderDetails = ({ itemId, onSuccess }) => {
  return useQuery(['item-status', itemId], {
    queryFn: getItemOrderDetails,
    onSuccess
 })
}


