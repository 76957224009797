import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getQuotationReport = async (context) => {
  const { signal, queryKey } = context
  const [_key, filterString] = queryKey
  const { data: { items }} = await axiosClient({
    url: `/reports/quotation?${filterString}`,
    signal
  })
  return items
}

export const useGetQuotationReport = (filterString) => {
  return useQuery(['quotation', filterString], { 
    queryFn: getQuotationReport,
    placeholderData: []
  })
}