import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

export const copyItem = async itemId => {
  await axiosClient({
    url: `/quotes/copy/item/${itemId}`,
    method: 'post'
  })
}

export const useCopyQuoteItem = quoteId => {
  const queryClient = useQueryClient()
  const history = useNavigate()
  const { toastSuccess, toastFail } = useResolveToast()

  return useMutation(copyItem, {
    onSuccess: () => {
      toastSuccess('Item copied successfully')
      queryClient.invalidateQueries(['quote-items'])
      history(`/quotes/${quoteId}`)
    },
    onError: () => {
      toastFail('Failed to copy item')
    }
  })
}