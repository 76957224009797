import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Button, Flex, FormControl, FormLabel, Icon } from '@chakra-ui/react'

export const SubscriptionStatus = ({
  active,
  handleActiveClick,
}) => {
  return (
    <Flex alignItems='flex-end'>
      <FormControl>
        <FormLabel>Status</FormLabel>
        {
          active
            ? (
              <>
                <Button
                  variant='unstyled'
                  leftIcon={<Icon as={ViewIcon} />}
                  p='5px'
                  color='white'
                  onClick={handleActiveClick}
                  bg='resolveGreen'
                >
                  Active
                </Button>
              </>
              )
            : (
              <>
                <Button
                  variant='unstyled'
                  leftIcon={<Icon as={ViewOffIcon} />}
                  p='5px'
                  color='white'
                  onClick={handleActiveClick}
                  bg='resolveRed'
                >
                  Inactive
                </Button>
              </>
              )
          }
      </FormControl>
    </Flex>
  )
}
