import { axiosClient } from 'lib/axiosClient'
import { useQuery } from 'react-query'

const getQuotesSearchResultsCount = async (context) => {
  const { queryKey } = context 
  const [_, searchState] = queryKey
  const { data: { quoteCount } } = await axiosClient({
    url: '/quotes/search/count',
    method: 'post',
    data: searchState
  })

  return { resultCount: quoteCount.quoteCount, complete: true }
}
export const useSearchQuotesResultCount = ({ searchState }) => { 
  const enabled = searchState !== null && searchState !== undefined
  return useQuery(['search-quotes-count', searchState], {
    queryFn: getQuotesSearchResultsCount,
    placeholderData: { resultCount: 0, complete: false },
    enabled 
  }) 
}