import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getQuote = async (context) => {
  const { signal, queryKey } = context
  const [_, quoteId] = queryKey
  const { data: { quote }} = await axiosClient({
    url: `/quotes/edit/${quoteId}`,
    signal
  })

  return quote
}

export const useGetQuote = ({quoteId, onSuccess}) => {
  return useQuery(['quote', quoteId], {
    queryFn: getQuote,
    onSuccess
  })
}