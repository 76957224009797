import { axiosClient } from 'lib/axiosClient';
import { useQuery } from 'react-query';

const getIOBillingCodes = async context => {
  const { signal } = context;
  const { data: billingCodes } = await axiosClient({
    url: '/settings/io-billing-codes',
    signal,
  });
  return billingCodes.filter(bc => (!!bc.enabled));
};

export const useGetIOBillingCodes = () => {
  return useQuery(['form-io-billing-codes'], {
    queryFn: getIOBillingCodes,
    placeholderData: [],
    staleTime: Infinity,
  });
};
