import { useResolveToast } from "hooks/useResolveToast"
import { useMutation, useQueryClient } from "react-query"

const { axiosClient } = require("lib/axiosClient")

const updateGeneralSettings = async ({ form }) => {
  await axiosClient({
    url: '/settings/general',
    method: 'post',
    data: {
      ...form,
      VAT: Number(form.VAT),
      mark_up_warn_perc: Number(form.mark_up_warn_perc)
    }
  })
}

export const useUpdateGeneralSettings = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateGeneralSettings, {
    onSuccess: () => {
      toastSuccess('Settings updated successfully')
      queryClient.invalidateQueries('settings-general')
    },
    onError: () => {
      toastFail('Failed to update settings')
    }
  })
}