import {
  Flex,
  Text,
  Badge,
  Icon,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Link
} from '@chakra-ui/react'

import { ViewOffIcon } from '@chakra-ui/icons'

import { Quantity } from './Quantity'
import { Status } from './Status'
import { SupplierAndDescription } from './SupplierAndDescription'
import { BulkSelect } from './BulkSelect'

export const ListItem = ({
  item,
  handleItemClick,
  handleItemBulkSelect,
  selectedBulkItems
}) => {
  return (
    <Flex id={item.id} key={item.id}>
      <Flex onClick={handleItemClick} width='100%'>
        <Flex
          _hover={{ boxShadow: 'rgba(0, 0, 0, .1) 0 0 0 1000000px inset' }}
          basis='100%'
          id={item.id}
          cursor='pointer'
          pt='1rem'
          pb='1rem'
        >
          <Quantity quantity={item.quantity} />

          <SupplierAndDescription
            supplier={item.supplier}
            description={item.item_desc}
          />


          <Flex direction='column' pointerEvents='none' justifyContent='center'>
            <Status status={item.item_status} />
            <Flex pointerEvents='none' justifyContent='center'>
              <Badge pl='0.3rem' pointerEvents='none' fontSize='x-small' color='resolveOrange'>{item.at_billing_code_id && item.at_billing_code_id.label}</Badge>
            </Flex>
          </Flex>

          <Flex
            flexBasis='20%'
            direction='column'
            id={item.id}
            pointerEvents='none'
            mr='1rem'
          >
            <Link target='_blank' pointerEvents='auto' rel='noreferrer' href={item.ticketUrl}>
              <Text fontSize='xs' id={item.id} textAlign='right'>
                {item.autotask_number}
              </Text>
            </Link>
            {
              item.hidden_print === 1 && (
                <Flex pointerEvents='none' direction='column' alignItems='flex-end'>
                  <Badge color='white' bg='resolveRed' size='xs'>
                    Hidden <Icon as={ViewOffIcon} />
                  </Badge>
                </Flex>
              )
            }
            <Stat id={item.id} pointerEvents='none'>
              <StatHelpText pointerEvents='none' textAlign='right'>
                {' '}
                <Badge
                  pointerEvents='none'
                  color='white'
                  bg={item.type_id?.value === 1 ? 'resolveOrange' : 'resolvePurple'}
                  size='sm'
                >
                  {item.item_ref}
                </Badge>
              </StatHelpText>
              <StatLabel pointerEvents='none' textAlign='right' fontSize='x-small'>
                Total (ex <b>VAT</b>)
              </StatLabel>
              <StatNumber textAlign='right' pointerEvents='none' fontSize='sm'>{item.totalexVAT}</StatNumber>
            </Stat>
          </Flex>
        </Flex>
      </Flex>
      <BulkSelect
        handleItemBulkSelect={handleItemBulkSelect}
        selectedBulkItems={selectedBulkItems}
        id={item.id}
      />
    </Flex>
  )
}