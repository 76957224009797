import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation } from "react-query"

const updateBillingCodes = async () => {
  await axiosClient({
    url: '/settings/billing-codes',
    method: 'post'
  })
}

export const useUpdateBillingCodes = () => {
  const { toastSuccess, toastFail } = useResolveToast()
  return useMutation(updateBillingCodes, {
    onSuccess: () => {
      toastSuccess('Billing codes syncronised successfully')
    },
    onError: () => {
      toastFail('Failed to syncronised billing codes')
    }
  })
}