import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation, useQueryClient } from "react-query";

const updateProduct = async ({ form, productId }) => {
  const { supplier_name, ...cForm } = form
  const nForm = {
    ...cForm,
    type_id: form.type_id.value, 
    unit_price: parseFloat(form.unit_price),
    mark_up_percentage: Number(form.mark_up_percentage),
    delivery_price: parseFloat(form.delivery_price),
    payment_plan_id: form.payment_plan_id?.value || 0,
    minimum_term_id: form.minimum_term_id?.value || 0,
    supplier_id: form.supplier_id.value 
  }
  await axiosClient({
    url: `/settings/products/${productId}`,
    method: 'patch',
    data: nForm
  })
}

export const useUpdateProduct = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateProduct, {
    onSuccess: () => {
      toastSuccess('Product successfully updated') 
      queryClient.invalidateQueries('settings-products')
    },
    onError: () => {
      toastFail('Failed to update product') 
    }
  })
}