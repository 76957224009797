import { axiosClient } from "lib/axiosClient";
import { useQuery } from "react-query";

const getUsers = async (context) => {
  const { signal } = context
  const { data: users } = await axiosClient({
    url: '/settings/users',
    signal
  })
  return users
}

export const useGetUsers = () => {
  return useQuery(['form-users'],
    {
      queryFn: getUsers,
      staleTime: Infinity,
      placeholderData: []
    }
  )
}