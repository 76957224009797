import { Flex, FormControl, IconButton } from '@chakra-ui/react'

import { FaEraser } from 'react-icons/fa'
import { Search2Icon } from '@chakra-ui/icons'

export const ActionButtons = ({ handleFilterReset }) => (
  <Flex>
    <FormControl flexBasis='100%'>
      <Flex justifyContent='flex-end'>
        <IconButton
          color='resolveBlue'
          variant='outline'
          icon={<Search2Icon />}
          mr='0.5rem'
          type='submit'
        />
        <IconButton
          color='resolveBlue'
          variant='outline'
          icon={<FaEraser />}
          onClick={handleFilterReset}
        />
      </Flex>
    </FormControl>
  </Flex>
)
