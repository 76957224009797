import { FormControl, FormLabel } from '@chakra-ui/react'
import { ControlledSelect } from './ControlledSelect'
import { useQuery } from 'react-query'
import { axiosClient } from 'lib/axiosClient'

const getSubTypes = async (context) => {
  const { signal } = context
  const { data: subTypes } = await axiosClient({
    url: '/settings/sub-types',
    signal
  })
  return subTypes
}
const useGetSubTypes = () => {
  return useQuery(['form-sub-types'], {
    placeholderData: [],
    queryFn: getSubTypes
  })
}

export const SubscriptionTypeSelect = ({
  name,
  control,
  required,
  errors,
  selectedOptionColor='resolveOrange',
}) => {

  const subTypes = useGetSubTypes()

  return (
    <FormControl isRequired={required} isInvalid={!!errors[name]} mb='1rem'>
      <FormLabel>Subscription Type</FormLabel>
      <ControlledSelect
        name={name}
        control={control}
        isLoading={subTypes.isLoading}
        rules={{ required }}
        options={subTypes.data}
        selectedOptionColor={selectedOptionColor}
      />
    </FormControl>
  )
}
