import React from 'react'
import { useColorMode, useColorModeValue, IconButton } from '@chakra-ui/react'
import { FaMoon, FaSun } from 'react-icons/fa'

export const ColorModeSwitcher = props => {
  const { toggleColorMode } = useColorMode()
  const text = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)

  return (
    <IconButton
      size='sm'
      fontSize='md'
      aria-label={`Switch to ${text} mode`}
      variant='ghost'
      color='resolveOrange'
      alignSelf='center'
      onClick={toggleColorMode}
      icon={<SwitchIcon />}
      {...props}
    />
  )
}
