import { Text, Button, Spacer, useDisclosure, Flex } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { TeamSelectInput } from '../Form/TeamSelectInput'
import { UsernameInput } from '../Form/UsernameInput'
import { EmailInput } from '../Form/EmailInput'
import { AutotaskIdInput } from '../Form/AutotaskIdInput'
import { PermissionsModal } from './PermissionsModal'
import { useResolveToast } from 'hooks/useResolveToast'
import { CheckboxController } from 'components/Form/CheckboxController'
import { useCreateUser } from 'features/Settings/api/createUser'

const UserState = {
  ad_username: '',
  team_id: '',
  admin_access: 0,
  email: '',
  autotask_id: '',
  leader: false,
  permissions: undefined
}

const AddUserFormHeader = () => (
  <Text color='resolveOrange' mb='1rem' fontSize='2rem' fontWeight='600'>
    Add User
  </Text>
)  

export const AddUserForm = () => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: UserState
  })

  const { onOpen, isOpen, onClose } = useDisclosure()
  const { toastFail } = useResolveToast()

  const handlePermissionsSubmit = form => {
    const { isAdmin, ...rest } = form
    setValue('permissions', rest)
    setValue('admin_access', isAdmin ? 1 : 0)
    onClose()
  }
  const createUser = useCreateUser()
  const handleUserSubmit = async form => {
    if (!form.permissions) {
      toastFail('No permissions selected')
      document.getElementById('permission-btn').focus()
      return
    }
    createUser.mutate({ form })
  }

  return (
    <>
      {isOpen && (
        <PermissionsModal
          isOpen={isOpen}
          onClose={onClose}
          handlePermissionsSubmit={handlePermissionsSubmit}
        />
      )}

      <form onSubmit={handleSubmit(handleUserSubmit)}>
        <AddUserFormHeader />
        <UsernameInput errors={errors} required register={register} name='ad_username' />
        <EmailInput required register={register} name='email' />
        <TeamSelectInput name='team_id' errors={errors} control={control} required />
        <CheckboxController
          mt='1rem'
          mb='1rem'
          control={control}
          name='leader'
          text='Leader'
        />
        <AutotaskIdInput required register={register} name='autotask_id' />
        <Flex flexFlow='row'>
          <Button type='submit' color='white' backgroundColor='resolveOrange'>
            Save
          </Button>
          <Spacer />
          <Button
            backgroundColor='resolveOrange'
            color='white'
            id='permission-btn'
            onClick={() => onOpen()}
          >
            Permissions
          </Button>
        </Flex>
      </form>
    </>
  )
}
