import moment from "moment";
import { useEffect, useState } from "react";

export const useFilter = ({ engineer, startDate, endDate }) => {
  const [filterString, setFilterString] = useState('')
  useEffect(() => {
    let filterStringTmp = ''
    const filteredByEngineer = engineer !== undefined ? engineer !== null : false
    if (filteredByEngineer) {
      filterStringTmp += `engineerId=${engineer?.value}&`
    }

    if (startDate) {
      const formattedStartDate = new Date(startDate)
      // adding two hours to ensure the correct day is selected (utc goes over)
      formattedStartDate.setHours(2)
      const startDateMySQL = moment(formattedStartDate).format('YYYY-MM-DD')
      filterStringTmp += `startDate=${startDateMySQL}`
    }

    if (endDate) {
      const formattedEndDate = new Date(endDate)
      // adding two hours to ensure the correct day is selected (utc goes over)
      formattedEndDate.setHours(2)
      const endDateMySQL = moment(formattedEndDate).format('YYYY-MM-DD')
      filterStringTmp += `&endDate=${endDateMySQL}`
    }
    setFilterString(filterStringTmp)
  }, [engineer, startDate, endDate]);

  return { filterString }
}