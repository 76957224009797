import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Text
} from '@chakra-ui/react'
import { useWatch } from 'react-hook-form'

import { ClientSelectInput } from 'components/Form/ClientSelectInput'
import { OtherClientInput } from 'components/Form/OtherClientInput'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { SupplierProductTypeSelectInput } from 'components/Form/SupplierProductTypeSelectInput'
import { SetProductSelectInput } from 'components/Form/SetProductSelectInput'
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput'
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput'
import { ItemInternalDescriptionInput } from 'components/Form/ItemInternalDescriptionInput'
import { ItemQuantityInput } from 'components/Form/ItemQuantityInput'
import { ItemUnitPriceInput } from 'components/Form/ItemUnitPriceInput'
import { ItemMarkupInput } from 'components/Form/ItemMarkupInput'
import { ItemClientCostInput } from 'components/Form/ItemClientCostInput'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import { MinimumTermSelectInput } from 'components/Form/MinimumTermSelectInput'
import { PaymentPlanSelectInput } from 'components/Form/PaymentPlanSelectInput'
import { Loading } from 'components/Loading'

export const Form = ({
  productIsLoading,
  control,
  handleSubmit,
  isSubmitting,
  formSubmit,
  errors,
  register,
  setValue
}) => {
  const watchClient = useWatch({ control, name: 'client_id' })
  const watchProductType = useWatch({ control, name: 'type_id' })
  const watchSupplier = useWatch({ control, name: 'supplier_id' })

  return (
    <form onSubmit={formSubmit(handleSubmit)}>
      <Text color='resolveOrange' mb='1rem' fontSize='2rem' fontWeight='600'>
        New Quote
      </Text>

      <ClientSelectInput
        name='client_id'
        errors={errors}
        control={control}
        required='Please select a client'
        mb='1rem'
      />

      {watchClient.value === 99999999 && (
        <OtherClientInput
          errors={errors}
          name='other_client'
          register={register}
        />
      )}

      <SupplierSelectInput
        name='supplier_id'
        register={register}
        ignoreOtherSupplier={false}
        control={control}
        required='Please select a supplier'
        errors={errors}
      />

      <SupplierProductTypeSelectInput
        name='type_id'
        errors={errors}
        control={control}
      />

      {watchProductType && watchSupplier && (
        <SetProductSelectInput
          supplierId={watchSupplier}
          type={watchProductType}
          control={control}
          name='set_product'
          required
        />
      )}

      {watchProductType.value === 2 && (
        <FormControl mt='1rem' mb='1rem'>
          <FormLabel>
            Subscription Options
          </FormLabel>
          <Flex>
            <MinimumTermSelectInput
              required='Please select a minimum term'
              control={control}
              errors={errors}
              name='minimum_term_id'
              mr='1rem'
            />
            <PaymentPlanSelectInput
              required='Please select a payment plan'
              control={control}
              errors={errors}
              name='payment_plan_id'
            />
          </Flex>
        </FormControl>
      )}
      {
        !productIsLoading ? (

        <>
          <Flex mb='1rem'>
            <ItemPartCodeInput
              register={register}
              errors={errors}
              name='part_code'
              flexBasis='30%'
              mr='1rem'
              showLabel
            />
            <ItemDescriptionInput
              register={register}
              errors={errors}
              name='description'
              required
              rules={{
                maxLength: {
                  value: 90,
                  message: 'Description must be under 90 characters' 
                }
              }}
            />
          </Flex>

          <ItemInternalDescriptionInput
            register={register}
            errors={errors}
            name='internal_description'
          />

          <HStack spacing={3} mb='1rem'>
            <ItemQuantityInput
              control={control}
              required='Please select an quantity'
              errors={errors}
              name='quantity'
            />
            <ItemUnitPriceInput
              control={control}
              required='Please set a unit price'
              errors={errors}
              name='unit_price'
              setValue={setValue}
              isDisabled={false}
            />
            <ItemMarkupInput
              control={control}
              required
              isDisabled={false}
              errors={errors}
              name='mark_up_percentage'
              setValue={setValue}
            />
          </HStack>
          <Flex flexDir='row' mb='1rem'>
            <ItemClientCostInput
              isDisabled={false}
              control={control}
              required
              errors={errors}
              name='client_cost'
              setValue={setValue}
            />
            <ItemDeliveryPriceInput
              control={control}
              required
              errors={errors}
              name='delivery_price'
            />
          </Flex>

          <Button
            variant='solid'
            backgroundColor='resolveOrange'
            color='white'
            type='submit'
            isLoading={isSubmitting}
            mt='1rem'
          >
            Add Quote
          </Button>
        </>
        ) : (
          <Loading />
        ) 
      }
    </form>
  )
}
