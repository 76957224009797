import {
  Button,
  Flex,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter
} from '@chakra-ui/react'

export const PopoverButton = ({
  title,
  handleClick,
  message,
  buttonTitle,
  isLoading,
  ...restProps
}) => (
  <Popover placement='top'>
    <PopoverTrigger>
      <Button {...restProps}>
        {title}
      </Button>
    </PopoverTrigger>
    <Portal>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>{message}</PopoverBody>
        <PopoverFooter>
          <Flex justifyContent='center'>
            <Button
              color='white'
              backgroundColor='resolveOrange'
              onClick={handleClick}
              isLoading={isLoading}
            >
              {buttonTitle}
            </Button>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Portal>
  </Popover>
)
