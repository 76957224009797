import { useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Loading } from 'components/Loading'
import { QuoteTemplateItemList } from './index'
import { Actions } from './Actions'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useGetTemplateItems } from 'features/Templates/api/getTemplateItems'
import { useDeleteTemplate } from 'features/Templates/api/deleteTemplate'

export const Container = () => {
  const { templateId, itemId } = useParams()
  const history = useNavigate()

  const handleItemClick = useCallback((e) => {
    if (itemId !== e.target.id) {
      const newPath = `/templates/${templateId}/edit/${e.target.id}`
      history(newPath)
    }
  }, [itemId, history, templateId])
 
  const items = useGetTemplateItems({ templateId })
  const deleteTemplate = useDeleteTemplate({ templateId })
  
  if (items.isFetching || items.isLoading) return <Loading />
  if (items.error) return <SomethingBroke message='Failed to fetch items' error={items.error.message} />
  return (
    <>
      <QuoteTemplateItemList
        items={items.data}
        handleItemClick={handleItemClick}
      />
      <Actions templateId={templateId} deleteTemplate={deleteTemplate} />
    </>
  )
}
