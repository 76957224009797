import { Center, Divider, Text } from '@chakra-ui/react'
import { ListItem } from './ListItem'
import { ItemListLayout } from 'components/Layout/ItemListLayout'

const NoItemsFound = () => (
  <ItemListLayout>
    <Center>
      <Text color='resolveOrange'>No quote items</Text>
    </Center>
  </ItemListLayout>
)

export const ItemList = ({
  handleItemClick,
  selectedBulkItems,
  handleItemBulkSelect,
  items
}) => {
  if(items.length < 1) return <NoItemsFound />
  return (
    <ItemListLayout>
      {items.map(item => {
        return (
          <div key={item.id}>
            <ListItem
              item={item}
              handleItemClick={handleItemClick}
              handleItemBulkSelect={handleItemBulkSelect}
              selectedBulkItems={selectedBulkItems}
            />
            <Divider pointerEvents='none' />
          </div>
        )
      })}
    </ItemListLayout>
  )
}
