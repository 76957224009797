import { useCallback, useContext } from 'react'
import {
  Button,
  Flex
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { UserContext } from 'features/Auth/context/user.context'

import { ClientSelectInput } from 'components/Form/ClientSelectInput'
import { TemplateSelectInput } from './components/TemplateSelectInput'
import { useCreateQuoteFromTemplate } from 'features/Quotes/api/createQuoteFromTemplate'

export const CreateTemplateQuote = () => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm()
  const { engineer } = useContext(UserContext)

  const createQuoteFromTemplate = useCreateQuoteFromTemplate()

  const handleCreateTemplateQuote = useCallback(form => {
    const engineerId = engineer.quotes.id
    createQuoteFromTemplate.mutate({ template: form, engineerId })
  }, [engineer, createQuoteFromTemplate])

  return (
    <form onSubmit={handleSubmit(handleCreateTemplateQuote)}>
      <Flex flexFlow='column'>
        <ClientSelectInput
          control={control}
          errors={errors}
          name='client_id'
          required
        />
        <TemplateSelectInput
          control={control}
          errors={errors}
          name='template_id'
          label
          required
        />
        <Flex>
          <Button type='submit' color='white' bgColor='resolveOrange'>
            Create
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}
