import { useEffect, useState } from 'react'
import { Text, Flex, Spacer, Button } from '@chakra-ui/react'
import {
  Link as ReactLink,
  Routes as Switch,
  Route,
} from 'react-router-dom'
import { Select } from 'chakra-react-select'
import { EditQuoteType } from './Edit'
import { AddQuoteType } from './Add'
import { useGetQuoteTypes } from 'features/Settings/api/getQuoteTypes'

export const QuoteTypesSettingsContainer = () => {
  return (
    <Switch>
      <Route path='add'element={<AddQuoteType />} />
      <Route path='/' element={<QuoteTypeSettings />} />
    </Switch>
  )
}

const QuoteTypeSelectList = ({ onChange }) => {
  const quoteTypes = useGetQuoteTypes()
  return (
    <Select
      selectedOptionColor='resolveOranger'
      onChange={onChange}
      isClearable
      isLoading={quoteTypes.isLoading}
      options={quoteTypes.data}
    />
  )
}

export const QuoteTypeSettings = () => {
  const [quoteTypeId, setQuoteTypeId] = useState(null)

  const onChange = e => {
    setQuoteTypeId(e?.value)
  }


  return (
    <Flex flexFlow='column'>
      <Flex alignItems='center' mb='2rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Quote Types
        </Text>
        <Spacer />
        <Button
          as={ReactLink}
          backgroundColor='resolveOrange'
          size='sm'
          color='white'
          to='/settings/quote-types/add'
        >
          Add Quote Type
        </Button>
      </Flex>

      <QuoteTypeSelectList onChange={onChange} />

      {quoteTypeId && <EditQuoteType mt='1rem' quoteTypeId={quoteTypeId} />}
    </Flex>
  )
}
