import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

const createUser = async ({ form }) => {
  const hydratedForm = {
    ...form,
    team_id: form.team_id.value,
    leader: form.leader ? 1 : 0
  }
  await axiosClient({
    url: '/settings/users',
    method: 'post',
    data: hydratedForm
  })
}

export const useCreateUser = () => {
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(createUser, {
    onSuccess: () => {
      toastSuccess('User added successfully')
      history('/settings/users')
    },
    onError: () => {
      toastFail('Failed to add user')
    }
  })
}