import { useRef, useState } from 'react'
import {
  Routes as Switch,
  Route,
  Link as ReactLink
} from 'react-router-dom'
import { Text, Flex, Button, Spacer } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { AddIOBillingCodeForm } from './Add'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Loading } from 'components/Loading'
import { useGetIOBillingCodes } from 'features/Settings/api/getIOBillingCodes'
import { EditBillingCode } from './Edit'

const BCSelectList = ({ billingCodes, onChange, selectRef }) => {
  return (
    <Select
      selectedOptionColor='resolveOranger'
      onChange={onChange}
      isClearable
      options={billingCodes}
      ref={selectRef}
    />
  )
}


export const IOBCSettingsContainer = () => {
  return (
    <Switch>
      <Route path='add' element={<AddIOBillingCodeForm />} />
      <Route path='/' element={<InternalBillingCodes />} />
    </Switch>
  )
}

export const InternalBillingCodes = () => {
  const [billingCodeId, setBillingCodeId] = useState(null)

  const selectRef = useRef()

  const onChange = billingCode => {
    if (billingCode) {
      setBillingCodeId(billingCode.value)
    } else {
      setBillingCodeId(null)
    }
  }

  const billingCodes = useGetIOBillingCodes()

  if(billingCodes.error) return <SomethingBroke message='Failed to get billing codes' error={billingCodes.error.message} />
  if(billingCodes.isLoading || billingCodes.isFetching) return <Loading />
  return (
    <Flex flexFlow='column'>
      <Flex alignItems='center' mb='2rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Internal Billing Codes
        </Text>
        <Spacer />
        <Button
          as={ReactLink}
          backgroundColor='resolveOrange'
          size='sm'
          color='white'
          to='/settings/io-billing-codes/add'
        >
          Add Internal Billing Code
        </Button>
      </Flex>

      <BCSelectList onChange={onChange} billingCodes={billingCodes.data} selectRef={selectRef} />

      {billingCodeId && (
        <EditBillingCode
          mt='1rem'
          billingCodeId={billingCodeId}
          selectRef={selectRef}
        />
      )}
    </Flex>
  )
}
