import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

const getQuoteType = async (context) => {
  const { signal, queryKey } = context
  const [_, quoteTypeId] = queryKey
  const { data: quoteType } = await axiosClient({
    url: `/settings/quote-types/${quoteTypeId}`,
    signal
  })
  return quoteType
}

export const useGetQuoteType = ({ quoteTypeId, onSuccess }) => {
  return useQuery(['settings-quote-type', quoteTypeId], {
    queryFn: getQuoteType,
    placeholderData: {},
    onSuccess
  })
}