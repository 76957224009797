import { Route, Routes } from 'react-router-dom'

import { EditIO } from 'features/Internal/components/Item/Edit'
import { AddIO } from 'features/Internal/components/IO/Add'
import { Container as IOListContainer } from 'features/Internal/components/IO/List/Container'
import { Container as IOItemListContainer } from 'features/Internal/components/Item/List/Container'
import { AddItem } from 'features/Internal/components/Item/Add'
import { Invoice } from 'features/Internal/components/Item/Invoice'
import { IONavigation } from 'features/Navigation/IONavigation'

export const Left = () => (
  <Routes>
    <Route
      exact
      path=':ioId/edit/:itemId'
      element={<EditIO />}
    />
    <Route
      path=':ioId/add'
      element={<AddItem />}
    />
    <Route
      exact
      path=':ioId/edit/:itemId/invoice'
      element={<Invoice />}
    />
    <Route path='add' element={<AddIO />} />
    <Route path=':ioId?' element={<IOListContainer />} />
  </Routes>
)

export const Right = () => (
  <Routes>
    {
      ['/', '/add'].map((path, i) => (
        <Route
          exact
          key={i}
          path={path}
          element={<IONavigation />}
        />
      ))
    }

    {
      [':ioId/edit/:itemId', ':ioId'].map((path, i) => (
        <Route
          exact
          path={path}
          key={i}
          element={<IOItemListContainer />}
        />
      ))
    }
  </Routes>
)