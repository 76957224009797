import { useQuery } from "react-query"
import { axiosClient } from "lib/axiosClient"

const getGeneralSettings = async (context) => {
  const { signal } = context
  const { data: { general } } = await axiosClient({
    url: '/settings/general',
    signal
  })
  return {
    VAT: general.find(s => s.label === 'VAT').value,
    company_address: general.find(s => s.label === 'company_address').value,
    mark_up_warn_perc: general.find(
      s => s.label === 'mark_up_warn_perc'
    ).value,
    mark_up_warn_mail: general.find(
      s => s.label === 'mark_up_warn_mail'
    ).value
 } 
}

export const useGetGeneralSettings = ({ onSuccess }) => {
  return useQuery(['settings-general'], {
    queryFn: getGeneralSettings,
    onSuccess
  })
}