import { FormControl } from '@chakra-ui/react'
import { ControlledSelect } from './ControlledSelect'
import { axiosClient } from 'lib/axiosClient'
import { useQuery } from 'react-query'

const getSubscriptionPlaymentPlans = async (context) => {
  const { signal } = context
  const { data: { paymentPlans } } = await axiosClient({
    url: '/settings/payment-plans',
    signal
  })
  return paymentPlans
}
const useGetSubscriptionPaymentPlans = () => {
  return useQuery(['form-payment-plans'], {
    queryFn: getSubscriptionPlaymentPlans,
    placeholderData: [],
    staleTime: Infinity
  })
}
export const PaymentPlanSelectInput = ({
  control,
  name,
  required,
  selectedOptionColor = 'resolveOranger',
  placeholder = 'Payment Plan',
  ...props
}) => {
  const paymentPlans = useGetSubscriptionPaymentPlans()
  return (
    <FormControl {...props}>
      <ControlledSelect
        name={name}
        control={control}
        options={paymentPlans.data}
        isLoading={paymentPlans.isLoading}
        rules={{ required }}
        placeholder={placeholder}
        selectedOptionColor={selectedOptionColor}
      />
    </FormControl>
  )
}
