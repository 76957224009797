import { Center, Flex, Spinner, Text } from '@chakra-ui/react'

const LoadingProfile = () => (
  <Center height='50rem'>
    <Flex>
      <Center>
        <Spinner thickness='5px' emptyColor='gray.200' color='resolveOrange' />
      </Center>
      <Text ml='1rem' fontSize='large' color='resolveOrange'>
        Loading Quotes Profile
      </Text>
    </Flex>
  </Center>
)

export default LoadingProfile
