import { Text, Flex, Button, FormLabel } from '@chakra-ui/react'
import { useForm, useWatch } from 'react-hook-form'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { SupplierProductTypeSelectInput } from 'components/Form/SupplierProductTypeSelectInput'
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput'
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput'
import { ItemUnitPriceInput } from 'components/Form/ItemUnitPriceInput'
import { ItemMarkupInput } from 'components/Form/ItemMarkupInput'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import { MinimumTermSelectInput } from 'components/Form/MinimumTermSelectInput'
import { PaymentPlanSelectInput } from 'components/Form/PaymentPlanSelectInput'
import { ItemQuantityInput } from 'components/Form/ItemQuantityInput'
import { useCreateProduct } from 'features/Settings/api/createProduct'
import { ItemClientCostInput } from 'components/Form/ItemClientCostInput'

const FormHeader = () => {
  return (
    <Flex flexFlow='row' mt='2rem' mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        Add Set Product
      </Text>
    </Flex>
  )
}

const ProductState = {
  supplier_id: { label: '', value: 0 },
  id: 0,
  description: '',
  part_code: '',
  unit_price: '0.00',
  mark_up_percentage: 45,
  delivery_price: '0.00',
  quantity: 1,
  type_id: 1,
  minimum_term_id: 0,
  payment_plan_id: 0,
  fixed_minimum_term_id: 0,
  client_cost: 0
}

export const AddProductForm = () => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues: ProductState })

  const typeId = watch('type_id') 

  const createProduct = useCreateProduct()
  const onSubmit = form => {
    createProduct.mutate({ form })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader />
      <SupplierSelectInput
        control={control}
        name='supplier_id'
        errors={errors}
        register={undefined}
        required
        ignoreOtherSupplier={undefined}
      />
      <SupplierProductTypeSelectInput
        control={control}
        name='type_id'
        errors={errors}
      />

      <ItemDescriptionInput
        register={register}
        name='description'
        errors={errors}
        required
        rules={undefined}
      />

      {typeId === 2 && (
        <>
          <FormLabel mt='1rem'>
            Subscription Options
          </FormLabel>
          <Flex>
            <MinimumTermSelectInput
              required
              control={control}
              errors={errors}
              name='minimum_term_id'
              mr='1rem'
            />
            <PaymentPlanSelectInput
              control={control}
              errors={errors}
              name='payment_plan_id'
              required
            />
          </Flex>
        </>
      )}

      <ItemPartCodeInput
        showLabel
        register={register}
        name='part_code'
        mt='1rem'
        errors={errors}
      />
      
      <Flex mt='1rem'>
        <ItemQuantityInput 
          control={control}
          name='quantity'
          mr='1rem'
          required
          errors={errors}
        />
        <ItemMarkupInput
          control={control}
          name='mark_up_percentage'
          mr='1rem'
          errors={errors}
          isDisabled={false}
          setValue={setValue}
          required
        />

        <ItemDeliveryPriceInput
          control={control}
          name='delivery_price'
          errors={errors}
          required={undefined}
        />
      </Flex>

      <Flex mt='1rem'>
        <ItemUnitPriceInput
          isDisabled={false}
          control={control}
          name='unit_price'
          errors={errors}
          mr='1rem'
          required
          setValue={setValue}
        />
        <ItemClientCostInput 
          control={control}
          name='client_cost'
          errors={errors}
          isDisabled={false} 
          setValue={setValue}
          required
        />
      </Flex>

      <Button
        type='submit'
        isLoading={createProduct.isLoading}
        mt='1rem'
        color='white'
        backgroundColor='resolveOrange'
      >
        Save
      </Button>
    </form>
  )
}
