import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation, useQueryClient } from "react-query";

const deleteTeam = async ({ teamId }) => {
  await axiosClient({
    url: `/settings/teams/${teamId}`,
    method: 'delete'
  })
}

export const useDeleteTeam = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(deleteTeam, {
    onSuccess: () => {
      toastSuccess('Team deleted successfully')
      queryClient.invalidateQueries('settings-teams') 
    },
    onError: () => {
      toastFail('Failed to delete team')
    }
  })
}