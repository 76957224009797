import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation } from "react-query"
import { useNavigate } from "react-router"

export const setBulkInvoiced = async ({ items, form }) => {
  await axiosClient({
    url: '/quotes/bulk/invoice',
    method: 'post',
    data: {items, form}
  })
}
export const useBulkInvoice = quoteId => {
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(setBulkInvoiced, {
    onSuccess: () => {
      history(`/quotes/${quoteId}`)
      toastSuccess('Items invoiced successfully')
    },
    onError: () => {
      toastFail('Failed to invoice items')
    }
  })
}
