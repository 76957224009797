import React from 'react'
import SomethingBroke from './SomethingBroke'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: {},hasError: false }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    console.log(error)
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState(s => ({ ...s, error}))
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <SomethingBroke error={this.state.error.message} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
