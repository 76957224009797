import { useCallback, useContext, useState } from 'react'
import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom'
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react'
import {
  Link,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Text,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Icon
} from '@chakra-ui/react'
import { Search2Icon } from '@chakra-ui/icons'
import { UserContext } from 'features/Auth/context/user.context'
import UserAvatar from 'components/UserAvatar'
import { ColorModeSwitcher } from 'components/ColorModeSwitcher'

const AddSubButton = () => (
  <Button     
    borderRadius='5px'
    _hover={{ color: 'resolveGreen' }}
    variant='ghost'
    as={ReactLink}
    to='/subs/add'
    color='resolveGreen'
  >
  Add Sub
</Button>
)

const AddIOButton = () => (
  <Button     
    borderRadius='5px'
    _hover={{ color: 'resolveBlue' }}
    variant='ghost'
    color='resolveBlue'
    as={ReactLink}
    to='/internal/add'
  >
    Add IO
  </Button>
)

const AddTemplateButton = () => (
  <Button     
    borderRadius='5px'
    _hover={{ color: 'resolvePurple' }}
    as={ReactLink}
    to='/templates/add'
    variant='ghost'
    color='resolvePurple'
  >
  Add Template
</Button>
)

const AddQuoteButton = () => (
  <Menu>
    <MenuButton
      borderRadius='5px'
      _hover={{ color: 'resolveOrange' }}
      as={Button}
      variant='ghost'
      color='resolveOrange'
    >
      Add Quote
    </MenuButton>
    <MenuList>
      <Link
        as={ReactLink}
        _hover={{ color: 'resolveOrange' }}
        to='/quotes/add'
      >
        <MenuItem
          _hover={{ backgroundColor: 'resolveOrange', color: 'white' }}
        >
          New Quote
        </MenuItem>
      </Link>
      <Link
        as={ReactLink}
        _hover={{ color: 'resolveOrange' }}
        to='/quotes/add/template'
      >
        <MenuItem
          _hover={{ backgroundColor: 'resolveOrange', color: 'white' }}
        >
          Template
        </MenuItem>
      </Link>
    </MenuList>
  </Menu>
)

export const AuthenticatedNavigation = props => {
  const { instance } = useMsal()
  const { engineer } = useContext(UserContext)
  const history = useNavigate()
  const [search, setSearch] = useState('')
  const location = useLocation()
  const handleLogout = () => {
    instance.logout()
  }

  const handleSearch = useCallback(() => {
    history('/search', { state: { search } })
    setSearch('')
  }, [search, history])

  const handleEnter = useCallback(e => {
    if (e.key === 'Enter') {
      history('/search', { state: { search }})
      setSearch('')
    }
  }, [search, history])

  return (
    <AuthenticatedTemplate>
      <Flex
        direction='row'
        flexGrow='1'
        ml='5rem'
      >
        <Flex alignItems='center'>
          <InputGroup>
            <Input
              focusBorderColor='resolveOrange'
              onKeyPress={handleEnter}
              variant='filled' onChange={e => setSearch(e.target.value)} value={search}
            />
            <InputLeftElement>
              <Icon onClick={handleSearch} as={Search2Icon} color='resolveOrange' />
            </InputLeftElement>
          </InputGroup>
        </Flex>
        <Flex alignItems='center' ml='1rem'>
          <Link as={ReactLink} _hover={{ color: 'resolveOrange' }} to='/search'>
            <Text fontSize='sm'>
              Advanced Search
            </Text>
          </Link>
        </Flex>
        <Spacer />
        <Flex alignItems='center' flexBasis='40%' mr='4rem' ml='4rem' justifyContent='space-between'>
          {
            location.pathname.match('internal') ? 
                <AddIOButton /> : <AddQuoteButton />
          }
          <Link as={ReactLink} _hover={{ color: 'resolveOrange' }} to='/quotes'>
            Quotes
          </Link>
          <Link as={ReactLink} _hover={{ color: 'resolveBlue' }} to='/internal'>
            Internal
          </Link>
          {/* <Link as={ReactLink} _hover={{ color: 'resolveGreen' }} to='/subs'>
            Subs
          </Link> */}
          {/* <Link as={ReactLink} _hover={{ color: 'resolvePurple' }} to='/templates'>
            Templates
          </Link> */}
          <Menu>
            <MenuButton
              borderRadius='5px'
              _hover={{ color: 'resolveOrange' }}
              as={Link}
            >
              Reports
            </MenuButton>
            <MenuList>
              <Link
                as={ReactLink}
                _hover={{ color: 'resolveOrange' }}
                to='/reports/ap'
              >
                <MenuItem
                  _hover={{ backgroundColor: 'resolveOrange', color: 'white' }}
                >
                  A&P'd / Invoiced
                </MenuItem>
              </Link>
              <Link
                as={ReactLink}
                _hover={{ color: 'resolveOrange' }}
                to='/reports/sales'
              >
                <MenuItem
                  _hover={{ backgroundColor: 'resolveOrange', color: 'white' }}
                >
                  Sales
                </MenuItem>
              </Link>
              <Link
                as={ReactLink}
                _hover={{ color: 'resolveOrange' }}
                to='/reports/quotation'
              >
                <MenuItem
                  _hover={{ backgroundColor: 'resolveOrange', color: 'white' }}
                >
                  Quotation
                </MenuItem>
              </Link>
            </MenuList>
          </Menu>
          <Link as={ReactLink} _hover={{ color: 'resolveOrange' }} to='/settings'>
            Settings
          </Link>
        </Flex>
        <Flex justifyContent='center' alignItems='center' mr='1rem'>
          <Menu>
            {engineer && (
              <Flex flexFlow='column' mr='1rem'>
                <Text textAlign='right' fontWeight='light' color='resolveOrange'>
                  {engineer.msal.givenName}
                </Text>
                <Text textAlign='right' fontWeight='bold' fontSize='0.6rem'>
                  {engineer.msal.jobTitle}
                </Text>
                {engineer.permissions.isAdmin && (
                  <Text
                    textAlign='right'
                    fontSize='xx-small'
                    fontWeight='light'
                    color='resolveOrange'
                  >
                    Administrator
                  </Text>
                )}
              </Flex>
            )}
            <MenuButton
              borderRadius='30px'
              as={IconButton}
              icon={<UserAvatar />}
            />
            <MenuList>
              <MenuItem
                _hover={{ backgroundColor: 'resolveOrange', color: 'white' }}
                onClick={handleLogout}
              >
                Log Out
              </MenuItem>
            </MenuList>
          </Menu>
          <ColorModeSwitcher ml='2rem' />
        </Flex>
      </Flex>
    </AuthenticatedTemplate>
  )
}
