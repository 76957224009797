import { axiosClient } from "lib/axiosClient";
import { useMutation } from "react-query";
import { useResolveToast } from "hooks/useResolveToast";
import { useQueryClient } from "react-query";

const updatePaymentMethod = async ({ paymentMethod }) => {
  await axiosClient({
    url: '/settings/payment-methods',
    method: 'patch',
    data: {
      ...paymentMethod,
      hidden: paymentMethod.hidden ? 0 : 1
    }
  })
}

export const useUpdatePaymentMethod = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updatePaymentMethod, {
    onSuccess: () => {
      toastSuccess('Payment method successfully updated')  
      queryClient.invalidateQueries('settings-payment-methods')
    },
    onError: () => {
      toastFail('Failed to update payment method') 
    }
  })
}