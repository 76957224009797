import { FormControl } from "@chakra-ui/react"
import { ControlledSelect } from "./ControlledSelect"

export const SupplierProductTypeSelectInput = ({control, errors, name}) => {
    return (
        <FormControl mb="1rem" isInvalid={!!errors[name]}>
            <ControlledSelect 
                isLoading={false}
                options={[{ value: 1, label: 'Goods'}, { value: 2, label: 'Subscription (Anything billed monthly)'}]}
                name={name}
                control={control}
                selectedOptionColor='resolveOranger'
                id='product'
                rules={true}
                placeholder='Select product type'
            />
        </FormControl>
    )
}