import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Text
} from '@chakra-ui/react'
import { useWatch } from 'react-hook-form'

import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { SupplierProductTypeSelectInput } from 'components/Form/SupplierProductTypeSelectInput'
import { SetProductSelectInput } from 'components/Form/SetProductSelectInput'
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput'
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput'
import { ItemInternalDescriptionInput } from 'components/Form/ItemInternalDescriptionInput'
import { ItemQuantityInput } from 'components/Form/ItemQuantityInput'
import { ItemUnitPriceInput } from 'components/Form/ItemUnitPriceInput'
import { ItemMarkupInput } from 'components/Form/ItemMarkupInput'
import { ItemClientCostInput } from 'components/Form/ItemClientCostInput'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import { MinimumTermSelectInput } from 'components/Form/MinimumTermSelectInput'
import { PaymentPlanSelectInput } from 'components/Form/PaymentPlanSelectInput'

export const Form = ({
    control,
    handleSubmit,
    formSubmit,
    errors,
    register,
    setValue
}) => {
    const watchProductType = useWatch({ control, name: 'type_id' })
    const watchSupplier = useWatch({ control, name: 'supplier_id' })
    const watchSetProduct = useWatch({ control, name: 'set_product' })

    return (
        <form onSubmit={formSubmit(handleSubmit)}>
            <Text color='resolvePurple' mb='1rem' fontSize='2rem' fontWeight='600'>
                New Template
            </Text>

            <FormControl mb='1rem' isRequired>
                <FormLabel>Template Name</FormLabel>
                <Input {...register('template_name')} />
            </FormControl>

            <SupplierSelectInput
                name='supplier_id'
                register={register}
                ignoreOtherSupplier={false}
                required
                control={control}
                errors={errors}
                selectedOptionColor='resolvePurpler'
            />

            <SupplierProductTypeSelectInput
                name='type_id'
                errors={errors}
                control={control}
            />

            {watchProductType && watchSupplier && (
                <SetProductSelectInput
                    supplierId={watchSupplier}
                    type={watchProductType}
                    control={control}
                    name='set_product'
                    required
                />
            )}

            <Flex mb='1rem'>
                <ItemPartCodeInput
                    register={register}
                    errors={errors}
                    name='part_code'
                    showLabel
                    flexBasis='30%'
                    mr='1rem'
                />

                <ItemDescriptionInput
                    register={register}
                    errors={errors}
                    name='description'
                    required
                    rules={{
                        maxLength: {
                            value: 90,
                            message: 'Description must be under 90 characters'
                        }
                    }}
                />
            </Flex>

            <ItemInternalDescriptionInput
                register={register}
                errors={errors}
                name='internal_description'
            />

            <HStack spacing={3} mb='1rem'>
                <ItemQuantityInput
                    control={control}
                    required
                    errors={errors}
                    name='quantity'
                />
                <ItemUnitPriceInput
                    control={control}
                    required
                    errors={errors}
                    name='unit_price'
                    setValue={setValue}
                />
                <ItemMarkupInput
                    control={control}
                    required
                    errors={errors}
                    name='mark_up_percentage'
                    setValue={setValue}
                />
            </HStack>
            <Flex flexDir='row' mb='1rem'>
                <ItemClientCostInput
                    control={control}
                    required
                    errors={errors}
                    name='client_cost'
                    setValue={setValue}
                />
                <ItemDeliveryPriceInput
                    control={control}
                    required
                    errors={errors}
                    name='delivery_price'
                />
            </Flex>

            {watchProductType === '2' && watchSetProduct && (
                <>
                    <FormLabel mt='1rem'>
                        Subscription Options
                    </FormLabel>
                    <Flex>
                        <MinimumTermSelectInput
                            required
                            control={control}
                            errors={errors}
                            name='minimum_term_id'
                            mr='1rem'
                        />
                        <PaymentPlanSelectInput
                            required
                            control={control}
                            errors={errors}
                            name='payment_plan_id'
                        />
                    </Flex>
                </>
            )}

            <Button
                variant='solid'
                backgroundColor='resolvePurple'
                color='white'
                type='submit'
            >
                Add Template
            </Button>
        </form>
    )
}
