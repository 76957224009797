import { useState, createContext, useEffect } from "react";
import { useSteps } from "chakra-ui-steps";
export const StepContext = createContext({
    nextStep: () => {},
    prevStep: () => {},
    reset: () => {},
    lastStep: false,
    complete: false,
})



const useStepper = () => {

    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });

    return {
        nextStep,
        prevStep,
        reset,
        activeStep
    }
}

export const StepContextProvider = ({ children }) => { 
    const steps = useStepper()
    return (
        <StepContext.Provider value={steps}>
            {children}
        </StepContext.Provider>
    )
}