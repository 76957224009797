import { createContext, useEffect, useState } from 'react'
import { PAGE_SIZE } from 'config'
import { useLocation } from 'react-router-dom'
import { useQuoteSearch } from '../hooks/useQuoteSearch'

export const SearchContext = createContext([{}, () => {}])

export const SearchContextProvider = ({ children }) => {
  const [page, setPage] = useState(1)
  const [searchState, setSearchState] = useState(null)
  const { state: stateFromRouter } = useLocation()
  const { resultsCount, search } = useQuoteSearch({ searchState, page })

  useEffect(() => {
    if(stateFromRouter) {
      setSearchState(stateFromRouter)
    }
  }, [stateFromRouter])

  const searchContextState = {
    results: search.data.quotes,
    resultCount: resultsCount.data?.resultCount || 1,
    error: search.error,
    page,
    setPage,
    pageSize: PAGE_SIZE,
    searchState: searchState,
    isSearching: search.isLoading || search.isFetching
  }

  return (
    <SearchContext.Provider value={searchContextState}>
      {children}
    </SearchContext.Provider>
  )
}
