import { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Flex, Spacer } from '@chakra-ui/react'
import moment from 'moment'

import { UserSelectInput } from 'components/Form/UserSelectInput'
import { DateInput } from './components/DateInput'
import { IONumberInput } from './components/IONumberInput'
import { ActionButtons } from './components/ActionButtons'

export const SearchFilter = ({ setPage, filter, setFilter }) => {
  const [date, setDate] = useState({
    selectedDay: undefined
  })

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      ioId: filter.ioId,
      engineer: filter.engineer,
      date: filter.date
    }
  })

  const handleFilterReset = () => {
    const cleanFilter = { engineer: '', date: '', ioId: '' }
    setPage(1)
    setDate({ selectedDay: '' })
    setFilter(cleanFilter)
    reset(cleanFilter)
  }

  const handleFilterSubmit = useCallback(
    form => {
      let d
      if (date.selectedDay) {
        d = new Date(date.selectedDay)
        d.setHours(2)
      }

      const normalized = {
        ...form,
        engineer: form.engineer?.value?.toString() || '',
        date: date.selectedDay ? moment(d).format('YYYY-MM-DD') : ''
      }
      setFilter(normalized)
    },
    [setFilter, date]
  )

  const handleDayChange = date => {
    setDate({ selectedDay: date })
  }

  return (
    <form onSubmit={handleSubmit(handleFilterSubmit)}>
      <Flex justifyContent='space-between'>
        <IONumberInput register={register} name='ioId' />
        <DateInput
          name='date'
          handleDayChange={handleDayChange}
          date={date}
        />
        <UserSelectInput
          errors={errors}
          showLabel={false}
          control={control}
          validation={true}
          mr='1rem'
          name='engineer'
          filter={item => true}
          selectedOptionColor='resolveBluer'
        />
        <Spacer />
        <ActionButtons handleFilterReset={handleFilterReset} />
      </Flex>
    </form>
  )
}
