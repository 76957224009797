import { Flex, Text } from '@chakra-ui/react'

export const Quantity = ({ quantity }) => (
  <Flex
    basis='10%'
    pointerEvents='none'
    justifyContent='center'
    alignItems='center'
    mr='1rem'
  >
    <Text pointerEvents='none'>{Math.round(Number(quantity), 1)} X</Text>
  </Flex>
)
