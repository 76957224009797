import { useForm } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  Text,
  Input,
  Button,
  Spacer,
  Flex
} from '@chakra-ui/react'
import { useGetMailSettings } from 'features/Settings/api/getMailSettings'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Loading } from 'components/Loading'
import { useUpdateMailSettings } from 'features/Settings/api/updateMailSettings'

const initialState = {
  smtp_host: '',
  smtp_port: '',
  from_mail: '',
  feedback_mail: ''
}

export const MailSettingsContainer = () => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: initialState
  })

  const onSuccess = settings => {
    reset(settings)
  }
  const mailSettings = useGetMailSettings({ onSuccess })

  const updateSettings = useUpdateMailSettings()
  const handleSave = form => {
    updateSettings.mutate({ form })
  }

  if(mailSettings.error) return <SomethingBroke message='Failed to fetch mail settings' error={mailSettings.error.message} />
  if(mailSettings.isLoading || mailSettings.isFetching) return <Loading />
  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        Mail Settings
      </Text>
      <FormControl mt='2rem' mb='1rem'>
        <FormLabel color='resolveOrange'>SMTP Server</FormLabel>
        <Input {...register('smtp_host')} />
      </FormControl>
      <FormControl mb='1rem'>
        <FormLabel color='resolveOrange'>SMTP Port</FormLabel>
        <Input {...register('smtp_port')} />
      </FormControl>
      <FormControl mb='1rem'>
        <FormLabel color='resolveOrange'>From Address</FormLabel>
        <Input {...register('from_mail')} />
      </FormControl>
      <FormControl>
        <FormLabel color='resolveOrange'>Feedback Mail</FormLabel>
        <Input {...register('feedback_mail')} />
      </FormControl>
      <Flex mt='2rem'>
        <Button type='submit' isLoading={updateSettings.isLoading} backgroundColor='resolveOrange' color='white'>
          Save
        </Button>
        <Spacer />
        {/* TODO: Implement mail test button -> send test message */}
        <Button isDisabled backgroundColor='resolveOrange' color='white'>
          Test
        </Button>
      </Flex>
    </form>
  )
}
