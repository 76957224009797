import { axiosClient } from "lib/axiosClient";
import { useQuery } from "react-query";

const getBuildTier = async (context) => {
  const { signal, queryKey } = context
  const [_key, buildTierId] = queryKey
  const { data: team } = await axiosClient({
    url: `/settings/build-tiers/${buildTierId}`,
    signal
  })
  return team
}

export const useGetBuildTier = ({ buildTierId, onSuccess }) => {
  return useQuery(['settings-build-tier', buildTierId], {
    queryFn: getBuildTier,
    placeholderData: { label: '', value: '' },
    onSuccess
  })
}