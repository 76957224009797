import { FormControl, FormLabel, Input } from '@chakra-ui/react'

export const LicenseCountInput = ({
  register,
  name,
}) => {
  return (
    <FormControl mr='1rem'>
      <FormLabel>Number of Licenses</FormLabel>
      <Input {...register(name)} />
    </FormControl>
  )
}
