import { FormControl, Flex } from '@chakra-ui/react'

import { DatePicker } from 'components/DatePicker'

export const DateInput = ({
  name,
  handleDayChange,
  date,
  placeholder = 'Select date'
}) => {
  return (
    <Flex mr='1rem' flexBasis='30%'>
      <FormControl id={name}>
        <DatePicker
          selectedDate={date.selectedDay}
          placeholder={placeholder}
          onChange={handleDayChange}
          isClearable
        />
      </FormControl>
    </Flex>
  )
}
