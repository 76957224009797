import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'

export const UsernameInput = ({ required, register, name, errors }) => {
  return (
    <FormControl isInvalid={errors[name]} isRequired={required} mb='1rem'>
      <FormLabel>Username</FormLabel>
      <Input {...register(name, { required, maxLength: { value: 250, message: 'Username too long' } })} />
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  )
}
