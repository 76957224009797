import { Button, Flex, Text } from '@chakra-ui/react'
import { ClientPOInput } from 'components/Form/ClientPOInput'
import { ItemClientCostInput } from 'components/Form/ItemClientCostInput'
import { ItemDeliveryPriceInput } from 'components/Form/ItemDeliveryPriceInput'
import { ItemPartCodeInput } from 'components/Form/ItemPartCodeInput'
import { ItemQuantityInput } from 'components/Form/ItemQuantityInput'
import { PaymentMethodSelectInput } from 'components/Form/PaymentMethodSelectInput'
import { SupplierSelectInput } from 'components/Form/SupplierSelectInput'
import { ItemDescriptionInput } from 'components/Form/ItemDescriptionInput'
import { IOBillingCodeSelect } from 'components/Form/IOBillingCodeSelect'
import { AuthSelectInput } from 'components/Form/AuthSelectInput'

export const Form = ({
  formSubmit,
  handleSubmit,
  nextOrderId,
  control,
  register,
  errors
}) => {
  return (
    <form onSubmit={formSubmit(handleSubmit)}>
      <Text color='resolveBlue' mr='1rem' fontSize='2rem' fontWeight='600'>
        New Internal Order - IO{nextOrderId}
      </Text>
      <SupplierSelectInput
        mt='2rem'
        control={control}
        ignoreOtherSupplier={false}
        name='supplier_id'
        errors={errors}
        selectedOptionColor='resolveBluer'
        register={register}
        required
      />

      <ItemDescriptionInput
        register={register}
        errors={errors}
        rules={{ maxLength: { value: 245, message: 'Description must be less than 245 characters' }}}
        showLabel
        name='item_name'
        mb='1rem'
        required
      />


      <Flex dir='row' mb='1rem' mt='1rem'>
        <ItemQuantityInput
          name='quantity'
          errors={errors}
          control={control}
          mr='1rem'
          required
        />
        <ItemClientCostInput
          name='cost_price'
          errors={errors}
          setValue={null}
          required
          control={control}
          labelText='Cost Price'
          mr='1rem'
        />
        <ItemDeliveryPriceInput
          name='delivery_price'
          required
          errors={errors}
          control={control}
        />
      </Flex>

      <Flex>
        <PaymentMethodSelectInput
          name='payment_method_id'
          control={control}
          errors={errors}
          required
          mr='1rem'
          selectedOptionColor='resolveBluer'
        />
        <IOBillingCodeSelect
          name='io_billing_code_id'
          control={control}
          errors={errors}
          required
          selectedOptionColor='resolveBluer'
          labelText='Billing Code'
        />
      </Flex>

      <Flex>
        <ItemPartCodeInput
          register={register}
          errors={errors}
          showLabel
          mt='1rem'
          name='part_code'
          mr='1rem'
        />
        <ClientPOInput
          register={register}
          required={false}
          name='supplier_order_number'
          mt='1rem'
          labelText='Supplier Order Number'
        />
      </Flex>

      <AuthSelectInput
        name='authorized_by_id'
        errors={errors}
        control={control}
        labelText='Authorized By'
        required
        selectedOptionColor='resolveBluer'
        mt='1rem'
        mb='1rem'
      />

      <Button
        variant='solid'
        backgroundColor='resolveBlue'
        color='white'
        _hover={{ bg: 'gray', color: 'resolveBlue' }}
        type='submit'
        mt='1rem'
      >
        Add
      </Button>
    </form>
  )
}
