import { useResolveToast } from "hooks/useResolveToast";
import { axiosClient } from "lib/axiosClient";
import { useMutation, useQueryClient } from "react-query";

const updateBuildTier = async ({ buildTierId, form }) => {
  await axiosClient({
    url: `/settings/build-tiers/${buildTierId}`,
    method: 'patch',
    data: form
  })
}

export const useUpdateBuildTier = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(updateBuildTier, {
    onSuccess: async () => {
      toastSuccess('Build tier updated successfully')
      await queryClient.invalidateQueries({ queryKey: [ 'settings-build-tiers' ] })
    },
    onError: () => {
      toastFail('Failed to update build tier')
    }
  })
}