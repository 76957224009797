import { Flex } from '@chakra-ui/react'

import { Engineer } from './Engineer'
import { ListItemDateAdded } from './DateAdded'
import { Description } from './Description'
import { LicenseCount } from './LicenseCount'
import { CompanyName } from './CompanyName'
import { SubType } from './SubType'

export const ListItem = ({ sub, handleSubClick, isSelected }) => {
  return (
    <Flex
      onClick={handleSubClick}
      id={sub.sub_id}
      style={isSelected ? { backgroundColor: '#3EBFAC', color: 'white' } : null}
      height='4rem'
      alignItems='center'
      _hover={{
        boxShadow: 'rgba(0, 0, 0, .1) 0 0 0 1000000px inset',
        cursor: 'pointer'
      }}
      paddingLeft='1rem'
      alignContent='space-evenly'
    >
      <CompanyName companyName={sub.company_name} />
      <SubType type={sub.label} />
      <Description description={sub.sub_name} />
      <LicenseCount licenseCount={sub.num_licenses} />
      <ListItemDateAdded date={sub.date_first_renewal} />
      <Engineer engineer={sub.ad_username} blob={sub.picture_blob} />
    </Flex>
  )
}
