import { useForm } from 'react-hook-form'
import {
  Text,
  Flex
} from '@chakra-ui/react'
import { Form } from './Form'
import { useTestAutotaskAPI } from 'features/Settings/api/testAutotaskAPI'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { useGetAutotaskSettings } from 'features/Settings/api/getAutotaskSettings'
import { Loading } from 'components/Loading'
import { useUpdateAutotaskSettings } from 'features/Settings/api/updateAutotaskSettings'

const autotaskSettingState = {
  defaultRoleId: ''
}

export const AutotaskSettingsContainer = () => {
  const { register, reset, handleSubmit } = useForm({
    defaultValues: autotaskSettingState
  })

  const testAutotaskAPI = useTestAutotaskAPI()

  const onSuccess = data => {
    reset(data)
  }
  const autotaskSettings = useGetAutotaskSettings({ onSuccess })
  const updateAutotaskSettings = useUpdateAutotaskSettings()

  const handleUpdate = form => {
    updateAutotaskSettings.mutate({ form })
  }

  if(autotaskSettings.isLoading || autotaskSettings.isFetching) return <Loading />
  if(autotaskSettings.error) return <SomethingBroke message='Failed to load Autotask settings' error={autotaskSettings.error.message} />
  return (
    <>
      <Flex flexFlow='column'>
        <Flex alignItems='center'>
          <Text
            color='resolveOrange'
            mr='1rem'
            fontSize='2rem'
            fontWeight='600'
          >
            Autotask
          </Text>
        </Flex>
      </Flex>
      <Form register={register} handleSubmit={handleSubmit} handleUpdate={handleUpdate} testAutotaskAPI={testAutotaskAPI} />
    </>
  )
}
