import { useNavigate } from 'react-router'
import {
  Flex,
  Button
} from '@chakra-ui/react'

import { PopoverButton } from 'components/PopoverButton'

export const Actions = ({ ioId, handleDeleteOrder }) => {
  const history = useNavigate()

  const handleAddIoClick = () => {
    history(`/internal/${ioId}/add`)
  }

  return (
    <Flex flexWrap='wrap' ml='1rem' mb='1rem' justifyContent='flex-end'>
      <Button
        backgroundColor='resolveBlue'
        onClick={handleAddIoClick}
        size='sm'
        mb='1rem'
        color='white'
        ml='1rem'
      >
        Add Item
      </Button>
      <PopoverButton
        isLoading={false}
        title='Delete Order'
        ml='1rem'
        isDisabled
        handleClick={handleDeleteOrder}
        message='Are you sure? This will delete the order and all items'
        buttonTitle='Delete'
        size='sm'
        variant='outline'
      />
    </Flex>
  )
}
