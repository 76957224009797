import { useForm } from 'react-hook-form'

import { Text, Button } from '@chakra-ui/react'
import { TeamNameInput } from 'components/Form/TeamNameInput'
import { useCreateTeam } from 'features/Settings/api/createTeam'

export const AddTeamForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const createTeam = useCreateTeam()
  const handleAddTeam = async form => {
    createTeam.mutate({ form })
  }

  return (
    <form onSubmit={handleSubmit(handleAddTeam)}>
      <Text
        color='resolveOrange'
        mr='1rem'
        mb='1rem'
        fontSize='2rem'
        fontWeight='600'
      >
        Add Team
      </Text>
      <TeamNameInput register={register} errors={errors} name='name' />
      <Button
        mt='1rem'
        color='white'
        type='submit'
        backgroundColor='resolveOrange'
      >
        Save
      </Button>
    </form>
  )
}
