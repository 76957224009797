import { Flex, Text } from '@chakra-ui/react'

export const Header = ({ name }) => {
  return (
    <Flex flexFlow='row' mt='2rem' mb='1rem'>
      <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
        {name}
      </Text>
    </Flex>
  )
}
