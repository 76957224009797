import { axiosClient } from "lib/axiosClient";
import { useQuery } from "react-query";

const getUser = async (context) => {
  const { queryKey, signal } = context
  const [_key, userId] = queryKey
  const { data: user } = await axiosClient({
    url: `/settings/users/${userId}`,
    signal 
  })
  return user
}

export const useGetUser = ({ userId, onSuccess }) => {
  return useQuery(['settings-user', userId], {
    queryFn: getUser,
    onSuccess
  })
}