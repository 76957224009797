import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

export const deleteTemplate = async ({templateId}) => {
  await axiosClient({
    url: `/quotes/templates/${templateId}`,
    method: 'delete'
  })
}

export const useDeleteTemplate = () => {
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(deleteTemplate, { 
    onSuccess: () => {
      toastSuccess('Quote template deleted successfully')
      queryClient.invalidateQueries('templates')
      history('/templates')
    },
    onError: () => {
      toastFail('Failed to delete quote template')
    }
  })
}