import { useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { UserContext } from 'features/Auth/context/user.context'
import { Form } from './Form'
import { useCreateTemplate } from 'features/Templates/api/createTemplate'
import { useGetSupplier } from 'features/Quotes/api/getSupplier'
import { useGetProduct } from 'features/Quotes/api/getProduct'

const INITIAL_STATE = {
  client_id: '',
  engineer_id: 0,
  quantity: 1,
  unit_price: 0.00,
  mark_up_percentage: 45,
  delivery_price: 0,
  other_supplier: '',
  client_cost: 0.00,
  internal_description: '',
  type_id: { value: 1, label: 'Goods'},
  description: '',
  subscription_id: '',
  minimum_term_id: null,
  payment_plan_id: null,
  part_code: '',
  set_product: null,
  supplier_id: null
}

export const AddTemplate = () => {
  const { engineer } = useContext(UserContext)

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: INITIAL_STATE
  })

  const productId = watch('set_product')
  const productTypeId = watch('type_id')
  const supplierId = watch('supplier_id')

  const createTemplate = useCreateTemplate()

  const handleTemplateAdd = useCallback(form => {
    createTemplate.mutate({ form, engineerId: engineer.quotes.id })
  }, [engineer, createTemplate])

  const product = useGetProduct({ productId: productId?.value, onSuccess: product => {
    setValue('part_code', product.part_code)
    setValue('client_cost', product.client_cost)
    setValue('unit_price', product.unit_price)
    setValue('description', product.description)
    setValue('mark_up_percentage', product.mark_up_percentage)
    setValue('delivery_price', product.delivery_price)
    if(productTypeId?.value === 2) {
      setValue('minimum_term_id', product.minimum_term_id)
      setValue('payment_plan_id', product.payment_plan_id)
    }
  }})

  const supplier = useGetSupplier({ supplierId, productTypeId, onSuccess: supplier => {
    setValue('delivery_price', supplier.default_delivery)
  }})

  return (
    <Form
      formSubmit={handleSubmit}
      handleSubmit={handleTemplateAdd}
      control={control}
      register={register}
      errors={errors}
      setValue={setValue}
    />
  )
}
