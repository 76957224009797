import { useNavigate } from 'react-router-dom'
import { Flex, Box, Divider, Center, SlideFade } from '@chakra-ui/react'
import { ListItem } from './ListItem'
import { Loading } from 'components/Loading'
import SomethingBroke from 'components/Errors/SomethingBroke'

const Layout = ({ children }) => {
  return (
    <Flex direction='column' mt='1rem'>
      <SlideFade in>{children}</SlideFade>
    </Flex>
  )
}

const NoSubsFound = () => (
  <Layout>
    <Center>
      <div>No subscriptions found</div>
    </Center>
  </Layout>
)

export const SubList = ({ isFetching, isLoading, subs, subId, isError }) => {
  const history = useNavigate()

  const handleSubClick = e => {
    let targetSubId = ''
    if (e.target.id.length < 1) {
      targetSubId = e.target.parentElement.id
    } else {
      targetSubId = e.target.id
    }
    if (targetSubId !== subId) {
      history(`/subs/${targetSubId}`)
    }
  }

  if (isError) return <SomethingBroke message='Failed to get subs' error={isError.message} />
  if (isFetching || isLoading) return <Loading />
  if (subs.length < 1) return <NoSubsFound />
  
  return (
    <Layout>
      {subs.map((sub, i) => {
        return (
          <Box key={`${sub.sub_id}`}>
            <ListItem
              handleSubClick={handleSubClick}
              isSelected={Number(sub.sub_id) === Number(subId)}
              sub={sub}
            />
            <Divider />
          </Box>
        )
      })}
    </Layout>
  )
}
