import { Text } from '@chakra-ui/react'

export const Total = ({ total }) => (
  <Text
    pointerEvents='none'
    overflow='hidden'
    whiteSpace='nowrap'
    maxWidth='90%'
    textOverflow='ellipsis'
    fontWeight='bold'
    fontSize='sm'
  >
    {total}
  </Text>
)
