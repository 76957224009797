import { Checkbox } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'

export const CheckboxController = ({ control, name, text, ...restProps }) => (
  <Controller
    control={control}
    name={name}
    key={name}
    render={({ field: { ref, value, ...restField } }) => {
      return (
        <Checkbox
          isChecked={value}
          colorScheme='resolveOranger'
          ref={ref}
          {...restField}
          {...restProps}
        >
          {text}
        </Checkbox>
      )
    }}
  />
)
