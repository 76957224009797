import { Center, Spinner, Text } from '@chakra-ui/react'
import { Layout } from './Layout/Layout'

export const Loading = ({ color = 'resolveOrange' }) => {
  return (
    <Layout>
      <Center height='100px'>
        <Spinner thickness='5px' emptyColor='gray.200' color={color} />
        <Text ml='1rem'>Loading..</Text>
      </Center>
    </Layout>
  )
}
