const { useResolveToast } = require("hooks/useResolveToast")
const { axiosClient } = require("lib/axiosClient")
const { useMutation, useQueryClient } = require("react-query")

const deleteSupplier = async ({ supplierId }) => {
  await axiosClient({
    url: `/settings/suppliers/${supplierId}`,
    method: 'delete'
  }) 
}

export const useDeleteSupplier = () => {
  const { toastFail, toastSuccess } = useResolveToast()
  const queryClient = useQueryClient()
  return useMutation(deleteSupplier, {
    onSuccess: () => {
      toastSuccess('Supplier deleted successfully')
      queryClient.invalidateQueries(['settings-suppliers'])
      queryClient.invalidateQueries(['form-suppliers'])
    },
    onError: () => {
      toastFail('Failed to delete supplier')
    }
  })
}