import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Routes as Switch,
  Route,
} from 'react-router-dom'
import { Text, Flex, Spacer, Button, Input } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { EditBillingCode } from './Edit'
import { useUpdateBillingCodes } from 'features/Settings/api/updateBillingCodes'
import { useGetBillingCodes } from 'features/Settings/api/getBillingCodes'
import SomethingBroke from 'components/Errors/SomethingBroke'
import { Loading } from 'components/Loading'

const BCSelectList = ({ billingCodes, onChange, selectRef }) => {
  return (
    <Select
      selectedOptionColor='resolveOranger'
      onChange={onChange}
      isClearable
      options={billingCodes}
      ref={selectRef}
    />
  )
}

export const BCSettingsContainer = () => {
  return (
    <Switch>
      <Route path='/'element={<BillingCodes />} />
    </Switch>
  )
}

export const BillingCodes = () => {
  const [bc, setBc] = useState(null)

  const selectRef = useRef()

  const onChange = e => {
    setBc(e)
  }

  const updateBillingCodes = useUpdateBillingCodes()
  const billingCodes = useGetBillingCodes()

  if(billingCodes.isLoading) return <Loading />
  if(billingCodes.error) return <SomethingBroke message='Failed to get Billing Codes' error={billingCodes.error.message} />
  return (
    <Flex flexFlow='column'>
      <Flex alignItems='center' mb='2rem'>
        <Text color='resolveOrange' mr='1rem' fontSize='2rem' fontWeight='600'>
          Billing Codes
        </Text>
        <Spacer />
        <Button
          onClick={() => updateBillingCodes.mutate()}
          backgroundColor='resolveOrange'
          size='sm'
          isLoading={updateBillingCodes.isLoading}
          color='white'
        >
          Synchronise Billing Codes
        </Button>
      </Flex>

      <BCSelectList onChange={onChange} billingCodes={billingCodes.data} selectRef={selectRef} />

      {bc && (
        <EditBillingCode
          billingCodeId={bc.value}
          selectRef={selectRef}
        />
      )}
    </Flex>
  )
}
