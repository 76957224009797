
import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import moment from "moment"

export const updateItemBillingDetails = async ({itemId, form, engineerId }) => {
    const { 
        payment_method_id, 
        at_billing_code_id,
        client_pon,
        date_ordered,
        ordered_by,
        supplier_order_number
    } = form

    const nForm = {
        at_billing_code_id: at_billing_code_id.value,
        payment_method_id: payment_method_id.value,
        item_status_id: 7,
        ordered_by: ordered_by ? ordered_by : engineerId,
        client_pon,
        supplier_order_number,
        date_ordered: date_ordered
            ? moment(date_ordered).format('YYYY-MM-DD HH:mm:ss')
            : moment().format('YYYY-MM-DD HH:mm:ss'),
    }

    await axiosClient({
        url: `/quotes/item/billing/${itemId}`,
        method: 'patch', 
        data: nForm
    })
}

export const useUpdateItemBillingDetails = () => {
  const queryClient = useQueryClient()
  const { toastSuccess, toastFail } = useResolveToast()
  return useMutation(updateItemBillingDetails, {
    onSuccess: () => {
      toastSuccess('Item billing details saved successfully')
      queryClient.invalidateQueries(['quote-items'])
    },
    onError: () => {
      toastFail('Failed to update item billing details')
    }
  })
}
