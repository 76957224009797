import { FormControl, FormLabel, Input } from '@chakra-ui/react'

export const AutotaskIdInput = ({ required, register, name }) => {
  return (
    <FormControl isRequired={required} mb='1rem'>
      <FormLabel>Autotask ID</FormLabel>
      <Input {...register(name, { required })} />
    </FormControl>
  )
}
