import { axiosClient } from "lib/axiosClient";
import { useQuery } from "react-query";

const getTeam = async (context) => {
  const { signal, queryKey } = context
  const [_key, teamId] = queryKey
  const { data: team } = await axiosClient({
    url: `/settings/teams/${teamId}`,
    signal
  })
  return team
}

export const useGetTeam = ({ teamId, onSuccess }) => {
  return useQuery(['settings-team', teamId], {
    queryFn: getTeam,
    placeholderData: { label: '', value: '' },
    onSuccess
  })
}