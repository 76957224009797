import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"

const deleteItem = async ({ itemId }) => {
  await axiosClient({ 
    url: `/quotes/templates/item/${itemId}`,
    method: 'delete'
  })
}

export const useDeleteTemplateItem = ({ templateId }) => {
  const queryClient = useQueryClient()
  const history = useNavigate()
  const { toastFail, toastSuccess } = useResolveToast()
  return useMutation(deleteItem, { 
    onSuccess: () => {
      toastSuccess('Item deleted successfully')
      queryClient.invalidateQueries('template-items')
      history(`/templates/${templateId}`)
    },
    onError: () => {
      toastFail('Failed to delete item')
    }
  })
}

